import React, { useRef, useState } from "react";
import { usePairs } from '../../../query-hooks/order-query-hooks/useOrder';
import { useAuth } from '../../../Hooks/useAuth'
import styles from "../OrderForm.module.scss"

export const PairsHandler = ({setSelectedPair}) => {
    const [filteredPairs, setFilteredPairs] = useState()
    const [pairs, setPairs] = useState("")
    const allPairs = usePairs(pairs)
    const { darkmode } = useAuth()
    const ref = useRef(null)

    const filterPairsQuery = (query) => {
        setPairs(query)
        setSelectedPair(query.toUpperCase())
        let filteredPairsArray = []
        filteredPairsArray = allPairs.data && allPairs.data.items.filter(pair => pair.pairString.toLowerCase().indexOf(query.toLowerCase()) === 0 )
        query.length === 0 ? setFilteredPairs([]) : setFilteredPairs(filteredPairsArray)
    }

    const handlePairSelected = (pairSelected) => {
        // setValue("pair", pairSelected.pairString);
        setSelectedPair(pairSelected.pairString)
        setFilteredPairs([])
        ref.current.value = pairSelected.pairString;
    }

  return (
    <div>
      <div>
        <input className={styles.fundFormInputs} ref={ref} placeholder="Type Coin Pair" onChange={(e) => filterPairsQuery(e.target.value)} style={{textTransform:"uppercase"}} />
      </div>
      <ul className={darkmode ? styles.listFrameDark : styles.listFrame}>
        {filteredPairs && filteredPairs.map((pair, i) => (
            <li key={i} className={darkmode ? styles.listItemsDark : styles.listItems} value={pairs} onClick={() => handlePairSelected(pair)}>
              {pair.pairString}
            </li>
          ))}
      </ul>
    </div>
  );
};
