import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WalletBalanceForm from "./WalletBalanceForm";
import { useWalletBalance, usePostWalletBalance, useEditWalletBalance } from "../../../query-hooks/wallet-query-hook/useWallet"
import FormComponent from "../../../Components/FormComponent";

const WalletBalanceFormMutation = () => {
    const navigate = useNavigate();
    const { address, balanceId } = useParams()
    const walletBalance = useWalletBalance(address, balanceId)
    const createWalletBalance = usePostWalletBalance()
    const editWalletBalance = useEditWalletBalance();
    const onSubmit = data => {
        if (balanceId) {
            data.address = address
            return editWalletBalance.mutate( {walletBalance: data, address: address, balanceId: balanceId} )
        } 
        data.address = address
        return createWalletBalance.mutate({ walletBalance: data, address: address  })
    } 
    useEffect(() => { editWalletBalance.isSuccess && navigate(-1) }, [editWalletBalance])
    useEffect(() => { createWalletBalance.isSuccess && navigate(-1) }, [createWalletBalance])
    
    return (
        <FormComponent title="Wallet Balances Dashboard" subtitle={balanceId ? "Edit Wallet Balance" : "Create Wallet Balance"}>
            { !balanceId && <WalletBalanceForm onSubmit={onSubmit} /> }
            { walletBalance.isSuccess && <WalletBalanceForm onSubmit={onSubmit} data={walletBalance.data}  /> }
        </FormComponent>
    );
};
export default WalletBalanceFormMutation;