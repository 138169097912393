import React, { useState, useEffect } from 'react'
import { useAuth } from "../../Hooks/useAuth";
import styles from "./NavigationBar.module.scss";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { useUserRole } from '../../query-hooks/user-query-hooks/useUser';
import Notifications from '../../Components/Notifications/Notifications';
import useSidebarItems from './useSidebarItems';
// import Logo from "../images/logo-pinegreen.png";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import SidebarDisplay from './SidebarDisplay';

const NavigationBar = () => {
  const { logout, user, darkmode } = useAuth();
  const userRoles = useUserRole(user.token)
  const [ filteredSidebarItems, setFilteredSidebarItems ] = useState([])
  const [ displaySidebar, setDisplaySidebar ] = useState(true)
  const sidebarItems = useSidebarItems()
  const iconStyle = {color: "#6D9E93", fontSize: "25px", fontWeight: "200", padding: "0 3% 0 15%"}
  const iconStyleOnToggle = {color: "#6D9E93", fontSize: "25px", fontWeight: "200", padding: "0 3% 0 6%"}
  
  useEffect(() => { // Filters menu by user Roles
    if (userRoles.isSuccess) {
      const roles = userRoles.data.length < 1 ? [{ normalizedName: "NO_ROLE" }] : userRoles.data 
      const filter = sidebarItems.filter(item => item.allowedRoles.some(x => roles.some(role => role.normalizedName === x))) 
      setFilteredSidebarItems(filter)
    } 
  }, [userRoles.data]) 

  const fullURL =  window.location.href
  return (
    /* Sidebar disappears when screen is MD or lower*/
    <div className={darkmode ? styles.mainBackgroundDark : styles.mainBackground}>
      <Row className={styles.main}>
        <div>
          <div className={`${darkmode ? styles.sidebarDark : styles.sidebar} d-none d-xxl-block`} style={!displaySidebar ? {width: "4%"} : {}}>
            { displaySidebar ? 
              <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "1rem"}}>
                {/* <img className="my-4" src={Logo} width="25%" alt="Hodl" /> */}
                { fullURL.includes("localhost") && <h5 className={styles.devEnvAlert}>DEVELOPMENT</h5> }
                { fullURL.includes("test") && <h5 className={styles.devEnvAlert}>TEST ENV</h5> }
              </div> : <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "1rem"}}></div>
            }

            {/* Sidebar Menu Items Display on Full Mode and Toggle Mode   */}
            { filteredSidebarItems.map(item => <SidebarDisplay item={item} key={item.id} displaySidebar={displaySidebar} />) }
          </div>
          <button onClick={() => setDisplaySidebar(!displaySidebar)} className={`${darkmode ? styles.sidebarButtonDark : styles.sidebarButton} d-none d-xxl-block`} style={!displaySidebar ? {left: "3%"} : {left: "12%"}}>  
            { displaySidebar ? <MdKeyboardArrowLeft className={styles.toggleIcon} /> : <MdKeyboardArrowRight className={styles.toggleIcon} /> }
          </button>
        </div>
        {/* // Navbar with toggle */}
        <Col style={{padding: "1rem", color: "white"}}>
          <Navbar collapseOnSelect expand="xxl" variant={darkmode ? "dark" : "light"} style={{backgroundColor: "transparent", paddingBottom: "3rem"}} >
            <Navbar.Brand className={styles.mainIcon} href="/main/dashboard" style={!displaySidebar ? iconStyleOnToggle : iconStyle}><span style={{color:"#A5C6AC"}}>HODL</span> TradingDesk</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className={styles.navbarToggle} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className={`${styles.navLinks} me-auto d-sm-block d-xxl-none`}> 
                { filteredSidebarItems.map(item => ( <Nav.Link key={item.id} href={item.to}>{ item.title }</Nav.Link> )) }
                <Nav.Link onClick={logout}>Logout</Nav.Link>
                <Nav.Link className={styles.navEmail}>{user.email}</Nav.Link>         
              </Nav>
              {/* // Horizontal Navbar */}
              <Nav className={styles.navBarLinks}> 
                <Notifications />
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className={styles.outletStyle} style={!displaySidebar ? {padding: "0 3% 0 6%"} : {}}>
            <Outlet />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default NavigationBar;
