import React from 'react'
import { useForm } from "react-hook-form";
import DashboardComponent from "../../../Components/DashboardComponent"
import styles from "./Settings.module.scss"
import { Row, Col } from "react-bootstrap"
import { useAuth } from '../../../Hooks/useAuth'
import { useResetPassword, useUserRole } from '../../../query-hooks/user-query-hooks/useUser';
import MFAuth from './MFAuth/MFAuth';
import CollapsibleComponent from '../../../Components/CollapsibleComponent';
import { BsFillPersonFill } from "react-icons/bs";

const Settings = () => {
    const { handleDarkmode, darkmode, user } = useAuth()
    const userRole = useUserRole(user.token)

    // Handle form data
    const {register, reset, handleSubmit, formState: { errors } } = useForm();
    const resetPassword = useResetPassword()
    const onSubmit = data => {
        resetPassword.mutate({ data: data, token: user.token });
        reset()
    } 

    return (
        <DashboardComponent title="User Preferences" subtitle="User Preferences">
            <CollapsibleComponent title="User Roles">
                <Row className="my-5">
                    {   userRole.isSuccess && userRole.data.map(role => 
                            <Col xl={4} key={role.name}>
                                <div className={styles.roleCard}>
                                    <p><BsFillPersonFill className={styles.icon} />{ role.name }</p>
                                </div>
                            </Col> 
                        ) 
                    }
                </Row>
            </CollapsibleComponent>
            {/* Select Dark or Light Mode  */}
            <CollapsibleComponent title="Dark / Light Themes">
                <Row className={styles.featureContainer}>
                    <Col xl={4}>
                        <p className={styles.subTitle}>Interface Themes</p>
                        <p className={styles.text}>Select a customized UI theme</p>
                    </Col>
                    <Col xl={4}>
                        <p className={styles.subTitle}>Dark</p>
                        <div onClick={!darkmode ? handleDarkmode : undefined} className={styles.themeImageDark}></div>
                    </Col>
                    <Col xl={4}>
                        <p className={styles.subTitle}>Light</p>
                        <div onClick={darkmode ? handleDarkmode : undefined} className={styles.themeImageLight}></div>
                    </Col>
                </Row>
            </CollapsibleComponent>

            {/* // Reset Password Form */}
            <CollapsibleComponent title="New Password">
                <Row className={styles.featureContainer}>
                    <Col xl={4}>
                        <p className={styles.subTitle}>Create a New Password</p>
                        <p className={styles.text}>To reset your current password, please enter the Current and New Password</p>
                    </Col>
                    <Col xl={5}>
                        <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <Row>
                                <Col lg={12} xs={12} md={12}>
                                    <input className={styles.input} type="password" {...register("oldPassword", { required: true })} placeholder="Enter Current Password" />
                                    {errors.oldPassword && (<p className={styles.formErrorNotice}>This field is required</p>)}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} xs={12} md={12}>
                                    <input className={styles.input} type="password" {...register("newPassword", { required: true })} placeholder="Enter New Password" />
                                    {errors.newPassword && (<p className={styles.formErrorNotice}>This field is required</p>)}

                                    <div className={styles.conditionsList}>
                                        <p style={{margin: "0"}}>be at least 8 characters</p>
                                        <p style={{margin: "0"}}>have at least one non alphanumeric character</p>
                                        <p style={{margin: "0"}}>have at least one digit ('0'-'9')</p>
                                        <p style={{margin: "0"}}>have at least one uppercase ('A'-'Z')</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} xs={12}>
                                    <button className={styles.registerButton} type="submit">
                                        Register
                                    </button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </CollapsibleComponent>

            {/* // Two Factor Authentication  Selection */}
            <MFAuth />
        </DashboardComponent>
    )
}
export default Settings