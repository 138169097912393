import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from "react-bootstrap"
import DashboardComponent from '../../../../Components/DashboardComponent'
import styles from "../../OrderForm.module.scss"
import { useForm } from "react-hook-form";
import { useAuth } from '../../../../Hooks/useAuth';
import { useBlockchainTransactions, useImportBlockchainTransactions, useImportBlockchainTransactionsToOrder } from '../../../../query-hooks/order-query-hooks/useOrder'
import OrderBlockchainList from './OrderBlockchainList';
import { ErrorLoading, Spinner, today, yearFromNow } from '../../../../Helpers/Helpers';
import { BiImport } from "react-icons/bi";
import { useWalletsImport } from '../../../../query-hooks/wallet-query-hook/useWallet';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import TransactionsCombiner from './TransactionsCombiner';

const OrderBlockchainImport = () => {
    const { darkmode } = useAuth()
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [ requestBody, setRequestBody ] = useState({walletAddress: undefined, startTime: undefined, endTime: undefined})
    const blockchainTransactions = useBlockchainTransactions(requestBody.walletAddress, requestBody.startTime, requestBody.endTime)
    const onSubmit = data => setRequestBody(data);
    const walletsForImport = useWalletsImport()
    const navigate = useNavigate()

    // Router Hooks
    const { orderId, walletAddressParams } = useParams()
    const { state } = useLocation()

    // Handler to manage Transactions selected on checkbox and Import them
    const importBlockchainTransactions = useImportBlockchainTransactions()
    const [ importList, setImportList ] = useState([])
    const handleImportList = (checked, transactionId) => !checked ? setImportList(importList.filter(order => order !== transactionId)) : setImportList([...importList, transactionId])
    
    useEffect(() => { 
      if (importBlockchainTransactions.isSuccess) {
        setImportList([])
        setTimeout(() => {
          navigate(`/main/orders/trades/${importBlockchainTransactions.data.id}`)
        }, [1500])
      }
    }, [importBlockchainTransactions.isSuccess])

    // Handler to manage transaction selected in checkbox and Import them to existing Order
    const importBlockchainTransactionsToOrder = useImportBlockchainTransactionsToOrder()

    useEffect(() => { 
      if (importBlockchainTransactionsToOrder.isSuccess) {
        setImportList([])
        setTimeout(() => {
          navigate(`/main/orders/trades/${orderId}`)
        }, [1500])
      }
    }, [importBlockchainTransactionsToOrder.isSuccess])

    const watchWalletInput = watch("walletAddress") // variable listens to walletAddress input to dynamically display clear input button

    // When navigating from AllTrades component, the Order Wallet Address is sent by params. If this happens, will set it in the input value and start date
    useEffect(() => {
      if (walletAddressParams) {
        setValue("walletAddress", walletAddressParams)
      }
    }, [ walletAddressParams ])

    // Also the start date is sent on the link state as props to set the default value if necessary
    useEffect(() => {
      if (state && state.startDateParams) {
        setValue("startTime", state.startDateParams)
      }
    }, [ state ])
    
    return (
        <DashboardComponent title="Orders Dashboard" subtitle="Import Orders from Blockchain">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xxl={4} xl={12} className='mb-5'>
                  <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Wallet Address<em className={styles.reqSymbol}>*</em></h6>
                  <input list="data" autoComplete='off' disabled={walletAddressParams} className={styles.fundFormInputs} {...register("walletAddress", { required: true })} placeholder='Wallet Address'/>
                  <datalist id="data">
                    { walletsForImport.isSuccess && walletsForImport.data.map(wallet => <option key={wallet.address} value={wallet.address}>{ wallet.address }</option>) }
                  </datalist>
                  { (watchWalletInput && !walletAddressParams) && 
                    <button 
                      className={styles.removeButton} 
                      onClick={e => {
                        e.preventDefault()
                        setValue("walletAddress", "")
                        setRequestBody({ walletAddress: undefined, startTime: undefined, endTime: undefined })
                        setImportList([])
                      }} 
                    >x</button> 
                  }
                  { errors.walletAddress && errors.walletAddress.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>) }
                </Col>
                <Col xxl={2} xl={12} className='mb-5'>
                  <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Start Date<em className={styles.reqSymbol}>*</em></h6>
                  <input className={styles.datetime} type="date" name="start-time" defaultValue={yearFromNow()} {...register("startTime")}/>
                </Col>
                <Col xxl={2} xl={12} className='mb-5'>
                  <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>End Date<em className={styles.reqSymbol}>*</em></h6>
                  <input className={styles.datetime} type="date" name="end-time" defaultValue={today()} {...register("endTime")}/>
                </Col>
                <Col xxl={2} xl={6} className='mb-5 mx-auto'>
                  <button className={styles.blockchainSubmitButton} type="submit">Get Transactions</button>
                </Col>
                {  blockchainTransactions.isSuccess && <TransactionsCombiner requestBody={requestBody} orderId={orderId} /> }
                { importList.length > 0 &&      
                    <Col xxl={2} xl={6} className='mb-5'>
                      <button 
                        className={styles.blockchainSubmitButton} 
                        style={{background:"#6D9E93"}}
                        onClick={() => orderId ? 
                          importBlockchainTransactionsToOrder.mutate({ 
                            payload: importList, 
                            walletAddress: requestBody.walletAddress, 
                            startTime: requestBody.startTime, 
                            endTime: requestBody.endTime,
                            orderId: orderId
                          }) : importBlockchainTransactions.mutate({ 
                            payload: importList, 
                            walletAddress: requestBody.walletAddress, 
                            startTime: requestBody.startTime, 
                            endTime: requestBody.endTime,
                        })}>Import <BiImport style={{fontSize:"20px"}} />
                      </button>
                    </Col>
                }
            </Row>
          </Form>
          { blockchainTransactions.isSuccess && <OrderBlockchainList data={blockchainTransactions.data} handleImportList={handleImportList} /> }
          { blockchainTransactions.isLoading && <Spinner /> }
          { blockchainTransactions.isError && <ErrorLoading /> }
        </DashboardComponent>
    )
}
export default OrderBlockchainImport