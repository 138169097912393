import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";
import styles from "./AllFunds.module.scss";
import { useNavigate } from "react-router-dom";
import PaginationAPI from '../../Components/PaginationAPI';
import { useDeleteFund, confirmationAlertDeleteFund } from '../../query-hooks/fund-query-hooks/useFund'
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

const AllFunds = ({ funds, setPage }) => {
  const [currentItems, setCurrentItems] = useState();
  const getCurrentItems = updatedItems => setCurrentItems(updatedItems)
  const mutation = useDeleteFund()
  const navigate = useNavigate()
  const navigateToFundPage = fundId => navigate(`/main/funds/fund-detail/${fundId}`)
  
  const handleEditFund = (e, fundId) => {
    e.stopPropagation()
    navigate(`/main/funds/edit-fund/${fundId}`)
  }

  return (
    <>
      <Row>
        {currentItems &&
          currentItems.map(fund => (
              <Col xl={3} md={6} xs={12} key={fund.id}>
                <div className={styles.headerCardDark} onClick={() => navigateToFundPage(fund.id)}>
                  <Row>
                    <Col xl={8}>
                      <h1>{ fund.fundName }</h1>
                      <h2>{ fund.fundOwner.name }</h2>
                    </Col>
                    <Col xl={4}>
                      <p className={styles.detailButtonDark}>→</p>
                    </Col>
                    <div style={{display:"block", width:"12rem", margin:"auto", position:"absolute", bottom:"1rem"}}>
                      <div style={{display:"inline-block", margin:"0 .5rem"}} onClick={e => handleEditFund(e, fund.id)}>
                        <AiOutlineEdit className={styles.icons} style={{fontSize:"18px"}}  />
                      </div>
                      <div style={{display:"inline-block", margin:"0 .5rem"}} onClick={(e) => confirmationAlertDeleteFund(e, fund.id, mutation)}>
                        <AiOutlineDelete className={styles.icons} style={{fontSize:"18px"}} />
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
          ))
        }
      </Row>
      <PaginationAPI data={funds.isSuccess && funds.data} getCurrentItems={getCurrentItems} setPage={setPage} />
    </>
  )
}
export default AllFunds