import React, { useState, useEffect, useRef } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import styles from "./OrderForm.module.scss";
import { Link } from 'react-router-dom';
import { useAuth } from "../../Hooks/useAuth";
import { useExchangeAccountsSelect } from "../../query-hooks/exchange-query-hooks/useExchange"
import useCryptos from "../../query-hooks/crypto-query-hooks/useCryptos";
import useFunds from '../../query-hooks/fund-query-hooks/useFund';
import { usePairs } from '../../query-hooks/order-query-hooks/useOrder';
import { ORDERDIRECTION, ORDERSTATES } from '../../constants/enums';
import useWallets from "../../query-hooks/wallet-query-hook/useWallet"
import OrderSlider from './OrderSlider';
import FormGroupInputs from '../../Components/FormInputs/FormGroupInputs';
import SelectInput from '../../Components/FormInputs/SelectInput';
import TextInput from '../../Components/FormInputs/TextInput';
import ModalComponent from '../../Components/ModalComponent';
import CryptoForm from '../Cryptos/CryptoForm';

const OrderForm = ({onSubmit, data}) => {
    const { darkmode } = useAuth();
    const allExchangeAccounts = useExchangeAccountsSelect()
    const ref = useRef(null)
    const allCryptos = useCryptos()
    const allFunds = useFunds()
    const [filteredPairs, setFilteredPairs] = useState()
    const [pairs, setPairs] = useState("")
    const allPairs = usePairs(pairs)
    const allWallets = useWallets()
    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const [ unitPriceTicker, setUnitPriceTicker ] = useState()
    const [ amountPriceTicker, setAmountPriceTicker ] = useState()
    const [ openCryptoFormModal, setOpenCryptoFormModal ] = useState(false)

    useEffect(() => {
        if (data && allCryptos.isSuccess && allWallets.isSuccess && ORDERDIRECTION && ORDERSTATES) {
            reset({ 
                orderNumber: data.orderNumber, 
                amount: data.amount, 
                datetime: data.datetime, 
                unitPrice: data.unitPrice, 
                orderFundings: data.orderFundings,
                quoteAssetId: data.quoteAsset.id,
                baseAssetId: data.baseAsset.id, 
                walletAddress: data.walletAddress,
                exchangeAccountId: data.exchangeAccount?.id,
                direction: data.direction,
                state: data.state,
                type: data.type
            });
        } 
    }, [data, allCryptos.data, allWallets.data, ORDERDIRECTION]);
    
    const filterPairsQuery = (query) => {
        setPairs(query)
        let filteredPairsArray = []
        filteredPairsArray = allPairs.data && allPairs.data.items.filter(pair => pair.pairString.toLowerCase().indexOf(query.toLowerCase()) === 0 )
        query.length === 0 ? setFilteredPairs([]) : setFilteredPairs(filteredPairsArray)
    }

    const handlePairSelected = (pairSelected) => {
        setValue("quoteAssetId", pairSelected.quoteAsset.id)
        setValue("baseAssetId", pairSelected.baseAsset.id)
        setUnitPriceTicker(pairSelected.quoteAsset.symbol)
        setAmountPriceTicker(pairSelected.baseAsset.symbol)
        setFilteredPairs([])
        ref.current.value = '';
    }

    const onHandleCurrencyTicker = id => {
        const unitCoin = allCryptos.data.find(crypto => crypto.id === id)
        setUnitPriceTicker(unitCoin && unitCoin.symbol)
    }

    const onHandleAssetTicker = id => {
        const assetCoin = allCryptos.data.find(crypto => crypto.id === id)
        setAmountPriceTicker(assetCoin && assetCoin.symbol)
    }

    const today = () => new Date().toISOString().slice(0, 16)

    //OrderSlider Percentages
    const [ amount, setAmount ] = useState(0)
    const [ totalFundingsFilled, setTotalFundingsFilled  ] = useState(false)
    useEffect(() => { data && setAmount(data.amount) }, [data])

    // Open Create Crypto Form Modal 
    const openCreateCryptoModalFn = e => {
        e.preventDefault()
        setOpenCryptoFormModal(true)
    }
    
    return (
    <>
    <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="d-flex justify-content-center">
            <TextInput fieldName="orderNumber" register={register} errors={errors} title="Enter an Order Number" placeHolder="Order Number (Optional)" maxLength={64} autoComplete="off" fullWidth />
        </Row>
        <Row className="d-flex justify-content-center">
            {allExchangeAccounts.isSuccess && <SelectInput title="Choose an Exchange Account" register={register} errors={errors} fieldName="exchangeAccountId" data={data} optionMessage="Select an Exchange Account (Optional)" optionsArray={allExchangeAccounts.data} keyId="id" value="id" name="name" />}
            {allWallets.isSuccess && <SelectInput title="Choose an Wallet" register={register} errors={errors} fieldName="walletAddress" data={data} optionMessage="Select a Wallet (Optional)" optionsArray={allWallets.data.items} keyId="address" value="address" name="description" />}
        </Row>
        <Row className="justify-content-center">
            <Col lg={12} md={12} className="py-1">
                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Search Pairs (Optional)</h6>
                <div> 
                    <div><input className={styles.fundFormInputs} ref={ref} placeholder="Search Pairs" onChange={e => filterPairsQuery(e.target.value)} /></div>
                    <ul className={darkmode ? styles.listFrameDark : styles.listFrame}>
                        {filteredPairs && filteredPairs.map((pair, i) => (
                            <li key={i} className={darkmode ? styles.listItemsDark : styles.listItems} value={pair.baseAsset.id} 
                                onClick={() => handlePairSelected(pair)} >{pair.pairString}</li>
                        ))} 
                    </ul>
                </div> 
            </Col>
        </Row>
        <Row className="d-flex justify-content-center">
            {allCryptos.isSuccess && <SelectInput title="Base Asset" register={register} errors={errors} fieldName="baseAssetId" optionMessage="" optionsArray={allCryptos.data} keyId="id" value="id" name="name" onChangeFunction={onHandleAssetTicker} isRequired={true} buttonFn={openCreateCryptoModalFn} /> }
            {allCryptos.isSuccess && <SelectInput title="Quote Asset" register={register} errors={errors} fieldName="quoteAssetId" optionMessage="" optionsArray={allCryptos.data} keyId="id" value="id" name="name" onChangeFunction={onHandleCurrencyTicker} isRequired={true} buttonFn={openCreateCryptoModalFn} />}
        </Row>
        <Row className="d-flex justify-content-center">
            { ORDERDIRECTION && <SelectInput title="Select a direction" register={register} errors={errors} fieldName="direction" optionMessage="" optionsArray={ORDERDIRECTION} keyId="value" value="value" name="name" isRequired={true} /> }
            <Col lg={6} md={12} className="py-1">
                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter an Order Type (Optional)</h6>
                <input className={styles.fundFormInputs} type="text" name="type" list="type" autoComplete="off" placeholder="Enter an Order Type (Optional)" {...register("type")} /> 
                <datalist className={styles.selectInput} id="type"> 
                    <option value="SPOT"></option>
                    <option value="STOP LOSS"></option>
                </datalist>
            </Col>
        </Row>
        { data && 
            <Row className="d-flex justify-content-center">
               <SelectInput title="Select a state" register={register} errors={errors} fieldName="state" optionMessage="" optionsArray={ORDERSTATES} keyId="value" value="value" name="name" isRequired={true} fullWidth /> 
            </Row>
        }
        <Row className="d-flex justify-content-center">
            <FormGroupInputs title="Enter a Unit Price" fieldName="unitPrice" register={register} errors={errors} inputText={data ? data.quoteAsset.symbol : unitPriceTicker} placeHolder="Unit Price" isRequired={true} />
            <FormGroupInputs title="Enter Amount" fieldName="amount" register={register} errors={errors} inputText={data ? data.baseAsset.symbol : amountPriceTicker} placeHolder="Enter Amount" isRequired={true} setAmount={setAmount} />
        </Row>
        <Row className="justify-content-center">
            <Col lg={12} md={12}>
                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Date and Time <em className={styles.reqSymbol}>*</em></h6>
                <input className={styles.datetime} type="datetime-local" defaultValue={data ? data.dateTime.slice(0,16) : today()} min="2018-06-07T00:00" max="2025-06-14T00:00" {...register("dateTime", { required: true })} />
            </Col>
        </Row>
        <Row className="d-flex justify-content-center mt-2">
            <Col lg={12} md={12}>
                <Row>
                    { allFunds.data && allFunds.data.items.map((item, index) => 
                        <Col key={item.id} md={12 / allFunds.data.items.length} >
                            <div className={totalFundingsFilled ? styles.orderSliderContainerFilled : styles.orderSliderContainerNotFilled}>
                                <OrderSlider fund={item} fieldNameId={`orderFundings.${index}.fundId`} fieldNameAmount={`orderFundings.${index}.orderAmount`} register={register} amount={amount} setValue={setValue} watch={watch} totalFundingsFilled={totalFundingsFilled} setTotalFundingsFilled={setTotalFundingsFilled} amountPriceTicker={amountPriceTicker} data={data} />
                            </div>
                        </Col>
                    )} 
                </Row>
                { allFunds.isError && <h3 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Error loading the funds</h3> }
            </Col>
        </Row>
        <Row className="d-flex justify-content-center mt-3">
            <Col lg={6} md={12}>
                <input className={styles.submitButton} disabled={!totalFundingsFilled} type="submit" />
            </Col>
        </Row>
        <Row className="d-flex text-center mt-2 mb-5">
            <Link className={styles.backLink} to={-1}>Back</Link>
        </Row>
    </Form>
    {/* Modal to display dynamic Crypto Form */}
    { openCryptoFormModal && <ModalComponent stateTrigger={setOpenCryptoFormModal}><CryptoForm closeModalMode={setOpenCryptoFormModal} /></ModalComponent> } 
    </>
  )
}
export  default OrderForm