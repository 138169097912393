import React, { useState } from "react";
import useCurrencies from "../../query-hooks/currency-query-hooks/useCurrency.js";
import AllCurrencies from "./AllCurrencies.js";
import DashboardComponent from "../../Components/DashboardComponent.js";
import { ErrorLoading, Spinner } from "../../Helpers/Helpers"
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "../Cryptos/CryptoForm.module.scss"
import ImportRatesModal from "./Rates/ImportRatesModal.js";

const CurrenciesDashboard = () => {
  const currencies = useCurrencies(); 
  const [ importInput, setImportInput ] = useState(false)

  return (
    <DashboardComponent title="Currencies Dashboard" subtitle="Currencies Dashboard">
      <Row>
        <Col xl={8} className={styles.dashboardButtonsWrapper}>
          <button className={styles.createCryptoButton}>
            <Link className={styles.linktoCreateCrypto} to="/main/currencies/create-currency">
              Create Currency
            </Link> 
          </button>
          <button className={styles.importListingsButton} onClick={() => setImportInput(!importInput)}>
            Import Excel Rates Data
          </button> 
        </Col>
        { importInput && <ImportRatesModal setImportInput={setImportInput} /> }
      </Row>
      {currencies.isLoading && <Spinner /> }
      {currencies.isError && <ErrorLoading />}
      {currencies.isSuccess && <AllCurrencies currencies={currencies.data} /> }
    </DashboardComponent>
  );
}
export default CurrenciesDashboard;
