import React, { useEffect, useState, useMemo } from 'react';
import {useDropzone} from 'react-dropzone';
import styles from "./ImportListingsModal.module.scss"
import { Col, Row } from 'react-bootstrap';
import { MdOutlineUpload } from "react-icons/md";

const Previews = ({ getFilesHandler }) => {
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png'],
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            getFilesHandler(acceptedFiles)
        }
    });

    // Drag n Drop container styling
    const baseStyle = { borderStyle: 'dotted', borderWidth: "3px", borderColor:"#858585", padding: "1.5rem", borderRadius: '9px' };
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragAccept ? { borderColor: '#A5C6AC' } : {}),
        ...(isDragReject ? { borderColor: '#CD5C5C' } : {}),
    }), [ isDragAccept, isDragReject ]);

    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    useEffect(() => { return () => files.forEach(file => URL.revokeObjectURL(file.preview)); }, []);

    return (
        <>
            <section>
                <div {...getRootProps({ style }) } className={styles.dragDropContainer}>
                    <input {...getInputProps()}  />
                    <MdOutlineUpload className={styles.uploadIcon} />
                    <p>Drag 'n' drop some files here, or Click to select files</p>
                </div>
            </section>
            {   files.length > 0 && 
                    <Row className='mt-3'>
                        {
                            files.map(file => (
                                <Col xl={3} md={4} xs={6} className={styles.thumb} key={file.name}>
                                    <div>
                                        <img
                                            src={file.preview}
                                            onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                            width="100%"
                                        />
                                        <p className={styles.filePath}> {file.path}</p>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
            }
        </>
    );
}

export default Previews;