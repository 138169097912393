import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom'
import styles from "./TradeForm.module.scss";
import useCryptos from '../../../query-hooks/crypto-query-hooks/useCryptos';
import { useOrder } from "../../../query-hooks/order-query-hooks/useOrder"

const TradeForm = ({ onSubmit }) => {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
    const cryptos = useCryptos()
    const { orderId } = useParams()
    const order = useOrder(orderId)
    const [ executedToFillOrder, setExecutedToFillOrder ] = useState()
    useEffect(() => { // Effect to update select input
        const timer = order.isSuccess && setTimeout(() => {
            setValue("unitPrice", order.data.unitPrice)
            setValue("feeCurrencyId", order.data.quoteAsset.id)
        }, [500] )
        return () => clearTimeout(timer) 
    }, [order]);

    const fillOrderAmount = async e => { // Function to calculate amount to fill the order after clicking button
        e.preventDefault()
        const fillAmount = await order.data.amount - order.data.executed
        reset({ executed: fillAmount })
        setExecutedToFillOrder(fillAmount)
    }

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="justify-content-center">
                    <Col lg={8} md={12} className="py-1">
                        <h6 className={styles.inputLabel}>Enter a Transaction ID</h6>
                        <input className={styles.fundFormInputs} placeholder="Transaction ID (Optional)" {...register("transactionId")} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={8} md={12} className="py-1">
                        <h6 className={styles.inputLabel}>Select a Date and Time <em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.datetime} type="datetime-local" defaultValue={order.data && order.data.dateTime.slice(0,16)} min="2018-06-07T00:00" max="2025-06-14T00:00" {...register("dateTime", { required: true })} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={4} md={12} className="py-1">
                        <h6 className={styles.inputLabel}>Enter a Unit Price <em className={styles.reqSymbol}>*</em></h6>
                        <InputGroup className="mb-3" size="md">
                            <Form.Control style={{borderRadius: "15px 0 0 15px", height:"3rem"}} className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Enter a Unit Price" {...register("unitPrice", { required: true, valueAsNumber: true })} />
                            <InputGroup.Text style={{borderRadius: "0 15px 15px 0", height:"3rem"}} className={styles.fundFormInputs}>{order.data && order.data.quoteAsset.symbol}</InputGroup.Text>
                        </InputGroup>
                        {errors.unitPrice && errors.unitPrice.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    </Col>
                    <Col lg={4} md={12} className="py-1">
                        <h6 className={styles.inputLabel}>Executed <em className={styles.reqSymbol}>*</em></h6>
                        <InputGroup className="mb-3" size="md">
                            <Form.Control style={{borderRadius: "15px 0 0 15px", height:"3rem"}} className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Executed" {...register("executed", { required: true, valueAsNumber: true })} />
                            { order.isSuccess && <button className={styles.editButton} onClick={ e => fillOrderAmount(e) }>Fill Order</button> }
                            <InputGroup.Text style={{borderRadius: "0 15px 15px 0", height:"3rem"}} className={styles.fundFormInputs}>{order.data && order.data.baseAsset.symbol}</InputGroup.Text>
                        </InputGroup>
                        { executedToFillOrder === 0 && <h5 className={styles.formErrorNotice}>This Order is fulfilled</h5> }
                        {errors.executed && errors.executed.type === "required" && (<span className={styles.formErrorNotice}>This field is required. If the Order is fulfilled, just fill with a 0</span>)}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={4} md={12} className="py-1">
                        <h6 className={styles.inputLabel}>Fee Currency <em className={styles.reqSymbol}>*</em></h6>
                        <select className={styles.selectInput} {...register("feeCurrencyId", { required: true })}> 
                            {cryptos.data && cryptos.data.map(crypto => (
                                <option key={crypto.id} value={crypto.id}>{crypto.name}</option>
                                ))} 
                        </select>
                    </Col>
                    <Col lg={4} md={12} className="py-1">
                        <h6 className={styles.inputLabel}>Fee <em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.fundFormInputs} type="number" onWheel={ e => e.currentTarget.blur() } step="any" placeholder="Fee" {...register("fee", { required: true, valueAsNumber: true })} />
                        {errors.fee && errors.fee.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
                <Row className="d-flex text-center mt-2 mb-5">
                    <Link className={styles.backLink} to={-1}>Back</Link>
                </Row>
            </Form>
        </>
    )
}
export default TradeForm