import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL, LOGIN_API_URL } from '../../settings'
import { AlertFunction, simpleSuccessfulMessage, simpleErrorMessage } from "../../Helpers/Helpers";

// Function to get current user data
const fetchUser = (userToken) =>
  axios.get(`${LOGIN_API_URL}/user`, 
    {  headers: {
          "accept": "text/plain",
          "Authorization": userToken
        } 
    },
  ).then(res => res.data);

export function useUser(userToken) {
  return useQuery(["user", userToken ], () => fetchUser(userToken), { enabled: userToken !== undefined });
}

// Function to get all users
const fetchUsers = (userToken) =>
  axios.get(`${LOGIN_API_URL}/user/list?itemsPerPage=1000`, 
    {  headers: {
          "accept": "text/plain",
          "Authorization": userToken
        } 
    },
    ).then((response) => response.data);

export function useUsers(userToken) {
  return useQuery(["users", userToken ], () => fetchUsers(userToken));
}

// Function to get all user Roles
const fetchUserRoles = (userToken) =>
  axios.get(`${LOGIN_API_URL}/roles/all`,
    {  headers: {
          "accept": "text/plain",
          "Authorization": userToken
        } 
    },
    ).then((response) => response.data);

export function useUserRoles(userToken) {
  return useQuery(["userRoles", userToken ], () => fetchUserRoles(userToken));
}

// Function to get the current user Roles
const fetchUserRole = (userToken) =>
  axios.get(`${LOGIN_API_URL}/roles`, 
    {  headers: {
          "accept": "text/plain",
          "Authorization": userToken
        } 
    },
    ).then((response) => response.data);

export function useUserRole(userToken) {
  return useQuery(["userRole", userToken ], () => fetchUserRole(userToken));
}

// Function to filter Users by role
const filterUsersByRole = async (userToken, chosenRole) =>
  await axios.get(`${LOGIN_API_URL}/user/list`, 
    {  headers: {
          "accept": "text/plain",
          "Authorization": userToken
        } 
    },
    ).then((response) => response.data.items.filter(role => role.roles.some(r => r === chosenRole ) ));

export function useFilterUserByRole(userToken, chosenRole) {
  return useQuery(["users", userToken, chosenRole ], () => filterUsersByRole(userToken, chosenRole));
}

// Function to check if role exists
const checkRole = queryRole => axios.get(`${LOGIN_API_URL}/roles`).then(res => res.data.some(user => user.normalizedName === queryRole.toUpperCase() ))
export function useCheckRole(queryRole) {
  return useQuery(["userRole", queryRole ], () => checkRole(queryRole));
}

// Function to fetch Changelogs
const getChangelogs = page => axios.get(`${API_URL}/admin/changelog`, { params: { page: page }}).then(res => res.data)
export const useGetChangelogs = page => useQuery(["changelogs", page], () => getChangelogs(page))


// MUTATIONS

//Function to create or delete a User Role
const mutateUserRole = async (action, userId, role, user) => {
  await axios({ 
    method: action, 
    url: `${LOGIN_API_URL}/roles/${userId}/${role}`,
    data: {},
    headers: { "accept": "*/*", "Authorization": user.token } 
  }).then(response => response.data)
} 
export function useMutateUserRole() {
  const queryClient = useQueryClient()
  return useMutation(({ action, userId, role, user }) => mutateUserRole(action, userId, role, user), {
      onSuccess: () => {
        queryClient.invalidateQueries("users")
        queryClient.invalidateQueries("user")
        queryClient.invalidateQueries("userRole")
      } 
  });
}

// Function to reset user's password
const resetPassword = async (data, token) => {
  return await axios.post(`${LOGIN_API_URL}/user/update/password`, data, 
    {  headers: {
      "accept": "*/*",
      "Authorization": token
    } 
  },).then(res => res.data)
}

export function useResetPassword() {
  return useMutation(({data, token}) => resetPassword(data, token), { onSuccess: () => simpleSuccessfulMessage("Password Reset Successful") , onError: error => AlertFunction(error) });
}

// Function to activate MFAuth
const activateMFA = data => axios.post(`${LOGIN_API_URL}/user/mfa/activate`, data).then(res => res.data)
export function useActivateMFA() {
  const queryClient = useQueryClient()
  return useMutation((data) => activateMFA(data), 
    { 
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries("user")
        }, [1500])
      } 
      , onError: error => AlertFunction(error) });
}

// Function to verify MFAuth
const verifyMFA = async (data, token) => {
  return await axios.post(`${LOGIN_API_URL}/auth/mfa/verify`, data, 
    {  headers: {
      "accept": "*/*",
      "Authorization": token
    } 
  },).then(res => res.data)
}

export function useVerifyMFA() {
  const queryClient = useQueryClient()
  return useMutation(({data, token}) => verifyMFA(data, token), 
    { 
      onSuccess: () => {
        queryClient.invalidateQueries("user")
      } 
      , onError: error => AlertFunction(error) });
}

// Function to verify GoogleAuth Token
const checkGoogleOAuthToken = async (credential) => await axios.post(`${LOGIN_API_URL}/auth/oauth`, credential).then(res => res.data)

export function useCheckGoogleOAuthToken() {
  const queryClient = useQueryClient()
  return useMutation((credential) => checkGoogleOAuthToken(credential), 
    { 
      onSuccess: () => {
        queryClient.invalidateQueries("user")
        simpleSuccessfulMessage("Logged in with your Google Account") 
      } 
      , onError: error => simpleErrorMessage("Not possible to log in") 
    });
}

// Function to delete user
const deleteUser = userId => axios.delete(`${LOGIN_API_URL}/user/${userId}`).then(res => res.data)
export function useDeleteUser() {
  const queryClient = useQueryClient()
  return useMutation((userId) => deleteUser(userId), 
    { 
      onSuccess: () => {
        queryClient.invalidateQueries("users")
        simpleSuccessfulMessage("User removed from database succesfully") 
      } 
      , onError: error => simpleErrorMessage("Not possible to delete user") 
    });
}


// Function to lockout user by its ID
const lockoutUser = userId => axios.get(`${LOGIN_API_URL}/user/${userId}/lockout`).then(res => res.data)
export function useLockoutUser() {
  const queryClient = useQueryClient()
  return useMutation((userId) => lockoutUser(userId), { onSuccess: () => queryClient.invalidateQueries("users") })
}


// Function to lockout user by its ID
const resetLockoutUser = userId => axios.get(`${LOGIN_API_URL}/user/${userId}/resetlockout`).then(res => res.data)
export function useResetLockoutUser() {
  const queryClient = useQueryClient()
  return useMutation((userId) => resetLockoutUser(userId), { onSuccess: () => queryClient.invalidateQueries("users") })
}

// Function to Disable own MFA when logged in from Settings interface
const disableMFAuth = () => axios.delete(`${LOGIN_API_URL}/user/mfa`).then(res => res.data)
export function useDisableMFAuth() {
  const queryClient = useQueryClient()
  return useMutation((email) => disableMFAuth(email), { onSuccess: () => {
      queryClient.invalidateQueries("user") 
      simpleSuccessfulMessage("Two Factor Authentication has been disabled")
    }, onError: () => simpleErrorMessage("The server has encountered a problem disabling MFA")
  })
}


// Function to enable own MFA when logged in from Settings interface
export const enableMFA = () => axios.get(`${LOGIN_API_URL}/user/mfa/enable`).then(res => res.data);
export function useEnableMFA() {
  const queryClient = useQueryClient()
  return useMutation(() => enableMFA(), { onSuccess: () => {
      queryClient.invalidateQueries("user")
    } 
  });
}

// Function to enable MFA users account as Admin role from Users Dashboard 
export const disableAdminMFA = (userId) => axios.delete(`${LOGIN_API_URL}/user/mfa/${userId}`).then(res => res.data);
export function useDisableAdminMFA() {
  const queryClient = useQueryClient()
  return useMutation((userId) => disableAdminMFA(userId), { onSuccess: () => {
      queryClient.invalidateQueries("users")
      simpleSuccessfulMessage("User MFA has been disabled")
    } 
  });
}

// Function to Disable Two Factor authentication when logged out
const disableMFA = email => axios.get(`${LOGIN_API_URL}/auth/mfa/disable/${email}`).then(res => res.data);
export function useDisableMFA() {
  return useMutation((email) => disableMFA(email), 
    { onSuccess: () =>  simpleSuccessfulMessage("Please check your email to retrieve the token code") }
  );
}

// Function to recover MFA Account
const recoverMFA = data => axios.put(`${LOGIN_API_URL}/auth/mfa/disable/recovery`, data).then(res => res.data);
export function useRecoveryMFA() {
  return useMutation(data => recoverMFA(data))
}

// API STATUS
// Function to get API status
const getAPIStatus = () => axios.get(`${API_URL}/admin/system/state`).then(res => res.data);
export const useGetAPIStatus = () =>  useQuery("APIState", getAPIStatus);

// Function to get app info
const getAPPInfo = () => axios.get(`${LOGIN_API_URL}/app/info`).then(res => res.data);
export const useGetAPPInfo = () =>  useQuery("APPInfo", getAPPInfo);

// Function to get app Stats
const getAPPStats = () => axios.get(`${LOGIN_API_URL}/app/stats`).then(res => res.data);
export const useGetAPPStats = () =>  useQuery("APPStats", getAPPStats);
