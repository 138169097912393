import React, { useEffect, useState } from 'react'
import { useCreateTransfer } from '../../query-hooks/transfer-query-hooks/useTransfer'
import styles from "./FundDetail.module.scss"
import { numberEditor } from '../../Helpers/Helpers'

const ComparerModalCorrection = ({ holding, parseMoneyString }) => {
    const [ displayButton, setDisplayButton ] = useState(false)
    const createTransfer = useCreateTransfer()

    // Effect to evaluate if button is displayed in case difference of values is not 0
    useEffect(() => {
        if (holding) {
            const excelAmount = parseMoneyString(holding.pair.amount)
            const endBalance = holding.endBalance
            if (excelAmount - endBalance !== 0) {
                return setDisplayButton(true)
            } 
            setDisplayButton(false)
        }
    }, [holding.endBalance])

    // Function to correct EndBalance / Excel Amount through a transfer
    const balanceCorrectionTransfer = holding => {
        const excelAmount = parseMoneyString(holding.pair.amount)
        const endBalance = holding.endBalance
        const transferAmount = excelAmount - endBalance
        createTransfer.mutate({
            fromHoldingId: holding.id,
            transactionType: "Correction",
            dateTime: new Date().toISOString(),
            feeHoldingId: holding.id,
            transactionId: "-",
            fromAddress: "-",
            transferAmount: transferAmount,
            transferFee: 0,
            shares: 0,
            reference: "-"
        })
    }

    if (displayButton) {
        return (
            <div className={styles.tooltip}>
                <button 
                    className={styles.editButtonDark} 
                    onClick={() => balanceCorrectionTransfer(holding)}>Balance Correction 
                </button>
                <div className={styles.tooltiptextComparerTable}>
                    <h5 style={{fontSize:"12px", color:"#858585", fontWeight:"900", margin:"0.3rem 0.2rem"}}>Holding: 
                        <span style={{color: "#A5C6AC", margin:"0.3rem 0.2rem", fontWeight:"100"}}>
                            { holding.displayName }
                        </span>
                    </h5>
                    <h5 style={{fontSize:"12px", color:"#858585", fontWeight:"900", margin:"0.3rem 0.2rem"}}>Amount: 
                        <span 
                            style={ 
                                parseMoneyString(holding.pair.amount) - holding.endBalance > 0 ? {color: "#A5C6AC", margin:"0.3rem 0.2rem"} : {color: "#CD5C5C", margin:"0.3rem 0.2rem"}
                            }
                        > 
                            { numberEditor(parseMoneyString(holding.pair.amount) - holding.endBalance) }
                        </span>
                    </h5>
                </div>
            </div>
        )  
    }
    
}
export default ComparerModalCorrection;