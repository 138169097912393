import React, { useState } from 'react'
import PieChartCoins from "./Charts/PieChartCoins";
import styles from "./ChartsDashboard.module.scss"
import { useAuth } from "../../Hooks/useAuth";

const CoinsPieChartHandler = ({holdings}) => {
    const [ coinRankPieChart, setCoinRankPieChart ] = useState(5)
    const { darkmode } = useAuth()
    return (
        <>
            <h5 className={styles.chartTitle}>Coin Distribution</h5>
            <select className={ darkmode ? styles.selectInputDark : styles.selectInput } onChange={e => setCoinRankPieChart(e.target.value)} defaultValue="intro">
                <option value="intro" disabled="disabled">Display Number of Coins:</option>
                <option value={2}>3</option>
                <option value={4}>5</option>
                <option value={6}>7</option>
                <option value={8}>9</option>
                <option value={10}>10</option>
            </select>
            <PieChartCoins data={holdings} dataKey="endPercentage" nameKey="displayName" outerRadius={160} minHeight={600} height={500} topRank={coinRankPieChart} />
        </>
    )
}
export default CoinsPieChartHandler