import React from 'react'
import { Table } from 'react-bootstrap'
import { bookingPerioddateFormat, numberEditor, dateFormat } from '../../Helpers/Helpers'
import { useAuth } from '../../Hooks/useAuth'
import styles from "./Reports.module.scss"
import { downloadScreenshots } from "../../query-hooks/report-query-hooks/useReport"

const ReportAssetPricings = ({ report, reportingCurrencyCode, bookingPeriod }) => {
    const { darkmode } = useAuth()

    const tagHolding = report.holdings.find((holding) => holding.priceSource.startsWith('NAV'))
    const downloadTag = tagHolding ? tagHolding.priceSource : bookingPeriod

    return (
        <div className={styles.navCard}>
            <h5 className={styles.title}>Asset Pricings</h5>
            <div>
                <div className={styles.navTags}>
                    <h5>Main <span>{ bookingPerioddateFormat(bookingPeriod) }</span></h5>
                    <button className={styles.pdfButton} onClick={() => downloadScreenshots(downloadTag) }>Download Screenshots</button>
                </div>
                <Table responsive borderless={true} className={styles.table}>
                    <thead>
                        <tr className={darkmode ? styles.theaderDark : styles.theader}>
                            <th>Asset</th>
                            <th>{ reportingCurrencyCode } Price</th>
                            <th>USD Price</th>
                            <th>BTC Price</th>
                            <th>Date Time</th>
                            <th>Source</th>
                        </tr>
                    </thead>
                    { report.holdings && report.holdings.map((holding) => (
                        <tbody key={holding.id} >
                            <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                <td>{ holding.symbol }</td>
                                <td title={ holding.endUSDPrice / report.nav.currencyRate.usdRate }>{ numberEditor(holding.endUSDPrice / report.nav.currencyRate.usdRate) }</td>
                                <td title={ holding.endUSDPrice }>{ numberEditor(holding.endUSDPrice) } </td>
                                <td title={ holding.navBalance }>{ numberEditor(holding.endBTCPrice) }</td>
                                <td title={ holding.startDateTime }>{ dateFormat(holding.priceDateTime) }</td>
                                <td title={ holding.priceSource }>{ holding.priceSource } </td>
                            </tr>
                        </tbody>
                        ))
                    }
                </Table>
            </div>
        </div>
    )
}
export default ReportAssetPricings