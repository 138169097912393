import React from 'react'
import { Table, Row, Col } from 'react-bootstrap';
import styles from "../../Funds/FundDetail.module.scss"
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";
import { numberEditor, timestampFormatter } from '../../../Helpers/Helpers';
import { BiImport, BiCheck } from "react-icons/bi";

const ExplorersTable = ({ explorers, formData, setOpenWindow, handleImportExplorer }) => {

    const handleImport = data => {
        setOpenWindow(false)
        handleImportExplorer({ explorer: data, queryParams: formData })
    }

    return (
        <Row className="d-flex justify-content-center mt-5">
            <Col xl={12}>
                <Table borderless={true} responsive>
                    <thead className={styles.thead}>
                        <tr className={styles.theader}>
                            <th>Date</th>
                            <th></th>
                            <th>Transfer Amount</th> 
                            <th>Fee</th>
                            <th>Transaction Id</th>
                            <th>Import</th>  
                        </tr>
                    </thead>
                    { explorers.map((explorer, i) => (
                        <tbody key={i}>
                            <tr className={styles.tableStripsDark}>
                                <td>{ timestampFormatter(explorer.dateTime) }</td>
                                <td>{ explorer.direction === "In" ?
                                    <div className={styles.tooltip}>
                                        <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "30px"}} /> 
                                        <span className={styles.tooltiptext}>{explorer.direction}</span>
                                    </div> 
                                        : 
                                    <div className={styles.tooltip}>
                                        <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "30px"}} />
                                        <span className={styles.tooltiptext}>{explorer.direction}</span>
                                    </div>
                                    }
                                </td> 
                                <td>{ numberEditor(explorer.transferAmount) } { explorer.asset.symbol }</td>
                                <td>{ numberEditor(explorer.transferFee) } { explorer.feeCurrency.symbol }</td>                       
                                <td><a href={explorer.transactionUrl} target="_blank" style={{color:"#6D9E93"}}>{ explorer.transactionId }</a></td>
                                <td>{ explorer.isImported ? 
                                        <div className={styles.tooltip}>
                                            <BiCheck style={{color: "#6D9E93", fontSize: "25px"}} /> 
                                            <span className={styles.tooltiptext}>Imported</span>
                                        </div> : <BiImport title="Import" className={styles.icons} onClick={() => handleImport(explorer)}  /> 
                                    }
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </Table>
            </Col>
        </Row>
    )
}
export default ExplorersTable;