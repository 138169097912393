import React from 'react'
import { Table } from 'react-bootstrap'
import { numberEditor } from '../../../Helpers/Helpers'
import { useAuth } from '../../../Hooks/useAuth'
import styles from "./Tables.module.scss"
import EmptyMessageComponent from "../../../Components/EmptyMessageComponent"

const DashboardFundStaking = ({ staking }) => {
    const { darkmode } = useAuth()

    if (staking.length < 1) {
        return <EmptyMessageComponent message="No existing Staking" /> 
    }
    
    return (
        <>
            <h2 className={styles.tableTitle}>Staking</h2>
            <Table responsive className="mt-4" borderless={true}>
                <thead>
                <tr className={styles.theader}>
                    <th>Coin</th>
                    <th>Start USD Price</th> 
                    <th>Start Balance</th>
                    <th>Staking Rewards</th>
                    <th>Reward Percentage</th>
                </tr>
                </thead>
                { staking && staking.map((staking) => (
                    <tbody key={staking.id} >
                        <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                            <td>{staking.displayName}</td>
                            <td>{numberEditor(staking.startUSDPrice)}</td>
                            <td>{numberEditor(staking.startBalance)}</td>
                            <td>{numberEditor(staking.stakingRewards)}</td>
                            <td>{numberEditor(staking.rewardPercentage)}</td>
                        </tr>
                    </tbody>
                ))
                }
            </Table>
        </>
    )
}
export default DashboardFundStaking