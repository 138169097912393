import React, { useState } from "react";
import styles from "../styles/Login.module.scss";
import { useForm } from "react-hook-form";
import Logo from "../images/logo1.png";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import ResetPasswordInputToken from "./ResetPasswordInputToken";
import { Link } from "react-router-dom";
import { LOGIN_API_URL } from "../settings";

const ResetPassword = () => {
  const [tokenRequested, setTokenRequested] = useState(false);
  const [email, setEmail] = useState("");
  const { register, handleSubmit, formState: { errors }, } = useForm();

  const onSubmit = async (data) => {
    setEmail(data.email);
    await axios.get(`${LOGIN_API_URL}/auth/password/${data.email}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => setTokenRequested(true))
      .catch((error) => console.log(error));
  };

  if (!tokenRequested)
    return (
      <main>
        <div>
          <Container className="d-flex justify-content-center">
            <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Row>
                <Col className="d-flex justify-content-center">
                  <img className="mb-5" src={Logo} alt="Hodl" />
                </Col>
                <Col lg={12} xs={12} md={12}>
                  <input type="email" {...register("email", { required: true })}  placeholder="Enter your Email" />
                  {errors.email && (<p className={styles.formErrorNotice}>This field is required</p>)}
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12}>
                  <button className={styles.registerButton} type="submit">
                    Reset the Password
                  </button>
                </Col>
              </Row>
              <Row>
                <Col className={styles.linkDiv} style={{ marginTop: "1rem" }}>
                  <Link className={styles.link} to="/">
                    Home
                  </Link>
                </Col>
              </Row>
            </form>
          </Container>
        </div>
      </main>
    );

  return <ResetPasswordInputToken email={email} />;
};
export default ResetPassword;
