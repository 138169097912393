import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { bookingPerioddateFormat, numberEditor } from "../../Helpers/Helpers";
import { useAuth } from "../../Hooks/useAuth";
import styles from "./Reports.module.scss";
import ReportFeePerformanceClasses from "./ReportFeePerformanceClasses";

const ReportFeePerformance = ({ report, bookingPeriod }) => {
    const { darkmode } = useAuth();

    if (!report.inflowFees || report.inflowFees.length < 1) {
        return
    }

    return (
        <div className={styles.navCard}>
            <Row>
                <Col xl={3}>
                    <h5 className={styles.title}>
                        Performance Fee Overview
                    </h5>
                </Col>
                <Col xl={3}>
                    <p className={styles.tradingLogsTitle} title={report.nav.managementFee}>Management Fee: {numberEditor(report.nav.managementFee)}</p>
                </Col>
            </Row>
            <div className={styles.navTags}>
                <h5>Main <span>{ bookingPerioddateFormat(bookingPeriod) }</span></h5>
            </div>
            <Table responsive className="mt-5" borderless={true}>
                <thead >
                    <tr className={styles.theader} data-toggle="collapse" data-target=".multi-collapse1" aria-controls="multiCollapseExample1">
                        <th>Inflow Period</th>
                        <th>Shares In</th>
                        <th>Shares Out</th>
                        <th>HWM</th>
                        <th>Fee / Share</th>
                        <th>Fee (EUR)</th>
                    </tr>
                </thead>
                <tbody>
                    {   report.inflowFees.map((inflowFee, i) => (
                        <tr key={i} className={darkmode ? styles.tableStripsDark : styles.tableStrips}>
                            <td>{bookingPerioddateFormat(inflowFee.bookingPeriod)}</td>
                            <td title={inflowFee.sharesIn}>{ numberEditor(inflowFee.sharesIn)} </td>
                            <td title={inflowFee.sharesOut}>{numberEditor(inflowFee.sharesOut)}</td>
                            <td title={inflowFee.hwm}>{numberEditor(inflowFee.hwm)}</td>
                            <td title={inflowFee.performanceFeePerShare}>{numberEditor(inflowFee.performanceFeePerShare)}</td>
                            <td title={inflowFee.performanceFee}>{numberEditor(inflowFee.performanceFee)}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips } style={{fontWeight: "900", color: "#6D9E93"}}>
                        <th colSpan="5">Total :</th>
                        <td title={report.nav.performanceFee}>{ numberEditor(report.nav.performanceFee) }</td>
                        <td></td>
                    </tr>
                </tfoot>
            </Table>
            {
                ( report.classes.length > 0 && report.classes.map(fundClass => <ReportFeePerformanceClasses key={fundClass.id} fundClass={fundClass} />  ))
            }
        </div>
    );
}
export default ReportFeePerformance;