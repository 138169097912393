import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useAuth } from "../Hooks/useAuth"
import styles from "./Pagination.module.scss";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const Pagination = ({ items, getCurrentItems, itemsPerPage, offsetReset }) => {
  const { darkmode } = useAuth() || {}
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  
  useEffect(() => {   // React paginate workflow for dynamic pagination
    const endOffset = itemOffset + itemsPerPage;
    items && getCurrentItems(items.slice(itemOffset, endOffset));
    items && setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };
  
  useEffect(() => { setItemOffset(0) }, [offsetReset]) // Effect that sets itemOffset in 0 only at search input change - forces to start pagination from beginning

  if (pageCount === 1) {   
    return null
  }
  return (
    <div className={styles.paginationParentContainer}>
      <Container className="d-flex justify-content-center mt-5">
        <ReactPaginate
          breakLabel="..."
          nextLabel={ <FaArrowRight /> }
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={ <FaArrowLeft /> }
          pageClassName={`${styles.paginationContainer}`}
          pageLinkClassName={darkmode ? styles.pageLinkDark : styles.pageLink }
          previousClassName={darkmode ? styles.prevStyleDark : styles.prevStyle }
          previousLinkClassName={darkmode ? styles.prevStyleDark : styles.prevStyle }
          nextClassName={darkmode ? styles.prevStyleDark : styles.prevStyle }
          nextLinkClassName={darkmode ? styles.prevStyleDark : styles.prevStyle }
          breakClassName="page-item"
          breakLinkClassName={styles.breakLink}
          containerClassName="pagination"
          subContainerClassName={"pages pagination"}
          activeClassName={styles.activePage}
          activeLinkClassName="text-white"
          renderOnZeroPageCount={null}
        />
      </Container>
    </div>
  );
};
export default Pagination;
