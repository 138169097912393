import React, { useState, useEffect, useRef } from 'react'
import { Table, Row, Col } from "react-bootstrap";
import styles from "./AllCategories.module.scss";
import Pagination from '../../Components/Pagination';
import { useAuth } from "../../Hooks/useAuth";
import useCryptos from "../../query-hooks/crypto-query-hooks/useCryptos"
import { useAddCryptoToCategory, useDeleteCategoryCrypto, confirmationAlertDeleteCategoryCrypto } from '../../query-hooks/category-query-hooks/useCategories';
import EmptyMessageComponent from '../../Components/EmptyMessageComponent';

const AllCategoryCryptos = ({items, categoryId}) => {
    const { darkmode } = useAuth();
    const [currentItems, setCurrentItems] = useState();
    const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
    const input = useRef()
    const cryptos = useCryptos()
    const addCryptoToCategory = useAddCryptoToCategory()
    const deleteCategoryCrypto = useDeleteCategoryCrypto()
    useEffect(() => { addCryptoToCategory.isSuccess && (input.current.selectedIndex = 0) }, [addCryptoToCategory]) // Side effect to reset select input when crypto is added
    useEffect(() => { addCryptoToCategory.isError && (input.current.selectedIndex = 0) }, [addCryptoToCategory]) // Side effect to reset select input if cypto is already added

    return (
        <>
            <Row>
                <Col lg={4} md={12} className="py-3">
                    <select className={styles.selectInput} ref={input} onChange={e => addCryptoToCategory.mutate({categoryId: categoryId, cryptoId: e.target.value})}>
                        <option value={1}>Add a cryptocurrency to this category</option> 
                        {cryptos.isSuccess && cryptos.data.map(crypto => (
                            <option key={crypto.id} value={crypto.id}>{crypto.name}</option>
                        ))} 
                    </select>
                </Col>
            </Row>
            {   items.length < 1 ?  (
                <EmptyMessageComponent message="No Cryptocurrencies linked to this Category" />
                )  :
                (<>
                    <Table responsive borderless={true}>
                        <thead>
                            <tr className={darkmode ? styles.theaderDark : styles.theader}>
                                <th></th>
                                <th>Name</th>
                                <th>Symbol</th>
                                <th></th>
                            </tr>
                        </thead>
                        {currentItems &&
                            currentItems.map((crypto) => (
                                <tbody key={crypto.id}>
                                <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                                    <td></td>
                                    <td style={{textAlign: "left", width: "2%"}}>{crypto.name}</td>
                                    <td >{crypto.symbol.toUpperCase()}</td>
                                    <td><button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteCategoryCrypto(e, categoryId, crypto.id, deleteCategoryCrypto)}>Delete</button></td>
                                </tr>
                            </tbody>
                            ))
                        }
                    </Table>
                    <Pagination items={items} getCurrentItems={getCurrentItems} itemsPerPage={10} />
                </>) 
            }
        </>
    )
}
export default AllCategoryCryptos