import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import styles from "./PercentageBar.module.scss";
import { useEditOrderFundings } from "../../../query-hooks/order-query-hooks/useOrder";
import PercentageBar from "./PercentageBar";

const PercentageBarComponent = ({ order, orderId, orderFundings, setDisplayEditButton}) => {
    const [percentages, setPercentages] = useState();
    const [fullPercentageFulfilled, setFullPercentageFulfilled] = useState();
    const editOrderFundings = useEditOrderFundings();

    const handlePercentageChange = (fundId, value) => {
        setPercentages((prevPercentages) => [
            ...prevPercentages.map(obj => {
                if (obj.fundId === fundId) {
                    return {
                        ...obj,
                        orderPercentage: value,
                        orderAmount: (value * order.amount) / 100,
                    };
                }
                return obj;
            }),
        ]);
    };

    useEffect(() => {
        // Effect to set Percentage state according to the Funds React Query async values
        orderFundings &&
        setPercentages(
            orderFundings.map(orderFunding => ({
            fundId: orderFunding.fundId || orderFunding.id,
            orderPercentage: orderFunding.orderPercentage || 0,
            orderAmount: orderFunding.orderAmount || 0,
            name: orderFunding.fundName,
            }))
        );
    }, [orderFundings]);

    useEffect(() => {
        // Effect to count the total percentages to activate if fullPercentageFulfilled state 100% is reached
        const totalPercentage = percentages && percentages.reduce((acc, curr) => curr.orderPercentage + acc, 0);
        totalPercentage === 100 ? setFullPercentageFulfilled(true) : setFullPercentageFulfilled(false);
    }, [percentages]);

    useEffect(() => { // Effect that Displays Edit button when fundings stored in DB is succesful
        editOrderFundings.isSuccess && setDisplayEditButton(true)
    }, [editOrderFundings, setDisplayEditButton])

    // Function that sends orderFundings to Edit Order Hook
    const onHandleSaveFundings = () => {
        const filter = percentages.map((fund) => ({
            fundId: fund.fundId,
            orderPercentage: fund.orderPercentage,
            orderAmount: fund.orderAmount,
        }));
        return editOrderFundings.mutate({ orderFundings: filter, orderId: order.id ? order.id : orderId });
    };

    return (
        <>
            <tr>
                <td colSpan="14">
                    <Row className={styles.barContainer}>
                        { percentages && percentages.map(percentage => (
                            <PercentageBar 
                                key={percentage.fundId}
                                percentage={percentage} 
                                handlePercentageChange={handlePercentageChange} 
                                fullPercentageFulfilled={fullPercentageFulfilled}
                                orderFundings={orderFundings}
                            /> 
                        ))}
                    </Row>
                </td>
            </tr>
            <tr>
                <td>
                    <button
                        disabled={!fullPercentageFulfilled}
                        onClick={() => onHandleSaveFundings()}
                        className={fullPercentageFulfilled ? styles.editButton : styles.editButtonDisabled}
                    >
                        Save Fundings
                    </button>
                    <button
                        onClick={() => setDisplayEditButton(true)}
                        className={styles.editButton}
                    >
                        Cancel
                    </button>
                </td>
            </tr>
        </>
    );
};
export default PercentageBarComponent;
