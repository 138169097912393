import React from 'react'
import { Link } from "react-router-dom";
import styles from "../AllExchanges.module.scss";
import { Table } from "react-bootstrap";
import { confirmationAlertDeleteExchangeAccount, useDeleteExchangeAccount } from '../../../query-hooks/exchange-query-hooks/useExchange';
import { useAuth } from "../../../Hooks/useAuth";

const AllExchangeAccounts = ({ exchangeAccounts, exchangeId }) => {
  const { darkmode } = useAuth()
  const mutation = useDeleteExchangeAccount()
  if (exchangeAccounts.length > 0) {
    return (
      <div>
      <Table responsive borderless={true} >
        <thead>
          <tr className={`${darkmode ? styles.theaderDark : styles.theader} align-middle text-center`}>
            <th>Account Name</th>
            <th>Account Key</th>
            <th>Parent Account</th>
            <th>Active</th>
          </tr>
        </thead>
        {  exchangeAccounts.map((account) => 
            <tbody key={account.id}>
              <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle text-center`}>
                <td>{account.name}</td>
                <td>{account.accountKey}</td>
                <td>{account.parentAccount}</td>
                <td>
                  <label className={styles.checkbox}>
                    <input type="checkbox" disabled readonly defaultChecked={account.isActive} />
                  </label>
                </td>
                <td><Link className={styles.editButton} to={`/main/exchanges/edit-exchange-account/${account.id}/${exchangeId}`} >Edit</Link></td>
                <td><button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteExchangeAccount(e, account.id, mutation)}>Delete</button></td>
              </tr>
            </tbody> 
          )
        }
      </Table>
    </div>
    )
  }
  return <h5 className={darkmode ? styles.noListingDark : styles.noListing}>No Exchange Accounts created</h5>;
};
export default AllExchangeAccounts