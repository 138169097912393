import React, { useState, useEffect } from 'react'
import ModalComponent from "../ModalComponent"
import { useGetAttachments, usePostAttachment } from '../../query-hooks/order-query-hooks/useOrder';
import AttachmentsTable from './AttachmentsTable';
import styles from "./Attachments.module.scss"
import Logo from "../../images/logo-pinegreen.png"
import { MdOutlineAttachFile } from "react-icons/md";

const FileAttachment = ({ attachments, table, recordId }) => {
    const [ file, setFile ] = useState()
    const [ desc, setDesc ] = useState("")
    const [ imageIcon, setImageIcon ] = useState(Logo)
    const [ openModal, setOpenModal ] = useState(false)
    const postAttachment = usePostAttachment()
    const getAttachments = useGetAttachments(table, recordId)

    useEffect(() => { setFile() }, [ recordId ]) // Effect to set File as undefined when the modal is closed and opened again so it restarts if upload file was uncomplete

    // Function to Upload File
    const handleUpload = () => postAttachment.mutate({ table, recordId, payload: { description: desc, files: file } })
    useEffect(() => { postAttachment.isSuccess && setFile()  }, [postAttachment.isSuccess])
    
    // Function to convert file from input into Base64
    function readURL(input) {
        if (input.files && input.files[0]) {
            setFile(input.files[0])
            const reader = new FileReader();

            reader.onloadend = function (e) {
                setImageIcon(e.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    // Handle img src error
    const handleImageError = (e) => setImageIcon(Logo)
    
    if (recordId && openModal)
        return (
            <ModalComponent stateTrigger={setOpenModal} >
                <div style={{position:"relative"}}>
                    { file ?  
                        <div style={{minHeight:"40rem"}}>
                            <div className={styles.inputContainer}>
                                <img key={Math.random()} src={ imageIcon } onError={ handleImageError } className={styles.formImg} alt="File" />            
                            </div>
                            <div className={styles.inputContainer}>
                                <textarea placeholder='Description' className={styles.textarea} onChange={e => setDesc(e.target.value)} /> 
                            </div>
                            <div className={styles.inputContainer}>
                                <button onClick={() => handleUpload()} className={styles.uploadButton}>Upload</button> 
                            </div>
                            <div className={styles.inputContainer}>
                                    <button onClick={() => setFile()} className={styles.uploadButton} style={{background:"#CD5C5C"}}>Cancel</button> 
                            </div>
                        </div> :
                        <>
                            <label className={styles.fileInput}>
                                <input type="file" accept="image/jpeg, image/jpg, image/png, application/pdf" onChange={e => readURL(e.target)} />
                                <span className={styles.iconLabel}>+</span>
                            </label>
                            { getAttachments.isSuccess && <AttachmentsTable data={ getAttachments.data } /> }
                        </>
                    }
                </div>
            </ModalComponent>
    )

    if (openModal) {
        return (
            <ModalComponent stateTrigger={setOpenModal}>
                <AttachmentsTable data={attachments} report={true} />
            </ModalComponent>
        )
    }

    if (attachments && attachments.length < 1) {
        return
    }

    if (attachments) {
        return (
            <div className={styles.clipboardContainer} onClick={() => setOpenModal(true)} title={`${attachments.length} Files`}>
                <MdOutlineAttachFile className={styles.clipboardIcon} />
                <span>{ attachments.length }</span>
            </div>
        ) 
    } 

    if (getAttachments && getAttachments.isSuccess) {
        return (
            <div className={styles.clipboardContainer} onClick={() => setOpenModal(true)} title={`${getAttachments.data.length} Files`}>
                <MdOutlineAttachFile  className={styles.clipboardIcon} />
                <span>{ getAttachments.data.length }</span>
            </div>
        ) 
    }
}

export default FileAttachment