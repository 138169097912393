import React, { useState, useEffect, useRef } from 'react'
import { useSearchCryptos } from '../../../query-hooks/crypto-query-hooks/useCryptos'
import { Row, Col } from 'react-bootstrap'
import styles from './Sandbox.module.scss'
import { useSandboxCurrencies, useAddSandboxCurrency } from '../../../query-hooks/dashboard-query-hooks/useDashboard'
import { useAuth } from '../../../Hooks/useAuth'
import { AiOutlineDelete } from "react-icons/ai";
import {  MdDragIndicator } from "react-icons/md";

const SandboxCurrencies = () => {
    const { darkmode } = useAuth()
    const [ filterQuery, setFilterQuery ] = useState()
    const [ selectedCrypto, setSelectedCrypto ] = useState()
    const cryptos = useSearchCryptos(filterQuery, 1, 1000);
    const inputRef = useRef(null)
    const sandboxCurrencies = useSandboxCurrencies()
    const addSandboxCurrency = useAddSandboxCurrency()

    useEffect(() => {
        if (selectedCrypto === "") {
            setSelectedCrypto(undefined)
        }
        setFilterQuery(selectedCrypto)
    }, [selectedCrypto])

    // Function to add a crypto 
    const onHandleSelectedCrypto = crypto => {
        const currencies = sandboxCurrencies.isSuccess ? sandboxCurrencies.data : []
        const allCryptosList = [ ...currencies, { id: crypto.id, name: crypto.name } ]
        const coinsIds = allCryptosList.map(crypto => crypto.id)
        addSandboxCurrency.mutate(coinsIds);
        setSelectedCrypto(undefined)
        inputRef.current.value = ""
    }
    // // Function to remove selected crypto from list
    const removeSelectedCrypto = cryptoId => {
        const currencies = sandboxCurrencies.isSuccess ? sandboxCurrencies.data : []
        const filteredList = currencies.filter(crypto => crypto.id !== cryptoId); 
        const coinsIds = filteredList.map(crypto => crypto.id)
        addSandboxCurrency.mutate(coinsIds);
    } 

    // Function to handle when Drop
    const onHandleDrop = e => {
        e.preventDefault()
        // Reset styling when drop item
        e.target.parentElement.style.backgroundColor = "" 
        e.target.parentElement.style.scale = ""

        // Data Handling
        const originId = e.dataTransfer.getData("OriginalId"); // Get the id set as "OriginalId" in the onDragStart event
        let listOfIds = sandboxCurrencies.isSuccess && sandboxCurrencies.data.map(curr => curr.id) // Get the array of ids from API
        const fromIndex = listOfIds.indexOf(originId) // Get the index in the array of the item being dragged
        const toIndex = listOfIds.indexOf(e.target.parentElement.id) // Get the index in the array of the item where the original is being dropped
        listOfIds.splice(fromIndex, 1) // Remove the item being dragged to avoid duplication
        listOfIds.splice(toIndex, 0, originId) // Add dragged item in the array position of the destination item
        addSandboxCurrency.mutate(listOfIds) // Mutate new array order
    }

    if (sandboxCurrencies.isError) {
        return (
            <div className={darkmode ? styles.cardTableDark : styles.cardTable} style={{padding:"2rem"}}>
                <Row>
                    <Col xl={4} className="mb-5 px-5">
                        <input placeholder="Search..." onChange={e => setSelectedCrypto(e.target.value)} ref={inputRef} className={styles.searchInput}/>
                        <ul className={styles.listUl}>
                            { cryptos.isSuccess && cryptos.data.items.map(crypto => (
                                <li
                                    key={crypto.id}
                                    onClick={() => onHandleSelectedCrypto(crypto)}
                                    className={styles.listLi}
                                >
                                    { crypto.name }
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <h1 className={styles.errorTitle}>No Currencies Found</h1>
            </div>
        )
    }

    return (
        <>
            <div className={darkmode ? styles.cardTableDark : styles.cardTable} style={{padding:"2rem"}}>
                <Row>
                    <Col xl={4} className="mb-5 px-5">
                        <input placeholder="Search..." onChange={e => setSelectedCrypto(e.target.value)} ref={inputRef} className={styles.searchInput}/>
                        <ul className={styles.listUl}>
                            { cryptos.isSuccess && cryptos.data.items.map(crypto => (
                                <li
                                    key={crypto.id}
                                    onClick={() => onHandleSelectedCrypto(crypto)}
                                    className={styles.listLi}
                                >
                                    { crypto.name }
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                {
                    sandboxCurrencies.isSuccess && sandboxCurrencies.data.map(crypto => (
                        <Row 
                            key={crypto.id} 
                            draggable={true} 
                            onDragStart={e => e.dataTransfer.setData("OriginalId", e.target.id)} 
                            id={crypto.id} 
                            onDrop={e => onHandleDrop(e)} 
                            onDragOver={e => {
                                    e.preventDefault()
                                    e.target.parentElement.className === "row" && (e.target.parentElement.style.backgroundColor = "#6D9E93");
                                    e.target.parentElement.className === "row" && (e.target.parentElement.style.borderRadius = "10px");
                                    e.target.parentElement.className === "row" && (e.target.parentElement.style.scale = "1.05");

                                }
                            }
                            onDragLeave={e =>  {
                                    e.target.parentElement.style.backgroundColor = ""
                                    e.target.parentElement.style.scale = ""
                                }
                            } 
                            className={styles.draggableDiv}
                        >
                            <Col>
                                <MdDragIndicator className={styles.dragIcon} />
                            </Col>
                            <Col>
                                <p>{ crypto.name }</p>
                            </Col>
                            <Col>
                                <p>{ crypto.symbol.toUpperCase() }</p>
                            </Col>
                            <Col>
                                <AiOutlineDelete className={styles.icons} onClick={() => removeSelectedCrypto(crypto.id)} />
                            </Col>
                        </Row>
                    ))
                }
            </div>
        </>
    )
}
export default SandboxCurrencies;