import React from 'react'
import DashboardComponent from './Components/DashboardComponent';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <DashboardComponent>
        <h2 style={{textAlign:"center", fontSize:"115px", color:"#6D9E93"}}>404</h2>
        <h3 style={{textAlign:"center", fontSize:"55px", color: "#A5C6AC"}}>Error</h3>
        <h4 style={{textAlign:"center", fontSize:"25px"}}>Page Not Found</h4>
        <Link to="/" style={{color:"#A5C6AC"}}>Home</Link>
    </DashboardComponent>
  )
}
export default NotFound;