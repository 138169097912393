import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import Swal from "sweetalert2";
import { API_URL } from "../../settings";

// Function to get all Categories
const fetchCategories = (groupQuery) => axios.get(`${API_URL}/categories?group=${groupQuery}&itemsPerPage=1000`).then(response => response.data);
export function useCategories(groupQuery) {
  return useQuery(["categories", groupQuery], () => fetchCategories(groupQuery));
}

// Function to get Category by its ID
const fetchCategory = categoryId => axios.get(`${API_URL}/categories/${categoryId}`).then(response => response.data)
export function useCategory(categoryId) {
  return useQuery(["category", categoryId], () => fetchCategory(categoryId), {enabled: categoryId !== undefined })
}

// Function to get Cryptos related to Category
const fetchCategoryCryptos = categoryId => axios.get(`${API_URL}/categories/${categoryId}/cryptos`).then(response => response.data)
export function useCategoryCryptos(categoryId) {
  return useQuery(["categoryCryptos", categoryId], () => fetchCategoryCryptos(categoryId), { enabled: categoryId !== undefined }  )
}

// Function to get all Category Groups
const fetchCategoryGroups = () => axios.get(`${API_URL}/categories/groups`).then(response => response.data)
export function useCategoryGroups() {
  return useQuery("categoryGroups", fetchCategoryGroups)
}


// MUTATIONS

//Function to post a Category
const postCategory = category => axios.post(`${API_URL}/categories`, category).then(response => response.data);

export function useCreateCategory() {
  const queryClient = useQueryClient();
  return useMutation(category => postCategory(category), 
  { onSuccess: async () => queryClient.invalidateQueries("categories"), onError: async (error) => Swal.fire(error.response.data.errors[0].description)  })
}

//Function to edit a Category
const editCategory = (category, categoryId) => axios.put(`${API_URL}/categories/${categoryId}`, category).then(response => response.data);
export function useEditCategory() {
  const queryClient = useQueryClient();
  return useMutation(({ category, categoryId }) => editCategory(category, categoryId), 
  { onSuccess: async () => queryClient.invalidateQueries("categories"), onError: async (error) => Swal.fire(error.response.data.errors[0].description)  })
}

// Function to add Crypto to Category 
const addCryptoToCategory = (categoryId, cryptoId) => axios.post(`${API_URL}/categories/${categoryId}/cryptos/${cryptoId}`).then(response => response.data)
export function useAddCryptoToCategory() {
  const queryClient = useQueryClient();
  return useMutation(({ categoryId, cryptoId }) => addCryptoToCategory(categoryId, cryptoId), 
  { onSuccess: async () => {
      queryClient.invalidateQueries("categoryCryptos")
      Swal.fire(categoryCryptoAddedMessage)
    }, onError: async (error) => Swal.fire(error.response.data.errors[0].description)  })
}

//Function to delete Category 
const deleteCategory = categoryId => axios.delete(`${API_URL}/categories/${categoryId}`).then(response => response.data)
export function useDeleteCategory() {
  const queryClient = useQueryClient()
  return useMutation(({ categoryId }) => deleteCategory(categoryId), 
    { onSuccess: async () => queryClient.invalidateQueries("categories") , onError: async (error) => Swal.fire(error.response.data.errors[0].description) }
  )
}

export const confirmationAlertDeleteCategory = (event, categoryId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteFundHoldingMessageObject).then(result => result.isConfirmed && mutation.mutate({ categoryId: categoryId }) );
};


//Function to delete Category Crypto
const deleteCategoryCrypto = (categoryId, cryptoId) => axios.delete(`${API_URL}/categories/${categoryId}/cryptos/${cryptoId}`).then(response => response.data)
export function useDeleteCategoryCrypto() {
  const queryClient = useQueryClient()
  return useMutation(({ categoryId, cryptoId }) => deleteCategoryCrypto(categoryId, cryptoId), 
    { onSuccess: async () => queryClient.invalidateQueries("categoryCryptos") , onError: async (error) => Swal.fire(error.response.data.errors[0].description) }
  )
}

// Message object inserted in delete message
const deleteFundHoldingMessageObject = {
  title: "Are you sure you want to delete this?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteCategoryCrypto = (event, categoryId, cryptoId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteFundHoldingMessageObject).then(result => result.isConfirmed && mutation.mutate({ categoryId: categoryId, cryptoId: cryptoId }) );
};

// Message object inserted in crypto added to category confirmation message
const categoryCryptoAddedMessage = {
  title: "The Cryptocurrency has been added succesfully", 
  icon: "success", 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Ok"
}
