import React, { useState } from 'react'
import ModalComponent from '../../../Components/ModalComponent';
import { useBenchmarkAllQuotes, useDeleteBenchmark, confirmationAlertDeleteBenchmark } from '../../../query-hooks/benchmark-query-hooks/useBenchmark';
import styles from "./AllBenchmarks.module.scss"
import { useAuth } from '../../../Hooks/useAuth';
import { Table } from 'react-bootstrap';
import { AiOutlineDelete, AiOutlineEdit, AiFillDatabase  } from 'react-icons/ai';
import BenchmarkFormMutation from './BenchmarkFormMutation';
import BenchmarkCandlesData from './BenchmarkCandlesData';

const AllBenchmarks = ({ setDisplayCrudModal }) => {
    const allBenchmarks = useBenchmarkAllQuotes()
    const [ displayForm, setDisplayForm ] = useState({ benchmarkId: null, display: false })
    const [ displayBenchmarkCandles, setDisplayBenchmarkCandles ] = useState({ benchmarkId: null, display: false })
    const { darkmode } = useAuth()
    const deleteBenchmark = useDeleteBenchmark()

    if (displayForm.display) {
        return (
            <ModalComponent stateTrigger={setDisplayCrudModal}>
                <BenchmarkFormMutation displayForm={displayForm} setDisplayForm={setDisplayForm} />
            </ModalComponent>
        )
    }

    if (displayBenchmarkCandles.display) {
        return (
            <ModalComponent stateTrigger={setDisplayBenchmarkCandles}>
                <BenchmarkCandlesData displayBenchmarkCandles={displayBenchmarkCandles} setDisplayBenchmarkCandles={setDisplayBenchmarkCandles} />
            </ModalComponent>
        )
    }

    if (allBenchmarks.isSuccess) {
        return (
            <ModalComponent stateTrigger={setDisplayCrudModal}>
                <div style={{padding:"7rem"}}>
                    <button 
                        className={darkmode ? styles.deleteButton : styles.createOrderButton} 
                        onClick={() => setDisplayForm({ benchmarkId: null, display: true })}
                        style={{marginBottom:"2rem"}}
                    >Add Benchmark</button>
                    <div className={styles.scrollableTable}>
                        <Table borderless={true}>
                            <thead className={styles.stickyHeader}>
                                <tr className={styles.theader}>
                                    <th>Symbol</th>
                                    <th>Name</th>
                                    <th>Data</th>
                                </tr>
                            </thead>
                            {   allBenchmarks.data.map(benchmark => (
                                <tbody key={benchmark.id}>
                                    <tr className={darkmode ? styles.tableStripsDark : styles.tableStrips}>
                                        <td>{ benchmark.symbol }</td>
                                        <td>{ benchmark.name }</td>
                                        <td>
                                            <AiFillDatabase  
                                                className={styles.icons} 
                                                style={{color:"#6D9E93"}}
                                                onClick={() => setDisplayBenchmarkCandles({ benchmarkId: benchmark.id, display: true, name: benchmark.name })} 
                                                title="Display Data" 
                                            />
                                        </td>
                                        <td>
                                            <AiOutlineEdit
                                                className={styles.icons} 
                                                style={{color:"#A5C6AC"}}
                                                onClick={() => setDisplayForm({ benchmarkId: benchmark.id, display: true })} 
                                                title="Edit"
                                            />
                                        </td>
                                        <td>
                                            <AiOutlineDelete 
                                                className={styles.icons} 
                                                style={{color:"#CD5C5C"}}
                                                onClick={e => confirmationAlertDeleteBenchmark(e, benchmark.id, deleteBenchmark)} 
                                                title="Delete" 
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </Table>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}
export default AllBenchmarks;