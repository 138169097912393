import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useWalletBalances } from "../../../query-hooks/wallet-query-hook/useWallet";
import AllWalletBalances from "./AllWalletBalances";
import DashboardComponent from "../../../Components/DashboardComponent";
import { ErrorLoading, Spinner } from "../../../Helpers/Helpers"

const WalletBalancesDashboard = () => {
  const { address } = useParams();
  const [ page, setPage ] = useState(1)
  const walletBalances = useWalletBalances(address, page);
    
  return (
    <DashboardComponent
      title="Wallets Balances Dashboard"
      subtitle="Wallet Balances Dashboard"
      toCreate={`/main/wallets/balances/create-balance/${address}`}
      buttonMessage="Create a Wallet Balance"
    >
      {walletBalances.isLoading && <Spinner /> }
      {walletBalances.isError && (<ErrorLoading />)}
      <AllWalletBalances walletBalances={walletBalances} setPage={setPage} />
    </DashboardComponent>
  );
};
export default WalletBalancesDashboard;
