import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDeleteAttachment, confirmationAlertDeleteAttachment } from '../../query-hooks/order-query-hooks/useOrder';
import DisplayImage from './DisplayImage';
import EmptyMessageComponent from '../EmptyMessageComponent';
import ImagesCarousel from './ImagesCarousel';

const AttachmentsTable = ({ data, report }) => {
    const [ selectedImageId, setSelectedImageId ] = useState()

    // Function to delete Attachment
    const deleteAttachment = useDeleteAttachment()
    const handleDeleteFile = (e, fileId) => {
        e.stopPropagation()
        confirmationAlertDeleteAttachment(e, deleteAttachment, fileId)
    } 

    if (data.length < 1) {
        return <EmptyMessageComponent message="No files stored" />
    }

    if (selectedImageId) {
        return <ImagesCarousel data={data} selectedImageIndex={data.findIndex(x => x.id === selectedImageId)} setSelectedImageId={setSelectedImageId} />
    }

    return (
        <Row style={{padding:"0 3rem"}}>
            {   data.map(file => (
                    <Col xl={3} key={file.id}>
                        <DisplayImage
                            fileId={file.id} 
                            name={file.fileName} 
                            handleDeleteFile={handleDeleteFile} 
                            report={report} 
                            setSelectedImageId={setSelectedImageId}
                        />
                    </Col>
                ))
            }
        </Row>
    )
}
export default AttachmentsTable;