import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { IoIosArrowDropdownCircle , IoIosArrowDropupCircle  } from "react-icons/io";
import styles from "./CollapsibleComponent.module.scss";
import { useAuth } from "../Hooks/useAuth"; 
import { bookingPerioddateFormat } from "../Helpers/Helpers";

const CollapsibleComponent = ({children, title, fundsCards, bookingPeriod}) => {

    const [open, setOpen] = useState(true);
    const { darkmode } = useAuth()
    
    return (
      <div className={ fundsCards && (darkmode ? styles.cardModelDark : styles.cardModel) }>
        <div>
          {open ? (
            <div className={styles.hideShowMessage}>
              <IoIosArrowDropupCircle 
                className={styles.toggleIcon}
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
              />
              <h5 className={styles.title}>{ title }</h5>
              { bookingPeriod && <span className={styles.bookingPeriodTag}>{ bookingPerioddateFormat(bookingPeriod) }</span> }
            </div>
          ) : (
            <div className={styles.hideShowMessage}>
              <IoIosArrowDropdownCircle 
                className={styles.toggleIcon}
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
              />
              <h5 className={styles.title}>{ title }</h5>
            </div>
          )}
        </div>
        <Collapse in={open}>
            <div className={styles.collapsibleContainer} id="example-collapse-text" >
                {children}
            </div>
        </Collapse>
    </div>
    )
}
export default CollapsibleComponent