import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import styles from "./Reports.module.scss"
import { numberEditor } from '../../Helpers/Helpers';
import PieChartComponent from '../Dashboard/Charts/PieChart';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ReportNAV = ({ report }) => {
    const [open, setOpen] = useState(true);

    return (
        <div className={styles.navCard}>
            <div>
                {open ? (
                    <div className={styles.hideShowMessage}>
                    <IoIosArrowUp
                        className={styles.toggleIcon}
                        onClick={() => setOpen(!open)}
                    />
                    <h5 className={styles.title}>NAV Overview</h5>
                    </div>
                ) : (
                    <div className={styles.hideShowMessage}>
                    <IoIosArrowDown
                        className={styles.toggleIcon}
                        onClick={() => setOpen(!open)}
                    />
                    <h5 className={styles.title}>NAV Overview</h5>
                    </div>
                )}
            </div>

            <Row>
                <Col xl={3} className={styles.navTags} style={{height:"300", margin:"auto"}}>
                    <div className={styles.navTags}>
                        <h4>Main</h4>
                    </div>
                    <h5><strong>GAV </strong>{ numberEditor(report.nav.gav) }</h5>
                    <h5><strong>NAV </strong>{ numberEditor(report.nav.nav) }</h5>
                    <h5><strong>Prev. NAV </strong>{ numberEditor(report.nav.previousNAV) }</h5>
                    <h5><strong>HWM </strong>{ numberEditor(report.nav.hwm) }</h5>
                    <h5><strong>Performance </strong>{ numberEditor((report.nav.nav - report.nav.previousNAV) / report.nav.previousNAV * 100) }%</h5>
                </Col>
                <Col xl={3} className={styles.navTags} style={{height:"300", margin:"auto"}}>
                    <h5><strong>Base Value </strong>{ numberEditor(report.nav.previousNAV * report.nav.totalShares) } {report.reportingCurrencyCode}</h5>
                    <h5><strong>Profit </strong>{ numberEditor(report.nav.totalValue - (report.nav.previousNAV * report.nav.totalShares)) } {report.reportingCurrencyCode}</h5>
                    <h5><strong>Management Fee </strong>{ numberEditor(report.nav.managementFee)} {report.reportingCurrencyCode}</h5>
                    <h5><strong>Performance Fee </strong>{ numberEditor(report.nav.performanceFee)} {report.reportingCurrencyCode}</h5>
                </Col>
                <Col xl={3} className={styles.navTags} style={{height:"300", margin:"auto"}}>
                    <h5><strong>Net Capital </strong>{ numberEditor(report.nav.nav * report.nav.totalShares) } {report.reportingCurrencyCode}</h5>
                    <h5><strong>Total Shares </strong>{ numberEditor(report.nav.totalShares) }</h5>
                    <h5><strong>In-Out Value </strong>{ numberEditor(report.nav.inOutValue) } {report.reportingCurrencyCode}</h5>
                    <h5><strong>In-Out Shares </strong>{ numberEditor(report.nav.inOutShares) }</h5>
                </Col>
                <Col xl={3}>
                    <PieChartComponent data={report.layers} dataKey="currentPercentage" nameKey="name" minHeight={400} height={300}/>                        
                </Col>
            </Row>
        
            {
                (report.classes.length > 0 && open) &&
                report.classes.map(fundClass => (
                        <Row key={fundClass.id}>
                            <div className={styles.navTags}>
                                <h4>{ fundClass.className }</h4>
                            </div>
                            <Col xl={3} className={styles.navTags}>
                                <h5><strong>GAV </strong>{ numberEditor(fundClass.nav.gav) }</h5>
                                <h5><strong>NAV </strong>{ numberEditor(fundClass.nav.nav) }</h5>
                                <h5><strong>Prev. NAV </strong>{ numberEditor(fundClass.nav.previousNAV) }</h5>
                                <h5><strong>HWM </strong>{ numberEditor(fundClass.nav.hwm) }</h5>
                                <h5><strong>Performance </strong>{ numberEditor((fundClass.nav.nav - fundClass.nav.previousNAV) / fundClass.nav.previousNAV * 100) }%</h5>
                            </Col>
                            <Col xl={3} className={styles.navTags}>
                                <h5><strong>Base Value </strong>{ numberEditor(fundClass.nav.previousNAV * fundClass.nav.totalShares) } {fundClass.fundClassingCurrencyCode}</h5>
                                <h5><strong>Profit </strong>{ numberEditor(fundClass.nav.totalValue - (fundClass.nav.previousNAV * fundClass.nav.totalShares)) } {fundClass.reportingCurrencyCode}</h5>
                                <h5><strong>Management Fee </strong>{ numberEditor(fundClass.nav.managementFee)} {fundClass.reportingCurrencyCode}</h5>
                                <h5><strong>Performance Fee </strong>{ numberEditor(fundClass.nav.performanceFee)} {fundClass.reportingCurrencyCode}</h5>
                            </Col>
                            <Col xl={3} className={styles.navTags}>
                                <h5><strong>Net Capital </strong>{ numberEditor(fundClass.nav.nav * fundClass.nav.totalShares) } {fundClass.reportingCurrencyCode}</h5>
                                <h5><strong>Total Shares </strong>{ numberEditor(fundClass.nav.totalShares) }</h5>
                                <h5><strong>In-Out Value </strong>{ numberEditor(fundClass.nav.inOutValue) } {fundClass.reportingCurrencyCode}</h5>
                                <h5><strong>In-Out Shares </strong>{ numberEditor(fundClass.nav.inOutShares) }</h5>
                            </Col>
                        </Row>
                ))
            }
                
            {
                (   report.classes.length > 0 && !open) &&
                        report.classes.map(fundClass => (
                            <Row key={fundClass.id}>
                                <Col xl={3} className={styles.navTags}>
                                    <h4>{ fundClass.className }</h4>
                                </Col>
                                <Col xl={3} className={styles.navTags}>
                                    <h5><strong>GAV </strong>{ numberEditor(fundClass.nav.gav) }</h5>
                                </Col>
                                <Col xl={3} className={styles.navTags}>
                                    <h5><strong>NAV </strong>{ numberEditor(fundClass.nav.nav) }</h5>
                                </Col>
                                <Col xl={3} className={styles.navTags}>
                                    <h5><strong>Total Value </strong>{ numberEditor(fundClass.nav.totalValue) } {fundClass.reportingCurrencyCode}</h5>
                                </Col>
                            </Row>
                ))
            }
        </div>
  )
}
export default ReportNAV;