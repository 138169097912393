import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import CurrencyForm from "./CurrencyForm";
import { useCurrency, useCreateCurrency, useEditCurrency, useRates } from "../../query-hooks/currency-query-hooks/useCurrency";
import FormComponent from "../../Components/FormComponent";
import { ErrorLoading, Spinner, today, yearFromNow } from "../../Helpers/Helpers";
import styles from "./CurrencyForm.module.scss"
import AllCurrencyRates from "./Rates/AllCurrencyRates";
import { Row, Col } from 'react-bootstrap'

const CurrencyFormMutation = () => {
    const navigate = useNavigate()
    const { isoCode } = useParams();
    const currency = useCurrency(isoCode)
    const createCurrency = useCreateCurrency()
    const editCurrency = useEditCurrency()
    const [ fromDate, setFromDate ] = useState(yearFromNow())
    const [ toDate, setToDate ] = useState(today())
    const [ page, setPage ] = useState(1)
    const rates = useRates(isoCode, fromDate, toDate, page)
    const onSubmit = data => isoCode ? editCurrency.mutate( { currency: data, isoCode: isoCode } ) : createCurrency.mutate(data)  
    useEffect(() => {createCurrency.isSuccess && navigate(-1)}, [createCurrency])
    useEffect(() => {editCurrency.isSuccess && navigate(-1)}, [editCurrency])
    return (
        <FormComponent title="Currency Dashboard" subtitle={isoCode ? "Edit Currency" : "Create Currency"}>
            { !isoCode && <CurrencyForm onSubmit={onSubmit} /> }
            { currency.isLoading && <Spinner /> }
            { currency.isError && <ErrorLoading /> }
            { currency.isSuccess && <CurrencyForm onSubmit={onSubmit} data={currency.data} /> }    
            { isoCode && 
                <>
                    <Link className={styles.createButton} to={`/main/currencies/create-currency-rate/${isoCode}`}>Add Rate</Link> 
                    <Row className="my-5">
                        <Col xl={6}></Col>
                        <Col xl={3}><input className={styles.datetime} type="date" onChange={e => setFromDate(e.target.value)} defaultValue={yearFromNow()} /></Col>
                        <Col xl={3}><input className={styles.datetime} type="date" onChange={e => setToDate(e.target.value)} defaultValue={today()} /></Col>
                    </Row>
                </>
            }
            <AllCurrencyRates rates={rates} setPage={setPage} /> 
        </FormComponent>
    );
};
export default CurrencyFormMutation;
