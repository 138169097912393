import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from '../../settings'
import { AlertFunction, simpleSuccessfulMessage } from "../../Helpers/Helpers";

// Function to get current all notifications
const fetchNotifications = () => axios.get(`${API_URL}/notifications/notifications`).then(res => res.data);
  export function useNotifications() {
  return useQuery(["notifications"], () => fetchNotifications());
}

// Function to get current one Notification by its ID
const fetchNotification = notificationId => axios.get(`${API_URL}/notifications/${notificationId}`).then(res => res.data);
  export function useNotification(notificationId) {
  return useQuery(["notification", notificationId], () => fetchNotification(notificationId), { enabled: notificationId !== undefined });
}
