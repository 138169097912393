import React, { useState } from 'react'
import { numberEditor } from '../../Helpers/Helpers';
import styles from "./Reports.module.scss"
import { Table } from 'react-bootstrap';
import { useAuth } from '../../Hooks/useAuth';

const ReportFundTradesClasses = ({ fundClass }) => {
    const { darkmode } = useAuth()
    const [open, setOpen] = useState(false);

    return (
        <div className={styles.conditionalContainerClasses}>
            <div>
                { open ? 
                    <p className={styles.toggleIcon} title="Show Less" onClick={() => setOpen(!open)}>-</p> 
                        : 
                    <p className={styles.toggleIcon} title="Show More" onClick={() => setOpen(!open)}>+</p> 
                }
            </div>
            <div className={styles.navTags}>
                <h5>{ fundClass.className }</h5>
            </div>
            {   open &&
                <div>
                    <Table responsive className="mt-4" borderless={true}>
                        <thead>
                            <tr className={darkmode ? styles.theaderDark : styles.theader}>
                                <th>Asset</th> 
                                <th>Start Balance</th>
                                <th>In-&-Out (Shares)</th>
                                <th>Transferred</th>
                                <th>Transaction Fees</th>
                                <th>Staking Rewards</th>
                                <th>PNL</th>
                                <th>Liabilites</th>
                                <th>NAV Balance</th>
                                <th>End Balance</th>
                            </tr>
                        </thead>
                        {   fundClass.tradeSummary.map(classTrade => (
                                <tbody key={classTrade.id} >
                                    <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                        <td>{classTrade.symbol} </td>
                                        <td title={classTrade.startBalance}>{numberEditor(classTrade.startBalance)} </td>
                                        <td title={classTrade.inOutFlow}>{numberEditor(classTrade.inOutFlow)} ({numberEditor(classTrade.inOutFlowShares)}) </td>
                                        <td title={classTrade.tradeSum}>{numberEditor(classTrade.tradeSum)} </td>
                                        <td title={classTrade.transactionFees}>{numberEditor(classTrade.transactionFees)} </td>
                                        <td title={classTrade.stakingRewards}>{numberEditor(classTrade.stakingRewards)} </td>
                                        <td title={classTrade.profitAndLoss}>{numberEditor(classTrade.profitAndLoss)}</td>
                                        <td title={classTrade.fees}>{numberEditor(classTrade.liabilities + classTrade.fundFees)} </td>
                                        <td title={classTrade.navBalance}>{numberEditor(classTrade.navBalance)} </td>
                                        <td title={classTrade.endBalance}>{numberEditor(classTrade.endBalance)} </td>
                                    </tr>
                                </tbody>
                        ))}
                    </Table>
                </div>
            }
        </div>
    )
}
export default ReportFundTradesClasses;