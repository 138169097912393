import React from "react";
import styles from "./Dashboard.module.scss";
import useDashboard from "../../query-hooks/dashboard-query-hooks/useDashboard";
import ChartsDashboard from "./ChartsDashboard";
import { Spinner } from "../../Helpers/Helpers";
const Dashboard = () => {
  const dashboardData = useDashboard()
  // dashboardData.isSuccess && console.log("mainDashboard", dashboardData.data)

  return (
    <div className={styles.mainDashboard}>
      { dashboardData.isSuccess && <ChartsDashboard data={dashboardData.data} /> }
      { dashboardData.isLoading && <Spinner /> }
    </div>
  );
};

export default Dashboard;
