import React, { useState, useEffect } from "react";
import Logo from "../../images/logo-pinegreen.png"
import styles from "./CryptoForm.module.scss";
import { useAuth } from "../../Hooks/useAuth";
import { API_URL } from "../../settings";

const CryptosImageHandler = ({ crypto_id, getImageValue, newCryptoFetched }) => {
    const [ imageIcon, setImageIcon ] = useState(Logo)
    const { darkmode } = useAuth()
    useEffect(() => { crypto_id && setImageUrl(crypto_id) }, [crypto_id]);
    useEffect(() => { newCryptoFetched && setImageUrlfromApi(newCryptoFetched.symbol) }, [newCryptoFetched]);

    // Function to convert file from input into Base64
    function readURL(input) {
        if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onloadend = function (e) {
            getImageValue(e.target.result)
            setImageIcon(e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        }
    }

    // Function to edit Crypto
    function setImageUrl(crypto_id) {
            fetch(`${API_URL}/cryptos/${crypto_id}/icon`) // Get the remote image as a Blob with the fetch API
                .then(res => res.status === 200 ? res.blob() : false)
                .then((blob) => {  // Read the Blob as DataURL using the FileReader API
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        //console.log(reader.result);
                        // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...
                        getImageValue(reader.result)
                        setImageIcon(reader.result);
                    };
                    reader.readAsDataURL(blob);
                });
    }

    // Function to assign Crypto logo from API
    function setImageUrlfromApi(cryptoSymbol) {
        fetch(`${API_URL}/cryptos/icons/${cryptoSymbol}`) // Get the remote image as a Blob with the fetch API
            .then((res) => res.blob())
            .then((blob) => {  // Read the Blob as DataURL using the FileReader API
                const reader = new FileReader();
                reader.onloadend = () => {
                    //console.log(reader.result);
                    // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...
                    getImageValue(reader.result)
                    setImageIcon(reader.result);
                };
                reader.readAsDataURL(blob);
            });
    }

    // Handle img src error
    const handleImageError = (e) => setImageIcon(Logo)

    return (
        <>
            <label className={styles.fileInput}>
                <input type="file" accept="image/*" id="fileupload" onChange={(e) => readURL(e.target)} />
                <div className={styles.cryptoLogo}>
                    <img key={Math.random()} src={ imageIcon } onError={ handleImageError } width="120px" alt="Logo" />            
                </div>
                <h6 className={ `${darkmode ? styles.inputLabelDark : styles.inputLabel} text-center` }>Add an Icon</h6>
            </label>
        </>
    )
};
export default CryptosImageHandler;
