import React, { useState } from 'react'
import { numberEditor } from '../../Helpers/Helpers';
import styles from "./Reports.module.scss"
import { Table } from 'react-bootstrap';
import { useAuth } from '../../Hooks/useAuth';
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";
import FileAttachment from '../../Components/Attachments/FileAttachment';

const ReportFundTransferLogsClasses = ({ fundClass }) => {
    const { darkmode } = useAuth()
    const [open, setOpen] = useState(false);

    return (
        <div className={styles.conditionalContainerClasses}>
            <div>
                { open ? 
                    <p className={styles.toggleIcon} title="Show Less" onClick={() => setOpen(!open)}>-</p> 
                        : 
                    <p className={styles.toggleIcon} title="Show More" onClick={() => setOpen(!open)}>+</p> 
                }
            </div>
            <div className={styles.navTags}>
                <h5>{ fundClass.className }</h5>
            </div>
            {   open &&
                <div>
                    <Table responsive className="mt-4" borderless={true}>
                        <thead>
                            <tr className={styles.theader}>
                                <th>Transaction ID</th>  
                                <th>Date Time</th>
                                <th>Type</th>
                                <th>Direction</th>
                                <th>Asset</th>
                                <th>Amount (Shares)</th>
                                <th>Fee</th>
                                <th></th>
                            </tr>
                        </thead>
                        { fundClass.transferLog.map((fundClassTransferLog, i) => (
                                <tbody key={i} style={fundClassTransferLog?.oppositeTransfer ? (i % 2 === 0 ? {background:"#2F4858"} : {background:"#4F7A7F"}) : {}}>
                                    <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                        <td>{ fundClassTransferLog.transactionSource } : { fundClassTransferLog.transactionId}</td>
                                        <td>{ fundClassTransferLog.dateTime.replace("T", " / ").replace("Z", "")}</td>
                                        <td>{ fundClassTransferLog.transactionType}</td>
                                        <td>{ fundClassTransferLog.direction === "In" ?
                                            <div className={styles.tooltip}>
                                                <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "33px"}} /> 
                                                <span className={styles.tooltiptext}>{fundClassTransferLog.direction}</span>
                                            </div> 
                                                : 
                                                <div className={styles.tooltip}>
                                                <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "33px"}} />
                                                <span className={styles.tooltiptext}>{fundClassTransferLog.direction}</span>
                                            </div>
                                            }
                                        </td>
                                        <td>{ fundClassTransferLog.currencySymbol} </td>
                                        <td title={ fundClassTransferLog.transferAmount}>{numberEditor(fundClassTransferLog.transferAmount)} 
                                            { fundClassTransferLog.transactionType === "Inflow" && ` (${fundClassTransferLog.shares})` } 
                                            { fundClassTransferLog.transactionType === "Outflow" && ` (${fundClassTransferLog.shares})` } 
                                        </td>
                                        <td title={ fundClassTransferLog.transferFee}>{numberEditor(fundClassTransferLog.transferFee) } { fundClassTransferLog.transferFee > 0 && fundClassTransferLog.feeCurrencySymbol}</td>
                                        <td><FileAttachment attachments={fundClassTransferLog.attachments} /></td>
                                    </tr>
                                    {
                                        fundClassTransferLog.oppositeTransfer && 
                                            <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips } style={i % 2 === 0 ? {background:"#2F4858"} : {background:"#4F7A7F"}}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    {   fundClassTransferLog.oppositeTransfer.direction === "In" ?
                                                            <div className={styles.tooltip}>
                                                                <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "30px"}} /> 
                                                                <span className={styles.tooltiptext}>{fundClassTransferLog.oppositeTransfer.direction}</span>
                                                            </div> 
                                                                : 
                                                            <div className={styles.tooltip}>
                                                                <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "30px"}} />
                                                                <span className={styles.tooltiptext}>{fundClassTransferLog.oppositeTransfer.direction}</span>
                                                            </div>
                                                    }
                                                </td>
                                                <td>{ fundClassTransferLog.oppositeTransfer.currencySymbol }</td>
                                                <td>{ numberEditor(fundClassTransferLog.oppositeTransfer.transferAmount) }</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                    }
                                </tbody>
                            ))
                        }
                    </Table>
                </div>
            }
        </div>
    )
}
export default ReportFundTransferLogsClasses