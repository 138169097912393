import React, { useState } from 'react'
import ModalComponent from '../../Components/ModalComponent'
import { Table, Form, Row, Col } from 'react-bootstrap'
import styles from "./FundDetail.module.scss"
import { useForm } from "react-hook-form";
import { useFundHoldingsForComparerModal } from '../../query-hooks/fund-query-hooks/useFund';
import { useAuth } from "../../Hooks/useAuth"
import { bookingPerioddateFormat, numberEditor } from '../../Helpers/Helpers';
import { API_URL } from '../../settings';
import Logo from "../../images/Hodl-logo.jpg"
import ComparerModalCorrection from './ComparerModalCorrection';
import Diagram from '../../images/comparer-diagram.jpg'

const ComparerModal = ({ setDisplayComparerModal, fundId, bookingPeriod, selectedFundClassId }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { darkmode } = useAuth()
    const handleImageError = (e) => e.target.src = Logo // Handles img src error

    // Flow to structure holdings comparison data 
    const [ payload, setPayload ] = useState()
    const fundHoldingsForComparerModal = useFundHoldingsForComparerModal(fundId, bookingPeriod, bookingPeriod, selectedFundClassId, payload)
    const onSubmitExcelData = e => setPayload(e.payload)

    // Function to parse US Currency format string to number
    function parseMoneyString(inputString) {
        if (!inputString) return "No Value";
        const stringNumberFormat = inputString.replaceAll('$', '').replaceAll('.', '').replaceAll(',', '.').replaceAll('€', '').replaceAll('£', '');
        return parseFloat(stringNumberFormat);
    }

    // Display Table Comparer after input Excel sheet String
    if (fundHoldingsForComparerModal.isSuccess) {
        return (
            <ModalComponent stateTrigger={setDisplayComparerModal}>
                <Row className='justify-content-center'>
                    <Col xl={10}>
                        <h3 className={`${styles.mainFundTitle} text-center my-4`}>Values Comparison { bookingPerioddateFormat(bookingPeriod) }</h3>
                        <Table responsive borderless={true} style={{minHeight:"20rem"}}>
                            <colgroup>
                                <col span="2" />
                                <col span="3" style={{backgroundColor:"#222f3f", border:"solid 1px #222f3f"}} />
                                <col span="2" style={{backgroundColor:"#859689", border:"solid 1px #859689"}} />
                            </colgroup>
                            <thead className={styles.thead}>
                                <tr className={styles.theader}>
                                    <th style={{border:"solid 1px #1B2236"}}></th>
                                    <th style={{border:"solid 1px #1B2236"}}></th>
                                    <th style={{border:"solid 1px #1B2236", fontSize:"12px"}}>Amount</th>
                                    <th style={{border:"solid 1px #1B2236", fontSize:"12px"}}>Excel Amount</th>
                                    <th style={{border:"solid 1px #1B2236"}}></th>
                                    <th style={{border:"solid 1px #1B2236", fontSize:"12px"}}>USD Value</th>
                                    <th style={{border:"solid 1px #1B2236", fontSize:"12px"}}>Excel USD Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                { fundHoldingsForComparerModal.data.map(holding => (
                                    <tr key={holding.id} className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                        <td>
                                            { holding.cryptoCurrency ? 
                                                <img 
                                                    key={Date.now()} 
                                                    src={`${API_URL}/cryptos/${holding.cryptoCurrency.id}/icon`} 
                                                    width="25px" 
                                                    onError={ handleImageError } 
                                                    className={styles.cryptoIcons} 
                                                    alt="crypto" 
                                                /> : holding.currency ? <h5>{ holding.currency.symbol }</h5> : <h5> </h5> 
                                            } 
                                        </td> 
                                        <td style={{textAlign:"left"}}><span style={{color:"#A5C6AC"}}>{ holding.displayName }</span></td> 
                                        <td>{ numberEditor(holding.endBalance)}</td>
                                        <td>{ holding.excelPair ? numberEditor(parseMoneyString(holding.pair.amount)) : <span style={{color:"#6D9E93", fontWeight:"600"}}>No Pair</span> }</td>
                                        <td><ComparerModalCorrection holding={holding} parseMoneyString={parseMoneyString} /></td>
                                        <td>{ numberEditor(holding.endUSDValue)}</td>
                                        <td>{ numberEditor(parseMoneyString(holding.pair.usdvalue)) }</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalComponent>
        ) 
    }

    // Display Form to input Data string from Excel Sheet
    return (
        <ModalComponent stateTrigger={setDisplayComparerModal}>
            <Form onSubmit={handleSubmit(onSubmitExcelData)}>
                <Row className='justify-content-center my-3'>
                    <Col xl={6}>
                        <h3 className={styles.mainFundTitle}>Values Comparer</h3>
                        <h6 className={styles.inputLabelDark}>Paste Navs Rows and Columns from Excel Table</h6>
                        <textarea className={styles.textareaInput} {...register("payload", {required: true})} placeholder="Paste text..." />
                        {errors.payload && errors.payload.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <div className={styles.tooltip}>
                            <p className='text-center'>Help</p>
                            <div className={styles.tooltiptextComparerTable}>
                                <img src={Diagram} alt="Hodl Comparer Diagram" width="600px" />
                            </div>
                        </div>
                        <input className={styles.submitButton} type="submit" value="Compare Values" />
                    </Col>
                </Row>
            </Form>
        </ModalComponent>
    )
}
export default ComparerModal;