import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { spyBndxStringHandler } from '../../Helpers/Helpers';
import styles from "./BenchmarkDashboard.module.scss"

const BenchmarkTableInputs = ({ data, fund, investment, setInvestment, quotesPercentages, fulfilledPercentage, setQuotePercentages }) => {
    // Function to reassign percentages to quotesPercentage state when input changes 
    const updateQuotesPercentages = (val, quoteId) => {
        const updatedQuotes = quotesPercentages.map(quote => {
            if (quote.id === quoteId) {
                return { ...quote, percentage: Number(val) }
            }
            return quote
        })
        setQuotePercentages(updatedQuotes)
    }

    return (
        <Row className='justify-content-center'>
            <Col xl={2}>
                <div className={styles.formInputs}>
                    <p style={{color:"#6D9E93"}}><strong>Investment</strong></p>
                    <input type="number" onChange={e => setInvestment(e.target.value)} value={investment} />
                </div>
            </Col>
            {   quotesPercentages?.map(quote => (
                    <Col key={quote.id} xl={2}>
                        <div className={styles.formInputs}>
                            <p style={{color:"#6D9E93"}}><strong>{ spyBndxStringHandler(quote.symbol) }</strong> <span>{  Number.isInteger(quote.percentage) ? quote.percentage : quote.percentage.toFixed(2) }%</span></p>
                            <input type="number" onChange={e => updateQuotesPercentages(e.target.value, quote.id)} value={quote.percentage} />
                            { !fulfilledPercentage && <p className={styles.alertMessage}>Total Percentage Unfulfilled</p> }
                        </div>
                    </Col>
                ))
            }
        </Row>
    )
}
export default BenchmarkTableInputs;