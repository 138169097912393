import React from "react";
import AllExchanges from "./AllExchanges";
import useExchanges from "../../query-hooks/exchange-query-hooks/useExchange";
import DashboardComponent from "../../Components/DashboardComponent";
import { ErrorLoading, Spinner } from "../../Helpers/Helpers"

const ExchangesDashboard = () => {
  const exchanges = useExchanges()
  return (
    <DashboardComponent title="Exchanges Dashboard" subtitle="Exchanges Dashboard" toCreate="/main/exchanges/create-exchange" buttonMessage="Create an Exchange">
      {exchanges.isLoading && <Spinner />}
      {exchanges.isError && <ErrorLoading />}
      {exchanges.isSuccess && <AllExchanges items={exchanges.data.items} />}
    </DashboardComponent>
  );
};
export default ExchangesDashboard