import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "./FundClassForm.module.scss";
import { useAuth } from "../../../Hooks/useAuth";
import useCryptos, { useListingSources } from "../../../query-hooks/crypto-query-hooks/useCryptos"; 
import useCurrencies from "../../../query-hooks/currency-query-hooks/useCurrency.js";
import { useFundsOwners } from "../../../query-hooks/fund-query-hooks/useFund"
import { FUND_CLOSE_DAY } from "../../../constants/enums.js"

const FundClassForm = ({ onSubmit, data, fundData }) => {
    const { darkmode } = useAuth();
    const { register, handleSubmit, reset, formState: { errors } } = useForm()
    const cryptos = useCryptos()
    const currencies = useCurrencies()
    const fundsOwners = useFundsOwners()
    const listingSources = useListingSources()

    useEffect(() => { 
        if (data || fundData) {
            reset({
                className: data?.className, 
                description: data?.description, 
                dateStart: data?.dateStart?.slice(0, 10),
                dateEnd: data?.dateEnd?.slice(0, 10),
                initialShareValue: data ? data.initialShareValue : fundData.initialShareValue,
                managementFee: data?.managementFee, 
                managementFeeFrequency: data?.managementFeeFrequency, 
                performanceFee: data?.performanceFee, 
                performanceFeePolicy: data?.performanceFeePolicy,
                totalValue: data?.totalValue, 
                totalShares: data?.totalShares,
                // Fund Data
                maxVolume: fundData.maxVolume,
                preferedListingSource: fundData.preferedListingSource,
                reportingCurrencyCode: fundData.reportingCurrencyCode,
                primaryCryptoCurrencyId: fundData.primaryCryptoCurrencyId,
                closeDay: fundData.closeDay,
                hwmResetPolicy: fundData.hwmResetPolicy,
                hwmResetDate: fundData.hwmResetDate?.slice(0, 10)
            });
        }
    }, [data, fundData, reset]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Class Name <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} placeholder="Fund Name" {...register("className", { required: true, maxLength: 40 })} />
                    {errors.fundName && errors.fundName.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    {errors.fundName && errors.fundName.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 40 digits exceeded</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Fund Owner</h6>
                    <select className={styles.selectInput} {...register("fundOwnerId")} disabled={true}> 
                        {fundsOwners.data && fundsOwners.data.items.map(fundOwner => (
                            <option key={fundOwner.id} value={fundOwner.id}>{fundOwner.name}</option>
                        ))} 
                    </select>
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Fund Description</h6>
                    <textarea className={styles.textarea} placeholder="Description (Optional)" {...register("description")} />
                    {errors.description && errors.description.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Share Symbol</h6>
                    <input className={styles.fundFormInputs} placeholder="Fund Name" {...register("shareSymbol", { maxLength: 10 })} />
                    {errors.shareSymbol && errors.shareSymbol.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 10 digits exceeded</span>)}
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Max Volume</h6>
                    <input className={styles.fundFormInputs} type="number" disabled={true} placeholder="Max Volume" {...register("maxVolume")} />
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Preferred Listing Source</h6>
                    <select className={styles.selectInput} {...register("preferedListingSource")} disabled={true}> 
                        { listingSources.isSuccess && listingSources.data.map((listingSource, i) => (<option key={i} value={listingSource}>{listingSource}</option>))} 
                    </select>
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Reporting Currency</h6>
                    <select className={styles.selectInput} {...register("reportingCurrencyCode")} disabled={true}> 
                        {currencies.data && currencies.data.items.map(currency => (
                            <option key={currency.isoCode} value={currency.isoCode}>{currency.name}</option>
                        ))} 
                    </select>  
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Primary Crypto Currency</h6>
                    <select className={styles.selectInput} {...register("primaryCryptoCurrencyId")} disabled={true}> 
                        {cryptos.data && cryptos.data.map(crypto => (
                            <option key={crypto.id} value={crypto.id}>{crypto.name}</option>
                        ))} 
                    </select>
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose a Performance Fee Policy</h6>
                    <select className={styles.selectInput} {...register("performanceFeePolicy")}> 
                        <option value="FundHwm">FundHwm</option>
                        <option value="InflowDate">InflowDate</option>
                    </select>
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Management Fee Percentage<em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Management Fee" {...register("managementFee", { required: true, valueAsNumber: true })} />
                    {errors.managementFee && errors.managementFee.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Management Fee Frequency (Times per year)<em className={styles.reqSymbol}>*</em></h6>
                    <select className={styles.selectInput} {...register("managementFeeFrequency", { required: true, valueAsNumber: true })}>
                            <option value="">Select an option</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="4">4</option>
                            <option value="12">12</option>
                            <option value="365">365</option>
                    </select>
                    {errors.managementFeeFrequency && errors.managementFeeFrequency.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Close Day</h6>
                    <select className={styles.selectInput} {...register("closeDay")} disabled={true}>
                            <option value="">Select an option</option>
                            { FUND_CLOSE_DAY.map(closeDay => <option key={closeDay.value} value={closeDay.value}>{ closeDay.name }</option>) }
                    </select>
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Performance Fee Percentage<em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Performance Fee" {...register("performanceFee", { required: true, valueAsNumber: true })} />
                    {errors.performanceFee && errors.performanceFee.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Total Shares</h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Total Shares" {...register("totalShares", { valueAsNumber: true })} />
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Total Value</h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Total Value" {...register("totalValue", { valueAsNumber: true })} disabled={data} />
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Starting Date<em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.datetime} type="date" name="booking-period" {...register("dateStart", { required: true })}/>
                    {errors.dateStart && errors.dateStart.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Initial Share Value</h6>
                    <input className={styles.fundFormInputs} type="number" step="any" onWheel={e => e.currentTarget.blur()} placeholder="Initial Share Value" {...register("initialShareValue", { valueAsNumber: true })} />
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Reset Policy Mode</h6>
                    <select className={styles.selectInput} {...register("hwmResetPolicy")} disabled={true}> 
                        <option value="None">None</option>
                        <option value="SetDate">Set a Fixed Date</option>
                        <option value="Yearly">Set it Yearly</option>
                    </select>  
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Reset Date</h6>
                    <input className={styles.datetime} type="date" name="hwm-resetDate" {...register("hwmResetDate")} disabled={true} />
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>End Date</h6>
                    <input className={styles.datetime} type="date" name="booking-period" {...register("dateEnd")}/>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={4} md={12}>
                    <input className={styles.submitButton} type="submit" />
                </Col>
            </Row>
            <Row className="d-flex text-center mt-2 mb-5">
                <Link className={styles.backLink} to={-1}>Back</Link>
            </Row>
        </Form>
    )
}
export default FundClassForm