import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateTrade } from "../../../query-hooks/order-query-hooks/useOrder";
import TradeForm from "./TradeForm";
import FormComponent from "../../../Components/FormComponent";

const TradeFormMutation = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const createTrade = useCreateTrade()
  const onSubmit = data => createTrade.mutate( {orderId: orderId, tradeData: data} ) 
  useEffect(() => { createTrade.isSuccess && navigate(-1) }, [createTrade, navigate] ) 
  
  return (
    <FormComponent title="Trade Dashboard" subtitle="Add a Trade">
        <TradeForm onSubmit={onSubmit} /> 
    </FormComponent>
  );
};
export default TradeFormMutation;