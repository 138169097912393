import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from "../../settings";
import Swal from "sweetalert2";

// Function to get all currencies
const fetchCurrencies = () => axios.get(`${API_URL}/currencies`).then((response) => response.data);

export default function useCurrencies() {
  return useQuery("currencies", fetchCurrencies);
}

// Function to get one Currency by its ISO Code
const fetchCurrency = (isoCode) => axios.get(`${API_URL}/currencies/${isoCode}`).then((response) => response.data);
export function useCurrency(isoCode) {
  return useQuery(["currency", isoCode], () => fetchCurrency(isoCode), {enabled: isoCode !== undefined }  );
}

//Function to fetch Euro data for Fund Form default Value 
const fetchEuro = async () => await axios.get(`${API_URL}/currencies/EUR`).then(response => response.data);
export function useEuro() {
  return useQuery("euro", fetchEuro);
} 

// CURRENCY RATES
// Function to get all currencY RATES
const fetchRates = (isoCode, from, to, page) => axios.get(`${API_URL}/currencies/${isoCode}/rates`, {params: {from, to, page}}).then(res => res.data);
export const useRates = (isoCode, from, to, page) => useQuery(["rates", isoCode, from, to, page], () => fetchRates(isoCode, from, to, page), {enabled: isoCode !== undefined});


// MUTATIONS

//Function to post 
const postCurrency = (currency) => axios.post(`${API_URL}/currencies`, currency).then(response => response.data);

export function useCreateCurrency() {
  const queryClient = useQueryClient()
  return useMutation(currency => postCurrency(currency),
  { onSuccess: async () => queryClient.invalidateQueries("currencies"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) });
}

//Function to edit Currency
const editCurrency = (currency, isoCode) => axios.put(`${API_URL}/currencies/${isoCode}`, currency).then(response => response.data);

export function useEditCurrency() {
  const queryClient = useQueryClient()
  return useMutation(({ currency, isoCode }) => editCurrency(currency, isoCode),
  { onSuccess: async () => queryClient.invalidateQueries("currencies"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) });
}

//Function to delete Currency
const deleteCurrency = (isoCode) => axios.delete(`${API_URL}/currencies/${isoCode}`).then(response => response.data);
export function useDeleteCurrency() {
  const queryClient = useQueryClient()
  return useMutation(({ isoCode }) => deleteCurrency(isoCode), 
    { onSuccess: async () => queryClient.invalidateQueries("currencies"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) });
}
// Message object inserted in delete message
const deleteMessageObject = {
  title: "Are you sure you want to delete this Currency?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteCurrency = (event, isoCode, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageObject).then((result) => result.isConfirmed && mutation.mutate({ isoCode: isoCode }) );
};

// CURRENCY RATES MUTATION
//Function to post rate
const postRate = (isoCode, rate) => axios.post(`${API_URL}/currencies/${isoCode}/rates`, rate).then(response => response.data);
export function useCreateRate() {
  const queryClient = useQueryClient()
  return useMutation(({isoCode, rate}) => postRate(isoCode, rate),
  { onSuccess: async () => queryClient.invalidateQueries("rates"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) });
}

//Function to delete Rate
const deleteRate = (isoCode, rateId) => axios.delete(`${API_URL}/currencies/${isoCode}/rates/${rateId}`).then(response => response.data);
export function useDeleteRate() {
  const queryClient = useQueryClient()
  return useMutation(({ isoCode, rateId }) => deleteRate(isoCode, rateId), 
    { onSuccess: async () => queryClient.invalidateQueries("rates"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) });
}
// Message object inserted in delete message
const deleteMessageRate = {
  title: "Are you sure you want to delete this Rate?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteRate = (event, isoCode, rateId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageRate).then(result => result.isConfirmed && mutation.mutate({ isoCode: isoCode, rateId: rateId }) );
};

// Function to import Rates from Excel Sheets
const importRates = (rates, bookingPeriod) => axios.post(`${API_URL}/rates/${bookingPeriod}`, rates).then(response => response.data);
export const useImportRates = () => {
  const queryClient = useQueryClient()
  return useMutation( ({rates, bookingPeriod}) => importRates(rates, bookingPeriod),
  { onSuccess: () => { queryClient.invalidateQueries("rates") } }
  );
}
