import React from 'react'
import { Container } from "react-bootstrap";
import styles from "./FormComponent.module.scss";
import { useAuth } from "../Hooks/useAuth";

const FormComponent = ({title, subtitle, children}) => {
    const { darkmode } = useAuth();
    return (
        <Container>
            <h5 className={darkmode ? styles.subTitleDark : styles.subTitle}>{ title }</h5>
            <h2 className={darkmode ? styles.mainTitleDark : styles.mainTitle}>{ subtitle }</h2>
            <div className={darkmode ? styles.createCardDark : styles.createCard}>
                    { children }     
            </div>
        </Container>
    )
}
export default FormComponent 