import React, { useEffect, useState } from 'react'
import { useGetReservations } from '../../query-hooks/transfer-query-hooks/useTransfer';
import { Table } from 'react-bootstrap';
import styles from "../Funds/FundDetail.module.scss"
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";
import { numberEditor, timestampFormatter } from '../../Helpers/Helpers';
import { Row, Col } from 'react-bootstrap';

const ReservationsList = ({ fundId, setValue, selectedHoldingDisplayName, importTransferExplorer }) => {
    const reservations = useGetReservations(fundId)
    const [ reservationsList, setReservationsList ] = useState([])

    const handleReservationsSelected = e => {
        if (e.target.checked === true) {
            setReservationsList([...reservationsList, e.target.value])
        } else {
            setReservationsList(reservationsList.filter(reservation => reservation !== e.target.value))
        }
    }
    // console.log(reservationsList)
    useEffect(() => {
        if (importTransferExplorer) return
        setValue("reservations", reservationsList)
        const selectedReservations = reservations.isSuccess && reservations.data.filter(res => reservationsList.includes(res.id))
        // console.log(selectedHoldingDisplayName, selectedReservations && selectedReservations[0].holding.id)
        if (!selectedHoldingDisplayName || selectedReservations && selectedReservations.some(el => el.holding.displayName === selectedHoldingDisplayName) === true) {
            const sum = selectedReservations && selectedReservations.reduce((acc, curr) => acc + curr.transferAmount, 0)
            return setValue("transferAmount", sum)
        }
        setValue("transferAmount", 0)
    }, [reservationsList, selectedHoldingDisplayName])
    
    return (
        <Row className="d-flex justify-content-center mt-5">
            <Col xl={8} md={12} className="py-1">
                <Table borderless={true}>
                    <thead className={styles.thead} style={{top: "3rem"}}>
                        <tr className={styles.theader}>
                            <th></th>
                            <th>Date</th>
                            <th></th>
                            <th>Transaction Type</th>
                            <th>Currency</th>  
                            <th>Transfer Amount</th> 
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    { reservations.isSuccess && reservations.data.map((transfer) => (
                        <tbody key={transfer.id}>
                            <tr className={styles.tableStripsDark}>
                                <td>
                                    <label className={styles.checkbox}>
                                        <input type="checkbox" style={{height:"20px", width:"20px"}} value={transfer.id} onClick={e => handleReservationsSelected(e)} />
                                    </label>
                                </td>
                                <td>{timestampFormatter(transfer.dateTime)}</td>
                                <td>{ transfer.direction === "In" ?
                                    <div className={styles.tooltip}>
                                        <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "30px"}} /> 
                                        <span className={styles.tooltiptext}>{transfer.direction}</span>
                                    </div> 
                                        : 
                                    <div className={styles.tooltip}>
                                        <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "30px"}} />
                                        <span className={styles.tooltiptext}>{transfer.direction}</span>
                                    </div>
                                    }
                                </td>                        
                                <td>{ transfer.transactionType }</td>
                                <td>{ transfer.holding.displayName }</td>
                                <td>{ numberEditor(transfer.transferAmount) }</td>
                            </tr>
                        </tbody>
                    ))}
                </Table>
            </Col>
        </Row>
    )
}
export default ReservationsList;