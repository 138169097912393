import React from 'react'
import { Table } from "react-bootstrap";
import styles from "../../AllOrders.module.scss";
import { BiUpArrowCircle, BiDownArrowCircle } from "react-icons/bi";
import { useAuth } from "../../../../Hooks/useAuth";
import EmptyMessageComponent from '../../../../Components/EmptyMessageComponent';
import { BiCheck, BiX } from "react-icons/bi";
import { numberEditor } from '../../../../Helpers/Helpers';

const OrderBlockchainList = ({ data, handleImportList }) => {
    const { darkmode } = useAuth()

    if (data.length < 1) return <EmptyMessageComponent message="No Orders Found" />
    
    return (
        <div style={{height:"55rem", overflowY:"auto"}}>
            <Table responsive borderless={true} className={styles.tableStyle}>
                <thead>
                    <tr className={darkmode ? styles.theaderDark : styles.theader}>
                        <th>Import</th>
                        <th>Transaction ID</th>
                        <th>Date-Time</th>
                        <th>Pair</th>
                        <th>Direction</th>
                        <th>Unit Price</th>
                        <th>Amount</th>
                        <th>Total</th>
                        <th>Swap Contract</th>
                        <th>Imported</th>
                    </tr>
                </thead>
                { data.map((order, i) => (
                    <tbody key={i}> 
                        <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `} >
                            <td>
                                {   !order.isImported && 
                                    <label className={styles.checkbox}>
                                        <input type="checkbox" onClick={e => handleImportList(e.target.checked, order.transactionId)} />
                                    </label> 
                                }
                            </td>
                            <td title={order.transactionId}>{ `${order.transactionId.slice(0, 20)}...` }</td>
                            <td>{ order.dateTime.replace("T", " / ").replace("Z", "").slice(0, 18) }</td>
                            <td>{ order.baseAsset.symbol }/{ order.quoteAsset.symbol }</td>
                            <td>
                                { order.direction === "Buy" ?
                                    <div className={styles.tooltip}>
                                        <BiUpArrowCircle style={{color: "#6D9E93", fontSize: "33px"}} /> 
                                        <span className={styles.tooltiptext}>{order.direction}</span>
                                    </div> 
                                    : 
                                    <div className={styles.tooltip}>
                                        <BiDownArrowCircle style={{color: "#CD5C5C", fontSize: "33px"}} />
                                        <span className={styles.tooltiptext}>{order.direction}</span>
                                    </div>
                                }
                            </td>
                            <td>{ numberEditor(order.unitPrice) } { order.quoteAsset.symbol }</td>
                            <td>{ numberEditor(order.executed) } { order.baseAsset.symbol }</td> 
                            <td>{ numberEditor(order.total) } { order.quoteAsset.symbol }</td>
                            <td>{ order.swapContractPublicName }</td>
                            <td>{ order.isImported ? <BiCheck style={{color: "#6D9E93", fontSize: "25px"}} /> : <BiX style={{color: "#CD5C5C", fontSize: "25px"}} /> }</td>
                        </tr>
                    </tbody>
                ))}
            </Table>
        </div>
    )
}
export default OrderBlockchainList;