import React, { useState } from "react";
import { useCoinGeckoApi } from "../../query-hooks/crypto-query-hooks/useCryptos";
import styles from "./CryptoSearchInput.module.scss";
import { useAuth } from "../../Hooks/useAuth";

const CryptoSearchInput = ({ onHandleNewCryptoFetched }) => {
  const { darkmode } = useAuth();
  const cryptosAPI = useCoinGeckoApi();
  const [inputValue, setInputValue] = useState("");
  const [selectedCryptos, setSelectedCryptos] = useState([]);

  const getCryptobyName = (chain) => {
    // Function to filter input results
    setInputValue(chain);
    let filteredCryptos = [];
    filteredCryptos = cryptosAPI.data.filter(
      (crypto) => crypto.name.toLowerCase().indexOf(chain.toLowerCase()) === 0
    );
    chain.length === 0
      ? setSelectedCryptos([])
      : setSelectedCryptos(filteredCryptos);
  };

  const handleSelectedInput = (selectedCrypto) => {
    // Function to update Chosen crypto state in Create Component
    setSelectedCryptos([]);
    onHandleNewCryptoFetched(selectedCrypto);
    setInputValue("");
  };

  if (cryptosAPI.isSuccess) {
    return (
      <>
        <div className="mb-3">
          <div>
            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Search Engine</h6>
            <input
              placeholder="Search a crypto..."
              value={inputValue}
              onChange={(e) => getCryptobyName(e.target.value)}
              className={styles.mainInput}
            />
          </div>
          <ul className={darkmode ? styles.listFrameDark : styles.listFrame}>
            {selectedCryptos.map((selectedCryptoCoin) => (
              <li
                className={darkmode ? styles.listItemsDark : styles.listItems}
                key={selectedCryptoCoin.id}
                onClick={() => handleSelectedInput(selectedCryptoCoin)}
              >
                {selectedCryptoCoin.name}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
};
export default CryptoSearchInput;
