import React, { useState, useEffect } from "react";
import useOrders from "../../query-hooks/order-query-hooks/useOrder";
import AllOrders from "./AllOrders";
import { ErrorLoading, Spinner } from "../../Helpers/Helpers"
import { Row, Col } from "react-bootstrap";
import styles from "./OrderForm.module.scss"
import { useAuth } from "../../Hooks/useAuth";

const OrdersDashboard = () => {
  const { chosenOrderNumber, setChosenOrderNumber, chosenOrderTicker, setChosenOrderTicker, fundedOrdersFilter, setFundedOrdersFilter } = useAuth()
  const [ page, setPage ] = useState(1)
  const orders = useOrders(chosenOrderNumber, chosenOrderTicker, fundedOrdersFilter, page);
  useEffect(() => { setPage(1) }, [ chosenOrderNumber, chosenOrderTicker ]) // Sets page to 1 every time input queries change

  return (
    <>
      <Row>
        <Col xl={3}></Col>
        <Col xl={3} className="d-flex justify-content-center">
          <label className={styles.checkbox}>
              <span>Not Funded Orders</span>
              <input type="checkbox" onClick={e => setFundedOrdersFilter(e.target.checked)} defaultChecked={fundedOrdersFilter} />
          </label> 
        </Col>
        <Col xl={3} className="mb-5 px-2">
          <input className={styles.fundFormInputs} placeholder="Search by Order Number" value={chosenOrderNumber} onChange={e => setChosenOrderNumber(e.target.value)}/>
          { chosenOrderNumber && <button className={styles.removeButton} onClick={() => setChosenOrderNumber("")}>x</button> }
        </Col>
        <Col xl={3} className="mb-5 px-2">
          <input className={styles.fundFormInputs} placeholder="Search by Ticker" value={chosenOrderTicker} onChange={e => setChosenOrderTicker(e.target.value)}/>
          { chosenOrderTicker && <button className={styles.removeButton} onClick={() => setChosenOrderTicker("")}>x</button> }
        </Col>
      </Row>
      <AllOrders orders={orders} setPage={setPage} /> 
      {orders.isLoading && <Spinner /> }
      {orders.isError && <ErrorLoading error={orders.error} />}
    </>
  );
};
export default OrdersDashboard