import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import CurrencyRateForm from './CurrencyRateForm';
import FormComponent from '../../../Components/FormComponent';
import { useCreateRate } from '../../../query-hooks/currency-query-hooks/useCurrency';

const CurrencyRateFormMutation = () => {
   
    const navigate = useNavigate();  
    const { isoCode } = useParams()  
    const createRate = useCreateRate()
    const onSubmit = data => isoCode && createRate.mutate( {isoCode: isoCode, rate: data } ) 
    useEffect(() => { createRate.isSuccess && navigate(-1) }, [createRate]) 

    return (
      <FormComponent title="Currency Rates Dashboard" subtitle={isoCode ? "Edit Rate" : "Create Rate"}>
        { isoCode && <CurrencyRateForm onSubmit={onSubmit} /> }
        {/* { cryptoListing.data && <RateForm onSubmit={onSubmit} data={cryptoListing.data} /> }  */} 
      </FormComponent>
    );
}
export default CurrencyRateFormMutation