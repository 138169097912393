import React from "react";
import styles from "../styles/Login.module.scss";
import { useForm } from "react-hook-form";
import Logo from "../images/logo1.png";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { LOGIN_API_URL } from "../settings";
import { AlertFunction } from "../Helpers/Helpers"
import { useAuth } from "../Hooks/useAuth";

const Register = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth()
  const {register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    await axios.post(`${LOGIN_API_URL}/auth/register`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(res => { 
        setUser({ email: res.data.email })
        navigate("/authentication") 
      })
      .catch(err => {
        if (err.response.data) {
          AlertFunction(err)
        } else {
          alert("Unknown server error")
        }

      }) 
  };

  return (
    <main>
      <div>
        <Container className="d-flex justify-content-center">
          <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Row>
              <Col className="d-flex justify-content-center">
                <img className="mb-5" src={Logo} alt="Hodl" />
              </Col>
              <Col lg={12} xs={12} md={12}>
                <input type="email" {...register("email", { required: true })} placeholder="Email" />
                {errors.email && (<p className={styles.formErrorNotice}>This field is required</p>)}
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12} md={12} className="mt-3">
                <input type="password" {...register("password", { required: true })} placeholder="Password" />
                {errors.password && (<p className={styles.formErrorNotice}>This field is required</p>)}
                <ul className={styles.conditionsList} style={{ marginTop: "1rem" }}>
                  <li>be at least 8 characters</li>
                  <li>have at least one non alphanumeric character</li>
                  <li>have at least one digit ('0'-'9')</li>
                  <li>have at least one uppercase ('A'-'Z')</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <button className={styles.registerButton} type="submit">
                  Register
                </button>
              </Col>
            </Row>
            <Row>
              <Col className={styles.linkDiv} style={{ margin: "0.8rem" }}>
                <Link className={styles.link} to="/">
                  Home
                </Link>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </main>
  );
};

export default Register;
