// import React from "react";
// import { LineChart, Line, Tooltip, ResponsiveContainer } from "recharts";

// const NavCharts = ({dailyNavs}) => {
//   return (
//     <ResponsiveContainer width="99%" height={300} className="mb-5">
//       <LineChart width={500} height={300} data={dailyNavs} margin={{top: 5, right: 30, left: 20, bottom: 5 }}>
//         <Tooltip />
//         <Line type="monotone" dataKey="hwm" stroke="#CACCBD" strokeWidth={1} />
//         <Line type="monotone" dataKey="nav" stroke="#6D9E93" strokeWidth={1} />
//       </LineChart>
//     </ResponsiveContainer>
//   )
// };
// export default NavCharts;

import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ReferenceArea, ResponsiveContainer } from 'recharts';
import styles from "./NavCharts.module.scss"
import { MdTimeline } from "react-icons/md";

const getAxisYDomain = (dailyNavs, from, to, ref, offset) => {
  const indexedDailyNavs = dailyNavs.map((item, i) => ({index: i, date: item.date, nav: item.nav, hwm: item.hwm}))
  const refData = indexedDailyNavs.slice(from - 1, to);
  let [bottom, top] = [refData[0][to], refData[0][to]];
  refData.forEach((d) => {
    if (d[ref] > top) top = d[ref];
    if (d[ref] < bottom) bottom = d[ref];
  });

  return [(bottom | 0) - offset, (top | 0) + offset];
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.navTooltip}>
        <p><MdTimeline style={{color: "#A5C6AC", fontSize: "26px"}} /> {`NAV : ${payload[0].payload.nav.toFixed(4)}`}</p>
        <p><MdTimeline style={{color: "#566598", fontSize: "26px"}} />{`HMW : ${payload[0].payload.hwm.toFixed(4)}`}</p>
        <p>{`Date : ${payload[0].payload.date.slice(0,10)}`}</p>
      </div>
    );
  }
  return null;
};

const filter = data => data.map((item, i) => ({index: i, date: item.date, nav: item.nav, hwm: item.hwm})) // Function to arrange received data


export default class NavCharts extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      data: filter(this.props.dailyNavs),
      left: 'dataMin',
      right: 'dataMax',
      top: 'dataMax+30',
      bottom: 'dataMin-10',
      top2: 'dataMax+20',
      bottom2: 'dataMin-10',
      animation: true,
    };
  }

  zoom() {
    let { refAreaLeft, refAreaRight } = this.state;
    const { data } = this.state;
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      this.setState(() => ({
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    const [bottom, top] = getAxisYDomain(data, refAreaLeft, refAreaRight, 'NAV', Math.max(...data.map(o => o.nav)) + 5);
    const [bottom2, top2] = getAxisYDomain(data, refAreaLeft, refAreaRight, 'HWM', Math.max(...data.map(o => o.hwm)) + 5);

    this.setState(() => ({
      refAreaLeft: '',
      refAreaRight: '',
      data: data.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      bottom: Math.min(...data.map(o => o.nav)),
      top,
      bottom2: Math.min(...data.map(o => o.hwm)),
      top2,
    }));
  }

  zoomOut() {
    const { data } = this.state;
    this.setState(() => ({
      data: data.slice(),
      refAreaLeft: '',
      refAreaRight: '',
      left: 'dataMin',
      right: 'dataMax',
      // top: 'dataMax+1',
      // bottom: 'dataMin-1',
      // top2: 'dataMax+30',
      // bottom2: 'dataMin-10',
    }));
  }
  render() {
    const { data, barIndex, left, right, refAreaLeft, refAreaRight, top, bottom, top2, bottom2 } = this.state;
    const handleMaxHWMValue = () => {
      if (this.state.data.length > 0) {
        const maxHWMValue = Math.max(...this.state.data.map(x => x.hwm))
        const maxNAVValue = Math.max(...this.state.data.map(x => x.nav))
        const maxValues = []
        maxValues.push(maxHWMValue)
        maxValues.push(maxNAVValue)
        const maxVal = Math.max(...maxValues)
        return Math.round(maxVal + 2)
      } 
      return 20
    }
    return (
      <div className="highlight-bar-charts" style={{ userSelect: 'none', width: '100%', marginTop: "1rem"}}>
        <ResponsiveContainer height={250}>
          <LineChart
            // width={3000}
            // height={400}
            data={data}
            onMouseDown={(e) => this.setState({ refAreaLeft: e.activeLabel })}
            onMouseMove={(e) => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })}
            // eslint-disable-next-line react/jsx-no-bind
            onMouseUp={this.zoom.bind(this)}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis allowDataOverflow dataKey="index" type="number" domain={[left, right]} />
            <XAxis allowDataOverflow dataKey="date" tick={false} />
            <YAxis allowDataOverflow  type="number" yAxisId="1" tick={true} domain={[0, handleMaxHWMValue()]} />
            <YAxis orientation="right" allowDataOverflow type="number" yAxisId="2" tick={true} domain={[0, handleMaxHWMValue()]} />
            <Line yAxisId="1" type="natural" dataKey="nav" stroke="#A5C6AC" animationDuration={2000} dot={false} />
            <Line yAxisId="2" type="natural" dataKey="hwm" stroke="#566598" animationDuration={2000} dot={false} />
            <Tooltip content={<CustomTooltip />} position={{ y: -100 }} />
            {/* <Legend /> */}
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
        <div className="text-center">
          <button type="button" className={styles.zoomOutButton} onClick={this.zoomOut.bind(this)}>
            -
          </button>
        </div>
      </div>
    );
  }
}
