import React, { useEffect, useState } from 'react'
import styles from "./AllBenchmarks.module.scss"
import { usePostCandles } from '../../../query-hooks/benchmark-query-hooks/useBenchmark'
import { simpleErrorMessage } from '../../../Helpers/Helpers'

const AddCandleForm = ({ setAddCandle, benchmarkId }) => {
    const postCandles = usePostCandles()
    const [ payload, setPayload ] = useState({ date: "", close: "" })

    const handleCandlePost = () => {
        if (payload.date !== "" && payload.close !== "") {
            const date = new Date(payload.date)
            // Calculate Open Date
            const dateOpen = date.toISOString().slice(0, 19) + "+00:00"
            // Calculate Close Date
            date.setUTCMonth(date.getUTCMonth() + 1)
            const dateClose = date.toISOString().slice(0, 19) + "+00:00"
            return postCandles.mutate({ benchmarkId, payload : { dateOpen: dateOpen, dateClose: dateClose, close: payload.close, timeFrame: "Monthly" } })
        } simpleErrorMessage("You must enter the right values")
    }

    useEffect(() => {
        postCandles.isSuccess && setAddCandle(false)
    }, [ postCandles.isSuccess ])

    return (
        <tbody>
            <tr className={styles.tableStripsDark} style={{height:"4rem"}}>
                <td>
                    <input 
                        className={styles.datetime}
                        style={{height:"2rem", borderRadius:"8px"}}
                        type="month" 
                        min="2020-01" 
                        max="2030-06"
                        onChange={e => setPayload({ ...payload, date: e.target.value })} 
                    />
                </td>
                <td>                        
                    <input 
                        type="number"
                        placeholder="Price"
                        className={styles.tableInput}
                        style={{height:"2rem", borderRadius:"8px"}}
                        onChange={e => setPayload({...payload, close: e.target.value })}
                        onKeyDown={e => e.key === "Enter" && handleCandlePost()}
                    />
                </td>
                <td>
                    <button 
                        onClick={handleCandlePost}
                        className={styles.checkSymbol}
                        title="Save"
                    >✔
                    </button><button onClick={() => setAddCandle(false)} className={styles.XSymbol} title="Cancel">x</button>
                </td>
            </tr>
        </tbody> 
    )
}
export default AddCandleForm