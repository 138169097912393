import React, { useState, useEffect } from 'react'
import { Table } from "react-bootstrap";
import styles from "./AllOrders.module.scss";
import { Link } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";
import { useDeleteOrder, confirmationAlertDeleteOrder, useDeleteOrderWithTrades } from '../../query-hooks/order-query-hooks/useOrder';
import { BiUpArrowCircle, BiDownArrowCircle } from "react-icons/bi";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Logo from "../../images/Hodl-logo.jpg"
import { API_URL } from '../../settings';
import { numberEditor } from '../../Helpers/Helpers';
import EmptyMessageComponent from '../../Components/EmptyMessageComponent';
import CreatePercentageBar from './OrderFundings/CreatePercentageBar';
import EditPercentageBar from './OrderFundings/EditPercentageBar';
import PaginationAPI from '../../Components/PaginationAPI';
import Swal from 'sweetalert2';
import FileAttachment from '../../Components/Attachments/FileAttachment';

 const AllOrders = ({ orders, setPage }) => {
  const { darkmode } = useAuth();
  const [currentItems, setCurrentItems] = useState([]);
  const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
  const handleImageError = (e) => e.target.src = Logo // Handles img src error
  
  // Delete Order Handler
  const deleteOrder = useDeleteOrder() // Normal Delete Order Call
  const deleteOrderWithTrades = useDeleteOrderWithTrades()
  const [ orderIdDelete, setOrderIdDelete ] = useState()
  const handleDeleteOrder = (e, orderId) => {
    setOrderIdDelete(orderId)
    confirmationAlertDeleteOrder(e, orderId, deleteOrder)
  }

  useEffect(() => { 
    if (deleteOrder.isError && deleteOrder.error.response?.data.errors.some(x => x.code === "ItemIsReferenced" && orderIdDelete))  {
      const error = deleteOrder.error.response.data.errors.find(x => x.code === "ItemIsReferenced")
      Swal.fire({
        title: error.description, 
        text: "Do you want to delete this order anyway?", 
        icon: "warning", 
        showCancelButton: true, 
        confirmButtonColor: "#6d9e93",
        cancelButtonColor: "#d33", 
        confirmButtonText: "Yes, delete it!"
      }).then(result => result.isConfirmed && deleteOrderWithTrades.mutate({ orderId: orderIdDelete }) )
  }}, [ deleteOrder.isError, orderIdDelete ])

  useEffect(() => { deleteOrderWithTrades.isSuccess && setOrderIdDelete() }, [deleteOrderWithTrades]) // Reset OrderId to delete after delete order with trades is succesful
  return (
    <>
      <Link className={darkmode ? styles.createOrderButtonDark : styles.createOrderButton} to="/main/orders/create-order">Create an Order</Link>
      <Link className={darkmode ? styles.createOrderButtonDark : styles.createOrderButton} to="/main/orders/import-orders">Import Orders</Link>
      <Link className={darkmode ? styles.createOrderButtonDark : styles.createOrderButton} to="/main/orders/blockchain-import-orders">Import from Blockchain</Link>
      { orders.isSuccess && orders.data.items.length < 1 && <EmptyMessageComponent message="No Orders Found" />} 
      
      {currentItems &&
        currentItems.map((order) => (
          <div className={styles.cardtableDark} style={{margin:"5rem 0", boxShadow:"none", border:"solid .1px #6D9E93"}} key={order.id}>
            <Table responsive borderless={true} className={styles.tableStyle} key={order.id}>
              <thead>
                <tr className={darkmode ? styles.theaderDark : styles.theader}>
                  <th>Exchange</th>
                  <th>Order Number</th>
                  <th>Date</th>
                  <th>Direction</th>
                  <th>Base Asset</th>
                  <th>Quote Asset</th>
                  <th>Unit Price</th>
                  <th>Amount</th>
                  <th>Total</th> 
                  <th>State</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                  <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                    <td>{order.exchange}</td>
                    <td style={{fontSize: "11px"}}>{order.orderNumber}</td>
                    <td>{order.dateTime.slice(0,10)}</td>
                    <td>{ order.direction === "Buy" ?
                          <div className={styles.tooltip}>
                            <BiUpArrowCircle style={{color: "#6D9E93", fontSize: "33px"}} /> 
                            <span className={styles.tooltiptext}>{order.direction}</span>
                          </div> 
                            : 
                          <div className={styles.tooltip}>
                            <BiDownArrowCircle style={{color: "#CD5C5C", fontSize: "33px"}} />
                            <span className={styles.tooltiptext}>{order.direction}</span>
                          </div>
                        }
                    </td>
                    <td className={styles.tooltip}>
                      <img key={Date.now()} alt="icon" src={`${API_URL}/cryptos/${order.baseAsset.id}/icon`} width="30px" onError={ handleImageError } className={styles.cryptoIcons} />
                      <h5>{order.baseAsset.symbol}</h5>
                      <span className={styles.tooltiptext}>{order.baseAsset.name}</span>
                    </td>
                    <td className={styles.tooltip}>
                      <img key={Date.now()} alt="icon" src={`${API_URL}/cryptos/${order.quoteAsset.id}/icon`} width="30px" onError={ handleImageError } className={styles.cryptoIcons} />
                      <h5>{order.quoteAsset.symbol}</h5>
                      <span className={styles.tooltiptext}>{order.quoteAsset.name}</span>
                    </td>
                    <td title={order.unitPrice}>{numberEditor(order.unitPrice)} {order.quoteAsset.symbol}</td>
                    <td title={order.amount}>{numberEditor(order.amount)} {order.baseAsset.symbol}</td>
                    <td title={order.total}>{numberEditor(order.total)} {order.quoteAsset.symbol}</td>
                    <td>{order.state}</td>
                    <td><Link className={darkmode ? styles.editButtonDark : styles.editButton} to={`/main/orders/trades/${order.id}`}>Trades</Link></td>
                    <td><FileAttachment table="Orders" recordId={order.id} /></td>
                    <td>
                      <Link to={`/main/orders/edit-order/${order.id}`}>
                        <AiOutlineEdit title="Edit Order" className={styles.importIcons} />
                      </Link>
                    </td>
                    <td>
                      <AiOutlineDelete title="Delete Order" className={styles.importIcons} onClick={e => handleDeleteOrder(e, order.id)} />
                    </td>
                  </tr>
                  { order.orderFundings.length > 0 ? <EditPercentageBar order={order} /> : <CreatePercentageBar order={order} /> }
              </tbody>
            </Table>
          </div>
        ))}
      <PaginationAPI data={orders.isSuccess && orders.data} getCurrentItems={getCurrentItems} setPage={setPage} />
    </>
  )
}
export default AllOrders