import React, { useState, useEffect } from "react";
import styles from "./DateSliders.module.scss"
import MultiRangeSlider from "multi-range-slider-react";
import "./DateSliders.css"

const DateSliders = ({ dates, setDates }) => {
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(dates.length - 1);
    useEffect(() => {
      if (dates) {
        setMinValue(0);
        setMaxValue(dates.length - 1);
      }
    }, [dates]);

    const handleInput = e => {
      setMinValue(e.minValue);
      setMaxValue(e.maxValue);
    };

    const dateFormatter = dateString => {
      if (dateString) {
        const concatDate = dateString.slice(0, 4) + "-" + dateString.slice(4, 6)
        const date = new Date(concatDate)
        return date.toLocaleString("en-US", { month: "long", year: "numeric" })
      }
    } 

  if (dates) {
    return (
      <div className={styles.sliderContainer}>
        <MultiRangeSlider
          min={0}
          max={dates.length - 1}
          step={1}
          minValue={minValue}
          maxValue={maxValue}
          onInput={(e) => handleInput(e)}
          label={false}
          ruler={false}
          minCaption={dateFormatter(dates[minValue])}
          maxCaption={dateFormatter(dates[maxValue])}
          style={{border:"none", boxShadow: "none"}}
          barInnerColor="#6D9E93"
          thumbLeftColor="#6D9E93"
          thumbRightColor="#6D9E93"
        />
        <h5>{ dateFormatter(dates[minValue]) }</h5> 
        <h5 style={{float:"right"}}>{ dateFormatter(dates[maxValue]) }</h5>
        <div className={styles.datesDisplay}>
          <button onClick={() => setDates({ start: dates[minValue], end: dates[maxValue] })}>Submit</button>
        </div>
      </div>
    );
  }
};
    
  
export default DateSliders