import React from 'react'
import { Table } from 'react-bootstrap'
import { bookingPerioddateFormat, numberEditor } from '../../Helpers/Helpers'
import { useAuth } from '../../Hooks/useAuth'
import styles from "./Reports.module.scss"
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";
import EmptyMessageComponent from "../../Components/EmptyMessageComponent"
import ReportFundTransferLogsClasses from './ReportFundTransferLogsClasses'
import FileAttachment from '../../Components/Attachments/FileAttachment'

const ReportFundTransferLogs = ({ report, bookingPeriod }) => {
    const { darkmode } = useAuth()

    if (report.transferLog.length < 1) {
        return <EmptyMessageComponent message="No Trading Log found" />
    }

    return (
        <div className={styles.navCard}>
            <h5 className={styles.title}>Transfers Log</h5>
            <div>
                <div className={styles.navTags}>
                    <h5>Main <span>{ bookingPerioddateFormat(bookingPeriod) }</span></h5>
                </div>
                <Table responsive className="mt-4" borderless={true}>
                    <thead>
                        <tr className={styles.theader}>
                            <th>Transaction ID</th>  
                            <th>Date Time</th>
                            <th>Type</th>
                            <th>Direction</th>
                            <th>Asset</th>
                            <th>Amount (Shares)</th>
                            <th>Fee</th>
                            <th></th>
                        </tr>
                    </thead>
                    { report.transferLog && report.transferLog.map((transferLog, i) => (
                            <tbody key={i} style={transferLog?.oppositeTransfer ? (i % 2 === 0 ? {background:"#2F4858"} : {background:"#4F7A7F"}) : {}}>
                                <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                    <td>{ transferLog.transactionSource } : { transferLog.transactionId}</td>
                                    <td>{transferLog.dateTime.replace("T", " / ").replace("Z", "")}</td>
                                    <td>{transferLog.transactionType}</td>
                                    <td>{transferLog.direction === "In" ?
                                        <div className={styles.tooltip}>
                                            <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "33px"}} /> 
                                            <span className={styles.tooltiptext}>{transferLog.direction}</span>
                                        </div> 
                                            : 
                                            <div className={styles.tooltip}>
                                            <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "33px"}} />
                                            <span className={styles.tooltiptext}>{transferLog.direction}</span>
                                        </div>
                                        }
                                    </td>
                                    <td>{transferLog.currencySymbol} </td>
                                    <td title={transferLog.transferAmount}>
                                        {numberEditor(transferLog.transferAmount)} 
                                        {transferLog.transactionType === "Inflow" && ` (${numberEditor(transferLog.shares)})` } 
                                        {transferLog.transactionType === "Outflow" && ` (${numberEditor(transferLog.shares)})` } 
                                    </td>
                                    <td title={transferLog.transferFee}>{numberEditor(transferLog.transferFee)} {transferLog.transferFee > 0 && transferLog.feeCurrencySymbol}</td>
                                    <td><FileAttachment attachments={transferLog.attachments} /></td>
                                </tr>
                                {
                                    transferLog.oppositeTransfer && 
                                    <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips } style={i % 2 === 0 ? {background:"#2F4858"} : {background:"#4F7A7F"}}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            {   transferLog.oppositeTransfer.direction === "In" ?
                                                    <div className={styles.tooltip}>
                                                        <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "30px"}} /> 
                                                        <span className={styles.tooltiptext}>{transferLog.oppositeTransfer.direction}</span>
                                                    </div> 
                                                        : 
                                                    <div className={styles.tooltip}>
                                                        <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "30px"}} />
                                                        <span className={styles.tooltiptext}>{transferLog.oppositeTransfer.direction}</span>
                                                    </div>
                                            }
                                        </td>
                                        <td>{ transferLog.oppositeTransfer.currencySymbol }</td>
                                        <td>{ numberEditor(transferLog.oppositeTransfer.transferAmount) }</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }
                            </tbody>
                        ))
                    }
                </Table>
            </div>
            {
                ( report.classes.length > 0 && report.classes.map(fundClass => <ReportFundTransferLogsClasses key={fundClass.id} fundClass={fundClass} />  ))
            }
        </div>
    )
}
export default ReportFundTransferLogs;