import React from "react";
import { BiMessageSquareX } from "react-icons/bi";
import styles from "./EmptyMessageComponent.module.scss";

const EmptyMessageComponent = ({ message }) => {
  return (
    <div className="text-center">
      <BiMessageSquareX className={styles.emptyIconDark} />
      <h3 className={styles.emptyMessage}>{message}</h3>
    </div>
  );
};
export default EmptyMessageComponent;
