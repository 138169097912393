import React, { useState } from 'react'
import { Table, Row, Col } from 'react-bootstrap';
import styles from "./Reports.module.scss"
import { bookingPerioddateFormat, numberEditor } from '../../Helpers/Helpers';
import { useAuth } from '../../Hooks/useAuth';

const ReportFeePerformanceClasses = ({ fundClass }) => {
    const { darkmode } = useAuth()
    const [open, setOpen] = useState(false);

    if (!fundClass.inflowFees || fundClass.inflowFees.length < 1) {
        return
    }

    return (
        <div className={styles.conditionalContainerClasses}>
            <div>
                { open ? 
                    <p className={styles.toggleIcon} title="Show Less" onClick={() => setOpen(!open)}>-</p> 
                        : 
                    <p className={styles.toggleIcon} title="Show More" onClick={() => setOpen(!open)}>+</p> 
                }
            </div>
            {   open &&
                <div key={fundClass.id}>
                    <div className={styles.navTags}>
                        <h5>{ fundClass.className }</h5>
                    </div>
                    <Table responsive className="mt-4" borderless={true}>
                        <thead>
                            <tr className={darkmode ? styles.theaderDark : styles.theader}>
                                <th>Inflow Period</th>
                                <th>Shares In</th>
                                <th>Shares Out</th>
                                <th>HWM</th>
                                <th>Fee / Share</th>
                                <th>Fee (EUR)</th>
                            </tr>
                        </thead>
                            <tbody >
                                { fundClass.inflowFees.map((inflowFee, i) => (
                                    <tr key={i} className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                        <td>{bookingPerioddateFormat(inflowFee.bookingPeriod)}</td>
                                        <td title={inflowFee.sharesIn}>{ numberEditor(inflowFee.sharesIn)} </td>
                                        <td title={inflowFee.sharesOut}>{numberEditor(inflowFee.sharesOut)}</td>
                                        <td title={inflowFee.hwm}>{numberEditor(inflowFee.hwm)}</td>
                                        <td title={inflowFee.performanceFeePerShare}>{numberEditor(inflowFee.performanceFeePerShare)}</td>
                                        <td title={inflowFee.performanceFee}>{numberEditor(inflowFee.performanceFee)}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips } style={{fontWeight: "900", color: "#6D9E93"}}>
                                    <th colSpan="5">Total :</th>
                                    <td title={fundClass.nav.performanceFee}>{ numberEditor(fundClass.nav.performanceFee) }</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                    </Table>
                </div>
            }

            {
                !open &&
                    <Row>
                        <Col xl={4} className={styles.navTags}>
                            <h5>{ fundClass.className }</h5>
                        </Col>
                        <Col xl={4} className={styles.navTags}>
                            <h5><strong>Total Performance Fee </strong>{ numberEditor(fundClass.nav.performanceFee) }</h5>
                        </Col>
                        <Col xl={4} className={styles.navTags}>
                            <h5><strong>Management Fee </strong>{ numberEditor(fundClass.nav.managementFee) }</h5>
                        </Col>
                    </Row>
            }
        </div>
    )
}
export default ReportFeePerformanceClasses;