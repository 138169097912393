import React from 'react'
import { Col } from "react-bootstrap"
import styles from "../../dashboard/Banks/BankForm.module.scss"
import { useAuth } from '../../Hooks/useAuth'


const TextInput = ({fieldName, title, register, errors, placeHolder, isRequired, maxLength, minLength, ...props }) => {
    const { darkmode } = useAuth()
	return (
        <Col lg={props.fullWidth ? 12 : 6} md={12} className="py-3">
            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>{ title } { isRequired && <em className={styles.reqSymbol}>*</em> }</h6>
                        
            <input className={styles.fundFormInputs} placeholder= {placeHolder} autoComplete={props.autoComplete} {...register(fieldName, {
                required: {
                    value: isRequired,
                    message: "This is required"
                }, 
                maxLength: {
                    value: maxLength,
                    message: `Value must be maximum ${maxLength}`
                }, 
                minLength: {
                    value: minLength,
                    message: `Value must be minimum ${minLength}`
                },
            })} />

            { errors[fieldName] && (<span className={styles.formErrorNotice}>{ errors[fieldName].message }</span>) }
        </Col>
    ) 
} 

export default TextInput;