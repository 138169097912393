import React, { useEffect } from 'react'
import styles from "../Funds/FundDetail.module.scss"
import TransferFormMutation from './TransferFormMutation'

const TransferFormMutationStateHandler = ({ openFormCreateModalObject, setOpenFormCreateModalObject, bookingPeriod }) => {
    // Effect to close Modal when press down Esc onb keyboard
    useEffect(() => {
        const escapeTrigger = e => e.key === "Escape" && setOpenFormCreateModalObject(false)
        document.addEventListener("keydown", escapeTrigger)
        return () => document.removeEventListener("keydown", escapeTrigger)
    }, [])

    if (openFormCreateModalObject.fundId && !openFormCreateModalObject.transferId) {
        return (
            <div className={styles.sidepopupContainer}>
                <div style={{float:"right"}}>
                    <p className={styles.icons} onClick={() => setOpenFormCreateModalObject()}>X</p>
                </div>
                <TransferFormMutation 
                    fundId={openFormCreateModalObject.fundId} 
                    setOpenFormCreateModalObject={setOpenFormCreateModalObject} 
                    bookingPeriod={bookingPeriod}
                    action="Create Transfer"
                />
            </div>
        )
    }

    if (openFormCreateModalObject.fundId && openFormCreateModalObject.transferId) {
        return (
            <div className={styles.sidepopupContainer}>
                <div style={{float:"right"}}>
                    <p className={styles.icons} onClick={() => setOpenFormCreateModalObject()}>X</p>
                </div>
                <TransferFormMutation 
                    fundId={openFormCreateModalObject.fundId} 
                    transferId={openFormCreateModalObject.transferId} 
                    setOpenFormCreateModalObject={setOpenFormCreateModalObject} 
                    bookingPeriod={bookingPeriod}
                    action="Edit Transfer"
                />
            </div>
        )
    }

}
export default TransferFormMutationStateHandler;