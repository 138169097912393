import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useCrypto, useAllCryptoListings, useCreateCrypto, useEditCrypto } from "../../query-hooks/crypto-query-hooks/useCryptos";
import AllListings from "./CryptoListings/AllListings";
import styles from "./CryptoForm.module.scss";
import CryptoForm from "./CryptoForm";
import FormComponent from "../../Components/FormComponent";
import { Spinner, ErrorLoading, yearFromNow, today } from "../../Helpers/Helpers";
import { Row, Col } from "react-bootstrap"

const CryptoFormMutation = () => {
  const { crypto_id } = useParams();
  const cryptoData = useCrypto(crypto_id);
  const [ fromDate, setFromDate ] = useState(yearFromNow())
  const [ toDate, setToDate ] = useState(today())
  const [ page, setPage ] = useState(1)
  const allCryptoListings = useAllCryptoListings(crypto_id, fromDate, toDate, page)
  const navigate = useNavigate();
  const createCrypto = useCreateCrypto();
  const editCrypto = useEditCrypto()

  const onSubmit = data => {
    if (data.listingCryptoId === "1") {
      data.listingCryptoId = null
    }
    crypto_id ? editCrypto.mutate( {crypto: data, cryptoId: crypto_id} ) : createCrypto.mutate(data)
  } 
  useEffect(() => {editCrypto.isSuccess && navigate(-1)}, [editCrypto])
  useEffect(() => {createCrypto.isSuccess && navigate(-1)}, [createCrypto])

  return (
    <FormComponent title="Crypto Dashboard" subtitle={crypto_id ? "Edit CryptoCurrency" : "Create CryptoCurrency"}>
      { !crypto_id && <CryptoForm onSubmit={onSubmit} /> }
      { cryptoData.isSuccess && <CryptoForm onSubmit={onSubmit} data={cryptoData.data} /> }      
      <Row>
        <Col xl={2} className="d-flex justify-content-center">
          { crypto_id && <Link className={styles.createButton} to={`/main/cryptos/create-listing/${crypto_id}`}>Create Listing</Link> }
        </Col>
      </Row>  
      <Row className="my-5">
        <Col xl={6}></Col>
        <Col xl={3} className="my-2"><input className={styles.datetime} type="date" onChange={e => setFromDate(e.target.value)} defaultValue={yearFromNow()} /></Col>
        <Col xl={3} className="my-2"><input className={styles.datetime} type="date" onChange={e => setToDate(e.target.value)} defaultValue={today()} /></Col>
      </Row>
      <AllListings listings={allCryptoListings} setPage={setPage} />
      { allCryptoListings.isLoading && <Spinner /> } 
      { allCryptoListings.isError && <ErrorLoading /> }
    </FormComponent>
  );
};
export default CryptoFormMutation;
