import React, { useState } from 'react'
import CumulativeReturn from './Calculations/CumulativeReturn';
import SharpeRatioComponent from './Calculations/SharpeRatioComponent';
import BenchmarkInvestment from './Calculations/BenchmarkInvestment';
import styles from "./BenchmarkDashboard.module.scss"
import { Row, Col } from 'react-bootstrap';
import { quotesStringHandler } from '../../Helpers/Helpers';
import {  IoIosDownload } from "react-icons/io";

const BenchmarkTable = ({ data, investment, fund, quotesPercentages, handleGraphdataChange, tableRef, handleDownloadImage, handleDownloadSVG, benchmarkData, transformedFundName }) => {
    // Default Fund percentages for table
    const [ fundPercentagesState, setFundPercentagesState ] = useState([{ id: 0, percentage: 1 }, { id: 1, percentage: 3 }, { id: 2, percentage: 5 }]) 
    const [ zeroPercentSharpeRatio, setZeroPercentSharpeRatio ] = useState(0)

    const USDollar = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return (
        <>
            <div className={styles.dropdown} title="Download Chart Image">
                <IoIosDownload className={styles.downloadIcon} />
                <div className={styles.dropdownContent}>
                    <p onClick={() => handleDownloadImage("table", tableRef)}>Download as PNG</p>
                    <p onClick={() => handleDownloadSVG("table", tableRef)}>Download as SVG</p>
                </div>
            </div>
            <Row className={styles.tableContainer} ref={tableRef}>
                <Col xxl={4}>
                    <div className={styles.col1}>
                        <p className={styles.tableContent}>Total Return % (Cumulative)</p>
                        <p className={styles.tableContent}>Sharpe Ratio</p>
                        <p className={styles.tableContent}>Change in Sharpe Ratio</p>
                        <p className={styles.tableContent}>Return on a ${ investment / 1000000 } mln investment</p>
                    </div>
                </Col>
                <Col xxl={2}>
                    <h5 className={styles.tableTitle}>{ quotesStringHandler(quotesPercentages) }</h5> 
                    <div className={styles.col2}>
                        <div className={styles.tableContent}>
                            <CumulativeReturn 
                                data={data} 
                                investment={investment} 
                                fund={fund} 
                                fundPercentage={0} 
                                quotesPercentages={quotesPercentages} 
                                handleGraphdataChange={handleGraphdataChange} 
                            /> 
                        </div>
                        <div className={styles.tableContent}>
                            <SharpeRatioComponent 
                                data={data} 
                                investment={investment} 
                                fund={fund} 
                                fundPercentage={0} 
                                quotesPercentages={quotesPercentages} 
                                benchmarkData={benchmarkData}
                                setZeroPercentSharpeRatio={setZeroPercentSharpeRatio}
                            />  
                        </div>
                        <div className={styles.tableContent}>
                            <BenchmarkInvestment 
                                data={data} 
                                investment={investment} 
                                fund={fund} 
                                fundPercentage={0} 
                                quotesPercentages={quotesPercentages} 
                            />
                        </div>
                    </div>
                </Col>
                {   fundPercentagesState.map(fundPercentageState => (
                        <Col xxl={2} key={fundPercentageState.id}>
                            <h5 className={styles.tableTitle}>+{ fundPercentageState.percentage }% { transformedFundName }</h5>
                            <div className={styles[`col${fundPercentageState.id + 3}`]}>
                                <div className={styles.tableContent}>
                                    <CumulativeReturn 
                                        data={data} 
                                        investment={investment} 
                                        fund={fund} 
                                        fundPercentage={ fundPercentageState.percentage } 
                                        quotesPercentages={quotesPercentages} 
                                        handleGraphdataChange={handleGraphdataChange} 
                                    />
                                </div>
                                <div className={styles.tableContent}>
                                    <SharpeRatioComponent 
                                        data={data} 
                                        investment={investment} 
                                        fund={fund} 
                                        fundPercentage={ fundPercentageState.percentage } 
                                        quotesPercentages={quotesPercentages} 
                                        benchmarkData={benchmarkData}
                                        zeroPercentSharpeRatio={zeroPercentSharpeRatio}
                                    />  
                                </div>
                                <div className={styles.tableContent}>
                                    <BenchmarkInvestment 
                                        data={data} 
                                        investment={investment} 
                                        fund={fund} 
                                        fundPercentage={ fundPercentageState.percentage } 
                                        quotesPercentages={quotesPercentages} 
                                    /> 
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </>
    )
}
export default BenchmarkTable;