import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap';
import ImagesCarouselSingleImg from './ImagesCarouselSingleImg';
import styles from "./Attachments.module.scss"
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import "./ImagesCarousel.css"

const ImagesCarousel = ({ data, selectedImageIndex, setSelectedImageId }) => {
    const [ index, setIndex ] = useState(selectedImageIndex)
    const customNextIcon = <MdOutlineNavigateNext size={120} color="#6D9E93" />;
    const customPrevIcon = <MdOutlineNavigateBefore size={120} color="#6D9E93" />;
    
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <>
            <p onClick={() => setSelectedImageId()} className={styles.backToGalleryLink}>◄ Back to Gallery</p>
            <Carousel activeIndex={index} onSelect={handleSelect} interval={null} nextIcon={customNextIcon} prevIcon={customPrevIcon}>
                { data.map(image => (
                    <Carousel.Item key={image.id}>
                        <ImagesCarouselSingleImg imageData={image} />
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    )
}
export default ImagesCarousel;