import React, { useState, useRef } from 'react'
import { useReportFunds, useBookingPeriods, useReport, getPDFReport } from '../../query-hooks/report-query-hooks/useReport'
import { Row, Col } from "react-bootstrap"
import { useAuth } from '../../Hooks/useAuth'
import styles from "./Reports.module.scss"
import ReportFundHoldings from './ReportFundHoldings'
import { Spinner, ErrorLoading } from '../../Helpers/Helpers'
import ReportNAV from './ReportNAV'
import ReportFundTrades from './ReportFundTrades'
import ReportFundTransferLogs from './ReportFundTransferLogs'
import ReportFundTradingLogs from './ReportFundTradingLogs'
import ReportFeePerformance from './ReportFeePerformance'
import ReportAssetPricings from './ReportAssetPricings'

const Reports = () => {
    const { darkmode } = useAuth()
    const [ fund, setFund ] = useState()
    const [ period, setPeriod ] = useState()
    const periodInput = useRef()
    const reportFunds = useReportFunds()
    const bookingPeriods = useBookingPeriods(fund)
    const report = useReport(fund, period)
    const onHandleSelectedFund = fundId => {
        setFund(fundId)
        setPeriod()
        fund && (periodInput.current.selectedIndex = 0)
    } 
    const onHandleSelectedPeriod = period => setPeriod(period)
    return (
        <>
            <Row className="d-flex justify-content-center">
                <Col xl={6} md={12}>
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Fund<em className={styles.reqSymbol}>*</em></h6>
                    <select className={styles.selectInput} onChange={e => onHandleSelectedFund(e.target.value)} defaultValue="1">
                        <option disabled={true} value="1">Select a Fund</option>
                        { reportFunds.isSuccess && reportFunds.data.map(
                            fund => ( <option key={fund.id} value={fund.id}>{fund.fundName}</option> 
                            ))} 
                    </select> 
                </Col>
            </Row>
            {   fund &&
                <Row className="d-flex justify-content-center">
                    <Col xl={6} md={12}>
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Booking Period<em className={styles.reqSymbol}>*</em></h6>
                        <select className={styles.selectInput} onChange={e => onHandleSelectedPeriod(e.target.value)} defaultValue="1" ref={periodInput}>
                            <option disabled={true} value="1">Select a Booking Period</option>
                            { bookingPeriods.isSuccess && bookingPeriods.data.map(
                                (period, i) => ( <option key={i} value={period}>{period.slice(0,4) + "/" +  period.slice(4, 6)}</option> 
                                ))} 
                        </select> 
                    </Col>
                </Row>
            }

            {/* // Reports */}
            {
                report.isSuccess &&
                    <>
                        <div>
                            <h3 className={styles.reportTitle}>{ report.data.fundName }</h3>
                            <h2 className={styles.reportSubTitle}>BOOKING PERIOD {period.slice(0,4) + "/" +  period.slice(4, 6)}</h2>
                            <button className={styles.pdfButton} onClick={() => getPDFReport(fund, period) }>Download PDF Version</button>
                        </div>
                        <ReportNAV report={report.data} />
                        <ReportAssetPricings report={ report.data } reportingCurrencyCode={ report.data.reportingCurrencyCode } bookingPeriod={ period } />
                        <ReportFundHoldings report={ report.data } reportingCurrencyCode={report.data.reportingCurrencyCode} bookingPeriod={period} />
                        <ReportFundTrades report={ report.data } bookingPeriod={period} />
                        <ReportFeePerformance report={report.data} bookingPeriod={period} />
                        <ReportFundTransferLogs report={ report.data } bookingPeriod={period} />
                        <ReportFundTradingLogs report={ report.data } bookingPeriod={period} />
                    </>
            }
            { report.isLoading && <Spinner /> }{ report.isError && <ErrorLoading /> }
        </>
    )
}
export default Reports