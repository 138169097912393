import React, { useEffect, useState } from "react";
import { useSearchCryptos } from "../../query-hooks/crypto-query-hooks/useCryptos";
import AllCryptos from "./AllCryptos";
import DashboardComponent from "../../Components/DashboardComponent";
import styles from "./CryptoForm.module.scss"
import { Row, Col } from "react-bootstrap"
import { Spinner, ErrorLoading } from "../../Helpers/Helpers"
import { ImportListingsModal } from "./CryptoListings/ImportListingsModal";
import { Link } from "react-router-dom";

const CryptosDashboard = () => {
  const [ filterQuery, setFilterQuery ] = useState("")
  const [ page, setPage ] = useState(1)
  const [ importInput, setImportInput ] = useState(false)  // State to trigger display of Import Listings Window
  const cryptos = useSearchCryptos(filterQuery, page);
  useEffect(() => { setPage(1) }, [ filterQuery ]) // Sets first pagnation page when filterQuery changes

  return (
    <DashboardComponent title="Cryptos Dashboard" subtitle="Cryptos Dashboard">
      <Row>
        <Col xl={8} className={styles.dashboardButtonsWrapper}>
          <button className={styles.createCryptoButton}>
            <Link className={styles.linktoCreateCrypto} to="/main/cryptos/create-crypto">
              Create Crypto
            </Link> 
          </button>
          <button className={styles.importListingsButton} onClick={() => setImportInput(!importInput)}>
            Import Excel NAV Data
          </button> 
        </Col>
          { importInput && <ImportListingsModal setImportInput={setImportInput} /> }
        <Col xl={4} className="mb-5">
          <input className={styles.fundFormInputs} placeholder="Search..." onChange={e => setFilterQuery(e.target.value)}/>
        </Col>
      </Row>
      {cryptos.isLoading && <Spinner /> }
      {cryptos.isError && <ErrorLoading />}
      <AllCryptos cryptos={cryptos} setPage={setPage} />
    </DashboardComponent>
  );
};
export default CryptosDashboard;
