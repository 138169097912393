import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import WalletForm from "./WalletForm";
import { useWallet, useCreateWallet, useEditWallet } from "../../query-hooks/wallet-query-hook/useWallet";
import FormComponent from "../../Components/FormComponent";
import { ErrorLoading, Spinner } from "../../Helpers/Helpers";

const WalletFormMutation = () => {
    const navigate = useNavigate()
    const { address } = useParams();
    const wallet = useWallet(address)
    const createWallet = useCreateWallet()
    const editWallet = useEditWallet()
    const onSubmit = data => { 
        if (data.exchangeAccountId === "1" || data.exchangeAccountId === "") { 
            data.exchangeAccountId = null         
            if (address) {
                return editWallet.mutate({wallet: data, address: address})   
            } return createWallet.mutate(data)
        }
        if (address) {
            return editWallet.mutate({wallet: data, address: address})   
        } return createWallet.mutate(data)
    }
    useEffect(() => {createWallet.isSuccess && navigate(-1)}, [createWallet])
    useEffect(() => {editWallet.isSuccess && navigate(-1)}, [editWallet])
  
    return (
        <FormComponent title="Wallets Dashboard" subtitle={address ? "Edit Wallet" : "Create Wallet"}>
            { !address && <WalletForm onSubmit={onSubmit} /> }
            { wallet.isSuccess && <WalletForm onSubmit={onSubmit} data={wallet.data} /> }    
            { wallet.isLoading && <Spinner /> }
            { wallet.isError && <ErrorLoading /> }
        </FormComponent>
    );
};
export default WalletFormMutation;