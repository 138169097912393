import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "../../../FundForm.module.scss";
import useCurrencies, { useCurrency } from '../../../../../query-hooks/currency-query-hooks/useCurrency';
import { useFundLayers } from '../../../../../query-hooks/fund-query-hooks/useFund';

const CurrencyForm = ({ onSubmit, fundId, darkmode }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [ chosenCurrency, setChosenCurrency ] = useState()
    const currencies = useCurrencies();
    const currency = useCurrency(chosenCurrency)
    const layers = useFundLayers(fundId)

    useEffect(() => {
        (currency.isSuccess && currency.data.currencyRate) ? reset({startUSDPrice: 1 / currency.data.currencyRate.usdRate }) : reset({startUSDPrice: "" })
    }, [currency.isSuccess, currency.data])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose a currency</h6>
                <Col xl={5} md={4} className="py-1">
                    {   currencies.isSuccess &&
                        <select 
                            className={styles.selectInputDark} 
                            {...register("currencyISOCode", { required: true })} 
                            onChange={e => setChosenCurrency(e.target.value)} 
                            value={chosenCurrency}
                        > 
                            <option value="">Select a Currency</option> 
                            { currencies.data.items.map(currency => <option key={currency.isoCode} value={currency.isoCode}>{currency.name}</option>)} 
                        </select>
                    } 
                </Col>
            </Row>
            <Row className='justify-content-center my-5'>
                <Col xl={4} md={4}>
                    <Link className={darkmode ? styles.editButtonDark : styles.editButton} to="/main/currencies/create-currency" >+ Create Currency</Link>
                </Col>
            </Row>
            { chosenCurrency && 
                <>
                    <hr />
                    <Row className="justify-content-center">
                        <Col xl={5} md={8} className="py-1">
                            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Start Balance</h6> 
                            <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Start Balance" {...register("startBalance", { required: true, pattern: new RegExp(`^(\\d+(?:[\\.\\,]\\d{0,${chosenCurrency.decimals}})?)$`, 'gm') })} />
                            {errors.startBalance && errors.startBalance.type === "pattern" && (<span className={styles.formErrorNotice}>You have exceeded the maximum of decimals for this currency</span>)}
                            {errors.startBalance && errors.startBalance.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                            <p className={darkmode ? styles.decimalsNoticeDark : styles.decimalsNotice}><em>{ `*Max. of ${currency.isSuccess && currency.data.decimals} decimals for this currency` }</em></p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xl={5} md={8} className="py-1">
                            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Start USD Price</h6>
                            <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Start USD Price" {...register("startUSDPrice", { required: true, pattern: /^(\d+(?:[\.\,]\d{0,8})?)$/i })} />
                            {errors.startUSDPrice && errors.startUSDPrice.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                            {errors.startUSDPrice && errors.startUSDPrice.type === "pattern" && (<span className={styles.formErrorNotice}>Max decimal places: 8</span>)}
                        </Col>
                    </Row>
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Layer</h6>
                    <Row className="justify-content-center">
                        <Col xl={5} md={4} className="py-1">
                            <select className={styles.selectInput} {...register("layerIndex", { required: true, valueAsNumber: true })} defaultValue={0}> 
                            <option className={styles.selectOption} value={0} >Select a Layer</option>
                            { layers.isSuccess && layers.data.map(layer => (
                                <option key={layer.layerIndex} value={layer.layerIndex} className={styles.selectOption}>{layer.name}</option>
                                ))} 
                            </select>
                        </Col>
                    </Row>
                </>
            }
            <Row className="d-flex justify-content-center">
                <Col xl={4} md={6} xs={12}>
                    { chosenCurrency ? <input className={styles.submitButton} type="submit" /> : <input className={styles.submitButtonDisabled} type="submit" disabled={true} /> } 
                </Col>
            </Row>
        </Form>
      )
}
export default CurrencyForm;