import React, { useState } from 'react'
import { Table } from "react-bootstrap";
import styles from "./AllBanks.module.scss";
import { Link } from "react-router-dom";
import Pagination from '../../Components/Pagination';
import { useAuth } from "../../Hooks/useAuth";
import { useDeleteBank, confirmationAlertDeleteBank } from '../../query-hooks/bank-query-hooks/useBank';
import EmptyMessageComponent from '../../Components/EmptyMessageComponent';

 const AllBanks = ({items}) => {
  const { darkmode } = useAuth() || {}
  const [currentItems, setCurrentItems] = useState([]);
  const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
  const mutation = useDeleteBank()

  if (items.length < 1) {
    return (
      <>
        <EmptyMessageComponent message="No Banks have been created" />
      </>
    ) 
  }

  return (
    <>
      <Table responsive borderless={true} >
        <thead>
          <tr className={darkmode ? styles.theaderDark : styles.theader}>
            <th>Bank</th>
            <th>Bank Code</th>
            <th>City</th>
            <th>Country</th>
          </tr>
        </thead>
        {currentItems &&
          currentItems.map((bank) => (
            <tbody key={bank.id}>
              <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                <td>{bank.name}</td>
                <td>{bank.bic}</td>
                <td>{bank.city}</td>
                <td>{bank.country}</td>
                <td>
                  <Link className={styles.editButton} to={`/main/banks/edit-bank/${bank.id}`}>Edit</Link>
                </td>
                <td>
                    <button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteBank(e, bank.id, mutation)}>Delete</button>
                </td>
              </tr>
            </tbody>
          ))
        }
      </Table>
      <Pagination items={items} getCurrentItems={getCurrentItems} itemsPerPage={5} />
    </>
  )
}
export default AllBanks