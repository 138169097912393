import React from 'react'
import { Table } from 'react-bootstrap'
import { bookingPerioddateFormat, numberEditor } from '../../Helpers/Helpers'
import { useAuth } from '../../Hooks/useAuth'
import styles from "./Reports.module.scss"
import ReportFundHoldingsClasses from './ReportFundHoldingsClasses'

const ReportdFundHoldings = ({ report, reportingCurrencyCode, bookingPeriod }) => {
    const { darkmode } = useAuth()
    // Format to USD currency 
    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <div className={styles.navCard}>
            <h5 className={styles.title}>Holdings Overview</h5>
            <div>
                <div className={styles.navTags}>
                    <h5>Main <span>{ bookingPerioddateFormat(bookingPeriod) }</span></h5>
                </div>
                <Table responsive borderless={true} className={styles.table}>
                    <thead>
                        <tr className={darkmode ? styles.theaderDark : styles.theader}>
                            <th>Asset</th>
                            <th>Start { reportingCurrencyCode } Price</th>
                            <th>End { reportingCurrencyCode } Price</th>  
                            <th>Start Balance</th>
                            <th>End Balance</th>
                            <th>Base { reportingCurrencyCode } Value</th>
                            <th>End { reportingCurrencyCode } Value</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    { report.holdings && report.holdings.map((holding) => (
                        <tbody key={holding.id} >
                            <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                <td>{holding.symbol} </td>
                                <td title={holding.startUSDPrice / report.nav.previousReportingCurrencyRate}>{numberEditor(holding.startUSDPrice / report.nav.previousReportingCurrencyRate)} </td>
                                <td title={holding.endUSDPrice / report.nav.currencyRate.usdRate}>{numberEditor(holding.endUSDPrice / report.nav.currencyRate.usdRate)} </td>
                                <td title={holding.startBalance}>{numberEditor(holding.startBalance)}</td>
                                <td title={holding.endBalance}>{numberEditor(holding.endBalance)} </td>
                                <td title={(holding.endBalance * holding.startUSDPrice) / report.nav.previousReportingCurrencyRate}>{new Intl.NumberFormat().format(Math.round((holding.endBalance * holding.startUSDPrice) / report.nav.previousReportingCurrencyRate))}</td>
                                <td title={holding.endUSDValue / report.nav.currencyRate.usdRate}>{ new Intl.NumberFormat().format(Math.round(holding.endUSDValue / report.nav.currencyRate.usdRate)) } </td>
                                <td title={holding.endPercentage}>{numberEditor(holding.endPercentage)} % </td>
                            </tr>
                        </tbody>
                        ))
                    }
                    <tfoot>
                        <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips } style={{fontWeight: "900", color: "#6D9E93"}}>
                            <th colSpan="5">Totals :</th>
                            <td>{ numberEditor(report.holdings.map(holding => holding.navUSDValue / report.nav.currencyRate.usdRate).reduce((acc, curr) => acc + curr, 0)) }</td>
                            <td>{ numberEditor(report.holdings.map(holding => holding.endUSDValue / report.nav.currencyRate.usdRate).reduce((acc, curr) => acc + curr, 0)) }</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </Table>
            </div>
            {  report.classes.length > 0 && report.classes.map(fundClass => <ReportFundHoldingsClasses key={fundClass.id} fundClass={fundClass} reportingCurrencyCode={reportingCurrencyCode} report={report} />) }
        </div>
    )
}
export default ReportdFundHoldings