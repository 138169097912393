import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from "../../settings";
import Swal from "sweetalert2";
import { AlertFunction, simpleSuccessfulMessage } from "../../Helpers/Helpers";

// Function to get all orders
const fetchOrders = (orderNumber, ticker, filterFunded, page) => axios.get(`${API_URL}/orders`, {params: {orderNumber: orderNumber, cryptoSymbols: ticker, filterFunded: filterFunded, page: page}}).then(res => res.data);
export default function useOrders(orderNumber, ticker, filterFunded, page) {
  return useQuery(["orders", orderNumber, ticker, filterFunded, page], () => fetchOrders(orderNumber, ticker, filterFunded, page));
}

// Function to get detail of one order
const fetchOrder = (orderId) => axios.get(`${API_URL}/orders/${orderId}`).then(response => response.data);
export function useOrder(orderId) {
  return useQuery(["order", orderId], () => fetchOrder(orderId), {enabled: orderId !== undefined});
}

// Function to get Pairs of Crypto symbol from search input
const fetchPairs = (search) => axios.get(`${API_URL}/pairs/search?pairstring=${search}`).then(response => response.data) 
export function usePairs(search) {
  return useQuery(["pairs", search], () => fetchPairs(search), {enabled: search.length > 0});
}


// Function to get One Order Trades by its ID
const fetchOrderTrades = (orderId, page) => axios.get(`${API_URL}/orders/${orderId}/trades`, { params: { page: page, itemsPerPage: 30 }}).then(res => res.data);
export function useOrderTrades(orderId, page) {
  return useQuery(["trades", orderId, page], () => fetchOrderTrades(orderId, page));
}

// Function to get List of orders to Import from Binance
const fetchOrdersToImport = async (data) => {
  const { exchangeAccountId, pair, startTime, endTime } = data
  return await axios.get(`${API_URL}/orders/exchange/${exchangeAccountId}?pair=${encodeURIComponent(pair)}&startTime=${startTime}&endTime=${endTime}`).then(res => res.data)
} 
export function useOrderToImport(data) {
  return useQuery(["orders", data], () => fetchOrdersToImport(data), { enabled: data !== undefined, retry: 1 });
}

// Function to get all transactions from blockhain by wallet address
const getBlockchainTransactions = (walletAddress, startTime, endTime) => axios.get(`${API_URL}/orders/explorer/${walletAddress}?startTime=${startTime}&endTime=${endTime}`).then(res => res.data) 
export const useBlockchainTransactions = (walletAddress, startTime, endTime) => {
  return useQuery(["blockchainTransactions", walletAddress, startTime, endTime], () => getBlockchainTransactions(walletAddress, startTime, endTime), { enabled: startTime !== undefined && endTime !== undefined })
} 

// MUTATIONS
//Function to post an Order
const postOrder = (order) => axios.post(`${API_URL}/orders`, order).then(response => response.data);
export function useCreateOrder() {
  const queryClient = useQueryClient()
  return useMutation(order => postOrder(order),
    { onSuccess: async () => queryClient.invalidateQueries("orders"), onError: async (error) => AlertFunction(error)  }
  );
}

//Function to edit Order
const editOrder = (order, orderId) => axios.put(`${API_URL}/orders/${orderId}`, order).then(response => response.data);
export function useEditOrder() {
  const queryClient = useQueryClient()
  return useMutation(({ order, orderId }) => editOrder(order, orderId),
    { onSuccess: async () => queryClient.invalidateQueries("orders"), onError: async (error) => AlertFunction(error)  }
  );
}

//Function to edit Order's Fundings
const editOrderFundings = (orderFundings, orderId) => axios.put(`${API_URL}/orders/${orderId}/fundings`, orderFundings).then(res => res.data);
export const useEditOrderFundings = () => {
  const queryClient = useQueryClient()
  return useMutation(({ orderFundings, orderId }) => editOrderFundings(orderFundings, orderId),
    { onSuccess: async () => {
      queryClient.invalidateQueries("orders")
      queryClient.invalidateQueries("order")
      simpleSuccessfulMessage("Fundings Succesfully Added to Order")
    }, onError: async (error) => AlertFunction(error)  }
  );
}

//Function to delete Order
const deleteOrder = orderId => axios.delete(`${API_URL}/orders/${orderId}`).then(res => res.data);
export function useDeleteOrder() {
  const queryClient = useQueryClient()
  return useMutation(({orderId}) => deleteOrder(orderId), { onSuccess: async () => queryClient.invalidateQueries("orders") } );
}

// Message object inserted in delete message
const deleteMessageOrder = {
  title: "Are you sure you want to delete this order?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteOrder = (event, orderId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageOrder).then(result => result.isConfirmed && mutation.mutate({ orderId: orderId }) );
}

//Function to delete Order with trades inherited
const deleteOrderWithTrades = orderId => axios.delete(`${API_URL}/orders/${orderId}`, { params: { removeTrades: true } }).then(res => res.data);
export function useDeleteOrderWithTrades() {
  const queryClient = useQueryClient()
  return useMutation(({orderId}) => deleteOrderWithTrades(orderId), { onSuccess: async () => {
    queryClient.invalidateQueries("orders") 
    simpleSuccessfulMessage("The order has been deleted succesfully")
  }});
}

// TRADES MUTATION

//Function to post a Trade 
const postTrade = (orderId, tradeData) => axios.post(`${API_URL}/orders/${orderId}/trades`, tradeData).then(response => response.data);
export function useCreateTrade() {
  const queryClient = useQueryClient()
  return useMutation(({orderId, tradeData}) => postTrade(orderId, tradeData),
    { onSuccess: async () => queryClient.invalidateQueries("trades"), onError: async (error) => AlertFunction(error)  }
  );
}

//Function to delete trade
const deleteTrade = (orderId, tradeId) => axios.delete(`${API_URL}/orders/${orderId}/trades/${tradeId}`).then(response => response.data);
export function useDeleteTrade() {
  const queryClient = useQueryClient()
  return useMutation(({orderId, tradeId}) => deleteTrade(orderId, tradeId), 
    { onSuccess: async () => queryClient.invalidateQueries("trades"), onError: async (error) => AlertFunction(error)  } );
}

// Message object inserted in delete message
const deleteMessageTrade = {
  title: "Are you sure you want to delete this trade?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteTrade = (event, orderId, tradeId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageTrade).then(result => result.isConfirmed && mutation.mutate({ orderId: orderId, tradeId: tradeId }) );
}

//Function to delete more than one trade
const deleteManyTrades = (orderId, trades) => axios.delete(`${API_URL}/orders/${orderId}/trades`, { params: { tradeIds: trades }}).then(res => res.data);

export function useDeleteManyTrades() {
  const queryClient = useQueryClient()
  return useMutation(({ orderId, trades }) => deleteManyTrades(orderId, trades), 
    { onSuccess: async () => queryClient.invalidateQueries("trades") } );
}

// Message object inserted in delete message
const deleteMessageTrades = message => {
  return {
    title: `Are you sure you want to delete ${message} trades?`, 
    text: "You won't be able to revert this!", 
    icon: "warning", 
    showCancelButton: true, 
    confirmButtonColor: "#6d9e93",
    cancelButtonColor: "#d33", 
    confirmButtonText: "Yes, delete it!"
  }
}

export const confirmationAlertDeleteTrades = (event, orderId, trades, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageTrades("the selected")).then(result => result.isConfirmed && mutation.mutate({ orderId: orderId, trades: trades }) );
}

//Function to delete All Trades
const deleteAllTrades = orderId => axios.delete(`${API_URL}/orders/${orderId}/trades/all`).then(res => res.data);
export function useDeleteAllTrades() {
  const queryClient = useQueryClient()
  return useMutation(({ orderId }) => deleteAllTrades(orderId), 
    { onSuccess: async () => queryClient.invalidateQueries("trades") } );
}

export const confirmationAlertDeleteAllTrades = (event, orderId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageTrades("all")).then(result => result.isConfirmed && mutation.mutate({ orderId }) );
}


//Function to Import an Order by ExchangeAccountId 
const importOrder = (exchangeAccountId, orderBody) => axios.post(`${API_URL}/orders/exchange/${exchangeAccountId}/import`, orderBody).then(response => response.data)
export function useImportOrder() {
  const queryClient = useQueryClient()
  return useMutation(({exchangeAccountId, orderBody}) => importOrder(exchangeAccountId, orderBody),
    { onSuccess: async () => queryClient.invalidateQueries("orders") }
  );
}

// Function to import Trades from Excel Sheets
const importTrades = async (trades, orderId, feeCurrencyId) => axios.post(`${API_URL}/orders/${orderId}/trades/data/${feeCurrencyId}`, trades, { headers: {'Content-Type': 'application/json'}}).then(res => res.data);
export const useImportTrades = () => {
  const queryClient = useQueryClient()
  return useMutation( ({trades, orderId, feeCurrencyId}) => importTrades(trades, orderId, feeCurrencyId),
  {
    onSuccess: async () => {
      queryClient.invalidateQueries("trades")
      queryClient.invalidateQueries("order")
    }
  }
  );
}

// Function to import Blockchain Transactions
const importBlockchainTransactions = (payload, walletAddress, startTime, endTime) => axios.post(`${API_URL}/orders/explorer/${walletAddress}/import?startTime=${startTime}&endTime=${endTime}`, payload).then(res => res.data) 
export const useImportBlockchainTransactions = () => {
  const queryClient = useQueryClient()
  return useMutation(({ payload, walletAddress, startTime, endTime }) => importBlockchainTransactions(payload, walletAddress, startTime, endTime), { 
    onSuccess: async () => {
      queryClient.invalidateQueries("blockchainTransactions")
      simpleSuccessfulMessage("Transactions Imported Succesfully")
    } 
  })
} 

// Function to import Blockchain Transactions to Existing Order
const importBlockchainTransactionsToOrder = (payload, walletAddress, startTime, endTime, orderId) => axios.post(`${API_URL}/orders/explorer/${walletAddress}/import/${orderId}?startTime=${startTime}&endTime=${endTime}`, payload).then(res => res.data) 
export const useImportBlockchainTransactionsToOrder = () => {
  const queryClient = useQueryClient()
  return useMutation(({ payload, walletAddress, startTime, endTime, orderId }) => importBlockchainTransactionsToOrder(payload, walletAddress, startTime, endTime, orderId), { 
    onSuccess: async () => {
      queryClient.invalidateQueries("blockchainTransactions")
      simpleSuccessfulMessage("Transactions Imported Succesfully")
    } 
  })
} 

// Function to combine two blockchain transactions as trade only by wallet address
const importBlockchainTransactionsAsTrade = (payload, walletAddress, startTime, endTime) => axios.post(`${API_URL}/orders/explorer/${walletAddress}/import/manual?startTime=${startTime}&endTime=${endTime}`, payload).then(res => res.data) 
export const useImportBlockchainTransactionsAsTrade = () => {
  const queryClient = useQueryClient()
  return useMutation(({ payload, walletAddress, startTime, endTime }) => importBlockchainTransactionsAsTrade(payload, walletAddress, startTime, endTime), { 
    onSuccess: async () => {
      queryClient.invalidateQueries("orders")
      simpleSuccessfulMessage("Transactions Combined Succesfully")
    } 
  })
}

// Function to combine two blockchain transactions as trade linked to Order
const importBlockchainTransactionsAsTradeToOrder = (payload, walletAddress, startTime, endTime, orderId) => axios.post(`${API_URL}/orders/explorer/${walletAddress}/import/${orderId}/manual?startTime=${startTime}&endTime=${endTime}`, payload).then(res => res.data) 
export const useImportBlockchainTransactionsAsTradeToOrder = () => {
  const queryClient = useQueryClient()
  return useMutation(({ payload, walletAddress, startTime, endTime, orderId }) => importBlockchainTransactionsAsTradeToOrder(payload, walletAddress, startTime, endTime, orderId), { 
    onSuccess: async () => {
      queryClient.invalidateQueries("orders")
      simpleSuccessfulMessage("Transactions Combined Succesfully")
    } 
  })
} 

// ATTACHMENTS

// Function to get all attachments of one record
const getAttachments = (table, recordId) => axios.get(`${API_URL}/attachments/${table}/${recordId}`).then(res => res.data) 
export const useGetAttachments = (table, recordId) => useQuery(["attachments", table, recordId], () => getAttachments(table, recordId), { enabled: recordId !== undefined && recordId !== false  })

// Function to post attachment
const postAttachment = async (table, recordId, payload) => { 
  let formData = new FormData()
  formData.append("description", "")
  formData.append("location", "")
  formData.append("files", payload.files)
  return await axios.post(`${API_URL}/attachments/${table}/${recordId}`, formData).then(res => res.data) 
} 
export const usePostAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation(({ table, recordId, payload }) => postAttachment(table, recordId, payload), { 
    onSuccess: async () => {
      queryClient.invalidateQueries("attachments")
      simpleSuccessfulMessage("File saved!")
    } 
  })
} 


//Function to delete Attachment
const deleteAttachment = fileId => axios.delete(`${API_URL}/attachments/${fileId}`).then(res => res.data);
export function useDeleteAttachment() {
  const queryClient = useQueryClient()
  return useMutation(({fileId}) => deleteAttachment(fileId), {onSuccess: async () => queryClient.invalidateQueries("attachments")});
}

// Message object inserted in delete message
const deleteMessageAttachment = {
  title: "Are you sure you want to delete this File?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteAttachment = (event, mutation, fileId) => {
  event.preventDefault();
  Swal.fire(deleteMessageAttachment).then(result => result.isConfirmed && mutation.mutate({ fileId: fileId }) );
}