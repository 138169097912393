import React, { useEffect } from "react";
import BenchmarkForm from "./BenchmarkForm";
import { useBenchmarkData, useEditBenchmark, usePostBenchmark } from "../../../query-hooks/benchmark-query-hooks/useBenchmark";
import { Spinner, ErrorLoading } from "../../../Helpers/Helpers";

const BenchmarkFormMutation = ({ displayForm, setDisplayForm }) => {
  const benchmarkData = useBenchmarkData(displayForm.benchmarkId);
  const postBenchmark = usePostBenchmark()
  const editBenchmark = useEditBenchmark()
  const onSubmit = data => displayForm.benchmarkId ? editBenchmark.mutate({payload: data, benchmarkId: displayForm.benchmarkId}) : postBenchmark.mutate(data)
  useEffect(() => { postBenchmark.isSuccess && setDisplayForm({ benchmarkId: null, display: false }) }, [postBenchmark] ) 
  useEffect(() => { editBenchmark.isSuccess && setDisplayForm({ benchmarkId: null, display: false }) }, [editBenchmark] )

    if (!displayForm.benchmarkId) {
        return <BenchmarkForm onSubmit={onSubmit} setDisplayForm={setDisplayForm} /> 
    }

    if (benchmarkData.isSuccess) {
        return <BenchmarkForm onSubmit={onSubmit} data={benchmarkData.data} setDisplayForm={setDisplayForm} /> 
    }

    if (benchmarkData.isLoading) { 
        <Spinner />
    }

    if (benchmarkData.isError) { 
        <ErrorLoading />
    }
};
export default BenchmarkFormMutation;
