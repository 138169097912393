import React, { useEffect } from 'react'
import { useNavigate, useParams, Link } from "react-router-dom";
import FundBankAccountForm from './FundBankAccountForm';
import { useBankAccount, useCreateBankAccount, useEditBankAccount } from '../../../query-hooks/bank-query-hooks/useBank';
import FormComponent from '../../../Components/FormComponent';
import styles from "../FundForm.module.scss"
import BankAccountBalancesDashboard from '../../Banks/BankAccountBalances/BankAccountBalancesDashboard';

const FundBankAccountMutation = () => {
    const navigate = useNavigate();
    const { fundId, bankAccountId } = useParams()
    const bankAccount = useBankAccount(bankAccountId)
    const createBankAccount = useCreateBankAccount()
    const editBankAccount = useEditBankAccount()
    const onSubmit = data => {
        data.fundId = fundId
        bankAccountId ? editBankAccount.mutate({ bankAccountEdit: data, bankAccountId: bankAccountId }) : createBankAccount.mutate({ bankAccount: data })
    }
    useEffect(() => { createBankAccount.isSuccess && navigate(-1) }, [createBankAccount])
    useEffect(() => { editBankAccount.isSuccess && navigate(-1) }, [editBankAccount])

    return (
        <FormComponent title="Fund Bank Accounts" subtitle={bankAccountId ? "Edit Bank Account" : "Create Bank Account"}>
            { !bankAccountId && <FundBankAccountForm onSubmit={onSubmit} /> }
            { bankAccount.isSuccess && <FundBankAccountForm onSubmit={onSubmit} data={bankAccount.data} /> }
            { bankAccountId && <Link className={styles.createButton} to={`/main/funds/bank-account/balances/create-balance/${bankAccountId}`}>Add Balance</Link> }
            { bankAccountId && <BankAccountBalancesDashboard bankAccountId={bankAccountId}  /> }
        </FormComponent>
    );
};
export default FundBankAccountMutation 
