import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBank, useCreateBank, useEditBank, useBankAccounts } from "../../query-hooks/bank-query-hooks/useBank";
import BankForm from "./BankForm";
import FormComponent from "../../Components/FormComponent";
import { Spinner, ErrorLoading } from "../../Helpers/Helpers";
import BankAccounts from "./BankAccounts/BankAccounts";

const BankFormMutation = () => {
  const navigate = useNavigate();
  const { bankId } = useParams();
  const bank = useBank(bankId);
  const bankAccounts = useBankAccounts(bankId)
  const createBank = useCreateBank();
  const editBank = useEditBank()
  const onSubmit = data => bankId ? editBank.mutate( {bank: data, bankId: bankId} ) : createBank.mutate(data)
  useEffect(() => { createBank.isSuccess && navigate(-1) }, [createBank] ) 
  useEffect(() => { editBank.isSuccess && navigate(-1) }, [editBank] )

  return (
    <FormComponent title="Bank Dashboard" subtitle={bankId ? "Edit Bank" : "Create Bank"}>
      { !bankId && <BankForm onSubmit={onSubmit} /> }
      { bank.isSuccess && <BankForm onSubmit={onSubmit} data={bank.data} /> }  
      { bank.isLoading && <Spinner /> }
      { bank.isError && <ErrorLoading /> }    
      { bankAccounts.isSuccess && <BankAccounts items={bankAccounts.data.items} bankId={bankId} /> }
    </FormComponent>
  );
};
export default BankFormMutation;
