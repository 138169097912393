import React from "react";
import { Table } from "react-bootstrap";
import styles from "./Averages.module.scss"
import { useAuth } from "../../../Hooks/useAuth";
import { numberEditor, bookingPerioddateFormat } from "../../../Helpers/Helpers";

export const AveragesTableComponent = ({ averagePrices, orderDirection }) => {
  const { darkmode } = useAuth()

  return (
    <div className="mt-5">
      <h2 className={styles.title}><span>{ averagePrices.baseAssetSymbol }</span> { averagePrices.baseAssetName }</h2>
      <Table className={styles.averagesTable} responsive borderless={true} >
        <thead>
          <tr className={darkmode ? styles.theaderDark : styles.theader}>
            <th>Fund</th>
            <th>Coin Amount</th>
            <th>BTC {orderDirection === "Buy" ? "Cost" : "Gain" }</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
            { averagePrices.assetFundAggPriceStats.map(fund => (
                <tr key={fund.fundId} className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                  <td>{ fund.fundName }</td>
                  <td title={fund.amount}>{ numberEditor(fund.amount) }</td>
                  <td title={fund.total}>{ numberEditor(fund.total) }</td>
                  <td style={{background:"#6D9E93", color:"#FFFFFF"}}>{ numberEditor(fund.price) }</td>
                </tr>
                ))
            }
        </tbody>
      </Table>
        { averagePrices.bookingPeriodAggPriceStats.map(period => (
          <div key={period.bookingPeriod} className={styles.periodContainer} >
            <h3 className={styles.bookingTitle}>{ bookingPerioddateFormat(period.bookingPeriod) }</h3>
            <Table className={styles.averagesTable} responsive borderless={true}>
              <tbody>
                { period.fundAggPriceStats.map(fund => (
                    <tr key={fund.fundId} className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                      <td>{ fund.fundName }</td>
                      <td title={fund.amount}>{ numberEditor(fund.amount) }</td>
                      <td title={fund.total}>{ numberEditor(fund.total) }</td>
                      <td title={fund.price}>{ numberEditor(fund.price) }</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
          ))
        }
    </div>
  )
};
