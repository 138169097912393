import React, { useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import ExchangeForm from "./ExchangeForm";
import styles from "./ExchangeForm.module.scss";
import { useExchange, useExchangeAccounts, useCreateExchange, useEditExchange } from "../../query-hooks/exchange-query-hooks/useExchange"
import AllExchangeAccounts from "./ExchangeAccounts/AllExchangeAccounts";
import FormComponent from "../../Components/FormComponent";

const ExchangeFormMutation = () => {
    const navigate = useNavigate()
    const { exchangeId } = useParams()
    const exchange = useExchange(exchangeId)
    const exchangeAccounts = useExchangeAccounts(exchangeId)
    const createExchange = useCreateExchange()
    const editExchange = useEditExchange()  
    const onSubmit = data => exchangeId ? editExchange.mutate( {exchange: data, exchangeId: exchangeId } ) : createExchange.mutate(data)
    useEffect(() => { editExchange.isSuccess && navigate(-1) }, [editExchange]) 
    useEffect(() => { createExchange.isSuccess && navigate(-1) }, [createExchange])

    return (
        <FormComponent title="Exchanges Dashboard" subtitle={exchangeId ? "Edit Exchange" : "Create Exchange"}>
            { !exchangeId && <ExchangeForm onSubmit={onSubmit} /> }
            { exchange.isSuccess && <ExchangeForm onSubmit={onSubmit} data={exchange.data} /> }
            { exchange.isSuccess && <Link className={styles.createButton} to={`/main/exchanges/create-exchange-account/${exchangeId}`}>Create Exchange Account</Link> }
            { exchangeAccounts.data && <AllExchangeAccounts exchangeAccounts={exchangeAccounts.data.items} exchangeId={exchangeId} /> } 
        </FormComponent>
    );
};
export default ExchangeFormMutation