import React, { useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useBankAccount, useCreateBankAccount, useEditBankAccount } from "../../../query-hooks/bank-query-hooks/useBank";
import BankAccountForm from "./BankAccountForm";
import FormComponent from "../../../Components/FormComponent";
import { Spinner, ErrorLoading } from "../../../Helpers/Helpers";
import styles from "../../Funds/FundForm.module.scss"
import BankAccountBalancesDashboard from '../BankAccountBalances/BankAccountBalancesDashboard';

const BankAccountFormMutation = () => {
  const navigate = useNavigate();
  const { bankId, bankAccountId } = useParams();
  const bankAccount = useBankAccount(bankAccountId)
  const createBankAccount = useCreateBankAccount();
  const editBankAccount = useEditBankAccount()
  const onSubmit = data => {
    data.bankId = bankId
    if (bankAccountId) {
        editBankAccount.mutate( { bankAccountEdit: data, bankAccountId: bankAccountId } ) 
    } else {
        createBankAccount.mutate({ bankAccount: data })
    } 
  } 
  useEffect(() => { createBankAccount.isSuccess && navigate(-1) }, [createBankAccount] ) 
  useEffect(() => { editBankAccount.isSuccess && navigate(-1) }, [editBankAccount] )

  return (
    <FormComponent title="Bank Dashboard" subtitle={bankAccountId ? "Edit Bank Account" : "Create Bank Account"}>
      { !bankAccountId && <BankAccountForm onSubmit={onSubmit} /> } 
      { bankAccount.isSuccess && <BankAccountForm onSubmit={onSubmit} data={bankAccount.data} /> }  
      { bankAccount.isLoading && <Spinner /> }
      { bankAccount.isError && <ErrorLoading /> }    
      { bankAccountId && <Link className={styles.createButton} to={`/main/funds/bank-account/balances/create-balance/${bankAccountId}`}>Add Balance</Link> }
      { bankAccountId && <BankAccountBalancesDashboard bankAccountId={bankAccountId}  /> }
    </FormComponent>
  );
};
export default BankAccountFormMutation