import React from 'react'
import styles from "../styles/Login.module.scss";
import { useForm } from "react-hook-form";
import Logo from "../images/logo1.png";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LOGIN_API_URL } from '../settings';

const ResetPasswordInputToken = ({ email }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    await axios
      .post(`${LOGIN_API_URL}/auth/password/${email}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => navigate("/"))
      .catch((error) => {
          const err = error.response.data.errors
          err.map(errorDesc => alert(errorDesc.description))
      });
  };
  return (
    <main>
      <div>
        <Container className="d-flex justify-content-center">
          <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)} autoComplete="off" >
            <Row>
              <Col className="d-flex justify-content-center">
                <img className="mb-5" src={Logo} alt="Hodl" />
              </Col>
              <Col lg={12} xs={12} md={12}>
                <input type="email" {...register("email", { required: true })} placeholder="Enter your email again" />
                {errors.email && (<p className={styles.formErrorNotice}>This field is required</p>)}
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12} md={12} className="mt-3">
                <input type="password" {...register("password", { required: true })} placeholder="Enter the new password" />
                {errors.password && (<p className={styles.formErrorNotice}>This field is required</p>)}
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12} md={12} className="mt-3">
                <input type="text" {...register("token", { required: true })} placeholder="Enter the code sent to your email" />
                {errors.password && (<p className={styles.formErrorNotice}>This field is required</p>)}
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <input className={styles.registerButton} type="submit" value="Activate New Password" />
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </main>
  );
};

export default ResetPasswordInputToken;
