import React, { useState } from 'react'
import { Table } from "react-bootstrap";
import styles from "./AllWallets.module.scss";
import { Link } from "react-router-dom";
import Pagination from '../../Components/Pagination';
import { useDeleteWallet, confirmationAlertDeleteWallet } from '../../query-hooks/wallet-query-hook/useWallet'
import { useAuth } from "../../Hooks/useAuth";
import { timestampFormatter } from '../../Helpers/Helpers';

 const AllWallets = ({items}) => {
  const { darkmode } = useAuth();
  const [currentItems, setCurrentItems] = useState();
  const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
  const mutation = useDeleteWallet()

  return (
    <>
        <Table responsive borderless={true} >
          <thead>
            <tr className={darkmode ? styles.theaderDark : styles.theader}>
              <th>Date</th>
              <th>Address</th>
              <th>Description</th>
              <th>Exchange Account</th>
            </tr>
          </thead>
          {currentItems &&
            currentItems.map((wallet) => (
              <tbody key={wallet.address}>
                <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                  <td>{timestampFormatter(wallet.timestamp)}</td>
                  <td>{wallet.address}</td>
                  <td>{wallet.description}</td>
                  <td>{wallet.exchangeAccount && wallet.exchangeAccount.name}</td>
                  <td> <Link className={darkmode ? styles.editButtonDark : styles.editButton } to={`/main/wallets/balances/${wallet.address}`}>Balances</Link> </td>
                  <td> <Link className={styles.editButton} to={`edit-wallet/${wallet.address}`}>Edit</Link> </td>
                  <td> <button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteWallet(e, wallet.address, mutation)}>Delete</button> </td>
                </tr>
              </tbody>
            ))
          }
        </Table>
        <Pagination items={items} getCurrentItems={getCurrentItems} itemsPerPage={15} />
    </>
  )
}
export default AllWallets