import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import ListingForm from './ListingForm';
import { useCryptoListing, useCreateCryptoListing, useEditCryptoListing } from "../../../query-hooks/crypto-query-hooks/useCryptos"
import FormComponent from '../../../Components/FormComponent';

const ListingFormMutation = () => {
   
    const navigate = useNavigate();  
    const { crypto_id, listingId } = useParams()  
    const cryptoListing = useCryptoListing(crypto_id, listingId); 
    const createListing = useCreateCryptoListing()
    const editListing = useEditCryptoListing()
    const onSubmit = data => listingId ? editListing.mutate( {cryptoListing: data, cryptoId: crypto_id, listingId: listingId } ) : 
      createListing.mutate( {cryptoListing: data, cryptoId: crypto_id } )  
    useEffect(() => { editListing.isSuccess && navigate(-1) }, [editListing])  
    useEffect(() => { createListing.isSuccess && navigate(-1) }, [createListing]) 

    return (
      <FormComponent title="Listings Dashboard" subtitle={listingId ? "Edit Listing" : "Create Listing"}>
        { !listingId && <ListingForm onSubmit={onSubmit} /> }
        { cryptoListing.isSuccess && <ListingForm onSubmit={onSubmit} data={cryptoListing.data} /> } 
      </FormComponent>
    );
}
export default ListingFormMutation