import React from 'react'
import { Link } from 'react-router-dom'
import styles from "./ForbiddenMessage.module.scss"
import image from "../images/forbidden.svg"

const ForbiddenMessage = () => {
    return (
            <div>
                <img className={styles.image} src={image} alt="" />
                <h1 className={styles.title}>You are not authorized to access this resource</h1>
                <Link className={styles.link} to={-1}>Go back</Link>
            </div>
    )
}
export default ForbiddenMessage