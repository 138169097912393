import React, { useState } from 'react'
import { Table, Row } from "react-bootstrap";
import styles from "../AllWallets.module.scss";
import { Link } from "react-router-dom";
import PaginationAPI from '../../../Components/PaginationAPI';
import EmptyMessageComponent from '../../../Components/EmptyMessageComponent';
import { useDeleteWalletBalance, confirmationAlertDeleteWalletBalance } from '../../../query-hooks/wallet-query-hook/useWallet'
import { useAuth } from "../../../Hooks/useAuth";
import { numberEditor, timestampFormatter } from '../../../Helpers/Helpers';

 const AllWalletBalances = ({ walletBalances, setPage }) => {
    const { darkmode } = useAuth();
    const [currentItems, setCurrentItems] = useState();
    const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
    const mutation = useDeleteWalletBalance()

    if (walletBalances.isSuccess && walletBalances.data.items.length > 0) {
        return (
            <>
                <Table responsive="xl" borderless={true} >
                    <thead>
                        <tr className={darkmode ? styles.theaderDark : styles.theader}>
                            <th>Date</th>
                            <th>BlockChain Network</th>
                            <th>Description</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    {currentItems &&
                        currentItems.map((walletBalance) => (
                            <tbody key={walletBalance.id}>
                                <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                                    <td>{timestampFormatter(walletBalance.timestamp)}</td>
                                    <td>{walletBalance.blockchainNetwork?.name}</td>
                                    <td>{walletBalance.cryptoCurrency.name}</td>
                                    <td title={walletBalance.balance}>{numberEditor(walletBalance.balance)}</td>
                                    <td><Link className={styles.editButton} to={`/main/wallets/balances/edit-balance/${walletBalance.wallet.address}/${walletBalance.id}`}>Edit</Link></td>
                                    <td><button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteWalletBalance(e, walletBalance.wallet.address, walletBalance.id, mutation)}>Delete</button> </td>
                                </tr>
                            </tbody>
                        ))
                    }
                </Table>
                <PaginationAPI data={walletBalances.isSuccess && walletBalances.data} getCurrentItems={getCurrentItems} setPage={setPage} />
                <Row className="d-flex text-center mt-2 mb-5">
                    <Link className={styles.backLink} to="/main/wallets">Back</Link>
                </Row>
            </>
        )
    }   
    return (
        <>
            <EmptyMessageComponent message="There are no Balances for this Wallet" />
            <Row className="d-flex text-center mt-2 mb-5">
                <Link className={styles.backLink} to="/main/wallets">Back</Link>
            </Row>
        </>
    )
}
export default AllWalletBalances