import React, { useState } from 'react'
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { SetApiUrl, SetLoginApiUrl, SetGoogleClientId } from "./settings";

const fetchConfig = async (path) => {
  let response = await axios(path);
  if (response.status) {
    return response.data
  }
  throw new Error('No config')
};

const root = ReactDOM.createRoot(document.getElementById('root'));

fetchConfig("/version.json")
.then((data) => {
  localStorage.setItem("appVersion", data.appVersion)
})

fetchConfig("/config.json")
.then((data) => {
  SetApiUrl(data.td_api_url)
  SetLoginApiUrl(data.login_api_url)
  SetGoogleClientId(data.google_client_id)

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})
.catch(() => {
  root.render(
    <React.StrictMode>
      <div>Failed to fetch config</div>
    </React.StrictMode>
  );
})
