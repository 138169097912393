import React from "react";
import { Table } from "react-bootstrap";
import { bookingPerioddateFormat, numberEditor } from "../../Helpers/Helpers";
import { useAuth } from "../../Hooks/useAuth";
import DashboardTradingLogCollapseTable from "../Dashboard/Tables/DashboardTradingLogCollapseTable";
import { BiUpArrowCircle, BiDownArrowCircle } from "react-icons/bi";
import styles from "./Reports.module.scss";
import FileAttachment from "../../Components/Attachments/FileAttachment";

const ReportFundTradingLogs = ({ report, bookingPeriod }) => {
    const { darkmode } = useAuth();

    if (!report.tradeLog || report.tradeLog.length < 1) {
        return 
    }

    return (
    <>
        {   report.tradeLog.map((tradingLog, i) => (
                <div key={i} className={styles.navCard}>
                    <h5 className={styles.title}>Trading Log
                        <span className={styles.tradingLogsTitle}>{tradingLog.exchange} {tradingLog.orderNumber}</span>
                        <span title={tradingLog.dateTime} className={styles.tradingLogsTitle}>{tradingLog.dateTime.replace("T", " | ").replace("Z", "").slice(0, 21)}</span>
                    </h5>
                    <div className={styles.navTags}>
                        <h5>Main <span>{ bookingPerioddateFormat(bookingPeriod) }</span></h5>
                    </div>
                    <Table responsive className="mt-5" borderless={true}>
                        <thead >
                            <tr className={styles.theader} data-toggle="collapse" data-target=".multi-collapse1" aria-controls="multiCollapseExample1">
                                <th>Pair</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Order Price</th>
                                <th>Order Amount</th>
                                <th>Avg Price</th>
                                <th>Filled</th>
                                <th>Total</th>
                                <th></th>
                                <th>Fees</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={darkmode ? styles.tableStripsDark : styles.tableStrips}>
                                <td>{tradingLog.baseAssetSymbol + " / " + tradingLog.quoteAssetSymbol}</td>
                                <td>{ tradingLog.direction === "Buy" ?
                                        <div className={styles.tooltip}>
                                            <BiUpArrowCircle style={{color: "#6D9E93", fontSize: "33px"}} /> 
                                            <span className={styles.tooltiptext}>{tradingLog.direction}</span>
                                        </div> 
                                        : 
                                        <div className={styles.tooltip}>
                                            <BiDownArrowCircle style={{color: "#CD5C5C", fontSize: "33px"}} />
                                            <span className={styles.tooltiptext}>{tradingLog.direction}</span>
                                        </div>
                                    }
                                </td>
                                <td>{tradingLog.state} </td>
                                <td title={tradingLog.unitPrice}>{numberEditor(tradingLog.unitPrice)} {tradingLog.quoteAssetSymbol}</td>
                                <td title={tradingLog.amount}>{numberEditor(tradingLog.amount)} {tradingLog.baseAssetSymbol} </td>
                                <td title={tradingLog.filledTotal / tradingLog.filledAmount}>{numberEditor(tradingLog.filledTotal / tradingLog.filledAmount)} {tradingLog.quoteAssetSymbol}</td>
                                <td title={tradingLog.filledAmount}>{numberEditor(tradingLog.filledAmount)} {tradingLog.baseAssetSymbol} </td>
                                <td title={tradingLog.filledTotal}>{numberEditor(tradingLog.filledTotal)} {tradingLog.quoteAssetSymbol}</td>
                                <td><FileAttachment attachments={tradingLog.attachments} /></td>
                                <td>
                                    <ul className={darkmode ? styles.tableStripsDark : styles.tableStrips}>
                                        {tradingLog.totalFees.map((fee, i) => (
                                            <li key={i} style={{listStyle: "none", fontSize:"11px"}}>{fee.totalFee} {fee.feeCryptoSymbol}</li>
                                            ))}
                                    </ul>
                                </td>
                            </tr>
                            { (tradingLog.fundingPercentage > 0) && // Conditional rendering: if fund Percentage is 0, it wont display Fund data table 
                                <>
                                    <tr className={styles.theader}>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th colSpan="4">Funding</th>
                                        <th></th>
                                        <th>Fund Amount</th>
                                        <th>Fund Total</th>
                                        <th>Fund Fees</th>
                                    </tr>
                                    <tr className={darkmode ? styles.tableStripsDark : styles.tableStrips}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td colSpan="4">{tradingLog.fundingPercentage}%</td>
                                        <td></td>
                                        <td title={tradingLog.fundAmount}>{numberEditor(tradingLog.fundAmount)} {tradingLog.baseAssetSymbol}</td>
                                        <td title={tradingLog.fundTotal}>{numberEditor(tradingLog.fundTotal)} {tradingLog.quoteAssetSymbol}</td> 
                                        <td>
                                            <ul className={darkmode ? styles.tableStripsDark : styles.tableStrips}>
                                                {tradingLog.fundFees.map((fee, i) => (
                                                    <li key={i} style={{listStyle: "none", fontSize:"11px"}}>{fee.totalFee} {fee.feeCryptoSymbol}</li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                    <DashboardTradingLogCollapseTable trades={tradingLog.trades} quoteAsset={tradingLog.quoteAssetSymbol} baseAsset={tradingLog.baseAssetSymbol} />
                </div>
            ))}
        </>
    );
};
export default ReportFundTradingLogs;
