import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap"
import { useCategories, useCategoryGroups } from '../../query-hooks/category-query-hooks/useCategories'
import DashboardComponent from '../../Components/DashboardComponent'
import AllCategories from './AllCategories'
import styles from "./CategoryForm.module.scss"
import { Spinner, ErrorLoading } from "../../Helpers/Helpers"

const CategoriesDashboard = () => {
    const [ selectedGroup, setSelectedGroup ] = useState("")
    const categories = useCategories(selectedGroup)
    const categoryGroups = useCategoryGroups()
    return (
        <DashboardComponent title="Categories Dashboard" subtitle="Category Dashboard" toCreate="/main/categories/create-category" buttonMessage="Create a Category">
            <Row>
                <Col xl={8}></Col>
                <Col xl={4} className="mb-5 px-5">
                    <select className={styles.selectInput} onChange={e => setSelectedGroup(e.target.value)} defaultValue="1">
                        <option disabled={true} value="1" >Filter by group</option>
                        {
                            categoryGroups.isSuccess && categoryGroups.data.map((group, i) => (
                                <option key={i} value={group}>{group}</option>
                            ))
                        }
                    </select>
                </Col>
            </Row>
            {categories.isLoading && <Spinner /> }
            {categories.isError && <ErrorLoading />}
            {categories.isSuccess && <AllCategories items={categories.data.items} />}
        </DashboardComponent>
    )
}
export default CategoriesDashboard