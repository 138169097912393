/* eslint-disable max-classes-per-file */
import React, { PureComponent } from 'react';
import { Treemap, ResponsiveContainer, Tooltip, Label } from 'recharts';
import styles from "./NavCharts.module.scss"

const COLORS = ['#CACCBD', '#9ba5c6', '#a08895', '#566598', '#a08895', '#A5C6AC'];

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
        <div className={styles.navTooltip}>
          <p>{`Layer: ${payload[0].payload.root.layerName}`}</p>
          <p>{`Holding: ${payload[0].payload.holding}`}</p>
          <p>{`End Percentage: ${payload[0].payload.value.toFixed(4)}`}</p>
        </div>
        );
    }
    return null;
};


class CustomizedContent extends PureComponent {
    render() {
      const { root, depth, x, y, width, height, index, payload, colors, rank, layerName } = this.props;
      return (
        <g>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
              fill: depth < 2 ? colors[Math.floor((index / root.children.length) * 6)] : 'rgba(255,255,255,0)',
              stroke: '#fff',
              strokeWidth: 2 / (depth + 1e-10),
              strokeOpacity: 1 / (depth + 1e-10),
            }}
          />
          {depth === 1 ? (
            <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
              {layerName}
            </text>
          ) : null}
          {depth === 1 ? (
            <text x={x + 4} y={y + 18} fill="#fff" fontSize={16} fillOpacity={0.9}>
              {index + 1}
            </text>
          ) : null}
        </g>
      );
    }
  }
  
  export default class TreeMapChart extends PureComponent {
  
    render() {
        const holdings = this.props.holdings.reduce((acc, d) => {
            const found = acc.find(a => a.layerName === d.layerName);
            const value = { holding: d.displayName, endPercentage: d.endPercentage }; 
            if (!found) {
              acc.push({layerName: d.layerName, children: [value]}) 
            }
            else {
              found.children.push(value) 
            }
            return acc;
        }, []);
        // console.log("treemap", this.props.holdings)
        return (
            <ResponsiveContainer width="99%" height={500}>
                <Treemap
                    width={400}
                    height={200}
                    data={holdings && holdings.length > 0 ? holdings : [{layerName: "No Data", children: [ { holding: "No Data", endPercentage: 0 } ] }]}
                    dataKey="endPercentage"
                    aspectRatio={1 / 2}
                    stroke="#fff"
                    fill="#8884d8"
                    content={<CustomizedContent colors={COLORS} />}
                >
                <Tooltip content={<CustomTooltip /> } />
                </Treemap>
            </ResponsiveContainer>
        );
    }
  }
  