import React, { useState } from 'react'
import { useAuth } from '../../../Hooks/useAuth';
import styles from "./PercentageBar.module.scss"
import { COLORS } from '../../../Helpers/Helpers';
import PercentageBarComponent from "./PercentageBarComponent"

const EditPercentageBar = ({ order, orderId }) => {
    const { darkmode } = useAuth()
    const [ displayEditButton, setDisplayEditButton ] = useState(true)

    if (displayEditButton) {
        return (
            <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips}>
                <td style={{textAlign:"start"}}>
                    <button onClick={() => setDisplayEditButton(false)} className={styles.editButton}>Edit Fundings</button>
                </td>
                <td colSpan={12}>
                    <div style={{ display:"flex", flexDirection:"row" }}>
                    { order.orderFundings.map((order, i) => (
                        order.orderPercentage > 0 &&
                        <div key={i} style={darkmode ? 
                            { backgroundColor:COLORS[i], width:`${order.orderPercentage}%`, height:"2rem", borderRadius:"5px", lineHeight:"2rem" } : 
                            { border:`solid 1.5px ${COLORS[i]}`, color: COLORS[i], width:`${order.orderPercentage}%`, height:"2rem", borderRadius:"5px", lineHeight:"2rem" } 
                        }
                        >
                            <span>{order.fundName} {order.orderPercentage}%</span>
                        </div>
                        )) 
                    } 
                    </div> 
                </td>
            </tr>
        )
    } 
    return <PercentageBarComponent order={order} orderId={orderId} orderFundings={order.orderFundings} setDisplayEditButton={setDisplayEditButton} />
}
export default EditPercentageBar;