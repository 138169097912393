import React, { useState, useEffect, useRef } from 'react'
import { VscBell } from "react-icons/vsc";
import { useNotifications } from '../../query-hooks/notification-query-hooks/useNotification';
import styles from "./Notifications.module.scss"
import { CgMenuGridR } from "react-icons/cg";
import { useAuth } from '../../Hooks/useAuth';
import { Link } from 'react-router-dom';
import Sidepopup from './Sidepopup';

const Notifications = () => {
    const { logout } = useAuth()
    const [ notifications, setNotifications ] = useState([])
    const [ notificationId, setNotificationId ] = useState() // State to handle Notification ID to send to Popup component 
    const [ openNotificationsList, setOpenNotificationsList ] = useState(false) // Hook to trigger notifications list when click bell icon
    const [ openSettingsList, setOpenSettingsList ] = useState(false) // Hook to trigger Settings list when click squares icon

    const allNotifications = useNotifications()
    useEffect(() => { allNotifications.isSuccess && setNotifications(allNotifications.data.items); }, [allNotifications])

    const onHandleNotificationsList = () => { 
        setOpenNotificationsList(!openNotificationsList)
        setOpenSettingsList(false)
    }

    const onHandleSettingsList = () => {
        setOpenSettingsList(!openSettingsList)
        setOpenNotificationsList(false)
    }

    // Listener when click outside Notifications div it closes
    const notRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (notRef.current && !notRef.current.contains(event.target)) {
            setOpenNotificationsList(false)
          }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ ]);

    // Listener when click outside Settings div it closes
    const setRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (setRef.current && !setRef.current.contains(event.target)) {
            setOpenSettingsList(false)
          }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ ]);

    // Function to handle notification selected and display popup info
    const onHandleNotifPopup = notificationId => {
      setNotificationId(notificationId.target.value);  
      setOpenNotificationsList(false)
    } 

    return (
        <>  
            {/* Notification Icon List */}
            <div className={styles.dropdown}>
                <div onClick={() => onHandleNotificationsList()}>
                    <VscBell className={styles.dropbtn}  />
                    <span className={styles.redBubble}>{notifications.length}</span>
                </div>
                <div ref={notRef} className={styles.dropdownContent} style={openNotificationsList ? { display:"block" } : {} }>
                    <h2 className={styles.title}>Notifications</h2>
                    {
                        notifications.slice(0, 30).map(notification => (
                            <option key={notification.id} value={notification.id} onClick={(e) => onHandleNotifPopup(e)}>{notification.message}</option>
                        ))
                    }
                </div>
            </div>
            {/* Settings Icon List */}
            <div className={styles.dropdown}>
                <CgMenuGridR className={styles.dropbtnSettings} onClick={() => onHandleSettingsList()} />
                <div ref={setRef} className={styles.dropdownContentSettings} style={openSettingsList ? { display:"block" } : {}} >
                    <Link className={styles.settingsLink} to="/main/preferences" onClick={() =>  setOpenSettingsList(false)}>Preferences</Link>
                    <option onClick={logout}>Sign out</option>
                </div>
            </div>
            {
              notificationId && <Sidepopup notificationId={notificationId} setNotificationId={setNotificationId} />
            }
      </>
    )
}
export default Notifications