import React, { useEffect } from 'react'
import { Table } from "react-bootstrap";
import styles from "../AllOrders.module.scss";
import { BiUpArrowCircle, BiDownArrowCircle } from "react-icons/bi";
import { useAuth } from "../../../Hooks/useAuth";
import { numberEditor, simpleSuccessfulMessage } from '../../../Helpers/Helpers';
import EmptyMessageComponent from '../../../Components/EmptyMessageComponent';
import { useImportOrder } from '../../../query-hooks/order-query-hooks/useOrder';
import { Link } from 'react-router-dom';
import { BiImport, BiCheck, BiX, BiRefresh, BiDotsVerticalRounded, BiEdit } from "react-icons/bi";

const OrdersImportList = ({ ordersImportList, exchangeAccountId }) => {
    const { darkmode } = useAuth()
    const importOrder = useImportOrder()

    const onHandlerImportBody = async selectedOrderId => {
        const selectedOrderBody = await ordersImportList.find(order => order.orderId === selectedOrderId)
        importOrder.mutate({ exchangeAccountId: exchangeAccountId, orderBody: selectedOrderBody })
    } 

    useEffect(() => { importOrder.isSuccess && simpleSuccessfulMessage("Order imported successfully") }, [importOrder])

    if (ordersImportList && ordersImportList.length < 1) {
        return <EmptyMessageComponent message="No Orders Found" />
    }

    return (
        <>
          {ordersImportList &&
            ordersImportList.map((order) => (
                <Table responsive borderless={true} className={styles.tableStyle} key={order.orderId}>
                    <thead>
                        <tr className={darkmode ? styles.theaderDark : styles.theader}>
                            <th>Order ID</th>
                            <th>Date-Time</th>
                            <th>Pair</th>
                            <th>Direction</th>
                            <th>Unit Price</th>
                            <th>Amount</th>
                            <th>State</th>
                            <th>Imported</th>
                            <th></th>
                        </tr>
                    </thead>
                        <tbody>
                            <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                                <td>{order.orderId}</td>
                                <td>{order.dateTime.replace("T", " / ").replace("Z", "")}</td>
                                <td>{ order.baseAsset }/{ order.quoteAsset }</td>
                                <td>
                                    { order.direction === "Buy" ?
                                        <div className={styles.tooltip}>
                                            <BiUpArrowCircle style={{color: "#6D9E93", fontSize: "33px"}} /> 
                                            <span className={styles.tooltiptext}>{order.direction}</span>
                                        </div> 
                                        : 
                                        <div className={styles.tooltip}>
                                            <BiDownArrowCircle style={{color: "#CD5C5C", fontSize: "33px"}} />
                                            <span className={styles.tooltiptext}>{order.direction}</span>
                                        </div>
                                    }
                                </td>
                                <td>{numberEditor(order.unitPrice)} { order.quoteAsset }</td>
                                <td>{numberEditor(order.amount)} { order.baseAsset }</td>
                                <td>{order.state}</td>
                                <td>{ order.internalOrderId ? <BiCheck style={{color: "#6D9E93", fontSize: "25px"}} /> : <BiX style={{color: "#CD5C5C", fontSize: "25px"}} /> }</td>
                                <td>
                                    { 
                                        order.internalOrderId ? 
                                        <div className={styles.tooltip}>
                                            <BiDotsVerticalRounded className={styles.importIcons}  /> 
                                            <div className={styles.tooltiptextIcons}>
                                                <div style={{margin:"0.3rem 0"}}>
                                                    <BiRefresh style={{display:"inline-flex"}} className={styles.importIcons} onClick={() => onHandlerImportBody(order.orderId)}  />
                                                    <span style={{padding: "0.2rem", marginLeft:"0.1rem", color: "#6D9E93", fontWeight:"600"}}>Refresh</span>
                                                </div>
                                                <hr style={{margin:" 0.2rem 0"}} />
                                                <div>
                                                    <Link to={`/main/orders/edit-order/${order.internalOrderId}`} style={{textDecoration:"none", color: "#6D9E93", fontWeight:"600"}}>
                                                        <BiEdit className={styles.importIcons} />
                                                        <span style={{padding: "0.2rem", textDecoration:"none"}}>Edit Order</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div> : 
                                        <div className={styles.tooltip}>
                                            <BiImport className={styles.importIcons} onClick={() => onHandlerImportBody(order.orderId)} /> 
                                            <span className={styles.tooltiptext}>Import</span>
                                        </div>
                                    }                                   
                                </td>
                            </tr>
                        </tbody>
                </Table>
            ))}
        </>
      )
}
export default OrdersImportList