import React from "react";
import {Row, Col} from "react-bootstrap"
import styles from "./AllOrders.module.scss"
import { Spinner, ErrorLoading, numberEditor } from "../../Helpers/Helpers";
import { useOrder } from "../../query-hooks/order-query-hooks/useOrder";
import { BiTask } from "react-icons/bi";
import { useAuth } from "../../Hooks/useAuth";

const OrderMetadata = ({orderId}) => {
    const order = useOrder(orderId)
    const { darkmode } = useAuth()
    return ( 
        <Col xl={12} md={12} xs={12} className="mb-5">
            {order.isSuccess && (
                <>
                    { order.data.orderNumber ? <h2 className={styles.mainTitle}><BiTask className={styles.icons} /><span> {order.data.orderNumber}</span></h2> : <div />}
                    <Row>
                        <Col xl={6} md={6} xs={12} style={{padding:"2rem"}}>
                            <Row className={darkmode ? styles.cardDark : styles.card}>
                                <h5 className={`${styles.mainTitle} my-2`} style={{fontSize: "23px"}}>Order Summary</h5>
                                <Col xl={6} md={6}>
                                    <h5 className={styles.headerData}>{ order.data.baseAsset.symbol } / { order.data.quoteAsset.symbol } </h5><h5 className={styles.headerTitle}>coin pair</h5>
                                    <h5 className={styles.headerData}>{ order.data.direction }</h5><h5 className={styles.headerTitle}>direction</h5>
                                    <h5 className={styles.headerData}>{ order.data.state }</h5><h5 className={styles.headerTitle}>state</h5>
                                </Col>
                                <Col xl={6} md={6}>
                                    <h5 className={styles.headerData}>{numberEditor(order.data.amount)} {order.data.baseAsset.symbol}</h5><h5 className={styles.headerTitle}>amount</h5>
                                    <h5 className={styles.headerData}>{numberEditor(order.data.total)} {order.data.quoteAsset.symbol}</h5><h5 className={styles.headerTitle}>total</h5>
                                    <h5 className={styles.headerData}>{numberEditor(order.data.unitPrice)} {order.data.quoteAsset.symbol}</h5><h5 className={styles.headerTitle}>unit price</h5>  
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={6} md={6} xs={12} style={{padding:"2rem"}}>
                            <Row className={darkmode ? styles.cardDark : styles.card}>
                                <h5 className={`${styles.mainTitle} my-2`} style={{fontSize: "23px"}}>Trades Summary</h5>
                                <Col xl={6}>
                                    <h5 className={styles.headerData}>{numberEditor(order.data.executed)} {order.data.baseAsset.symbol}</h5><h5 className={styles.headerTitle}>total executed</h5>
                                    <h5 className={styles.headerData}>{numberEditor(order.data.totalCost)} {order.data.quoteAsset.symbol}</h5><h5 className={styles.headerTitle}>trades total</h5> 
                                    <h5 className={styles.headerData}>{numberEditor(order.data.averagePrice)} {order.data.quoteAsset.symbol}</h5><h5 className={styles.headerTitle}>average price</h5>
                                </Col>
                                <Col xl={6}>
                                    {   order.data.totalFees.length > 0 ?  order.data.totalFees.map((fee, i) => (
                                            <h5 key={i} className={styles.headerData}>{numberEditor(fee.feeSum)} {fee.feeCurrency.symbol}</h5>
                                        ))  : <div />
                                    }
                                    {   order.data.totalFees.length > 0 ? <h5 className={styles.headerTitle}>total fees</h5> : <div /> }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
            {order.isLoading && <Spinner />}{order.isError && <ErrorLoading />}
        </Col>
    );
};
export default OrderMetadata;