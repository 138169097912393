import React, { useState, useEffect, useRef } from "react";
import styles from "./OrderForm.module.scss";

const OrderSlider = ({ fieldNameId, fieldNameAmount, fund, register, amount, setValue, watch, totalFundingsFilled, setTotalFundingsFilled, amountPriceTicker, data }) => {
    const { id, fundName } = fund
    const inputEl = useRef()
    const [ amountPercentage, setAmountPercentage ] = useState(0)
    const [ percentage, setPercentage ] = useState() // Percentage state only to set input defaultValue on edit mode

    useEffect(() => { // Effect to set each Fund values in edit mode and set as Filled
        if (data) {
            setTimeout(() => {
                const filter = data.orderFundings.filter(order => order.fundName === fundName)
                filter.length > 0 ? setAmountPercentage(filter[0].orderAmount) : setAmountPercentage(0)
                filter.length > 0 ? setPercentage(filter[0].orderPercentage) : setPercentage(0)
                setTotalFundingsFilled(true) 
            }, [500])
        } 
    }, [data])
    
    useEffect(() => {
        getValue(inputEl.current.value);
    }, [amount])

    
    const getValue = val => { 
        const amountDecimalPositions = amount.toString().split(".")[1];
        const percentage = (val * amount) / 100
        setAmountPercentage(percentage.toFixed(amountDecimalPositions ? amountDecimalPositions.length : 2))
        setValue(fieldNameAmount, percentage)
        const orderFundings = watch("orderFundings")
        const orderFundingsAmountTotal = orderFundings ? orderFundings.reduce((acc, prev) => prev.orderAmount ? acc + prev.orderAmount : acc + 0, 0) : 0
        orderFundingsAmountTotal.toFixed(amountDecimalPositions ? amountDecimalPositions.length : 0) === amount.toString() ? setTotalFundingsFilled(true) : setTotalFundingsFilled(false)
    }

    return (
        <>
            <input defaultValue={id} type="hidden" {...register(fieldNameId)} />
            <div className={`${totalFundingsFilled ? styles.fundTitle : styles.fundTitleNotFilled} text-center`}>
                <span>{fundName.toUpperCase()}</span>
            </div>
            <div className={styles.valuesContainer}>
                <span>{amountPercentage}</span>
                <span> {data ? data.baseAsset.symbol : amountPriceTicker}</span>
            </div>
            <input
                ref={inputEl}
                defaultValue={percentage}
                className={styles.percentageInput}
                type="number"
                step="any"
                placeholder="Percentage"
                onWheel={(e) => e.currentTarget.blur()}
                autoComplete="off"
                onChange={e => getValue(e.target.value)}
            />
            <div className={styles.percentageLabel}>
                <span>%</span>
            </div>
        </>
    );
};
export default OrderSlider;
