import React, { useState, useEffect } from 'react'
import { Row, Col } from "react-bootstrap"
import styles from "./TransactionsCombiner.module.scss"
import { AiOutlineSwap  } from "react-icons/ai";
import ModalComponent from '../../../../Components/ModalComponent';
import { useGetExplorers } from '../../../../query-hooks/transfer-query-hooks/useTransfer';
import { numberEditor, simpleErrorMessage } from '../../../../Helpers/Helpers';
import { AiOutlineDelete } from "react-icons/ai";
import { RiDragDropFill } from "react-icons/ri";
import Logo from "../../../../images/Hodl-logo.jpg"
import { API_URL } from '../../../../settings';
import { MdOutlineKeyboardDoubleArrowRight, MdAddCircleOutline } from "react-icons/md";
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";
import { useImportBlockchainTransactionsAsTrade, useImportBlockchainTransactionsAsTradeToOrder } from '../../../../query-hooks/order-query-hooks/useOrder';

const TransactionsCombiner = ({ requestBody, orderId }) => {
    const [ modal, setModal ] = useState(false)
    const getExplorers = useGetExplorers(requestBody)
    const [ combination, setCombination ] = useState([ { baseTransactionHash: 0, quoteTransactionHash: 0 } ])
    const importBlockchainTransactionsAsTrade = useImportBlockchainTransactionsAsTrade()
    const importBlockchainTransactionsAsTradeToOrder = useImportBlockchainTransactionsAsTradeToOrder()
    
    const drop = (e) => {
        e.preventDefault();
        const transaction = e.dataTransfer.getData("transaction");
        const targetIndex = e.target.id;
        const targetName = e.target.getAttribute("name");
        const transactionObject = JSON.parse(transaction)
        if (targetName === "base") {
            return setCombination(prevCombination => {
                const updatedCombination = [...prevCombination]; // Create a copy of the current state
                updatedCombination[targetIndex].baseTransactionHash = transactionObject; // Update the property
                return updatedCombination; // Set the updated state
            });
        }
        if (targetName === "quote") {
            return setCombination(prevCombination => {
                const updatedCombination = [...prevCombination];
                updatedCombination[targetIndex].quoteTransactionHash = transactionObject;
                return updatedCombination; 
            });
        }
    }

    // Function to submit combinations
    const submitCombinations = () => {
        const hashes = combination.map(item => {
            if (item.baseTransactionHash === 0 || item.quoteTransactionHash === 0) {
                return undefined
            } else {
                return { baseTransactionHash: item.baseTransactionHash.transactionId, quoteTransactionHash: item.quoteTransactionHash.transactionId }
            }
        })
        const matchChecker = hashes.some(hash => hash === undefined)
        if (matchChecker) {
            return simpleErrorMessage("All combinations must have two transactions")
        }
        if (orderId) {
            return importBlockchainTransactionsAsTradeToOrder.mutate({ payload: hashes, walletAddress: requestBody.walletAddress, startTime: requestBody.startTime, endTime: requestBody.endTime, orderId: orderId  })
        }
        // If there is no OrderId, post transactions only by walletAddress
        return importBlockchainTransactionsAsTrade.mutate({ payload: hashes, walletAddress: requestBody.walletAddress, startTime: requestBody.startTime, endTime: requestBody.endTime  })
    }

    useEffect(() => { importBlockchainTransactionsAsTrade.isSuccess && setModal(false) }, [ importBlockchainTransactionsAsTrade.isSuccess ])

    // Function to format date 
    const formatDate = timestamp => {
        const date = new Date(timestamp);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate()
        const year = date.getFullYear()
        return {day, month, year}
    }
  

    if (!modal) {
        return (
            <Col xl={2} className='mb-5'>
                <button disabled={!requestBody.walletAddress} className={styles.blockchainSubmitButton} onClick={() => setModal(!modal)}><AiOutlineSwap style={{fontSize:"20px"}} /> Combine Transactions</button>
            </Col>
        )
    }

    const handleImageError = (e) => e.target.src = Logo // Handles img src error

    if (getExplorers.isSuccess) {
        return (
            <ModalComponent stateTrigger={setModal}>
                <Row className='mb-5'>
                    <Col xl={7}>
                        <h2 className={styles.combinersTitle}>Combine Transactions</h2>
                    </Col>
                    <Col xl={2}>
                        <div className={styles.tableDate} style={{float:"right"}}>
                            <span>{ formatDate(requestBody.startTime).day }</span>
                            <span> { formatDate(requestBody.startTime).month }</span>
                            <p>{ formatDate(requestBody.startTime).year }</p>
                        </div>
                    </Col>
                    <Col xl={1}>→</Col>
                    <Col xl={2}>
                        <div className={styles.tableDate} style={{float:"left"}}>
                            <span>{ formatDate(requestBody.endTime).day }</span>
                            <span> { formatDate(requestBody.endTime).month }</span>
                            <p>{ formatDate(requestBody.endTime).year }</p>
                        </div>
                    </Col>
                </Row>

                <div style={{padding:"0 5rem"}}>
                        <Row>
                            <Col xl={4} className={styles.explorersContainer}>
                            <Row>
                            {
                                [ ...getExplorers.data ].filter(explorer => !combination.some(item2 => item2.baseTransactionHash.transactionId === explorer.transactionId || item2.quoteTransactionHash.transactionId === explorer.transactionId)  ).map(transfer => (
                                    <Col
                                        xl={4} 
                                        key={transfer.transactionId}
                                        id={transfer.transactionId}
                                        draggable={true}
                                        onDragStart={e => e.dataTransfer.setData("transaction", JSON.stringify(transfer))}
                                        title={`Direction: ${transfer.direction.toUpperCase()}`}
                                    >
                                        <div className={styles.transactionCard}>
                                            <div className={styles.tableDate}>
                                                <span>{ formatDate(transfer.dateTime).day }</span>
                                                <span> { formatDate(transfer.dateTime).month }</span>
                                                <p>{ formatDate(transfer.dateTime).year }</p>
                                            </div>
                                            <div className={styles.ticker}>
                                                <img key={Date.now()} alt="icon" src={`${API_URL}/cryptos/${transfer.asset.id}/icon`} width="25px" onError={ handleImageError } className={styles.cryptoIcons} />
                                                <h5>{ transfer.asset.symbol }</h5>
                                            </div>
                                            { transfer.direction === "In" ?
                                                <div className={styles.tooltip}>
                                                    <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "30px"}} /> 
                                                </div> 
                                                    : 
                                                <div className={styles.tooltip}>
                                                    <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "30px"}} />
                                                </div>
                                            }
                                            <div className={styles.dataText}>
                                                <span>Amount </span>
                                                <p>{ numberEditor(transfer.transferAmount) }</p>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                        </Col>
                        <Col xl={1} style={{position:"relative"}}>
                            <button 
                                onClick={() => setCombination([ { baseTransactionHash: 0, quoteTransactionHash: 0 }, ...combination])} 
                                className={styles.addCombinations}
                                title="Add Combination Pair"
                            ><MdAddCircleOutline /></button>
                            <MdOutlineKeyboardDoubleArrowRight  className={styles.transactionIcon} />
                        </Col>
                        <Col xl={7}>
                            <div className={styles.explorersContainer}>
                                {   combination.map((item, index) => (
                                        <Row key={index} className='d-flex justify-content-center my-2'>
                                            <Col xl={5}>
                                                <div
                                                    id={index}
                                                    name='base' 
                                                    className={item.baseTransactionHash ? styles.fullDropContainer : styles.emptyDropContainer}
                                                    onDrop={e => drop(e)} 
                                                    onDragOver={e =>e.preventDefault()}
                                                >
                                                    { item.baseTransactionHash ?
                                                        <>
                                                            <Row name='base' id={index}>
                                                                <Col xl={6} name='base' id={index}>
                                                                <p style={{fontSize:"10px", margin:"0", color:"white"}}>Date</p>
                                                                    <div className={styles.tableDate} name='base' id={index}>
                                                                        <span>{ formatDate(item.baseTransactionHash.dateTime).day }</span>
                                                                        <span> { formatDate(item.baseTransactionHash.dateTime).month }</span>
                                                                        <p>{ formatDate(item.baseTransactionHash.dateTime).year }</p>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={6} name='base' id={index}>
                                                                    <div className={styles.ticker} name='base' id={index}>
                                                                        <p style={{fontSize:"10px", margin:"0", color:"white"}}>Token</p>
                                                                        <img key={Date.now()} alt="icon" src={`${API_URL}/cryptos/${item.baseTransactionHash.asset.id}/icon`} width="25px" onError={ handleImageError } className={styles.cryptoIcons} />
                                                                        <p style={{fontSize:"13px"}}>{ item.baseTransactionHash.asset.symbol }</p>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl={6}>
                                                                    <p style={{fontSize:"10px", margin:"0", color:"white"}}>Direction</p>
                                                                    { item.baseTransactionHash.direction === "In" ?
                                                                        <div className={styles.tooltip}>
                                                                            <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "30px"}} /> 
                                                                        </div> 
                                                                            : 
                                                                        <div className={styles.tooltip}>
                                                                            <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "30px"}} />
                                                                        </div>
                                                                    }
                                                                </Col>
                                                                <Col xl={6} name='base' id={index}>
                                                                    <p style={{fontSize:"10px", margin:"0", color:"white"}}>Amount</p>
                                                                    <p>{ numberEditor(item.baseTransactionHash.transferAmount) }</p>
                                                                </Col>
                                                            </Row> 
                                                        </> : <p name='base' id={index} style={{marginTop:"3rem"}}><RiDragDropFill className={styles.emptyIcon} name='base' id={index} /> <span name='base' id={index} style={{fontSize:"12px", verticalAlign:"middle"}}>Drop Transaction from Table</span></p>
                                                    }
                                                </div>
                                            </Col>
                                            <Col xl={5}>
                                                <div
                                                    id={index}
                                                    name='quote'
                                                    className={item.quoteTransactionHash ? styles.fullDropContainer : styles.emptyDropContainer}
                                                    onDrop={e => drop(e)} 
                                                    onDragOver={e => e.preventDefault()}
                                                >
                                                    { item.quoteTransactionHash ? 
                                                        <>
                                                        <Row name='quote' id={index}>
                                                            <Col xl={6} name='quote' id={index}>
                                                            <p style={{fontSize:"10px", margin:"0", color:"white"}}>Date</p>
                                                                <div className={styles.tableDate} name='quote' id={index}>
                                                                    <span>{ formatDate(item.quoteTransactionHash.dateTime).day }</span>
                                                                    <span> { formatDate(item.quoteTransactionHash.dateTime).month }</span>
                                                                    <p>{ formatDate(item.quoteTransactionHash.dateTime).year }</p>
                                                                </div>
                                                            </Col>
                                                            <Col xl={6} name='quote' id={index}>
                                                                <div className={styles.ticker} name='quote' id={index}>
                                                                    <p style={{fontSize:"10px", margin:"0", color:"white"}}>Token</p>
                                                                    <img key={Date.now()} alt="icon" src={`${API_URL}/cryptos/${item.quoteTransactionHash.asset.id}/icon`} width="25px" onError={ handleImageError } className={styles.cryptoIcons} />
                                                                    <p style={{fontSize:"13px"}}>{ item.quoteTransactionHash.asset.symbol }</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={6}>
                                                                <p style={{fontSize:"10px", margin:"0", color:"white"}}>Direction</p>
                                                                { item.quoteTransactionHash.direction === "In" ?
                                                                    <div className={styles.tooltip}>
                                                                        <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "30px"}} /> 
                                                                    </div> 
                                                                        : 
                                                                    <div className={styles.tooltip}>
                                                                        <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "30px"}} />
                                                                    </div>
                                                                }
                                                            </Col>
                                                            <Col xl={6} name='quote' id={index}>
                                                                <p style={{fontSize:"10px", margin:"0", color:"white"}}>Amount</p>
                                                                <p>{ numberEditor(item.quoteTransactionHash.transferAmount) }</p>
                                                            </Col>
                                                        </Row> 
                                                    </> : <p name='quote' style={{marginTop:"3rem"}} id={index}><RiDragDropFill className={styles.emptyIcon} name='quote' id={index} /> <span name='quote' id={index} style={{fontSize:"12px"}}>Drop Transaction from Table</span></p>
                                                }
                                                </div>
                                            </Col>
                                            <Col xl={1}>
                                                <p className={styles.removeCombination} onClick={() => setCombination(combination.filter((item, i) => i !== index ))} title="Remove Row"><AiOutlineDelete  /></p>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                        <Col xl={5}></Col>
                        <Col xl={7}>
                            <button className={styles.submitButton} onClick={() => submitCombinations()}>Submit</button>
                        </Col>
                    </Row>
                </div>
            </ModalComponent>
        )
    }
}
export default TransactionsCombiner;