import React, { useState } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from "../FundDetail.module.scss"
import Pagination from '../../../Components/Pagination'
import { useAuth } from '../../../Hooks/useAuth'
import { useDeleteFundLayer, confirmationAlertDeleteFundLayer} from "../../../query-hooks/fund-query-hooks/useFund"
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Spinner, ErrorLoading } from '../../../Helpers/Helpers'
import CollapsibleComponent from '../../../Components/CollapsibleComponent'

const FundLayerDashboardCard = ({ fundId, fund }) => {
  const { darkmode } = useAuth()
  const [currentItems, setCurrentItems] = useState();
  const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
  const deleteLayer = useDeleteFundLayer()
  
  return (
    <CollapsibleComponent title="Fund Layers">
      <Row className="mb-4">
        <Col lg={12} className={styles.addContainer}>Add a Layer <Link className={styles.addIcon} to={`/main/funds/create-fund-layer/${fundId}`}>+</Link></Col>
      </Row>
      <Table responsive borderless={true}>
      <thead>
        <tr className={styles.theader} >
          <th></th>  
          <th>Range Low Alert</th> 
          <th>Range High Alert</th>
          <th>Aim Percentage</th>
        </tr>
      </thead>
      { currentItems && currentItems.map((layer) => (
        <tbody key={layer.layerIndex}>
          <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
            <td style={{textAlign:"left"}}>{ layer.name }</td>
            <td>{ layer.alertRangeLow }</td>
            <td>{ layer.alertRangeHigh }</td>
            <td>{ layer.aimPercentage }</td>
            <td className={styles.tooltip}>
              <Link className={styles.icons} to={`/main/funds/edit-fund-layer/${fundId}/${layer.layerIndex}`}>
                <AiOutlineEdit />
                <span className={styles.tooltiptext}>Edit</span>
              </Link>
            </td>
            <td className={styles.tooltip} onClick={e => confirmationAlertDeleteFundLayer(e, layer.layerIndex, fundId, deleteLayer) }>
              <AiOutlineDelete className={styles.icons} />
              <span className={styles.tooltiptext}>Delete</span>
            </td>
          </tr>
        </tbody>
        )) 
      }
      </Table>
      { fund.isSuccess && <Pagination items={fund.data.layers} getCurrentItems={getCurrentItems} itemsPerPage={5} /> }
      { fund.isLoading && <Spinner /> } { fund.isError && <ErrorLoading error={fund.error} /> }
    </CollapsibleComponent>
  )
}
export default FundLayerDashboardCard
