import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "./FundForm.module.scss";
import useCryptos, { useBitcoin, useListingSources } from "../../query-hooks/crypto-query-hooks/useCryptos"; 
import useCurrencies, { useEuro } from "../../query-hooks/currency-query-hooks/useCurrency.js";
import { useFundsOwners } from "../../query-hooks/fund-query-hooks/useFund"
import { useAuth } from "../../Hooks/useAuth";
import { FUND_CLOSE_DAY } from "../../constants/enums.js"

const FundForm = ({ onSubmit, data }) => {
    const { darkmode } = useAuth();
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm()
    const cryptos = useCryptos()
    const currencies = useCurrencies()
    const fundsOwners = useFundsOwners()
    const bitcoin = useBitcoin()
    const euro = useEuro()
    const listingSources = useListingSources()

    useEffect(() => { 
        if (data && currencies.isSuccess && cryptos.isSuccess && fundsOwners.isSuccess) 
        return reset({ 
            fundName: data.fundName, 
            description: data.description, 
            maxVolume: data.maxVolume, 
            managementFee: data.managementFee, 
            managementFeeFrequency: data.managementFeeFrequency, 
            performanceFee: data.performanceFee, 
            totalShares: data.totalShares, 
            totalValue: data.totalValue,
            dateStart: data.dateStart?.slice(0, 10),
            dateEnd: data.dateEnd?.slice(0, 10),
            hwmResetPolicy: data.hwmResetPolicy,
            hwmResetDate: data.hwmResetDate?.slice(0, 10),
            reportingCurrencyCode: data.reportingCurrencyCode,
            primaryCryptoCurrencyId:  data.primaryCryptoCurrencyId,
            fundOwnerId: data.fundOwner.id,
            initialShareValue: data.initialShareValue,
            preferedListingSource: data.preferedListingSource,
            performanceFeePolicy: data.performanceFeePolicy,
            closeDay: data.closeDay
        });
    }, [data, currencies.isSuccess, cryptos.isSuccess, fundsOwners.isSuccess, reset]);

    useEffect(() => { !data && ( bitcoin.data && setValue("primaryCryptoCurrencyId", bitcoin.data.id )) }, [bitcoin]) // Effect to load Bitcoin dynamically as default value
    useEffect(() => { !data && ( euro.data && setValue("reportingCurrencyCode", euro.data.isoCode )) }, [euro]) // Effect to load Euro dynamically as default value
    useEffect(() => { !data && reset({managementFee: 2, managementFeeFrequency: 4, performanceFee: 20, initialShareValue: 1}) }, [bitcoin.data]) // Static default values

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Fund Name <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} placeholder="Fund Name" {...register("fundName", { required: true, maxLength: 40 })} />
                    {errors.fundName && errors.fundName.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    {errors.fundName && errors.fundName.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 40 digits exceeded</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose a Fund Owner</h6>
                    <select className={styles.selectInput} {...register("fundOwnerId")}> 
                        {fundsOwners.data && fundsOwners.data.items.map(fundOwner => (
                            <option key={fundOwner.id} value={fundOwner.id}>{fundOwner.name}</option>
                        ))} 
                    </select>
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Fund Description</h6>
                    <textarea className={styles.textarea} placeholder="Description (Optional)" {...register("description")} />
                    {errors.description && errors.description.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Share Symbol</h6>
                    <input className={styles.fundFormInputs} placeholder="Fund Name" {...register("shareSymbol", { maxLength: 10 })} />
                    {errors.shareSymbol && errors.shareSymbol.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 10 digits exceeded</span>)}
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Max Volume</h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Max Volume" {...register("maxVolume", { valueAsNumber: true, max: 2147483647 })} />
                    {errors.maxVolume && errors.maxVolume.type === "max" && (<span className={styles.formErrorNotice}>Max value: 2147483647</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Preferred Listing Source<em className={styles.reqSymbol}>*</em></h6>
                    <select className={styles.selectInput} {...register("preferedListingSource", {required: true})}> 
                        { listingSources.isSuccess && listingSources.data.map((listingSource, i) => (<option key={i} value={listingSource}>{listingSource}</option>))} 
                    </select>
                    {errors.preferedListingSource && errors.preferedListingSource.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose Reporting Currency</h6>
                    <select className={styles.selectInput} {...register("reportingCurrencyCode")}> 
                        {currencies.data && currencies.data.items.map(currency => (
                            <option key={currency.isoCode} value={currency.isoCode}>{currency.name}</option>
                        ))} 
                    </select>  
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose a Primary Crypto Currency</h6>
                    <select className={styles.selectInput} {...register("primaryCryptoCurrencyId")}> 
                        {cryptos.data && cryptos.data.map(crypto => (
                            <option key={crypto.id} value={crypto.id}>{crypto.name}</option>
                        ))} 
                    </select>
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose a Performance Fee Policy</h6>
                    <select className={styles.selectInput} {...register("performanceFeePolicy")}> 
                        <option value="FundHwm">FundHwm</option>
                        <option value="InflowDate">InflowDate</option>
                    </select>
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Management Fee Percentage<em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Management Fee" {...register("managementFee", { required: true, valueAsNumber: true })} />
                    {errors.managementFee && errors.managementFee.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Management Fee Frequency (Times per year)<em className={styles.reqSymbol}>*</em></h6>
                    <select className={styles.selectInput} {...register("managementFeeFrequency", { required: true, valueAsNumber: true })}>
                            <option value="">Select an option</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="4">4</option>
                            <option value="12">12</option>
                            <option value="365">365</option>
                    </select>
                    {errors.managementFeeFrequency && errors.managementFeeFrequency.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Close Day<em className={styles.reqSymbol}>*</em></h6>
                    <select className={styles.selectInput} {...register("closeDay", { required: true })}>
                            <option value="">Select an option</option>
                            { FUND_CLOSE_DAY.map(closeDay => <option key={closeDay.value} value={closeDay.value}>{ closeDay.name }</option>) }
                    </select>
                    {errors.closeDay && errors.closeDay.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Performance Fee Percentage<em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Performance Fee" {...register("performanceFee", { required: true, valueAsNumber: true })} />
                    {errors.performanceFee && errors.performanceFee.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Total Shares</h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Total Shares" {...register("totalShares", { valueAsNumber: true })} />
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Total Value</h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Total Value" {...register("totalValue", { valueAsNumber: true })} disabled={data} />
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Starting Date<em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.datetime} type="date" name="booking-period" {...register("dateStart", { required: true })}/>
                    {errors.dateStart && errors.dateStart.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Initial Share Value</h6>
                    <input className={styles.fundFormInputs} type="number" step="any" onWheel={e => e.currentTarget.blur()} placeholder="Initial Share Value" {...register("initialShareValue", { valueAsNumber: true })} />
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose Reset Policy Mode</h6>
                    <select className={styles.selectInput} {...register("hwmResetPolicy")}> 
                        <option value="None">None</option>
                        <option value="SetDate">Set a Fixed Date</option>
                        <option value="Yearly">Set it Yearly</option>
                    </select>  
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Reset Date</h6>
                    <input className={styles.datetime} type="date" name="hwm-resetDate" defaultValue={undefined} {...register("hwmResetDate")}/>
                </Col>
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>End Date</h6>
                    <input className={styles.datetime} type="date" name="booking-period" {...register("dateEnd")}/>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={4} md={12}>
                    <input className={styles.submitButton} type="submit" />
                </Col>
            </Row>
            <Row className="d-flex text-center mt-2 mb-5">
                <Link className={styles.backLink} to="/main/funds">Back</Link>
            </Row>
        </Form>
    )
}
export default FundForm