import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./AllListings.module.scss";
import { Table } from "react-bootstrap";
import { confirmationAlertDeleteListing } from "../../../query-hooks/crypto-query-hooks/useCryptos"
import { useAuth } from "../../../Hooks/useAuth";
import PaginationAPI from '../../../Components/PaginationAPI';
import { numberEditor, dateFormat } from "../../../Helpers/Helpers"
import EmptyMessageComponent from '../../../Components/EmptyMessageComponent';

const AllListings = ({ listings, setPage }) => {
  const { crypto_id } = useParams();
  const { darkmode } = useAuth()
  const [currentItems, setCurrentItems] = useState([]);
  const getCurrentItems = updatedItems => setCurrentItems(updatedItems)

  if (listings.isSuccess && listings.data.items.length > 0) {
    return (
      <>
        <Table responsive borderless={true} >
          <thead>
            <tr className={`${darkmode ? styles.theaderDark : styles.theader} align-middle text-center`}>
              <th>Date / Time</th>
              <th>Cmc Rank</th>
              <th>Source</th>
              <th>Listing BTC Price</th>
              <th>Listing USD Price</th>
            </tr>
          </thead>
          {  currentItems && currentItems.map((listing) => 
              <tbody key={listing.id}>
                <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle text-center`}>
                  <td>{dateFormat(listing.timeStamp)}</td>
                  <td>{listing.cmcRank}</td>
                  <td>{listing.source}</td>
                  <td>{numberEditor(listing.btcPrice)}</td>
                  <td>{numberEditor(listing.usdPrice)}</td>
                  <td><Link className={styles.editButton} to={`/main/cryptos/${crypto_id}/edit-listing/${listing.id}`}>Edit</Link></td>
                  <td><button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteListing(e, crypto_id, listing.id)}>Delete</button></td>
                </tr>
              </tbody> 
            )
          }
        </Table>
        <PaginationAPI data={listings.isSuccess && listings.data} getCurrentItems={getCurrentItems} setPage={setPage} />
      </>
    )
  }
  return <EmptyMessageComponent message="No Listings Found" />
};
export default AllListings;
