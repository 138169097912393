import React from "react";
import { useBankAccountBalances } from "../../../query-hooks/bank-query-hooks/useBank";
import AllBankAccountBalances from "./AllBankAccountBalances";
import { ErrorLoading, Spinner } from "../../../Helpers/Helpers"
import styles from "../AllBanks.module.scss"

const BankAccountBalancesDashboard = ({ bankAccountId }) => {
  const bankAccountBalances = useBankAccountBalances(bankAccountId);
  return (
    <>
      {bankAccountBalances.isLoading && <Spinner /> }{bankAccountBalances.isError && <ErrorLoading />}
      {bankAccountBalances.isSuccess && (bankAccountBalances.data.length < 1 ? (<h2 className={styles.noListing}>No balances</h2>) : (
      <AllBankAccountBalances items={bankAccountBalances.data} bankAccountId={bankAccountId} /> ))}
    </>
  );
};
export default BankAccountBalancesDashboard;
