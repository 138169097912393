import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "./CategoryForm.module.scss";
import { useAuth } from "../../Hooks/useAuth";

const FundCategoryForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth();
    
    useEffect(() => { data && reset({ name: data.name, description: data.description, group: data.group }) }, [data]);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter the Fund Category Name <em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.fundFormInputs} placeholder="Enter the Fund Category Name" {...register("name", { required: true, maxLength: 40 })} />
                        {errors.name && errors.name.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        {errors.name && errors.name.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 40 digits exceeded</span>)}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a description</h6>
                        <input className={styles.fundFormInputs} placeholder="Description" {...register("description")} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a group</h6>
                        <input className={styles.fundFormInputs} placeholder="Group" {...register("group", { maxLength: 128 })} />
                        {errors.group && errors.group.type === "maxLength" && (<span className={styles.formErrorNotice}>Max of 128 characters exceeded</span>)}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
                <Row className="d-flex text-center mt-2 mb-5">
                    <Link className={styles.backLink} to={-1}>Back</Link>
                </Row>
            </Form>
        </>
    )
}
export default FundCategoryForm
