import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from "../../settings";

// Function to get all Funds for Report
const fetchReportFunds = () => axios.get(`${API_URL}/reports/funds`).then(response => response.data);
export function useReportFunds() {
  return useQuery("reportFunds", fetchReportFunds);
}


// Function to get all Fund's Booking Period for Report
const fetchBookingPeriods = fundId => axios.get(`${API_URL}/reports/${fundId}/bookingperiods`).then(response => response.data);
export function useBookingPeriods(fundId) {
  return useQuery(["reportFunds", fundId], () => fetchBookingPeriods(fundId), { enabled: fundId !== undefined } );
}

// Function to get Fund's Report
const fetchReport = (fundId, bookingPeriod) => axios.get(`${API_URL}/reports/${fundId}/${bookingPeriod}`).then(response => response.data);
export function useReport(fundId, bookingPeriod) {
  return useQuery(["report", fundId, bookingPeriod], () => fetchReport(fundId, bookingPeriod), { enabled: bookingPeriod !== undefined } );
}

// Function to get PDF version of the Report
//export const getPDFReport = (fundId, bookingPeriod) => document.location = `${API_URL}/reports/${fundId}/${bookingPeriod}/pdf`
export const getPDFReport = (fundId, bookingPeriod) => {
  // Fetch the dynamically generated excel document from the server.
  axios
    .get(`${API_URL}/reports/${fundId}/${bookingPeriod}/pdf`, {responseType: 'blob'})
    .then((response) => {
      let filename = decodeURI(response.headers['content-disposition'].split(';')[1].split('=')[1] || `${fundId}_${bookingPeriod}_report.pdf`);
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
}

// Function to download Screenshots as ZIP File
export const downloadScreenshots = tag => {
  axios
    .get(`${API_URL}/listings/snapshot/${tag}/proof`, {responseType: 'blob'})
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `screenshots_${tag}.zip`); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
}