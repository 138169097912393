import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import styles from "./AllCategories.module.scss"
import { useAuth } from "../../Hooks/useAuth";
import { useDeleteCategory, confirmationAlertDeleteCategory } from "../../query-hooks/category-query-hooks/useCategories"

const AllCategories = ({ items }) => {
    const { darkmode } = useAuth() || {}
    const [currentItems, setCurrentItems] = useState([]);
    const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
    const deleteCategory = useDeleteCategory()

    return (
        <>
            <Table responsive borderless={true}>
                <thead>
                    <tr className={darkmode ? styles.theaderDark : styles.theader}>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Group</th>
                    </tr>
                </thead>
                {currentItems &&
                    currentItems.map(cat => (
                        <tbody key={cat.id}>
                            <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips } align-middle`} >
                                <td>{cat.name}</td>
                                <td>{cat.description}</td>
                                <td>{cat.group}</td>
                                <td><Link className={styles.editButton} to={`/main/categories/edit-category/${cat.id}`} >Edit</Link></td>
                                <td><button className={styles.deleteButton} onClick={e => confirmationAlertDeleteCategory(e, cat.id, deleteCategory)}>Delete</button></td>
                            </tr>
                        </tbody>
                ))}
            </Table>
            <Pagination items={items} getCurrentItems={getCurrentItems} itemsPerPage={10} />
        </>
    );
};
export default AllCategories;
