import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OrderForm from "./OrderForm";
import { useCreateOrder, useOrder, useEditOrder } from "../../query-hooks/order-query-hooks/useOrder";
import FormComponent from "../../Components/FormComponent";
import { Spinner } from "../../Helpers/Helpers"

const OrderFormMutation = () => {
  const navigate = useNavigate();
  const { orderId } = useParams()
  const order = useOrder(orderId)
  const createOrder = useCreateOrder()
  const editOrder = useEditOrder()

  const onSubmit = data => {
    if (data.exchangeAccountId === "1" || data.exchangeAccountId === "") { 
      data.exchangeAccountId = null         
    } 
    orderId ? editOrder.mutate( { order: data, orderId: orderId } ) : createOrder.mutate(data)
  }

  useEffect(() => { createOrder.isSuccess && navigate(`/main/orders/trades/${createOrder.data.id}`) }, [createOrder])
  useEffect(() => { editOrder.isSuccess && navigate(`/main/orders/trades/${orderId}`) }, [editOrder])

  return (
    <FormComponent title="Orders Dashboard" subtitle={orderId ? "Edit Order" : "Create Order"}>
      { !orderId && <OrderForm onSubmit={onSubmit} /> }
      { order.data && <OrderForm onSubmit={onSubmit} data={order.data} /> }
      { order.isLoading && <Spinner /> }{ order.isError && <p>Error: {order.error.message}</p> }
    </FormComponent>
  );
};
export default OrderFormMutation;