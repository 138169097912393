import React, { useEffect } from "react"
import Swal from "sweetalert2"

const VersionChecker = ({ children }) => {
  const reloader = () => {
    Swal.fire({
      title: "A new version of the App has been released. You must refresh the page to get the latest version",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6d9e93",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, refresh the page",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload()
      }
    })
  }
  const checkVersion = () => {
    fetch("/version.json")
      .then(res => res.json())
      .then(data => data.appVersion !== localStorage.getItem("appVersion") && reloader());
  }

  useEffect(() => {
    const interval = setInterval(checkVersion, 5*60000);

    return () => clearInterval(interval);
  }, []);

  return <>{ children }</>;
};
export default VersionChecker;
