import React from 'react'
import { Table, Row, Col } from 'react-bootstrap';
import ButtonComponent from '../../../Components/FormInputs/ButtonComponent';
import { useNavigate } from 'react-router-dom';
import styles from "./AllClasses.module.scss"
import { useAuth } from '../../../Hooks/useAuth';
import { Link } from 'react-router-dom';
import { useDeleteFundClass, confirmationAlertDeleteFundClass } from '../../../query-hooks/fund-query-hooks/useFund';
import EmptyMessageComponent from '../../../Components/EmptyMessageComponent';

const AllClasses = ({ classes, fundId }) => {
    const navigate = useNavigate()
    const toCreateClass = () => navigate(`/main/funds/${fundId}/create-fund-class`);
    const { darkmode } = useAuth()
    const deleteFundClass = useDeleteFundClass()

    if (classes.length < 1) {
        return (
            <>
                <Row className='my-5'>
                    <Col>
                        <ButtonComponent label="Create Class" fn={toCreateClass} />
                    </Col>
                </Row>
                <EmptyMessageComponent message="No Fund Class Created" /> 
            </>
        )
    }

    return (
        <>
            <Row className='my-5'>
                <Col>
                    <ButtonComponent label="Create Class" fn={toCreateClass} />
                </Col>
            </Row>
            <Table responsive borderless={true} >
                <thead>
                    <tr className={darkmode ? styles.theaderDark : styles.theader}>
                        <th>Class Name</th>
                        <th>Performance Fee</th>
                        <th>Management Fee</th>
                    </tr>
                </thead>
                {   classes.map(fundClass => (
                        <tbody key={fundClass.id}>
                            <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                                <td>{ fundClass.className }</td>
                                <td>{ fundClass.performanceFee }</td>
                                <td>{ fundClass.managementFee }</td>
                                <td>
                                    <Link className={styles.editButton} to={`/main/funds/${fundId}/${fundClass.id}/edit-fund-class`}>Edit</Link>
                                </td>
                                <td>
                                    <button 
                                        className={styles.deleteButton} 
                                        onClick={e => confirmationAlertDeleteFundClass(e, fundId, fundClass.id, deleteFundClass)}
                                    >Delete
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    ))
                }
            </Table>
        </>
    )
}
export default AllClasses;