import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import styles from "../FundForm.module.scss";
import FundsOwnerForm from './FundsOwnerForm';
import { useCreatefundOwner } from "../../../query-hooks/fund-query-hooks/useFund"

const FundsOwnerFormCreate = () => {
  const navigate = useNavigate();
  const mutation = useCreatefundOwner()
  const onSubmit = data => mutation.mutate(data)
  useEffect(() => { mutation.isSuccess && navigate(-1) }, [mutation])
  return (
    <Container>
      <h5 className={styles.subTitle}>Funds Owner Dashboard</h5>
      <h2 className={styles.mainTitle}>Create a new Fund Owner</h2>
      <FundsOwnerForm onSubmit={onSubmit} />
    </Container>
  );
};

export default FundsOwnerFormCreate;