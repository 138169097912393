import React from 'react'
import { Table, Row, Col } from 'react-bootstrap'
import EmptyMessageComponent from '../../../Components/EmptyMessageComponent'
import styles from "../AllBanks.module.scss";
import { useAuth } from "../../../Hooks/useAuth";
import { Link } from 'react-router-dom';
import { useDeleteBankAccount, confirmationAlertDeleteBankAccount } from '../../../query-hooks/bank-query-hooks/useBank';

const BankAccounts = ({ items, bankId }) => {
    const { darkmode } = useAuth()
    const deleteAccount = useDeleteBankAccount()
    if (items.length < 1) {
        return (
            <>
                { bankId && <Link className={styles.deleteButton} to={`/main/banks/create-bank-account/${bankId}`}>Create Bank Account</Link> }
                <EmptyMessageComponent message="No Accounts linked to this Bank" />
            </>
        )
    }
    return (
        <>
            { bankId && <Link className={styles.deleteButton} to={`/main/banks/create-bank-account/${bankId}`}>Create Bank Account</Link> }
            <Table responsive borderless={true} className="mt-5">
                <thead>
                    <tr className={darkmode ? styles.theaderDark : styles.theader}>
                        <th>IBAN</th>
                        <th>Holder Name</th>
                        <th>Fund</th>
                    </tr>
                </thead>
                {items &&
                    items.map((account) => (
                        <tbody key={account.id}>
                        <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                            <td>{ account.iban}</td>
                            <td>{ account.holderName}</td>
                            <td>{ account.fund.fundName }</td>
                            <td>
                                <Link className={styles.editButton} to={`/main/banks/edit-bank-account/${bankId}/${account.id}`}>Edit</Link>
                            </td>
                            <td><button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteBankAccount(e, account.id, deleteAccount)}>Delete</button></td>
                        </tr>
                    </tbody>
                    ))
                }
            </Table>
        </>
    )
}
export default BankAccounts