import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../Hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import useCurrencies from '../../../query-hooks/currency-query-hooks/useCurrency';
import styles from "../BankForm.module.scss"
import { useCreateBankAccountBalance, useBankAccountBalance, useEditBankAccountBalance } from '../../../query-hooks/bank-query-hooks/useBank';

const BankAccountBalanceFormMutation = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const { bankAccountId, bankBalanceId } = useParams()
    const { darkmode } = useAuth();
    const balance = useBankAccountBalance(bankAccountId, bankBalanceId)
    const currencies = useCurrencies()
    const createBalance = useCreateBankAccountBalance()
    const editBalance = useEditBankAccountBalance()
    const onSubmit = data => { 
        if (bankBalanceId) {
            return editBalance.mutate({ bankAccountId: bankAccountId, bankBalanceId: bankBalanceId, newBalance: data }) 
        } 
            data.bankAccountId = bankAccountId
            return createBalance.mutate({bankAccountId: bankAccountId, bankAccountBalance: data })
    } 
    useEffect(() => { createBalance.isSuccess && navigate(-1) }, [createBalance])
    useEffect(() => { editBalance.isSuccess && navigate(-1) }, [editBalance])
    useEffect(() => { balance.data && reset({ balance: balance.data.balance }) }, [balance.data])
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            { !bankBalanceId &&
                <Row className="justify-content-center">
                    <Col xl={7} md={8} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose a currency</h6>
                        <select className={darkmode ? `${styles.selectInputDark}` : `${styles.selectInput} rounded-pill`} {...register("currencyISOCode", { required: true })} > 
                            <option className={styles.selectOption} value="0">Select a Currency</option>
                            {currencies.isSuccess && currencies.data.items.map(currency => (
                                <option key={currency.isoCode} value={currency.isoCode} className={styles.selectOption}>{currency.name}</option>
                                ))} 
                        </select> 
                        {errors.currencyISOCode && errors.currencyISOCode.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}                   
                    </Col>
                </Row>
            }
            <Row className="justify-content-center">
                <Col xl={7}>
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Balance</h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Balance" {...register("balance", { required: true, valueAsNumber: true })} />
                    { errors.balance && errors.balance.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>) }
                    <div className="text-center mt-3">
                        { bankBalanceId && <h6 className={darkmode ? styles.currentCurrencyDark : styles.currentCurrency}>Currency: { balance.data && balance.data.currency.name }</h6> }
                    </div>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xl={4} md={6} xs={12}>
                    <input className={styles.submitButton} type="submit" />  
                </Col>
            </Row>
            <Row className="d-flex text-center mt-2 mb-5">
                <h5 className={styles.backLink} onClick={() => navigate(-1)}>Back</h5>
            </Row>
        </Form>
    )
}
export default BankAccountBalanceFormMutation