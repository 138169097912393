import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopHelper = (props) => {
  // Function wrapper to scroll to top when React-router Link is displayed
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{props.children}</>;
};

export default ScrollToTopHelper;
