import React, { useEffect } from 'react'
import ModalComponent from '../../Components/ModalComponent'
import { Form, Row, Col } from 'react-bootstrap'
import styles from "./FundDetail.module.scss"
import { useForm } from "react-hook-form";
import { simpleSuccessfulMessage } from '../../Helpers/Helpers';
import { useImportNavs } from '../../query-hooks/fund-query-hooks/useFund';

const ImportNavModal = ({ setImportNavWindow, fundId }) => {
    // Form to submit Excel string import 
    const { register, handleSubmit, formState: { errors } } = useForm();
    const importNavs = useImportNavs()

    const onSubmitExcelImport = e => importNavs.mutate({ fundId, payload: e.payload })
    
    // If Import Trades is succesful, the modal closes and a success message is displayed
    useEffect(() => {
        if (importNavs.isSuccess) {
            setImportNavWindow(false)
            simpleSuccessfulMessage("Navs Imported Successfully")
            return 
        } 
    }, [importNavs.isSuccess])

    return (
      <ModalComponent stateTrigger={setImportNavWindow}>
          <Form onSubmit={handleSubmit(onSubmitExcelImport)}>
              <Row className='justify-content-center my-3'>
                  <Col xl={6}>
                      <h6 className={styles.inputLabelDark}>Paste Navs Rows and Columns from Excel Table</h6>
                      <textarea className={styles.textareaInput} {...register("payload", {required: true})} placeholder="Paste text..." />
                      {errors.payload && errors.payload.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                  </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                  <Col lg={4} md={12}>
                      <input className={styles.submitButton} type="submit" value="Import Trades" />
                  </Col>
              </Row>
          </Form>
      </ModalComponent>
    )
}
export default ImportNavModal;