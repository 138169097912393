import React, { useState, useEffect } from 'react'
import styles from "../Settings.module.scss"
import { Row, Col } from "react-bootstrap"
import { useAuth } from '../../../../Hooks/useAuth'
import { useUser, useEnableMFA, useDisableMFAuth } from '../../../../query-hooks/user-query-hooks/useUser';
import { MdCheckCircleOutline, MdBlockFlipped  } from "react-icons/md";
import CollapsibleComponent from '../../../../Components/CollapsibleComponent';
import TokenComponent from "./TokenComponent"
import SuccesfulTokenMessage from './SuccesfulTokenMessage';

const MFAuth = () => {
    const { user } = useAuth()

    const userData = useUser(user.token)
    const [ twoFaEnabled, setTwoFaEnabled ] = useState() // Hook to set if MFA is enabled in User's data
    useEffect(() => { userData.isSuccess && setTwoFaEnabled(userData.data.twoFactorEnabled) }, [userData])
    // Query hooks when states are triggered
    const enableMFA = useEnableMFA()
    const disableMFAuth = useDisableMFAuth()

    const handleDisableMFA = () => {
        enableMFA.reset()
        disableMFAuth.mutate()
    }
    
    const [ recoveryCodes, setRecoveryCodes ] = useState()
    return (
        <CollapsibleComponent title="Two Factor Authentication">
            <Row className={styles.featureContainer}>
                <Col xl={6}>
                    <p className={styles.subTitle}>Two Factor Authentication with Google Auth App</p>
                    <p className={styles.text}>Choose if you would like to have Double Factor Authentication for higher security purposes</p>
                </Col>
                <Col xl={6}>
                    { twoFaEnabled && 
                        <div className={styles.MFAOptionsContainer}>
                            <h5 style={{color:"#A5C6AC", fontSize:"19px"}}><MdCheckCircleOutline style={{fontSize:"22px"}} /> Two Factor Authentication Enabled</h5>
                            <label className={styles.checkbox}>
                                <span>Uncheck to Disable</span>
                                <input type="checkbox" onChange={() => handleDisableMFA()} defaultChecked={twoFaEnabled} />
                            </label> 
                        </div>
                    }
                    { !twoFaEnabled && 
                        <div className={styles.MFAOptionsContainer} >
                            <h5 style={{color:"#CD5C5C", fontSize:"19px"}}><MdBlockFlipped style={{fontSize:"22px"}} /> Two Factor Autentication Disabled</h5>
                            <label className={styles.checkbox}>
                                <span>Check to Enable</span>
                                <input type="checkbox" onChange={() => enableMFA.mutate()} disabled={enableMFA.isSuccess} />
                            </label>
                            { enableMFA.isSuccess &&
                                <div className={styles.dynamicContainer}>
                                    {/* <h5>{ enableMFA.data.key }</h5>  */}
                                    <img src={enableMFA.data.qrImgSrc} />
                                    <TokenComponent setRecoveryCodes={setRecoveryCodes} />
                                </div>
                            }
                        </ div> 
                    }
                </Col>
            </Row>
            { recoveryCodes && <SuccesfulTokenMessage recoveryCodes={recoveryCodes} setRecoveryCodes={setRecoveryCodes} /> }
        </CollapsibleComponent>
    )
}
export default MFAuth