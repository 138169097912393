import React, { useState } from "react";
import { Link } from "react-router-dom";
import useFunds from "../../query-hooks/fund-query-hooks/useFund";
import styles from "./FundsDashboard.module.scss";
import AllFunds from "./AllFunds";
import { ErrorLoading, Spinner } from "../../Helpers/Helpers"

const FundsDashboard = () => {
  const [ page, setPage ] = useState(1)
  const funds = useFunds(page);

  return (
    <>
      <div className="d-flex justify-content-end">
        <Link className={styles.createButtonDark} to="/main/funds/create-fund">Create Fund</Link>
        <Link className={styles.createButtonDark} to="/main/funds/create-fund-owner">Create Fund Owner</Link>
      </div>
      {funds.isLoading && <Spinner />}
      {funds.isError && <ErrorLoading />}
      <AllFunds funds={funds} setPage={setPage} />
    </>
  );
};
export default FundsDashboard;