import React, { useState, useEffect } from 'react'
import { getMonthlyReturns } from '../../../query-hooks/benchmark-query-hooks/useBenchmark'

const CumulativeReturn = ({ data, investment, fund, fundPercentage, quotesPercentages, handleGraphdataChange }) => {
    const [ cumulativeReturnValue, setCumulativeReturnValue ] = useState(0)

    // 2- Get Asset Values
    const getAssetValues = (arrayData, initialValue) => {
        const assetValues = [];
        for (let i = 0; i < arrayData.length; i++) {
            if (i === 0) {
                assetValues.push({ date: arrayData[i].date, value: initialValue * (1 + arrayData[i].value) });
            } else {
                assetValues.push({ date: arrayData[i].date, value: assetValues[i - 1].value * (1 + arrayData[i].value) });
            }
        }
        return assetValues
    }
    useEffect(() => {
        // Fund Calculations
        const fundData = data[fund.id]
        const fundMonthlyReturns = getMonthlyReturns(fundData)
        const fundInitialValue = (fundPercentage * investment) / 100
        const fundAssetValues = { id: fund.id, name: fund.fundName, assetValues: getAssetValues(fundMonthlyReturns, fundInitialValue), fundPercentage }
        //------------------------------------------------------------------------------
        
        // Quotes Calculations 
        const quotesMonthlyReturns = []
        quotesPercentages.forEach(element => {
            if (data[element.id]) {
                const monthlyReturns = getMonthlyReturns(data[element.id])
                const quoteData = { id: element.id, name: element.name, symbol: element.symbol, percentage: element.percentage, monthlyReturns: monthlyReturns, initialValue: ((investment * (element.percentage - (fundPercentage / quotesPercentages.length))) / 100 )}
                quotesMonthlyReturns.push(quoteData)
            }
        });
        
        const quotesAssetValues = quotesMonthlyReturns.map(quote => ({ id: quote.id, name: quote.name, symbol: quote.symbol, assetValues: getAssetValues(quote.monthlyReturns, quote.initialValue), fundPercentage }))
        const allAssetValues = [ fundAssetValues, ...quotesAssetValues ]
        const portfolioLastValue = allAssetValues.map(val => val.assetValues[val.assetValues.length - 1]).reduce((acc, curr) => acc + curr.value, 1)
        // Return Final Value
        setCumulativeReturnValue(((portfolioLastValue / investment) - 1) * 100)
        
        // Generate Graph Data 
        const allAssetValuesMapped = allAssetValues.map(asset => asset.assetValues.map(element => element.value))
        const assetValuesPortfolio = allAssetValuesMapped.reduce((acc, curr) => {
            curr.forEach((element, i) => {
                acc[i] = (acc[i] || 0 ) + element
            })
            return acc
        }) 
        const graphing = assetValuesPortfolio.map(asset => (asset / investment) - 1)
        const fundPercentageSplit = fundPercentage / quotesPercentages.length
        handleGraphdataChange({ fundPercentage, datasets: graphing, fundPercentageSplit, quotes: quotesPercentages })
    }, [ data, investment, fund, fundPercentage, quotesPercentages ])
    
    return (
        <p>{ cumulativeReturnValue.toFixed(2) }%</p>
        )
    }
    export default CumulativeReturn


    // const fundWeightedMonthlyReturn = fundMonthlyReturns.map(fund => fund.value * fundPercentage)
    // const quotesWeightedMonthlyReturn = quotesMonthlyReturns.map(quote => quote.monthlyReturns.map(monthlyReturn => monthlyReturn.value * fundPercentageSplit)) 
    // const weightedMonthlyReturns = [ fundWeightedMonthlyReturn, ...quotesWeightedMonthlyReturn]
    // const portfolioWeightedMonthlyReturns = weightedMonthlyReturns.reduce((acc, curr) => {
    //     curr.forEach((val, i) => {
    //         acc[i] = (acc[i] || 0) + val;
    //     })
    //     return acc
    // }) 