import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import styles from "../TransferForm.module.scss"
import ModalComponent from '../../../Components/ModalComponent'
import { useForm } from "react-hook-form";
import { Spinner, today, yearFromNow } from '../../../Helpers/Helpers'
import { useGetExplorers } from '../../../query-hooks/transfer-query-hooks/useTransfer';
import ExplorersTable from './ExplorersTable';
import FundingsPercentageBar from './FundingsPercentageBar';
import { useWalletsImport } from '../../../query-hooks/wallet-query-hook/useWallet';

export const ImportExplorers = ({ handleImportExplorer, importData, setFundings }) => {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [ openWindow, setOpenWindow ] = useState(false)
    const [ formData, setFormData ] = useState()
    const getExplorers = useGetExplorers(formData)
    const onSubmit = data => setFormData({ walletAddress: data.walletAddress, startTime: data.startTime, endTime: data.endTime });
    const walletsForImport = useWalletsImport()
    const watchWalletInput = watch("walletAddress") // variable listens to walletAddress input to dynamically display clear input button

    return (
        <>
            {   importData ?   
                    <Col xl={12}>
                        <h2 className={styles.title}>Import Transfer from Explorers</h2>
                        <Row className="justify-content-center">
                            <Col xl={12} className="py-1">
                                <FundingsPercentageBar setFundings={setFundings} importData={importData} />
                            </Col>
                        </Row> 
                    </Col>
                    : 
                    <Col xl={6} className="py-1">
                        <button className={styles.importButton} onClick={() => setOpenWindow(true)}>Import Explorers</button>
                    </Col>
            }
            {   openWindow && 
                    <ModalComponent stateTrigger={setOpenWindow}>
                        <Form onSubmit={handleSubmit(onSubmit)} className='p-5'>
                            <Row>
                                <Col xl={4}>
                                    <h6 className={styles.inputLabelDark}>Enter Wallet Address<em className={styles.reqSymbol}>*</em></h6>
                                    <input list="data" autoComplete='off' className={styles.fundFormInputs} {...register("walletAddress", { required: true })} placeholder='Wallet Address'/>
                                    <datalist id="data">
                                        { walletsForImport.isSuccess && walletsForImport.data.map(wallet => <option key={wallet.address} value={wallet.address}>{ wallet.address }</option>) }
                                    </datalist>
                                    { watchWalletInput && 
                                        <button 
                                            className={styles.removeButton} 
                                            onClick={e => {
                                                e.preventDefault()
                                                setValue("walletAddress", "")
                                        }} 
                                        >x</button> 
                                    }
                                </Col>
                                <Col xl={3}>
                                    <h6 className={styles.inputLabelDark}>Start Date<em className={styles.reqSymbol}>*</em></h6>
                                    <input className={styles.datetime} type="date" name="start-time" defaultValue={yearFromNow()} {...register("startTime", { required: true })} />
                                    { errors.startTime && errors.startTime.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>) }
                                </Col>
                                <Col xl={3}>
                                    <h6 className={styles.inputLabelDark}>End Date<em className={styles.reqSymbol}>*</em></h6>
                                    <input className={styles.datetime} type="date" name="end-time" defaultValue={today()} {...register("endTime", { required: true })} />
                                    { errors.endTime && errors.endTime.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>) }
                                </Col>
                                <Col xl={2}>
                                    <button className={styles.importButton} style={{height:"3rem"}} type="submit">Get Explorers</button>
                                </Col>
                            </Row>
                            { getExplorers.isLoading && <Spinner /> }
                            { getExplorers.isSuccess && <ExplorersTable explorers={getExplorers.data} formData={formData} setOpenWindow={setOpenWindow} handleImportExplorer={handleImportExplorer} /> }
                        </Form>
                    </ModalComponent>
            }
        </>
    )
}
