import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import styles from './AddFundCategoryForm.module.scss'
import ScrollToTopHelper from '../../../Helpers/ScrollToTopHelper';
import { useAuth } from '../../../Hooks/useAuth';
import { useFundCategories, useAddCategoryToFund } from '../../../query-hooks/fund-query-hooks/useFund'

const AddFundCategoryForm = ( {fundId, categorySelected, closeModal } ) => {
    const { darkmode } = useAuth()
    const [ loadForm, setLoadForm ] = useState()
    const thisFundCategories = useFundCategories(fundId)
    const mutation = useAddCategoryToFund()

    useEffect(() => {
        thisFundCategories.isSuccess && setLoadForm(thisFundCategories.data.some(x => x.categoryId === categorySelected.id ))
    }, [thisFundCategories])

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => mutation.mutate( { category: data, fundId: fundId })
    useEffect(() => { mutation.isSuccess && closeModal()  }, [mutation])

    if (loadForm) {
        return (
            <ScrollToTopHelper>
                <div className={ styles.darkBG }>
                    <div className={darkmode ? styles.modalDark : styles.modal }>
                        <h4 className={styles.mainTitle}>Category already assigned to this fund</h4>
                        <h3 className={styles.backLink} onClick={closeModal}>Back</h3>
                    </div>
                </div>
            </ScrollToTopHelper>
        )
    }

    if (!loadForm) {
        return (
            <ScrollToTopHelper>
                <div className={ styles.darkBG }>
                    <div className={darkmode ? styles.modalDark : styles.modal }>
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <h2 className={styles.mainTitle}>Add a category to this fund</h2>
                            <h5 className={darkmode ? styles.inputTitleDark : styles.inputTitle}>Category Name</h5>
                            <input defaultValue={categorySelected.name} disabled className={styles.formInputs} />
                            <input defaultValue={categorySelected.id} {...register("categoryId")} type="hidden" />
                            <h5 className={darkmode ? styles.inputTitleDark : styles.inputTitle}>Minimum Percentage</h5>
                            <input type="number" onWheel={e => e.currentTarget.blur()} placeholder='Minimum Percentage' {...register("minPercentage", { required: true })} className={styles.formInputs} />
                            {errors.exampleRequired && <span>This field is required</span>}
                            <h5 className={darkmode ? styles.inputTitleDark : styles.inputTitle}>Maximum Percentage</h5>
                            <input type="number" onWheel={e => e.currentTarget.blur()} placeholder='Maximum Percentage' {...register("maxPercentage", { required: true })} className={styles.formInputs} />
                            {errors.exampleRequired && <span>This field is required</span>}
                            <input className={styles.submitButton} type="submit" />
                            <h3 className={styles.backLink} onClick={closeModal}>Back</h3>
                        </form>
                    </div>
                </div>
            </ScrollToTopHelper>
        )
    }
}
export default AddFundCategoryForm