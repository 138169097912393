import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "../FundForm.module.scss";
import { useAuth } from "../../../Hooks/useAuth";

const FundsOwnerForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth();
    
    // useEffect(() => { // Side effect to load funds data based on params id, resetting the form values in case of edit form (react-hook-form reset)
    //     data && reset({ fundName: data.fundName, description: data.description, maxVolume: data.maxVolume, reportingCurrencyCode: data.reportingCurrencyCode, 
    //         primaryCryptoCurrencyId: data.primaryCryptoCurrencyId });
    // }, [data]);

    return (
        <div className={darkmode ? styles.createCardDark : styles.createCard}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col lg={6} md={12} className="py-3">
                        <input className={styles.fundFormInputs} placeholder="Enter the Fund Owner Name" {...register("name", { required: true, maxLength: 40 })} />
                        {errors.name && errors.name.type === "required" && (<span>This field is required</span>)}
                        {errors.name && errors.name.type === "maxLength" && (<span>Max length of 40 digits exceeded</span>)}
                    </Col>
                    <Col lg={6} md={12} className="py-3">
                        <input className={styles.fundFormInputs} placeholder="Description" {...register("description", { required: true })} />
                        {errors.description && errors.description.type === "required" && (<span>This field is required</span>)}
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={12} className="py-3">
                        <input className={styles.fundFormInputs} placeholder="Department" {...register("department", { required: true })} />
                        {errors.department && errors.department.type === "required" && (<span>This field is required</span>)}
                    </Col>
                    <Col lg={4} md={12} className="py-3">
                        <input className={styles.fundFormInputs} placeholder="Country" {...register("country", { required: true })} />
                        {errors.country && errors.country.type === "required" && (<span>This field is required</span>)}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
                <Row className="d-flex text-center mt-2 mb-5">
                    <Link className={styles.backLink} to="/main/funds">Back</Link>
                </Row>
            </Form>
        </div>
    )
}
export default FundsOwnerForm