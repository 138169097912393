import React, { useState, useEffect } from 'react';
import { useGetChangelogs } from '../../query-hooks/user-query-hooks/useUser';
import styles from "./UsersDashboard.module.scss"
import { Table } from 'react-bootstrap';
import PaginationAPI from '../../Components/PaginationAPI';
import { useAuth } from '../../Hooks/useAuth';
import { timestampFormatter } from '../../Helpers/Helpers';


const ChangeLog = () => {
    const [ page, setPage ] = useState(1)
    const getChangelogs = useGetChangelogs(page)
    const { darkmode } = useAuth()
    const [ currentItems, setCurrentItems ] = useState()
    const getCurrentItems = updatedItems => setCurrentItems(updatedItems)

    return (
        <div className={darkmode ? styles.cardTableDark : styles.cardTable} style={{padding:"2rem"}}>
            <Table responsive borderless={true} >
                <thead>
                <tr className={darkmode ? styles.theaderDark : styles.theader}>
                    <th>Datetime</th>
                    <th>Name</th>
                    <th>Table</th>
                </tr>
                </thead>
                {currentItems &&
                    currentItems.map(changeLog => (
                        <tbody key={changeLog.id}>
                            <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                                <td>{ timestampFormatter(changeLog.dateTime) }</td>
                                <td>{ changeLog.normalizedName }</td>
                                <td>{ changeLog.tableName }</td>
                            </tr>
                        </tbody>
                    ))
                }
            </Table>
            <PaginationAPI data={getChangelogs.isSuccess && getChangelogs.data} getCurrentItems={getCurrentItems} setPage={setPage} />
        </div>
    )
}
export default ChangeLog;