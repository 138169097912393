import React, { useState, useEffect } from 'react'
import { useNotification } from '../../query-hooks/notification-query-hooks/useNotification'
import styles from "./Notifications.module.scss"

const Sidepopup = ({notificationId, setNotificationId}) => {
  const [ notificationData, setNotificationData ] = useState()
  const notification = useNotification(notificationId)

  useEffect(() => {
    notification.isSuccess && setNotificationData(notification.data)
  }, [notification])

  const onHandleCloseWindow = () => {
    setNotificationId()
    setNotificationData()
  }

  if (notificationData) {
    return (
      <div className={styles.sidepopupContainer}>
        <div style={{float:"right"}}>
          <button onClick={() => onHandleCloseWindow()}>Close</button>
        </div>
        <div style={{margin:"3rem 0 1rem 0"}}>
          <h2 className={styles.title}>{ notificationData.title }</h2>
          <p>{ notificationData.message }</p>
        </div>
      </div>
    )
  }
}
export default Sidepopup