import React, { useEffect } from 'react'
import styles from "./ModalComponent.module.scss"

const ModalComponent = ({ children, stateTrigger }) => {
    
    // Effect to close Modal when press down Esc onb keyboard
    useEffect(() => {
        const escapeTrigger = e => e.key === "Escape" && stateTrigger(false)
        document.addEventListener("keydown", escapeTrigger)
        return () => document.removeEventListener("keydown", escapeTrigger)
    }, [])
 
    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <span onClick={() => stateTrigger(false)} className={styles.close}>&times;</span>
                { children }
            </div>
        </div>
    )
}
export default ModalComponent;