import React from 'react'
import styles from "./ModalComponent.module.scss"
import { Spinner } from '../Helpers/Helpers'

const ModalComponent = ({ title }) => {
    
    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <Spinner />
                <h1>{ title }...</h1>
            </div>
        </div>
    )
}
export default ModalComponent;