import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import {  useGetBookingPeriods } from '../../../query-hooks/crypto-query-hooks/useCryptos';
import { bookingPerioddateFormat, simpleSuccessfulMessage } from '../../../Helpers/Helpers';
import { Form, Row, Col } from 'react-bootstrap'
import styles from "../../Cryptos/CryptoListings/ImportListingsModal.module.scss"
import ModalComponent from '../../../Components/ModalComponent';
import { useImportRates } from '../../../query-hooks/currency-query-hooks/useCurrency';

const ImportRatesModal = ({ setImportInput }) => {
    const importRates = useImportRates()
    const bookingPeriods = useGetBookingPeriods()

    // Form to submit Excel string import 
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    
    const onSubmitExcelImport = e => {
        const date = new Date(e.date)
        const rates = { data: e.payload, tag: e.tag, date: date.toISOString() }
        importRates.mutate({ rates, bookingPeriod: e.bookingPeriod } )
    }
    
    // If Import Listing is succesful, the modal closes and a success message is displayed
    useEffect(() => {
        if (importRates.isSuccess) {
            setImportInput(false)
            simpleSuccessfulMessage("Rates Import Successful")
            return 
        } 
    }, [importRates])

    // Default date value as the last day of previous month
    useEffect(() => {
        const today = new Date()
        today.setDate(1);
        today.setDate(0)
        setValue("date", today.toISOString().slice(0, 10))
    }, [])

    // Change default date value to last day of previous month when different booking period is selected
    const changeDefaultDate = period => {
        const year = period.slice(0, 4)
        const month = period.slice(4, 6)
        const date = new Date(year, month, 1, 18, 0, 0, 0);
        date.setDate(0)
        setValue("date", date.toISOString().slice(0, 10))
    }

    return (
        <ModalComponent stateTrigger={setImportInput}>
            <Form onSubmit={handleSubmit(onSubmitExcelImport)}>
                <Row className='justify-content-center'>
                    <Col xl={6} className="my-2">
                        <h6 className={styles.inputLabelDark}>Select a Booking Period</h6>
                        <select className={styles.selectInputDark} {...register("bookingPeriod", {required: true})} defaultValue="" onChange={e => changeDefaultDate(e.target.value)}>
                            <option value="" disabled>Select a Booking Period</option>
                            { bookingPeriods.isSuccess && bookingPeriods.data.map((bookingPeriod, i) => (<option key={i} value={bookingPeriod}>{ bookingPerioddateFormat(bookingPeriod) }</option>)) }
                        </select>
                        {errors.bookingPeriod && errors.bookingPeriod.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col xl={6} md={12}>
                        <h6 className={styles.inputLabelDark }>Select a Date<em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.datetime} type="date" {...register("date", { required: true })}  />
                        {errors.date && errors.date.type === "required" && (<span className={styles.formErrorNotice}>You must pick a date</span>)}
                    </Col>
                </Row>
                <Row className='justify-content-center my-3'>
                    <Col xl={6} md={12} className="py-1">
                        <h6 className={styles.inputLabelDark}>Enter a Tag</h6>
                        <input className={styles.fundFormInputs} placeholder="Enter a Tag" {...register("tag")} />
                    </Col>
                </Row>
                <Row className='justify-content-center my-3'>
                    <Col xl={6}>
                        <h6 className={styles.inputLabelDark}>Paste text from Pricings Excel Table</h6>
                        <textarea className={styles.textareaInput} {...register("payload", {required: true})} placeholder="Paste text..." />
                        {errors.payload && errors.payload.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" value="Import Rates" />
                    </Col>
                </Row>
            </Form>
        </ModalComponent>
    )
}
export default ImportRatesModal;