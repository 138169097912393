import React, { useState } from 'react'
import { Table } from "react-bootstrap";
import styles from "./AllExchanges.module.scss";
import { Link } from "react-router-dom";
import Pagination from '../../Components/Pagination';
import { useDeleteExchange, confirmationAlertDeleteExchange } from '../../query-hooks/exchange-query-hooks/useExchange'
import { useAuth } from "../../Hooks/useAuth";
import {  API_URL } from "../../settings"
import Logo from "../../images/Hodl-logo.jpg"

 const AllExchanges = ({items}) => {
  const { darkmode } = useAuth();
  const [currentItems, setCurrentItems] = useState();
  const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
  const mutation = useDeleteExchange()
  const handleImageError = (e) => e.target.src = Logo // Handles img src error

  return (
    <>
        <Table responsive borderless={true} >
          <thead>
            <tr className={darkmode ? styles.theaderDark : styles.theader}>
              <th></th>
              <th>Name</th>
              <th>URL</th>
            </tr>
          </thead>
          {currentItems &&
            currentItems.map((exchange) => (
              <tbody key={exchange.id}>
                <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                  <td><img key={Date.now()} src={`${API_URL}/exchanges/${exchange.id}/icon?t=${Date.now()}`} width="35px" onError={ handleImageError } className={styles.cryptoIcons} /></td>
                  <td>{exchange.exchangeName}</td>
                  <td>{exchange.url}</td>
                  <td>
                    <Link className={styles.editButton} to={`edit-exchange/${exchange.id}`}>Edit</Link>
                  </td>
                  <td>
                    <button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteExchange(e, exchange.id, mutation)}>Delete</button>
                  </td>
                </tr>
              </tbody>
            ))
          }
        </Table>
        <Pagination items={items} getCurrentItems={getCurrentItems} itemsPerPage={15} />
    </>
  )
}
export default AllExchanges