import React, { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import DashboardComponent from "../../../Components/DashboardComponent";
import { useFundHolding, useEditFundHolding, useFund } from "../../../query-hooks/fund-query-hooks/useFund"
import FundsHoldingFormEdit from './FundsHoldingFormEdit';
import { useCheckRole } from "../../../query-hooks/user-query-hooks/useUser"
import ForbiddenMessage from '../../../Components/ForbiddenMessage';

const FundsHoldingEdit = () => {
    const { fundId, holdingId } = useParams()
    const fund = useFund(fundId)
    const navigate = useNavigate();
    const holding = useFundHolding(fundId, holdingId)
    const mutation = useEditFundHolding()
    const onSubmit = data => mutation.mutate({fundId: fundId, holdingId: holdingId, holdingEdit: data })   
    useEffect(() => { mutation.isSuccess && navigate(-1)}, [mutation])

    // Block to restrict view only to Admin User
    const isAdmin = useCheckRole("ADMIN")
    if (isAdmin.isSuccess && isAdmin.data === false) {
        return <ForbiddenMessage />
    }

    return (
        <DashboardComponent title={`${fund.isSuccess && fund.data.fundName} Fund`} subtitle="Edit a holding">
            { holding.isSuccess && <FundsHoldingFormEdit onSubmit={onSubmit} data={holding.data} /> }
        </DashboardComponent>
    );
};
export default FundsHoldingEdit