import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "../WalletForm.module.scss";
import useCryptos, { useCrypto } from "../../../query-hooks/crypto-query-hooks/useCryptos"
import { useNetworks } from '../../../query-hooks/wallet-query-hook/useWallet';
import { useAuth } from '../../../Hooks/useAuth';

const WalletBalanceForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const cryptos = useCryptos()
    const networks = useNetworks()
    const { darkmode } = useAuth()
    const [ chosenCryptoId, setChosenCryptoId ] = useState()
    const crypto = useCrypto(chosenCryptoId)
    useEffect(() => { 
        if (data) {
            reset({ cryptoId: data.cryptoCurrency.id, blockchainNetworkId: data.blockchainNetwork?.id, balance: data.balance })
            setChosenCryptoId(data.cryptoCurrency.id)
        }
    }, [data]);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="justify-content-center">
                    <Col lg={8} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose a Blockchain Network</h6>
                        <select className={darkmode ? `${styles.selectInputDark}` : `${styles.selectInput} rounded-pill`} disabled={ data ? true : false } {...register("blockchainNetworkId", { required: true })}> 
                            {networks.isSuccess && networks.data.items.map(network => (
                                <option key={network.id} value={network.id}>{network.name}</option>
                                ))} 
                        </select>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={8} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose a Cryptocurrency</h6>
                        <select className={darkmode ? `${styles.selectInputDark}` : `${styles.selectInput} rounded-pill`} disabled={ data ? true : false } {...register("cryptoId", { required: true })} onChange={e => setChosenCryptoId(e.target.value)}> 
                            <option className={styles.selectOption}>Select a Crypto Currency</option>
                            {cryptos.isSuccess && cryptos.data.map(crypto => (
                                <option key={crypto.id} value={crypto.id} className={styles.selectOption}>{crypto.name}</option>
                                ))} 
                        </select>
                    </Col>
                </Row>
                { crypto.isSuccess &&
                    <>
                    <Row className="justify-content-center">
                        <Col lg={8} md={12} className="py-3">
                            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Balance</h6>
                            <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Balance" {...register("balance", { required: true, pattern: new RegExp(`^(\\d+(?:[\\.\\,]\\d{0,${crypto.data.decimals}})?)$`, 'gm') })} />
                            <p className={`${styles.formErrorNotice} text-center`}><em>{ `*Max. of ${crypto.data.decimals} decimals for this currency` }</em></p>
                            {errors.balance && errors.balance.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                            {errors.balance && errors.balance.type === "pattern" && (<span className={styles.formErrorNotice}>You have exceeded the maximum of decimals for this currency</span>)}
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Col lg={4} md={12}>
                            <input className={styles.submitButton} type="submit" />
                        </Col>
                    </Row>
                    </>
                }
                <Row className="d-flex text-center mt-2 mb-5">
                    <Link className={styles.backLink} to={-1}>Back</Link>
                </Row>
            </Form>
        </>
    )
}
export default WalletBalanceForm