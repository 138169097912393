import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import styles from "../../../FundForm.module.scss";
import { useFilteredFunds } from '../../../../../query-hooks/fund-query-hooks/useFund';

const FundShareForm = ({ onSubmit, darkmode, fundId }) => {
    const { register, handleSubmit } = useForm();
    const filteredFunds = useFilteredFunds(fundId)
    const [ chosenFundShare, setChosenFundShare ] = useState()

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
                <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose a Fund</h6>
                <Col xl={4} md={4} className="py-1">
                    { filteredFunds.isSuccess &&
                        <select 
                            className={styles.selectInputDark} 
                            {...register("sharesFundId", { required: true })} 
                            onChange={e => setChosenFundShare(e.target.value)} 
                            defaultValue=""
                        > 
                            <option value="" disabled>Select a Fund</option> 
                            { filteredFunds.data.map(fund => {
                                return (
                                    <optgroup key={fund.id} label={`${fund.fundName} Fund`}>
                                        <option  value={fund.id}>{fund.fundName}</option> 
                                        { fund.classes.map(funcClass => (<option key={funcClass.id} value={funcClass.id}>{ funcClass.className }</option>)) }
                                    </optgroup>
                                )
                            })}
                        </select>
                    }
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xl={4} md={6} xs={12}>
                    { chosenFundShare ? <input className={styles.submitButton} type="submit" /> : <input className={styles.submitButtonDisabled} type="submit" disabled={true} /> } 
                </Col>
            </Row>
        </Form>
      )
}
export default FundShareForm;