import "./Helpers.scss";
import { VscError } from "react-icons/vsc";
import Swal from "sweetalert2";

export const Spinner = () => {
  return (
    <div className="text-center">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h3 className="loadingMessage">Loading...</h3>
    </div>
  );
};

export const ErrorLoading = ({ error }) => {
  if (error && error.response && error.response.status === 403) {
    return (
      <div className="text-center"> 
        <VscError className="errorIcon" /> 
        <h3 className="errorMessage">You are not authorized to access this resource</h3>
      </div>
    )
  } else {
    return (
      <div className="text-center"> 
        <VscError className="errorIcon" /> 
        <h3 className="errorMessage">Error loading data</h3>
      </div>
    )
  }
}


// TODO Handle exponential values
export const numberEditor = (n) => {
  if (typeof n === "string") {
    return n
  }

  let abs_n = Math.abs(n);
  if (abs_n >= 10000000) {
    return ` ${(n / 1000000).toFixed(2)}mln`;
  }
  if (abs_n > 9999 && abs_n < 9999999) {
    return n.toLocaleString(undefined, { maximumFractionDigits: 0 });
  }
  if (abs_n > 99 && abs_n <= 9999) {
    return n.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
  if (abs_n <= 99 && abs_n >= 1) {
    return n.toLocaleString(undefined, { maximumFractionDigits: 4 });
  }
  if (abs_n > 0 && abs_n < 1) {
    return n.toLocaleString(undefined, { maximumFractionDigits: 8 });
  }
  return 0
};

export const AlertFunction = error => {
  const errors = error.response?.status === 403 ? ["You are not authorized to access this resource"] : error.response.data?.errors.map(err => err)
  Swal.fire({
    icon: 'error',
    title: errors.map(err => err.code || "Unknown Code Error" ),
    text: errors.map(err => err.description || "Unknown Error" ),
    confirmButtonColor: '#6d9e93',
  })
}

export const dateFormat = date => {
  const newDate = date.slice(0,10) + " / " + date.slice(11, 19)
  return newDate
}

export const bookingPerioddateFormat = date => {
  const newDate = date.slice(0,4) + " / " + date.slice(4, 6)
  return newDate
}

export const previousBookingPeriod = bookingPeriod => {
  return bookingPeriod.slice(4, 6) === "01"
    ? bookingPeriod - 89
    : bookingPeriod - 1
}

export const setBookingPeriodDateInput = date => {
  const newDate = date.slice(0,4) + "-" + date.slice(4, 6) + "-01T12:00"
  return newDate
}

export const today = () => {
  const now = new Date().toISOString().slice(0, 10)
  return now
}

export const yearFromNow = () =>  {
  const now = new Date()
  const lastYear = (now.getFullYear() - 1).toString()
  const month = ('0' + (now.getMonth() + 1)).slice(-2)
  const day = ('0' + now.getDate()).slice(-2)
  const fullDate = `${lastYear}-${month}-${day}`
  return fullDate
}

export const totalSummary = (objs, prop) => {  // Function to get table summaries or total
  const sum = objs.reduce((prev, acc) => prev + acc[prop], 0 )
  return numberEditor(sum)
}

export const totalSummaryTrades = (objs, prop) => {  // Function to get table summaries or total only for Trades
  const sum = objs.reduce((prev, acc) => prev + acc[prop], 0 )
  return sum
}

export const averageTradePrice = obj => {
    const total = totalSummaryTrades(obj, "total")
    const executed = totalSummaryTrades(obj, "executed")
    const averageTotal = total / executed
    return averageTotal.toFixed(8)
}

// TODO function to arrange Tradelog
export const groupBy = array => array.reduce((acc, d) => {
  const found = acc.find(a => a.orderNumber === d.orderNumber);
  const value = { currency: d.currencyName, endPercentage: d.endPercentage }; 
  if (!found) {
    acc.push({orderNumber: d.orderNumber, children: [value]}) 
  }
  else {
    found.children.push(value) 
  }
  return acc;
}, []);

// Successful rollback message displayed on the FundDetail component
export const rollbackSuccesfulMessage = () => {
  Swal.fire({
      icon: 'success',
      title: 'Rollback successful',
      confirmButtonColor: '#6d9e93',
    }).then(() => window.location.reload())
}

// Successful closing period message displayed on the FundDetail component
export const closePeriodSuccesfulMessage = bookingPeriod => {
  Swal.fire({
      icon: 'success',
      title: `Period ${bookingPerioddateFormat(bookingPeriod)} successfully closed`,
      confirmButtonColor: '#6d9e93',
    }).then(() => window.location.reload())
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export const simpleSuccessfulMessage = message => {
  Toast.fire({
    icon: 'success',
    title: message
  })
}

export const simpleErrorMessage = message => {
  Toast.fire({
      icon: 'error',
      title: message,
  })
}

export const COLORS = ['#2F4858', '#4F7A7F','#3D616D',  '#66938F', '#83AD9E', '#A5C6AC'];

// Get miliseconds to expiration date to set timeout
export const getTimeToRefresh = expirationTime => {
  const expirationTimestamp = new Date(expirationTime * 1000)
  const now = new Date()
  return expirationTimestamp - now.getTime() - 30000
}

// Function to Format Full Date into Booking Period 
export const formatDateToBookingPeriod = fullDate => {
  const formattedDate = fullDate.slice(0, 4) + fullDate.slice(5, 7)
  return formattedDate
} 

export const timestampFormatter = dateString => {
  const date = dateString.slice(8, 10) + "/" + dateString.slice(5, 7) + "/" + dateString.slice(0, 4)
  return date
}


// BENCHMARK FUNCTIONS 
// Function to format date for x label style
export const dateFormatter = dateString => {
  const date = new Date(dateString)
  return date.toLocaleString("en-US", { month: "long", year: "numeric" })
} 

// Function to concat quotes with percentages
export const quotesStringHandler = quotes => {  
  const quoteStrings = quotes.map(quote => {
    if (quote.symbol.toLowerCase() === "spy") {
      return `${quote.percentage}% Stocks `
    }
    if (quote.symbol.toLowerCase() === "bndx") {
      return `${quote.percentage}% Bonds `
    }
  })
  return quoteStrings.join("")
}

// Function to turn SPY and BNDX names
export const spyBndxStringHandler = name => {  
  if (name.toLowerCase() === "spy") {
    return 'Stocks'
  }
  if (name.toLowerCase() === "bndx") {
    return 'Bonds'
  }
}