import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import styles from "./TransferForm.module.scss";
import { useAuth } from '../../Hooks/useAuth';
import { useFundHoldingsByBookingPeriod } from "../../query-hooks/fund-query-hooks/useFund"
import { TRANSACTIONTYPES } from '../../constants/enums';
import TransferFormGroupInput from '../../Components/FormInputs/TransferFormGroupInput';
import ReservationsList from './ReservationsList';

const TransferFormEdit = ({ onSubmit, fundId, data, importTransferExplorer, transfersInflow, fundData, bookingPeriod, action }) => {
    const { register, setValue, reset, watch, handleSubmit, formState: { errors } } = useForm( { defaultValues: { fromHoldingId: null } });
    const { darkmode } = useAuth()
    const [ filterUnused, setFilterUnused ] = useState(false)
    const [ selectedHoldingDisplayName, setSelectedHoldingDisplayName ] = useState()
    const fundHoldings = useFundHoldingsByBookingPeriod(fundId, filterUnused, bookingPeriod)

    useEffect(() => { 
        if (data && fundHoldings.data) {
            reset({ 
                transactionId: data.transactionId, 
                transferAmount: data.transferAmount, 
                transferFee: data.transferFee, 
                shares: data.shares, 
                fromAddress: data.transactionSource, 
                reference: data.reference, 
                fromHoldingId: data.holding.id,
                toHoldingId: data.oppositeTransfer?.holding.id,
                feeHoldingId: data.feeHolding.id,
                transactionType: data.transactionType,
                exchangeRate: data.exchangeRate,
                receivedAmount: data.receivedAmount
            })
        } 
    }, [data, fundHoldings.data])

    // Flow to calculate fields dynamically: Transfer Amount, Received Amount & Exchange Rate
    const getTransferAmountOnChange = value => { 
        const watchExchangeRate = watch("exchangeRate")
        if (watchExchangeRate) return setValue("receivedAmount", value * watchExchangeRate) 
    }

    const getReceivedAmountOnChange = value => {
        const watchTransferAmount = watch("transferAmount")
        if (watchTransferAmount) return setValue("exchangeRate", value / watchTransferAmount)
    }

    const getExchangeRateOnChange = value => {
        const watchTransferAmount = watch("transferAmount")
        if (watchTransferAmount) return setValue("receivedAmount", value * watchTransferAmount)
    }

    return (
        <div className='px-5'>
            <Row>
                <Col xl={6}>
                    <h2 className={styles.formTitle}>{ action }: <span>{ fundData.fundName }</span></h2>
                </Col>
            </Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="d-flex justify-content-center">
                    <Col xl={6} md={12} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Transaction Type</h6>
                        <select className={styles.selectInput} {...register("transactionType")} disabled={true}>
                            { TRANSACTIONTYPES.map(transaction => ( <option key={transaction.value} value={transaction.value} >{transaction.name}</option> ))} 
                        </select> 
                    </Col>
                    <Col xl={6} md={12}>
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Date Time <em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.datetime} type="datetime-local" defaultValue={new Date(data.dateTime).toISOString().slice(0, 16)} {...register("dateTime")} disabled={true} />
                    </Col>
                </Row>
                
                <Row className="d-flex justify-content-center">
                    <Col xl={6} md={12}>
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Source Holding <em className={styles.reqSymbol}>*</em></h6>
                        <select className={styles.selectInput} {...register("fromHoldingId")} disabled={true}>
                            <option value="1"></option>
                            { fundHoldings.isSuccess && fundHoldings.data.map(holding => ( <option key={holding.id} value={holding.id} >{ holding.displayName }</option> ))} 
                        </select> 
                    </Col>
                    <Col xl={6} md={12}>
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Fee Holding<em className={styles.reqSymbol}>*</em></h6>
                        <select className={styles.selectInput} {...register("feeHoldingId")} disabled={true}>
                            <option></option>
                            { fundHoldings.isSuccess && fundHoldings.data.map(holding => ( <option key={holding.id} value={holding.id} >{ holding.displayName }</option> ))} 
                        </select> 
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                    <TransferFormGroupInput title="Enter a Transfer Amount" fieldName="transferAmount" register={register} errors={errors} inputText="" placeHolder="Enter Transfer Amount" isRequired={true} disabled={false} data={data} getTransferAmountOnChange={getTransferAmountOnChange} />
                    <TransferFormGroupInput title="Enter a Transfer Fee" fieldName="transferFee" register={register} errors={errors} inputText="" placeHolder="Enter Transfer Fee" isRequired={true} disabled={false} data={data} />
                </Row>
                {/* Transaction Type display conditions */}
                {   (data.transactionType === "Broker" || data.transactionType === "Transfer") &&
                        <>
                            <Row>
                                <Col xl={6} md={12} className="py-1">
                                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Destination Holding</h6>
                                    <select className={styles.selectInput} {...register("toHoldingId")} disabled={true}>
                                        { fundHoldings.isSuccess && fundHoldings.data.map(
                                            holding => ( <option key={holding.id} value={holding.id} >{ holding.displayName }</option> 
                                            ))} 
                                    </select> 
                                </Col>
                            </Row>
            
                            <Row>
                                <Col xl={6} md={12}>
                                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Received Amount <em className={styles.reqSymbol}>*</em></h6>
                                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Enter a Received Amount" {...register("receivedAmount", { required: true, valueAsNumber: true })} onChange={e => getReceivedAmountOnChange(e.target.value)} />
                                    {errors.receivedAmount && errors.receivedAmount.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                                </Col>
                                <Col xl={6} md={12}>
                                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter an Exchange Rate<em className={styles.reqSymbol}>*</em></h6>
                                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Enter an exchange rate" {...register("exchangeRate", { required: true, valueAsNumber: true })} onChange={e => getExchangeRateOnChange(e.target.value)} />
                                    {errors.exchangeRate && errors.exchangeRate.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                                </Col>
                            </Row>
                        </>
                }
                <Row>
                    <Col xl={6} md={12}>
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Transaction Id <em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.fundFormInputs} placeholder="Transaction Id" {...register("transactionId", { required: true, maxLength: 128})} />
                        {errors.transactionId && errors.transactionId.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        {errors.transactionId && errors.transactionId.type === "maxLength" && (<span className={styles.formErrorNotice}>Max of 128 characters has been exceeded</span>)}
                    </Col>
                    {/* Shares Input Display Conditions */}
                    {
                        (data.transactionType === "Inflow" || data.transactionType === "Outflow" || data.transactionType === "Correction") &&
                        <Col xl={6} md={12} className="py-1">
                            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Shares <em className={styles.reqSymbol}>*</em></h6>
                            <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Shares" {...register("shares", { valueAsNumber: true})} />
                        </Col>
                    }
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xl={6} md={12} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a source Address <em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.fundFormInputs} placeholder="Source Address" {...register("fromAddress", { required: true, maxLength: 64})} />
                        {errors.fromAddress && errors.fromAddress.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        {errors.fromAddress && errors.fromAddress.type === "maxLength" && (<span className={styles.formErrorNotice}>Max of 64 characters has been exceeded</span>)}
                    </Col>
                    <Col xl={6} md={12} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Destination Address</h6>
                        <input className={styles.fundFormInputs} placeholder="Destination Address" {...register("toAddress", { maxLength: 64})} />
                        {errors.toAddress && errors.toAddress.type === "maxLength" && (<span className={styles.formErrorNotice}>Max of 64 characters has been exceeded</span>)}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col xl={(data.transactionType === "Outflow" && fundData.performanceFeePolicy === "InflowDate") ? 6 : 12} md={12} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Reference</h6>
                        <input className={styles.fundFormInputs} placeholder="Reference (Optional)" {...register("reference")} />
                    </Col>

                    {   (data.transactionType === "Outflow" && fundData.performanceFeePolicy === "InflowDate") &&
                        <Col xl={6} md={12} className="py-1">
                            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select an Inflow Transfer</h6>
                            <select className={darkmode ? styles.selectInputDark : styles.selectInput} {...register("inflowTransferId", { required: true })}>
                                <option value=""></option>
                                { transfersInflow.isSuccess && transfersInflow.data.map(transferInflow => ( 
                                    <option key={transferInflow.id} value={transferInflow.id} >Booking Period: { transferInflow.bookingPeriod } / Shares: { transferInflow.shares }</option> 
                                ))} 
                            </select>
                            {errors.inflowTransferId && errors.inflowTransferId.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        </Col>
                    }
                </Row>

                {   data.transactionType === "BookReservations" && <ReservationsList fundId={fundId} setValue={setValue} selectedHoldingDisplayName={selectedHoldingDisplayName} importTransferExplorer={importTransferExplorer} /> }
                
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export default TransferFormEdit