import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Pagination from '../../../Components/Pagination'
import { useAuth } from '../../../Hooks/useAuth'
import styles from "../AllBanks.module.scss"
import { useDeleteBankAccountBalance, confirmationAlertDeleteBankAccountBalance } from "../../../query-hooks/bank-query-hooks/useBank"

const AllBankAccountBalances = ({items, bankAccountId}) => {
    const { darkmode } = useAuth() || {}
    const mutation = useDeleteBankAccountBalance()
    const [currentItems, setCurrentItems] = useState([]);
    const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
    return (
        <>
            <Table responsive borderless={true} >
            <thead>
                <tr className={darkmode ? styles.theaderDark : styles.theader}>
                    <th></th>
                    <th>Currency</th>
                    <th>Balance</th>
                </tr>
            </thead>
            {currentItems &&
                currentItems.map((balance) => (
                <tbody key={balance.id}>
                    <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                        <td>{balance.currency.isoCode}</td>
                        <td>{balance.currency.name}</td>
                        <td>{balance.balance}</td>
                        <td><Link className={styles.editButton} to={`/main/funds/bank-account/balances/edit-balance/${bankAccountId}/${balance.id}`}>Edit</Link></td>
                        <td><button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteBankAccountBalance(e, bankAccountId, balance.id, mutation)}>Delete</button></td>
                    </tr>
                </tbody>
                ))
            }
            </Table>
            <Pagination items={items} getCurrentItems={getCurrentItems} itemsPerPage={5} />
        </>
    )
}
export default AllBankAccountBalances