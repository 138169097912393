import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import styles from './AllUsers.module.scss'
import { useAuth } from '../../Hooks/useAuth'
import Swal from "sweetalert2";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import { useLockoutUser, useResetLockoutUser, useDeleteUser, useDisableAdminMFA, useCheckRole } from '../../query-hooks/user-query-hooks/useUser';
import { MdPhonelinkLock, MdPhonelinkErase, MdCheckCircleOutline, MdBlockFlipped  } from "react-icons/md";
import { simpleSuccessfulMessage } from '../../Helpers/Helpers';
import { AiOutlineDelete } from "react-icons/ai";
import RolesInput from './RolesInput';

const AllUsers = ( { currentItems, allRoles, handleUserRole  } ) => {
    const { darkmode } = useAuth()
    const lockoutUser = useLockoutUser()
    const resetLockoutUser = useResetLockoutUser()
    const deleteUser = useDeleteUser()
    const disableAdminMFA = useDisableAdminMFA()
    const isAdmin = useCheckRole("ADMIN")
    
    const onHandleLockoutInput = (boolean, userId) => {
        if (boolean) { 
            lockoutUser.mutate(userId);
        } else {
            resetLockoutUser.mutate(userId)
        }
    }

    useEffect(() => { lockoutUser.isSuccess && simpleSuccessfulMessage("User has been locked out succesfully") }, [ lockoutUser ])
    useEffect(() => { resetLockoutUser.isSuccess && simpleSuccessfulMessage("Locked out user has been reset succesfully")}, [ resetLockoutUser ])
    
    // Confirmation alert that shows Delete Role and Role Assigment Confirmation
    const confirmationAlert = (event, action, userId, role, message) => {
        event.preventDefault();
        Swal.fire({
        title: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#6d9e93",
        cancelButtonColor: "#AF2B0E",
        confirmButtonText: message,
        }).then((result) => {
        if (result.isConfirmed) {
            handleUserRole(action, userId, role);
        }
        });
    };

    return (
        <Table responsive="xl" borderless={true} >
            <thead>
                <tr className={darkmode ? styles.theaderDark : styles.theader}>
                    <th className='text-start'>Email</th>
                    <th className='text-start'>User Confirmed</th>
                    <th>Roles</th>
                    <th></th>
                    {  isAdmin.data && <th>Lockout</th> }
                    <th></th>
                    <th>Disable MFA</th>
                    {  isAdmin.data && <th></th> }
                </tr>
            </thead>
            {currentItems &&
                currentItems.map((currentUser) => (
                    <tbody key={currentUser.id}>
                        <tr className={`align-middle ${darkmode ? styles.tableStripsDark : styles.tableStrips}`}>
                            <td><h5 className={styles.emailFonts}> {currentUser.email} </h5></td>
                            <td>{ 
                                currentUser.emailConfirmed ? 
                                    <p className={styles.confirmed}><MdCheckCircleOutline className={styles.dotIcon} />Confirmed</p> : 
                                    <p className={styles.notConfirmed}><MdBlockFlipped className={styles.dotIcon} />Not Confirmed</p> }
                            </td>
                            {/* List of roles assigned or empty if nothing is assigned */}
                            <td>
                                {currentUser.roles.length > 0 ? 
                                    currentUser.roles.map(role => ( 
                                    <div key={role} className={styles.stickyNotes}>
                                        <p>{ role }</p>
                                        <MdOutlineRemoveCircleOutline className={styles.deleteXButton} onClick={e => confirmationAlert(e, 'delete', currentUser.id, role, "Yes, delete the role")} />
                                    </div>))
                                    : <p style={{margin:"0.5rem 0"}}>No Roles Assigned</p>
                                }
                            </td>
                            {/* Select input to assign Roles */}
                            <td>
                                { allRoles.isSuccess && <RolesInput rolesData={allRoles.data} userId={currentUser.id} confirmationAlert={confirmationAlert} />  }
                            </td>
                            {  isAdmin.data &&
                                <td>
                                    <label className={styles.checkbox}>
                                        <input type="checkbox" onClick={e => onHandleLockoutInput(e.target.checked, currentUser.id)} defaultChecked={currentUser.lockoutEnabled} />
                                    </label>
                                </td> 
                            }
                            <td>{currentUser.lockoutEnabled ? <p style={{margin:"0.5rem 0", color:"#CD5C5C"}}>Locked Out</p> : <p style={{margin:"0.5rem 0", color:"#A5C6AC"}}>Unlocked</p>}</td>
                            <td className={styles.tooltip}>
                                {
                                    currentUser.twoFactorEnabled ? 
                                    <>
                                        <MdPhonelinkLock className={styles.icons} onClick={() => isAdmin.isSuccess && (isAdmin.data && disableAdminMFA.mutate(currentUser.id))} />
                                        <span className={styles.tooltiptext}>Two FA Enabled - Click to disable</span> 
                                    </> : 
                                    <>
                                        <MdPhonelinkErase className={styles.icons} style={{color:"#CD5C5C"}} />
                                        <span className={styles.tooltiptext}>Two FA Disabled</span> 
                                    </>
                                }
                            </td>
                            {  isAdmin.data &&
                                <td className={styles.tooltip} onClick={() => deleteUser.mutate(currentUser.id) }>
                                    <AiOutlineDelete className={styles.icons} />
                                    <span className={styles.tooltiptext}>Delete</span>
                                </td>
                            }
                        </tr>
                    </tbody>
                ))
            }
        </Table>
    )
}
export default AllUsers