import React, { useState, useEffect } from 'react'
import { API_URL } from '../../settings'
import styles from "./Attachments.module.scss"
import { AiOutlineDelete, AiOutlineDownload } from "react-icons/ai";
import Logo from "../../images/logo-pinegreen.png"

const DisplayImage = ({ fileId, name, handleDeleteFile, report, setSelectedImageId }) => {
    const [ image, setImage ] = useState()

    useEffect(() => {
        fetch(`${API_URL}/attachments/${fileId}`) // Get the remote image as a Blob with the fetch API
            .then(res => res.blob())
            .then(blob => {  // Read the Blob as DataURL using the FileReader API
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImage(reader.result)
                };
                reader.readAsDataURL(blob);
        });
    }, [ fileId ]) 

    // Handle img src error
    const handleImageError = () => setImage(Logo)
        
    return (
        <div className={styles.imageContainer} onClick={() => setSelectedImageId(fileId)}>
            <img src={image} className={styles.imageContainerImg} alt={`${name}`} onError={ handleImageError } />
            <a href={`${API_URL}/attachments/${fileId}`} className={styles.imageContainerDownload} >
                <AiOutlineDownload title="Download File" />
            </a>
            { !report && <AiOutlineDelete title="Delete File" onClick={e => handleDeleteFile(e, fileId)} className={styles.imageContainerDelete} /> }
            <h5 className={styles.imageContainerName} onClick={() => setSelectedImageId(fileId)}>{ name }</h5>
        </div>
    )
}
export default DisplayImage;