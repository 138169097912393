import React, { useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import styles from "./AllBenchmarks.module.scss"
import { useForm } from "react-hook-form";
import { simpleSuccessfulMessage } from '../../../Helpers/Helpers';
import { useImportCandlesFromSheets } from '../../../query-hooks/benchmark-query-hooks/useBenchmark';

const ImportCandlesModal = ({ setImportCandlesModal, benchmarkId }) => {
    // Form to submit Excel string import 
    const { register, handleSubmit, formState: { errors } } = useForm();
    const importCandlesFromSheets = useImportCandlesFromSheets()

    const onSubmitExcelImport = e => importCandlesFromSheets.mutate({ benchmarkId, payload: e.payload })
    
    // If Import Candles data is succesful, the modal closes and a success message is displayed
    useEffect(() => {
        if (importCandlesFromSheets.isSuccess) {
            setImportCandlesModal(false)
            simpleSuccessfulMessage("Import Successful")
            return 
        } 
    }, [importCandlesFromSheets.data])

    return (
          <Form onSubmit={handleSubmit(onSubmitExcelImport)}>
                <Row className='justify-content-center my-3'>
                    <Col xl={6}>
                        <h6 className={styles.inputLabelDark}>Paste Navs Rows and Columns from Excel Table</h6>
                        <textarea className={styles.textareaInput} {...register("payload", {required: true})} placeholder="Paste text..." />
                        {errors.payload && errors.payload.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" value="Import Trades" />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center text-center">
                    <Col lg={4} md={12}>
                        <button className={styles.deleteButton} onClick={() => setImportCandlesModal(false)}>Back</button>
                    </Col>
                </Row>
          </Form>
    )
}
export default ImportCandlesModal;