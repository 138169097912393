import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import styles from "./AllBenchmarks.module.scss";
import { useAuth } from '../../../Hooks/useAuth';

const BenchmarkForm = ({ onSubmit, data, setDisplayForm }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth()
    useEffect(() => { 
        data && reset({ name: data.name, symbol: data.symbol, description: data.description, active: data.active });
    }, [data]);

    return (
        <div>
            <Row className="justify-content-center text-center">
                <Col lg={6} md={12}>
                    <h3 className={styles.mainTitleDark}>{ data ? "Edit Benchmark" : "Create Benchmark" }</h3>
                </Col>
            </Row>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Name</h6>
                        <input className={styles.fundFormInputs} placeholder="Name" {...register("name", { required: true })} />
                        {errors.name && errors.name.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Symbol <em className={styles.reqSymbol}>* Preferably to be identical to symbol from Yahoo Finance API</em></h6>
                        <input className={styles.fundFormInputs} placeholder="Symbol" {...register("symbol", { required: true })} />
                        {errors.symbol && errors.symbol.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Description</h6>
                        <input className={styles.fundFormInputs} placeholder="Description" {...register("description")} />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center text-center">
                    <Col lg={6} md={12} className="py-3">
                        <label className={styles.checkbox}>
                            <input type="checkbox" {...register("active")} defaultChecked={true} />
                            <span>Active</span>
                        </label>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input data-testid="submitButton" className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center text-center">
                    <Col lg={4} md={12}>
                        <button className={styles.deleteButton} onClick={() => setDisplayForm({ benchmarkId: null, display: false })}>Back</button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export default BenchmarkForm