import React from 'react'
import { Table } from 'react-bootstrap'
import { numberEditor } from '../../../Helpers/Helpers'
import { useAuth } from '../../../Hooks/useAuth'
import styles from "./Tables.module.scss"
import EmptyMessageComponent from "../../../Components/EmptyMessageComponent"

const DashboardFundTrades = ({trades}) => {
    const { darkmode } = useAuth()
    if (trades.length < 1) {
        return <EmptyMessageComponent message="No existing Trades" /> 
    }
    return (
        <>
            <Table responsive className="mt-4" borderless={true}>
                <thead>
                    <tr className={styles.theader}>
                    <th>Asset</th> 
                        <th>Start Balance</th>
                        <th>Transferred</th>
                        <th>Transaction Fees</th>
                        <th>Staking Rewards</th>
                        <th>PNL</th>
                        <th>Liabilites</th>
                        <th>NAV Balance</th>
                        <th>In-&-Out (Shares)</th>
                        <th>End Balance</th>
                    </tr>
                </thead>
                {trades && trades.map((trade) => (
                <tbody key={trade.id} >
                    <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                    <td>{trade.symbol} </td>
                        <td title={trade.startBalance}>{numberEditor(trade.startBalance)} </td>
                        <td title={trade.tradeSum}>{numberEditor(trade.tradeSum)} </td>
                        <td title={trade.transactionFees}>{numberEditor(trade.transactionFees)} </td>
                        <td title={trade.stakingRewards}>{numberEditor(trade.stakingRewards)} </td>
                        <td title={trade.profitAndLoss}>{numberEditor(trade.profitAndLoss)}</td>
                        <td title={trade.fees}>{numberEditor(trade.liabilities + trade.fundFees)} </td>
                        <td title={trade.navBalance}>{numberEditor(trade.navBalance)} </td>
                        <td title={trade.inOutFlow}>{numberEditor(trade.inOutFlow)} ({numberEditor(trade.inOutFlowShares)}) </td>
                        <td title={trade.endBalance}>{numberEditor(trade.endBalance)} </td>
                    </tr>
                </tbody>
                ))
                }
            </Table>
        </>
    )
}
export default DashboardFundTrades