import React, { useEffect } from 'react'
import ModalComponent from '../../../Components/ModalComponent'
import { Form, Row, Col } from 'react-bootstrap'
import styles from "./TradeForm.module.scss"
import { useForm } from "react-hook-form";
import { useImportTrades } from '../../../query-hooks/order-query-hooks/useOrder';
import useCryptos from '../../../query-hooks/crypto-query-hooks/useCryptos';
import { simpleSuccessfulMessage } from '../../../Helpers/Helpers';

const ImportTradesModal = ({ setImportTradesWindow, orderId }) => {
    // Form to submit Excel string import 
    const { register, handleSubmit, formState: { errors } } = useForm();
    const importTrades = useImportTrades()
    const cryptos = useCryptos()

    const onSubmitExcelImport = e => {
        const trades = e.payload
        importTrades.mutate({ trades, orderId, feeCurrencyId: e.feeCurrencyId } )
    }
    
    // If Import Trades is succesful, the modal closes and a success message is displayed
    useEffect(() => {
        if (importTrades.isSuccess) {
            setImportTradesWindow(false)
            simpleSuccessfulMessage("Trades Import Successful")
            return 
        } 
    }, [importTrades.isSuccess])

    return (
        <ModalComponent stateTrigger={setImportTradesWindow}>
            <Form onSubmit={handleSubmit(onSubmitExcelImport)}>
                <Row className='justify-content-center'>
                    <Col xl={6} className="my-2">
                        <h6 className={styles.inputLabelDark}>Select a Fee Currency</h6>
                        <select className={styles.selectInputDark} {...register("feeCurrencyId", {required: true})} defaultValue="">
                            <option value="" disabled>Select a Fee Currency</option>
                            { cryptos.isSuccess && cryptos.data.map(crypto => (<option value={crypto.id} key={crypto.id}>{crypto.name}</option>)) }
                        </select>
                        {errors.feeCurrencyId && errors.feeCurrencyId.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                    </Col>
                </Row>
                <Row className='justify-content-center my-3'>
                    <Col xl={6}>
                        <h6 className={styles.inputLabelDark}>Paste text from Trades Excel Table</h6>
                        <textarea className={styles.textareaInput} {...register("payload", {required: true})} placeholder="Paste text..." />
                        {errors.payload && errors.payload.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" value="Import Trades" />
                    </Col>
                </Row>
            </Form>
        </ModalComponent>
    )
}
export default ImportTradesModal;