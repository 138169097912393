import React, { useState, useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import "./BenchmarkChart.css"
import { Row, Col } from "react-bootstrap"
import styles from "../BenchmarkDashboard.module.scss"
import { IoIosDownload } from "react-icons/io";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 4,
    layout: { padding: 10 },
    plugins: {
        tooltip: {
            callbacks: {
                label: function(context) {
                    let label = context.dataset.label 
                    if (label) {
                        label = `${label}: `;
                    }
                    if (context.parsed.y !== null) {
                        label = `${label} ${context.parsed.y}%`;
                    }
                    return label;
                }
            }
        },
        legend: {
            position: "bottom",
            align: "center",
            labels: {
                font: { size: 20, family: "LarkenLight" },
                padding: 20
            },
        },
        datalabels: { display: false },
    },
    scales: {
        y: {
            ticks: {
                callback: function(val, index) {
                    return `${val}%`;
                },
                autoSkip: false, 
                font: { family: "LarkenMedium", size: 22 }, 
                padding: 10
            },
            grid: { display: true },
            border: { color: "black", width: 3 }, // Axis color and line weight
            offset: true, // Separation between chart line and axis line
        },
        x: {
            ticks: { 
                callback: function(val, index, values) {
                    const valuesLength = values.length;
                    if (valuesLength > 36) {
                        if (index === 0 || index === valuesLength - 1 || index % 6 === 0 && index !== valuesLength - 2) {
                            return this.getLabelForValue(index);
                        } return ''
                    }
                    if (index === 0 || index === valuesLength - 1 || index % 4 === 0 && index !== valuesLength - 2) {
                        return this.getLabelForValue(index);
                    } return ''
                },
                autoSkip: false,
                font: { family: "LarkenMedium", size: 22 }, 
                padding: 10,
                maxRotation: 0,
                minRotation: 0,
            },
            grid: { display: false },
            border: { color: "black", width: 3 }, // Axis color and line weight
            offset: true, // Separation between chart line and axis line
        }
    }
};

const dateFormatter = dateString => dateString.slice(5, 7) + '/' + dateString.slice(0, 4) // Function to format date for x label style

export function BitcoinFundChart({ hookedData, returnsChartData, fund, linechartRef, handleDownloadImage, handleDownloadSVG, transformedFundName }) {
    const [ graphLabels, setGraphLabels ] = useState([])
    const [ graphData, setGraphData ] = useState([])

    useEffect(() => {
        // 1 GET FUND DATA
        const fundData = hookedData[fund.id]
        // Function to retrieve only dates from fund data array
        const getMonthlyReturnsDates = arrayData => arrayData.map((element, i, array) => array[i] && ({date: element.date})).filter(val => typeof val !== 'undefined')
        const fundMonthlyReturnsDates = getMonthlyReturnsDates(fundData)
        // Set dates for graph labels
        setGraphLabels(fundMonthlyReturnsDates.map(x => x.date))

        // 2 HANDLE BITCOIN DATA
        const bitcoinMetadata = returnsChartData.chartQuotes.find(quote => quote.symbol === "BTC-USD")
        const bitcoinData = returnsChartData.returns[bitcoinMetadata.id]

        const colorFilter = fund => {
            if (fund.toLowerCase().includes("genesis")) return "#669771"
            if (fund.toLowerCase().includes("algorithmic")) return "#6D9E93"
            if (fund.toLowerCase().includes("oracle")) return "#6D9E93"
        }

        setGraphData([
            {
                label: transformedFundName,
                data: fundData.map(item => ((item.normalizedValue - 1) * 100).toFixed(2)),
                borderColor: colorFilter(fund.fundName),
                backgroundColor: colorFilter(fund.fundName),
            },
            {
                label: "Bitcoin",
                data: bitcoinData.map(item => ((item.normalizedValue - 1) * 100).toFixed(2)),
                borderColor: "#FFA500",
                backgroundColor: "#FFA500",
            },
        ])
    }, [ hookedData ])

    const data = {
        labels: graphLabels && [...graphLabels.map(label => dateFormatter(label) )], // Added First Date value as empty to start from Zero
        datasets: graphData
    };

    return (
        <>
            <div className={styles.dropdown} title="Download Chart Image">
                <IoIosDownload className={styles.downloadIcon} />
                <div className={styles.dropdownContent}>
                    <p onClick={() => handleDownloadImage("linechart", linechartRef)}>Download as PNG</p>
                    <p onClick={() => handleDownloadSVG("linechart", linechartRef)}>Download as SVG</p>
                </div>
            </div>
            <span className={styles.chartTitle} style={{fontFamily:"LarkenMedium"}}>Bitcoin - { transformedFundName } Chart</span>
            <Row>
                <Col xl={12} className={styles.chartsContainer} ref={linechartRef}>
                    <Line options={options} data={data} height={600} />
                </Col>
            </Row>
        </>
    ) 
}
