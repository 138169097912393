import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import styles from "./CurrencyForm.module.scss";
import { Link } from 'react-router-dom';
import { useAuth } from "../../Hooks/useAuth";

const CurrencyForm = ({onSubmit, data}) => {
  const { darkmode } = useAuth();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  // Side effect to load data currency based on params id, resetting the form values in case of edit form (react-hook-form reset)
  useEffect(() => {
      data && reset({ decimals: data.decimals, symbol: data.symbol, name: data.name, isoCode: data.isoCode, location: data.location, active: data.active, 
        default: data.default });
  }, [data]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          { !data &&
            <Col xl={8} md={12} className="py-3">
              <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter an ISOCode</h6>
              <input className={styles.fundFormInputs} placeholder="ISO Code must be 3 digits max" {...register("isoCode", { required: true, maxLength: 3 })} />
              {errors.isoCode && errors.isoCode.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
              {errors.isoCode && errors.isoCode.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 3 digits exceeded</span>)}
            </Col>
          }
        </Row>
        <Row className="justify-content-center">
          <Col xl={4} md={12} className="py-3">
            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Name</h6>
            <input className={styles.fundFormInputs} placeholder="Name" {...register("name", { required: true, maxLength: 40 })} />
            {errors.name && errors.name.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
            {errors.name && errors.name.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 40 characters exceeded</span>)}
          </Col>
          <Col xl={4} md={12} className="py-3">
            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Decimals</h6>
            <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Decimals" {...register("decimals", { required: true, valueAsNumber: true, max: 255, })} />
            {errors.decimals && errors.decimals.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
            {errors.decimals && errors.decimals.type === "max" && (<span className={styles.formErrorNotice}>Max decimals: 255</span>)}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xl={4} md={12} className="py-3">
            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Symbol</h6>
            <input className={styles.fundFormInputs} placeholder="Symbol" {...register("symbol", { required: true, maxLength: 5 })} />
            {errors.symbol && errors.symbol.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
            {errors.symbol && errors.symbol.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 5 digits exceeded</span>)}
          </Col>
          <Col xl={4} md={12} className="py-3">
            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Location</h6>  
            <input className={styles.fundFormInputs} placeholder="Location" {...register("location", { required: true, maxLength: 120 })} />
            {errors.location && errors.location.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
          </Col>
        </Row>
        <Row className="mt-5">
          <div style={{marginTop: "3rem", display: "flex", justifyContent: 'center'}}>
            <label className={styles.checkbox}>
                <input type="checkbox" {...register("active")} />
                <span>Active</span>
            </label>
            <label className={styles.checkbox}>
                <input type="checkbox" {...register("default")} />
                <span>Default</span>
            </label>
          </div>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={4} md={12}>
              <input className={styles.submitButton} type="submit" />
          </Col>
        </Row>
        <Row className="d-flex text-center mt-2 mb-5">
          <Link className={styles.backLink} to={-1}>Back</Link>
        </Row>
    </Form>
  )
}
export  default CurrencyForm