import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from "../../settings";
import Swal from "sweetalert2";

// Function to get benchmark funds with dates
// const fetchBenchmarkFunds = selectedFunds => axios.get(`${API_URL}/benchmarks/funds`).then(res => res.data.filter(fund => selectedFunds.indexOf(fund.fundName) >= 0 ));
const fetchBenchmarkFunds = () => axios.get(`${API_URL}/benchmarks/funds`).then(res => res.data.filter(fund => fund.normalizedFundName.includes("ALGORITHMIC") || fund.normalizedFundName.includes("GENESIS") || fund.normalizedFundName.includes("ORACLE")));
export const useBenchmarkFunds = () => useQuery("benchmarkFunds", fetchBenchmarkFunds);

// Function to get benchmark quotes filtered by SPY and BNDX
const fetchBenchmarkQuotes = () => axios.get(`${API_URL}/benchmarks/quotes`).then(res => res.data.filter(quote => quote.symbol === "SPY" || quote.symbol === "BNDX"))
export const useBenchmarkQuotes = () => useQuery("benchmarkQuotes", fetchBenchmarkQuotes); 

// Function to get all benchmarks
const fetchBenchmarkAllQuotes = () => axios.get(`${API_URL}/benchmarks`).then(res => res.data)
export const useBenchmarkAllQuotes = () => useQuery("allBenchmarks", fetchBenchmarkAllQuotes);

// Function to get one benchmark data
const fetchBenchmarkData = benchmarkId => benchmarkId && axios.get(`${API_URL}/benchmarks/${benchmarkId}`).then(res => res.data)
export const useBenchmarkData = benchmarkId => useQuery(["benchmarkData", benchmarkId], () => fetchBenchmarkData(benchmarkId), { enabled: benchmarkId !== undefined });

// Function to get one benchmarks candles
const fetchBenchmarkCandles = (benchmarkId, timeFrame, from, to) => axios.get(`${API_URL}/benchmarks/${benchmarkId}/candles?timeFrame=${timeFrame}&from=${from}&to=${to}`).then(res => res.data)
export const useBenchmarkCandles = (benchmarkId, timeFrame, from, to) => useQuery(["benchmarkCandles", benchmarkId, timeFrame, from, to], () => fetchBenchmarkCandles(benchmarkId, timeFrame, from, to));


// Function to build quotes query params 
const quotesParamsHandler = quotes => {
    const quoteStrings = quotes.map(quote => `&quotes=${quote.id}`)
    return quoteStrings.join("")
} 

// Function to get monthly Returns
const fetchMonthlyReturns = async (fund, dates, quotes) => {
    const benchmarkObject = await axios.get(`${API_URL}/benchmarks/quotes`).then(res => res.data.find(quote => quote.symbol === "IEF"))
    const allQuotes = [ ...quotes, benchmarkObject ]
    const monthlyReturnsResponse = await axios.get(`${API_URL}/benchmarks/monthly_returns/${fund.id}?startBookingPeriod=${dates.start}&endBookingPeriod=${dates.end}` + quotesParamsHandler(allQuotes))
    const benchmarkData = monthlyReturnsResponse.data[benchmarkObject.id]
    // 1- Monthly Returns
    const getMonthlyReturns = arrayData => arrayData.map((element, i, array) => array[i - 1] && ({date: element.date, value: ((element.price - array[i - 1].price) / array[i - 1].price) })).filter(val => typeof val !== 'undefined')
    const monthlyBenchmarkAverage = getMonthlyReturns(benchmarkData).reduce((acc, curr) => acc + curr.value, 0) / getMonthlyReturns(benchmarkData).length
    // 2- Get Annual Returns
    // INFO: Edit after request from Leonardo to calculate the risk free rate with a constant value of 3%.
    const annualizedBenchmarkReturn = 0.03
    // const annualizedBenchmarkReturn = Math.pow((monthlyBenchmarkAverage + 1), 12) - 1
    delete monthlyReturnsResponse.data[benchmarkObject.id]
    return { benchmarkData: { monthlyBenchmarkAverage, annualizedBenchmarkReturn}, data: monthlyReturnsResponse.data }
}
export const useMonthlyReturns = (fund, dates, quotes) => {
    return useQuery(["monthlyReturns", fund, dates, quotes], 
        () => fetchMonthlyReturns(fund, dates, quotes), 
        { enabled: fund !== undefined && dates !== undefined && quotes !== undefined }
    )
}

// Monthly Returns Reusable Function
export const getMonthlyReturns = arrayData => arrayData.map((element, i, array) => array[i - 1] && ({date: element.date, value: array[i - 1].price === 0 ? 0 : ((element.price - array[i - 1].price) / array[i - 1].price) })).filter(val => typeof val !== 'undefined')

// Function to get Monthly Returns only for Returns Chart
const returnsChartMonthlyReturns = async (fund, dates) => { 
    const chartQuotes = await axios.get(`${API_URL}/benchmarks/quotes`).then(res => res.data.filter(quote => quote.symbol === "SPY" || quote.symbol === "BNDX" || quote.symbol === "BTC-USD" || quote.symbol === "GC=F"))
    const monthlyReturnsResponse = await axios.get(`${API_URL}/benchmarks/monthly_returns/${fund.id}?startBookingPeriod=${dates.start}&endBookingPeriod=${dates.end}` + quotesParamsHandler(chartQuotes))
    return { returns: monthlyReturnsResponse.data, chartQuotes }
}

export const useReturnsChartMonthlyReturns = (fund, dates) => {
    return useQuery(["monthlyReturns", fund, dates], () => returnsChartMonthlyReturns(fund, dates), { enabled: fund !== undefined && dates !== undefined })
}

// MUTATIONS

// Post a new Benchmark
const postBenchmark = payload => axios.post(`${API_URL}/benchmarks`, payload).then(res => res.data);
export const usePostBenchmark = () => {
  const queryClient = useQueryClient()
  return useMutation(payload => postBenchmark(payload), { onSuccess: async () => queryClient.invalidateQueries("allBenchmarks")  });
}

//Function to edit Benchmark
const editBenchmark = (payload, benchmarkId) => axios.put(`${API_URL}/benchmarks/${benchmarkId}`, payload).then(res => res.data);
export const useEditBenchmark = () => {
  const queryClient = useQueryClient()
  return useMutation(({ payload, benchmarkId }) => editBenchmark(payload, benchmarkId), { onSuccess: async () => queryClient.invalidateQueries("allBenchmarks") });
}

//Function to delete Benchmark
const deleteBenchmark = benchmarkId => axios.delete(`${API_URL}/benchmarks/${benchmarkId}`).then(res => res.data);
export function useDeleteBenchmark() {
  const queryClient = useQueryClient()
  return useMutation(({ benchmarkId }) => deleteBenchmark(benchmarkId), { onSuccess: async () => queryClient.invalidateQueries("allBenchmarks") });
}
// Message object inserted in delete message
const deleteMessageObject = {
  title: "Are you sure you want to delete this?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteBenchmark = (event, benchmarkId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageObject).then((result) => result.isConfirmed && mutation.mutate({ benchmarkId }) );
};

// Function to import Benchmark from Yahoo API
const importBenchmarkData = benchmarkId => axios.get(`${API_URL}/benchmarks/${benchmarkId}/candles/api/import/`).then(res => res.data);
export const useImportBenchmarkData = () => {
  const queryClient = useQueryClient()
  return useMutation(({ benchmarkId }) => importBenchmarkData(benchmarkId), { onSuccess: async () => queryClient.invalidateQueries("benchmarkCandles") });
}

// Function to Import Candles from Excel Sheets
const importCandlesFromSheets = (benchmarkId, payload) => axios.post(`${API_URL}/benchmarks/${benchmarkId}/candles/import/Monthly`, payload, { headers: {'Content-Type': 'application/json'}}).then(res => res.data)
export const useImportCandlesFromSheets = () => {
  const queryClient = useQueryClient()
  return useMutation(({ benchmarkId, payload }) => importCandlesFromSheets(benchmarkId, payload), {
    onSuccess: () => queryClient.invalidateQueries("benchmarkCandles")
  }) 
}

// Function to Post Candles
const postCandles = (benchmarkId, payload) => axios.post(`${API_URL}/benchmarks/${benchmarkId}/candles`, payload).then(res => res.data)
export const usePostCandles = () => {
  const queryClient = useQueryClient()
  return useMutation(({ benchmarkId, payload }) => postCandles(benchmarkId, payload), {
    onSuccess: () => queryClient.invalidateQueries("benchmarkCandles")
  })
}

// Function to Edit Candles
const editCandles = (benchmarkId, date, payload) => axios.put(`${API_URL}/benchmarks/${benchmarkId}/candles/Monthly/${date}`, payload).then(res => res.data)
export const useEditCandles = () => {
  const queryClient = useQueryClient()
  return useMutation(({ benchmarkId, date, payload }) => editCandles(benchmarkId, date, payload), {
    onSuccess: () => queryClient.invalidateQueries("benchmarkCandles")
  }) 
}

//Function to delete Benchmark Candle
const deleteBenchmarkCandle = (benchmarkId, date) => axios.delete(`${API_URL}/benchmarks/${benchmarkId}/candles/Monthly/${date}`).then(res => res.data);
export function useDeleteBenchmarkCandle() {
  const queryClient = useQueryClient()
  return useMutation(({ benchmarkId, date }) => deleteBenchmarkCandle(benchmarkId, date), { onSuccess: async () => queryClient.invalidateQueries("benchmarkCandles") });
}

export const confirmationAlertDeleteBenchmarkCandle = (event, benchmarkId, date, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageObject).then((result) => result.isConfirmed && mutation.mutate({ benchmarkId, date }) );
};

