import React from 'react'
import { Table } from 'react-bootstrap'
import { useAuth } from '../../Hooks/useAuth'
import styles from "./Reports.module.scss"
import EmptyMessageComponent from "../../Components/EmptyMessageComponent"
import { bookingPerioddateFormat, numberEditor } from '../../Helpers/Helpers'
import ReportFundTradesClasses from './ReportFundTradesClasses'

const ReportFundTrades = ({ report, bookingPeriod }) => {
    const { darkmode } = useAuth()

    if (report.tradeSummary.length < 1) {
        return <EmptyMessageComponent message="No existing Trades" /> 
    }

    return (
        <div className={styles.navCard}>
            <h5 className={styles.title}>Transaction Summary</h5>
            <div>
                <div className={styles.navTags}>
                    <h5>Main <span>{ bookingPerioddateFormat(bookingPeriod) }</span></h5>
                </div>
                <Table responsive className="mt-4" borderless={true}>
                    <thead>
                        <tr className={darkmode ? styles.theaderDark : styles.theader}>
                            <th>Asset</th> 
                            <th>Start Balance</th>
                            <th>In-&-Out (Shares)</th>
                            <th>Transferred</th>
                            <th>Transaction Fees</th>
                            <th>Staking Rewards</th>
                            <th>PNL</th>
                            <th>Liabilites</th>
                            <th>NAV Balance</th>
                            <th>End Balance</th>
                        </tr>
                    </thead>
                    { report.tradeSummary && report.tradeSummary.map(trade => (
                    <tbody key={trade.id} >
                        <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                            <td>{trade.symbol} </td>
                            <td title={trade.startBalance}>{numberEditor(trade.startBalance)} </td>
                            <td title={trade.inOutFlow}>{numberEditor(trade.inOutFlow)} ({numberEditor(trade.inOutFlowShares)}) </td>
                            <td title={trade.tradeSum}>{numberEditor(trade.tradeSum)} </td>
                            <td title={trade.transactionFees}>{numberEditor(trade.transactionFees)} </td>
                            <td title={trade.stakingRewards}>{numberEditor(trade.stakingRewards)} </td>
                            <td title={trade.profitAndLoss}>{numberEditor(trade.profitAndLoss)}</td>
                            <td title={trade.fees}>{numberEditor(trade.liabilities + trade.fundFees)} </td>
                            <td title={trade.navBalance}>{numberEditor(trade.navBalance)} </td>
                            <td title={trade.endBalance}>{numberEditor(trade.endBalance)} </td>
                        </tr>
                    </tbody>
                    ))
                    }
                </Table>
            </div>
            {
                ( report.classes.length > 0 && report.classes.map(fundClass => <ReportFundTradesClasses key={fundClass.id} fundClass={fundClass} />  ))
            }
        </div>
    )
}
export default ReportFundTrades