import React, { useEffect, useState } from 'react'
import ModalComponent from "../../../Components/ModalComponent"
import { useMergeFundClasses, confirmationAlertMergeClasses } from '../../../query-hooks/fund-query-hooks/useFund'
import { Form, Row, Col } from "react-bootstrap"
import styles from "./FundClassForm.module.scss"
import { useForm } from "react-hook-form";

const MergeClassesModal = ({ fundId, selectedFundClass, classes }) => {
    const [ displayMergeClassesModal, setDisplayMergeClassesModal ] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [ filteredClasses, setFilteredClasses ] = useState([]) // State to handle classes list everytime the modal is opened
    const mergeFundClasses = useMergeFundClasses()
    const onSubmit = data => confirmationAlertMergeClasses(fundId, selectedFundClass, data.intoFundClassId, classes, mergeFundClasses)

    // Effect to filter the classes on mounting, to see the list of all classes, except the selected fund class
    useEffect(() => {
        if (!displayMergeClassesModal) return setFilteredClasses([]) // Trigger to reset class list everytime the modal is closed
        setFilteredClasses(classes.filter(fundClass => fundClass.id !== selectedFundClass.id))
    }, [ classes, displayMergeClassesModal ])

    // If the boolean state is false, display the merge button on the dashboard
    if (!displayMergeClassesModal) {
        return <button onClick={() => setDisplayMergeClassesModal(true)} className={` ${styles.editButton} mt-3`} >Merge to Another Class</button>
    }
   
    return (
        <ModalComponent stateTrigger={setDisplayMergeClassesModal}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className='justify-content-center'>
                    <h2 className={`${styles.subTitle} text-center mb-3`}>Merge Classes</h2>
                    <h3 className={`${styles.mainTitle} text-center mb-3`}>{ selectedFundClass.className }</h3>
                    <p style={{textAlign:"center", fontSize:"40px", color:"#6D9E93", fontWeight:"900"}}>↓</p>
                    <Col xl={6}>
                        <select className={styles.selectInput} { ...register("intoFundClassId", { required: true }) } defaultValue="">
                            <option value="">Select Class to Merge</option>
                            { filteredClasses.map(fundClass => (<option key={fundClass.id} value={fundClass.id}>{ fundClass.className }</option>)) }
                        </select>
                        {errors.intoFundClassId && errors.intoFundClassId.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    </Col>
                </Row>
                <Row className='justify-content-center mt-3'>
                    <Col xl={6}>
                        <input className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
            </Form>
        </ModalComponent>
    )
}
export default MergeClassesModal;