import React, { useState, useEffect } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import styles from "./Sandbox.module.scss";
import { numberEditor } from "../../../Helpers/Helpers";
import { useFundHoldings } from "../../../query-hooks/dashboard-query-hooks/useDashboard";

const SandboxComponent = ({ data, fundId, updateUSDTotalValues, updateUSDTotalPercentages, index, totalUSDPrice}) => {
  const { darkmode } = useAuth();
  const [ trade, setTrade ] = useState(0)
  const [ price, setPrice ] = useState(0)
  const [ realizedPnL, setRealizedPnL ] = useState(0)
  const [ unRealizedPnL, setUnRealizedPnL ] = useState(0)
  const [ holdingData, setHoldingData ] = useState()
  const fundHoldings = useFundHoldings(fundId)

  const calculateChangeBTCPercentage = () => {
    if (data.avgEntryPrice === 0) {
      return "No Data"
    }
    return ((data.endBTCPrice - data.avgEntryPrice) / data.avgEntryPrice) * 100 
  }

  const calculatePNL = (entryTotal, exitTotal, endBalance, endBTCPrice) => {
    const realizedPnL = exitTotal - entryTotal;
    const unRealizedPnL = endBalance * endBTCPrice;
    return unRealizedPnL + realizedPnL;
  };

  const calculateBTCValue = () => {
    if (trade && price) {
      return trade * price
    }
    return 0
  }

  const calculateNewEntryPrice = () => {
    if (trade && trade >= 0) {
      return (data.entryTotal - price) / (data.entryAmount + trade)
    }
     return data.avgEntryPrice
  }

  const calculateChangePercentageEntryPrices = () => {
    if (data.avgEntryPrice === 0) {
      return "No Data"
    }
    if (trade && trade > 0) {
      const changePercentage = -((data.avgEntryPrice - calculateNewEntryPrice()) / data.avgEntryPrice)
      return changePercentage
    }
    return 0
  }

  const usdPrice = () => {
    return price * data.endUSDPrice
  }

  const totalUSD = () => { 
    const usdPriceVar =  trade === 0 ? data.endUSDPrice : usdPrice()
    return data.endBalance * usdPriceVar
  }
  
  // Effect to update value sent to total USD Price sum callback
  useEffect(() => {
    updateUSDTotalValues(index, totalUSD());
  }, [price, trade])

  const totalUSDPricePercentage = () => {
    if (totalUSDPrice && totalUSDPrice > 0 ) {
      return (totalUSD() / totalUSDPrice) * 100
    } return 0
  }

  // Effect to update value sent to total USD Percentage sum callback
  useEffect(() => {
    updateUSDTotalPercentages(index, totalUSDPricePercentage())
  }, [price, trade, totalUSDPricePercentage()])

  // Effect to calculate End Percentage
  useEffect(() => { fundHoldings.isSuccess && setHoldingData(fundHoldings.data.find(holding => holding.symbol === data.cryptoCurrency.symbol)) }, [fundHoldings])

  // Effect to set PnL window data
  useEffect(() => {
    setRealizedPnL(data.exitTotal - data.entryTotal )
    setUnRealizedPnL(data.endBalance * data.endBTCPrice)
  }, [])
  
  const tableDataWidth = "3rem"
  return (
    <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
      <td>{ data.cryptoCurrency.name }</td>
      <td >{ data.cryptoCurrency.symbol.toUpperCase() }</td>
      <td title={data.endBalance}>{ numberEditor(data.endBalance) }</td>
      <td style={{width:tableDataWidth, borderBottom:"none", borderTop:"none"}}></td>
      <td title={data.endBTCPrice}>{ numberEditor(data.endBTCPrice) }</td>
      {
        calculateChangeBTCPercentage() === 0 || calculateChangeBTCPercentage() === "No Data" ?
        <td>{ numberEditor(calculateChangeBTCPercentage()) }</td> : 
        <td className={calculateChangeBTCPercentage() < 0 ? styles.negativePercentage : styles.positivePercentage}>{ numberEditor(calculateChangeBTCPercentage()) }</td>
      }
      <td className={styles.tooltip}>
        { numberEditor(calculatePNL(data.entryTotal, data.exitTotal, data.endBalance, data.endBTCPrice)) }
        <span className={styles.tooltiptext}>
          <ul style={{listStyle:"none"}}>
            <li>Realized PnL: <span>{ numberEditor(realizedPnL) }</span></li>  
            <li>Tokens in Fund: <span>{ numberEditor(data.endBalance) }</span></li>
            <li>Current Price: <span>{ numberEditor(data.endBTCPrice) }</span></li>
            <li>Unrealized PnL: <span>{ numberEditor(unRealizedPnL) }</span></li>
          </ul> 
        </span>
      </td>
      <td style={{width:tableDataWidth, borderBottom:"none", borderTop:"none"}}></td>
      <td><input type="number" placeholder="Set Trade..." className={styles.cardInput} onChange={e => setTrade(e.target.value)} /></td>
      <td><input type="number" placeholder="Set Price..." className={styles.cardInput} onChange={e => setPrice(e.target.value)} /></td>
      <td title={usdPrice()}>{ numberEditor(usdPrice()) }</td>
      <td>{ calculateBTCValue() }</td>
      <td style={{width:tableDataWidth, borderBottom:"none", borderTop:"none"}}></td>
      <td title={totalUSD()}>{ numberEditor(totalUSD()) }</td>
      <td title={totalUSDPricePercentage()}>{ numberEditor(totalUSDPricePercentage()) } %</td>
      <td style={{width:tableDataWidth, borderBottom:"none", borderTop:"none"}}></td>
      <td>{ numberEditor(data.avgEntryPrice) || 0 }</td>
      <td title={calculateNewEntryPrice()}>{ numberEditor(calculateNewEntryPrice()) }</td>
      <td title={calculateChangePercentageEntryPrices()}>{ numberEditor(calculateChangePercentageEntryPrices()) }</td>
      <td style={{width:tableDataWidth, borderBottom:"none", borderTop:"none"}}></td>
      <td>{ holdingData ? numberEditor(holdingData.endPercentage) : "0" } %</td>
      <td>{ holdingData ? holdingData.layerName : "-" }</td>
      <td>{ holdingData && (holdingData.categories ? holdingData.categories.map(cat => (<h5>{ cat.name }</h5>)) : "-" ) }</td>
    </tr>
  );
};
export default SandboxComponent;