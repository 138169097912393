import React, { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import CategoryForm from './CategoryForm';
import { useCategory, useCreateCategory, useEditCategory, useCategoryCryptos } from "../../query-hooks/category-query-hooks/useCategories"
import FormComponent from '../../Components/FormComponent';
import AllCategoryCryptos from './AllCategoryCryptos';

const CategoryFormMutation = () => {
    const navigate = useNavigate();
    const { categoryId } = useParams()
    const category = useCategory(categoryId)
    const createCategory = useCreateCategory()
    const editCategory = useEditCategory()
    const categoryCryptos = useCategoryCryptos(categoryId)
    const onSubmit = data => categoryId ? editCategory.mutate({category: data, categoryId: categoryId}) : createCategory.mutate(data)
    useEffect(() => { createCategory.isSuccess && navigate(-1)  }, [createCategory])
    useEffect(() => { editCategory.isSuccess && navigate(-1)  }, [editCategory])
    
    return (
        <FormComponent title="Categories Dashboard" subtitle={categoryId ? "Edit Category" : "Create Category"}>
            { !categoryId && <CategoryForm onSubmit={onSubmit} /> }
            { category.isSuccess && <CategoryForm onSubmit={onSubmit} data={category.data} /> }
            { categoryCryptos.isSuccess && <AllCategoryCryptos items={categoryCryptos.data.items} categoryId={categoryId} /> }
        </FormComponent>
    );
};
export default CategoryFormMutation 