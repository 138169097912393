import React from "react";
import styles from "./PieChart.module.scss"
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { numberEditor } from "../../../Helpers/Helpers";
class PieChartComponent extends React.Component {
    COLORS = ['#2F4858', '#3D616D', '#4F7A7F', '#66938F', '#83AD9E', '#A5C6AC'];

    CustomTooltip = ({ active, payload, label }) => {
        if (active) {
                return (
                <div className={styles.layerTooltip}>
                    <label>{`${payload[0].name} : ${numberEditor(payload[0].value)}%`}</label>
                </div>
            );
        }
        return null;
    };

    render() {
        const finalData = this.props.data.length > 0 ? this.props.data : [ { [this.props.nameKey]: "No Data", [this.props.dataKey]: 1 } ] 
        return (
            <div style={{ width: "100%", height: this.props.height }}>
                <ResponsiveContainer>
                    <PieChart >
                        <Pie
                            data={finalData}
                            color="#000000"
                            dataKey={this.props.dataKey}
                            nameKey={this.props.nameKey}
                            cx="50%"
                            cy="50%"
                            outerRadius={this.props.outerRadius}
                            fill="#8884d8"
                            >
                            {finalData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />
                                ))}
                        </Pie>
                        <Tooltip content={<this.CustomTooltip />} />
                        {/* <Legend /> */}
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
export default PieChartComponent;