import React, { useState, useEffect } from 'react'
import Logo from "../../images/logo-pinegreen.png"
import { API_URL } from '../../settings'

const ImagesCarouselSingleImg = ({ imageData }) => {
    const [ image, setImage ] = useState()
    const [ pdfUrl, setPdfUrl ] = useState()

    useEffect(() => {
        fetch(`${API_URL}/attachments/${imageData.id}`) // Get the remote image as a Blob with the fetch API
            .then(res => res.blob())
            .then(blob => {  // Read the Blob as DataURL using the FileReader API
                if (imageData.contentType.includes('pdf')) {
                    const pdfBlobUrl = URL.createObjectURL(blob);
                    setPdfUrl(pdfBlobUrl);
                } else {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImage(reader.result);
                    };
                    reader.readAsDataURL(blob);
                }
        });
    }, [ imageData.id ]) 

    // Handle img src error
    const handleImageError = () => setImage(Logo)

    if (pdfUrl) {
        return (
            <div style={{ border: '1px solid #6D9E93', height: '750px' }}>
                 <iframe
                    title="PDF Viewer"
                    src={pdfUrl}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    allowFullScreen
                />
            </div>
        )
    }

    if (image) return <img src={image} alt="" onError={ handleImageError } width="100%" /> 
}
export default ImagesCarouselSingleImg;
