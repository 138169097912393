import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "../BankForm.module.scss";
import { useAuth } from '../../../Hooks/useAuth';
import useFunds from '../../../query-hooks/fund-query-hooks/useFund';

const BankAccountForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth()
    const funds = useFunds()

    useEffect(() => { 
        data && reset({ iban: data.iban, holderName: data.holderName });
    }, [data]);
    
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="justify-content-center" >
                    <Col lg={8} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter the Account IBAN</h6>
                        <input className={styles.fundFormInputs} placeholder="IBAN" {...register("iban")} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={8} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter the Holder's Name</h6>
                        <input className={styles.fundFormInputs} placeholder="Enter the Holder's name" {...register("holderName")} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={8} md={12} className="py-1">
                        <h6 className={styles.inputLabel}>Select a Fund <em className={styles.reqSymbol}>*</em></h6>
                        <select className={styles.selectInput} {...register("fundId", { required: true })}> 
                            {funds.data && funds.data.items.map(fund => (
                                <option key={fund.id} value={fund.id}>{fund.fundName}</option>
                                ))} 
                        </select>
                        {errors.fundId && errors.fundId.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input data-testid="submitButton" className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
                <Row className="d-flex text-center mt-2 mb-5">
                    <Link className={styles.backLink} to={-1}>Back</Link>
                </Row>
            </Form>
        </>
    )
}
export default BankAccountForm