import React, { useState } from 'react'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import { useAuth } from '../../Hooks/useAuth'
import styles from './UsersDashboard.module.scss'
import UsersDashboard from './UsersDashboard'
import SandboxCurrencies from '../Dashboard/Sandbox/SandboxCurrencies'
import ServerStatus from './ServerStatus'
import { useUsers } from '../../query-hooks/user-query-hooks/useUser'
import ChangeLog from './ChangeLog'
import Configuration from './Configuration'

const MainUsersDashboard = () => {
    const { darkmode, user } = useAuth()
    const [ usersInterface, setUsersInterface ] = useState(0)
    const allUsers = useUsers(user.token) // Fetch all user only to check if the current user is allowed in Users Dashboard

    if (allUsers.isSuccess) {
        return (
            <>
                <Row>
                    <Col lg={12} md={12}>
                        <h5 className={darkmode ? styles.subTitleDark : styles.subTitle} >Administrator Dashboard</h5>
                        <h2 className={darkmode ? styles.mainTitleDark : styles.mainTitle}>
                            { usersInterface === 0 && "Users Dashboard" }
                            { usersInterface === 1 && "Sandbox Currencies"}
                            { usersInterface === 2 && "Server Status"}
                        </h2>
                        <ButtonGroup className='mt-3 mb-5'>
                            <Button onClick={() => setUsersInterface(0)} className='px-5' style={usersInterface === 0 ? {background:"#6D9E93", border:"none", fontSize:"15px"} : {background:"transparent", border:"solid 1px #6D9E93", fontSize:"14px"}}>User Roles</Button>
                            <Button onClick={() => setUsersInterface(1)} className='px-5' style={usersInterface === 1 ? {background:"#6D9E93", border:"none", fontSize:"15px"} : {background:"transparent", border:"solid 1px #6D9E93", fontSize:"14px"}}>Sandbox Currencies</Button>
                            <Button onClick={() => setUsersInterface(2)} className='px-5' style={usersInterface === 2 ? {background:"#6D9E93", border:"none", fontSize:"15px"} : {background:"transparent", border:"solid 1px #6D9E93", fontSize:"14px"}}>Server Status</Button>
                            <Button onClick={() => setUsersInterface(3)} className='px-5' style={usersInterface === 3 ? {background:"#6D9E93", border:"none", fontSize:"15px"} : {background:"transparent", border:"solid 1px #6D9E93", fontSize:"14px"}}>Change Log</Button>
                            <Button onClick={() => setUsersInterface(4)} className='px-5' style={usersInterface === 4 ? {background:"#6D9E93", border:"none", fontSize:"15px"} : {background:"transparent", border:"solid 1px #6D9E93", fontSize:"14px"}}>App Configuration</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                { usersInterface === 0 && <UsersDashboard /> }
                { usersInterface === 1 && <SandboxCurrencies /> }
                { usersInterface === 2 && <ServerStatus /> }
                { usersInterface === 3 && <ChangeLog /> }
                { usersInterface === 4 && <Configuration /> }
            </>
        )
    }
}
export default MainUsersDashboard;