import React, { useState } from 'react'
import { useCheckRole } from '../../query-hooks/user-query-hooks/useUser' 
import styles from "./AllUsers.module.scss"

const RolesInput = ({ rolesData, userId, confirmationAlert }) => {
    const [ selected, setSelected ] = useState()
    const isAdmin = useCheckRole("ADMIN")
    const isLeadTrader = useCheckRole("LEADTRADER")
    const isHeadSales = useCheckRole("HEADSALES")

    const onHandleConfirmationAlert = (even, action, userId, value, text) => {
        confirmationAlert(even, action, userId, value, text)
        setSelected(1) // Trigger to reset select input 
    }


    if (isAdmin.isSuccess && isAdmin.data) { 
        return (
            <select className={styles.selectInput} onChange={e => onHandleConfirmationAlert(e, 'put', userId, e.target.value, "Yes, assign the role")} defaultValue="1" value={selected}>
                <option className={styles.options} value={1} disabled={true}>Select a role</option>
                { rolesData.map(role => <option key={role.normalizedName} value={role.normalizedName} className={styles.options}>{ role.name }</option>) }
            </select>
        )      
    } 
                                    
    if (isLeadTrader.isSuccess && isLeadTrader.data && isHeadSales.isSuccess && isHeadSales.data) {
        return (
            <select className={styles.selectInput} onChange={e => onHandleConfirmationAlert(e, 'put', userId, e.target.value, "Yes, assign the role")} defaultValue="1" value={selected}>
                <option className={styles.options} value={1} disabled={true}>Select a role</option>
                <option value="LEADTRADER" className={styles.options}>Lead Trader</option> 
                <option value="TRADER" className={styles.options}>Trader</option>
                <option value="HEADSALES" className={styles.options}>Head Sales</option> 
                <option value="SALES" className={styles.options}>Sales</option> 
            </select>
        ) 
    }
    
    if (isLeadTrader.isSuccess && isLeadTrader.data) {
        return (
            <select className={styles.selectInput} onChange={e => onHandleConfirmationAlert(e, 'put', userId, e.target.value, "Yes, assign the role")} defaultValue="1" value={selected}>
                <option className={styles.options} value={1} disabled={true}>Select a role</option>
                <option value="LEADTRADER" className={styles.options}>Lead Trader</option> 
                <option value="TRADER" className={styles.options}>Trader</option>
            </select>
        ) 
    }

    if (isHeadSales.isSuccess && isHeadSales.data) {
        return (
            <select className={styles.selectInput} onChange={e => onHandleConfirmationAlert(e, 'put', userId, e.target.value, "Yes, assign the role")} defaultValue="1" value={selected}>
                <option className={styles.options} value={1} disabled={true}>Select a role</option>
                <option value="HEADSALES" className={styles.options}>Head Sales</option> 
                <option value="SALES" className={styles.options}>Sales</option>
            </select>
        ) 
    }
 
}
export default RolesInput;