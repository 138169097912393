import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useErrorBoundary } from 'react-error-boundary'
import { VscError } from "react-icons/vsc";

const ErrorBoundaryComponent = ({ error }) => {
    const { resetBoundary } = useErrorBoundary()
    return (
        <main>
            <Row>
                <Col className="d-flex justify-content-center">
                    <VscError className="errorIcon" /> 
                </Col>    
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <h1 style={{textAlign:"center", color:"#6D9E93"}}>Something went wrong</h1>
                </Col>    
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <p style={{textAlign:"center", color:"#CD5C5C"}}>{ error.message ? error.message : "Unknown Error" }</p>
                </Col>    
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <button 
                        style={{background:"transparent", border:"solid 1px #669771", color:"#CACCBD", borderRadius:"9px", padding:".5rem 1rem", fontSize:"15px"}} 
                        onClick={resetBoundary}
                    >Go Back</button>
                </Col>    
            </Row>
        </main>
    )
}
export default ErrorBoundaryComponent;