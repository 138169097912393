import React, { useState } from 'react'
import { Table, Row, Col } from 'react-bootstrap';
import styles from "./Reports.module.scss"
import { numberEditor } from '../../Helpers/Helpers';
import { useAuth } from '../../Hooks/useAuth';

const ReportFundHoldingsClasses = ({ fundClass, reportingCurrencyCode, report }) => {
    const { darkmode } = useAuth()
    const [open, setOpen] = useState(false);

    return (
        <div className={styles.conditionalContainerClasses}>
            <div>
                { open ? 
                    <p className={styles.toggleIcon} title="Show Less" onClick={() => setOpen(!open)}>-</p> 
                        : 
                    <p className={styles.toggleIcon} title="Show More" onClick={() => setOpen(!open)}>+</p> 
                }
            </div>
            {   open &&
                <div key={fundClass.id}>
                    <div className={styles.navTags}>
                        <h5>{ fundClass.className }</h5>
                    </div>
                    <Table responsive className="mt-4" borderless={true}>
                        <thead>
                            <tr className={darkmode ? styles.theaderDark : styles.theader}>
                                <th>Asset</th>
                                <th>Start { reportingCurrencyCode } Price</th>
                                <th>End { reportingCurrencyCode } Price</th>  
                                <th>Start Balance</th>
                                <th>End Balance</th>
                                <th>Base { reportingCurrencyCode } Value</th>
                                <th>End { reportingCurrencyCode } Value</th>
                                <th>%</th>
                            </tr>
                        </thead>
                        { fundClass.holdings.map(fundClassHolding => (
                            <tbody key={fundClassHolding.id} >
                                <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                    <td>{fundClassHolding.symbol} </td>
                                    <td title={fundClassHolding.startUSDPrice / report.nav.previousReportingCurrencyRate}>{numberEditor(fundClassHolding.startUSDPrice / report.nav.previousReportingCurrencyRate)} </td>
                                    <td title={fundClassHolding.endUSDPrice / report.nav.currencyRate.usdRate}>{numberEditor(fundClassHolding.endUSDPrice / report.nav.currencyRate.usdRate)} </td>
                                    <td title={fundClassHolding.startBalance}>{numberEditor(fundClassHolding.startBalance)}</td>
                                    <td title={fundClassHolding.endBalance}>{numberEditor(fundClassHolding.endBalance)} </td>
                                    <td title={(fundClassHolding.endBalance * fundClassHolding.startUSDPrice) / report.nav.previousReportingCurrencyRate}>{new Intl.NumberFormat().format(Math.round((fundClassHolding.endBalance * fundClassHolding.startUSDPrice) / report.nav.previousReportingCurrencyRate))}</td>
                                    <td title={fundClassHolding.endUSDValue / report.nav.currencyRate.usdRate}>{ new Intl.NumberFormat().format(Math.round(fundClassHolding.endUSDValue / report.nav.currencyRate.usdRate)) } </td>
                                    <td title={fundClassHolding.endPercentage}>{numberEditor(fundClassHolding.endPercentage)} % </td>
                                </tr>
                            </tbody>
                        ))}
                        <tfoot>
                            <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips } style={{fontWeight: "900", color: "#6D9E93"}}>
                                <th colSpan="4">Totals :</th>
                                <td>{numberEditor(fundClass.holdings.map(holding => holding.navUSDValue / report.nav.currencyRate.usdRate).reduce((acc, curr) => acc + curr, 0))}</td>
                                <td></td>
                                <td>{numberEditor(fundClass.holdings.map(holding => holding.endUSDValue / report.nav.currencyRate.usdRate).reduce((acc, curr) => acc + curr, 0))}</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            }

            {
                !open &&
                    <Row>
                        <Col xl={4} className={styles.navTags}>
                            <h5>{ fundClass.className }</h5>
                        </Col>
                        <Col xl={4} className={styles.navTags}>
                            <h5><strong>Total NAV { reportingCurrencyCode } Value </strong>{numberEditor(fundClass.holdings.map(holding => holding.navUSDValue / report.nav.currencyRate.usdRate).reduce((acc, curr) => acc + curr, 0))}</h5>
                        </Col>
                        <Col xl={4} className={styles.navTags}>
                            <h5><strong>Total { reportingCurrencyCode } Value </strong>{numberEditor(fundClass.holdings.map(holding => holding.endUSDValue / report.nav.currencyRate.usdRate).reduce((acc, curr) => acc + curr, 0))}</h5>
                        </Col>
                    </Row>
            }
        </div>
    )
}
export default ReportFundHoldingsClasses;