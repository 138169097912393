import React from 'react'
import styles from "./UsersDashboard.module.scss"
import { useAuth } from '../../Hooks/useAuth'
import CollapsibleComponent from '../../Components/CollapsibleComponent'
import { Table } from 'react-bootstrap'
import { useBenchmarkFunds } from '../../query-hooks/benchmark-query-hooks/useBenchmark'

const Configuration = () => {
    const { darkmode, selectedBenchmarkFunds, setSelectedBenchmarkFunds } = useAuth()
    const benchmarkFunds = useBenchmarkFunds(selectedBenchmarkFunds)

    return (
        <div className={darkmode ? styles.cardTableDark : styles.cardTable} style={{padding:"2rem"}}>
            <CollapsibleComponent title="Benchmark">
                <Table borderless responsive>
                    <thead>
                        <tr className={styles.theader}>
                            <th></th>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {   benchmarkFunds.isSuccess && benchmarkFunds.data.map(fund => (
                            <tr key={fund.id} className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                <td>{ fund.normalizedFundName }</td>
                                <td>{ fund.fundName }</td>
                                <td><button onClick={() => setSelectedBenchmarkFunds([...selectedBenchmarkFunds, fund.fundName])}>Add</button></td>
                            </tr>
                            ))
                        }
                    </tbody>
                </Table>
                {/* <ul>
                    {
                        selectedBenchmarkFunds.map(fund => <li>{ fund } <button>Remove</button></li>)
                    }
                </ul> */}
            </CollapsibleComponent> 
        </div>
    )
}
export default Configuration;