import React from 'react'
import { Col } from "react-bootstrap"
import styles from "./SelectInput.module.scss"
import { useAuth } from '../../Hooks/useAuth'

const SelectInput = ({ title, fieldName, register, errors, isRequired, optionsArray, optionMessage, ...props }) => {
    const { darkmode } = useAuth()
	return (
        <Col lg={props.fullWidth ? 12 : 6} md={12} className="py-1">
            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>{ title } { isRequired && <em className={styles.reqSymbol}>*</em> }</h6>
            <div style={{position:"relative"}}>
                <select 
                    className={styles.selectInput} 
                    {...register(fieldName, { required: {value: isRequired, message: "This field is required"} })} 
                    disabled={props.data} 
                    onChange={e => props.onChangeFunction && props.onChangeFunction(e.target.value)}
                    > 
                    <option value="">{optionMessage}</option>
                    {optionsArray.map(opt => (
                        <option key={opt[props.keyId]} value={opt[props.value]}>{opt[props.name]}</option>
                    ))} 
                </select> 
                { props.buttonFn && <button className={styles.selectInputButton} onClick={props.buttonFn}>Create</button> }
                { errors[fieldName] && (<span className={styles.formErrorNotice}>{ errors[fieldName].message }</span>) }
            </div>
        </Col>
    ) 
} 

export default SelectInput;