import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useAuth } from "../Hooks/useAuth"
import styles from "./Pagination.module.scss";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const PaginationAPI = ({ data, getCurrentItems, setPage }) => {
  const { darkmode } = useAuth() 
  const [pageCount, setPageCount] = useState();

  useEffect(() => {   
    if (data) {
      getCurrentItems(data.items)
      setPageCount(data.totalPages)
    }
  }, [data]);

  const handlePageClick = event => setPage(event.selected + 1)
  
  if (pageCount === 1 || !data) {   
    return null
  }

  if (data && pageCount) {
    return (
      <div className={styles.paginationParentContainer}>
        <Container className="d-flex justify-content-center mt-5">
          <ReactPaginate
              breakLabel="..."
              nextLabel={ <FaArrowRight /> }
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              forcePage={data.currentPage - 1}
              previousLabel={ <FaArrowLeft /> }
              pageClassName={`${styles.paginationContainer}`}
              pageLinkClassName={darkmode ? styles.pageLinkDark : styles.pageLink }
              previousClassName={darkmode ? styles.prevStyleDark : styles.prevStyle }
              previousLinkClassName={darkmode ? styles.prevStyleDark : styles.prevStyle }
              nextClassName={darkmode ? styles.prevStyleDark : styles.prevStyle }
              nextLinkClassName={darkmode ? styles.prevStyleDark : styles.prevStyle }
              breakClassName="page-item"
              breakLinkClassName={styles.breakLink}
              containerClassName="pagination"
              subContainerClassName={"pages pagination"}
              activeClassName={styles.activePage}
              activeLinkClassName="text-white"
              renderOnZeroPageCount={null}
          />
        </Container>
      </div>
    );
  }
};
export default PaginationAPI;