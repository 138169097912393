import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import styles from "./AllCurrencyRates.module.scss";
import { Table } from "react-bootstrap";
import { confirmationAlertDeleteRate, useDeleteRate } from "../../../query-hooks/currency-query-hooks/useCurrency"
import { useAuth } from "../../../Hooks/useAuth";
import PaginationAPI from '../../../Components/PaginationAPI';
import { numberEditor } from "../../../Helpers/Helpers"
import EmptyMessageComponent from '../../../Components/EmptyMessageComponent';

const AllCurrencyRates = ({ rates, setPage }) => {
  const { isoCode } = useParams();
  const { darkmode } = useAuth()
  const [currentItems, setCurrentItems] = useState([]);
  const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
  const deleteRate = useDeleteRate()

    if (rates && !rates.data) return

    if (rates.isSuccess && rates.data.items.length < 1) {
        return <EmptyMessageComponent message="No existing Rates for this Period" /> 
    }
    return (
      <>
        <Table responsive borderless={true} >
          <thead>
            <tr className={`${darkmode ? styles.theaderDark : styles.theader} align-middle text-center`}>
              <th>Date</th>
              <th>USD Rate</th>
              <th>Source</th>
            </tr>
          </thead>
          {  currentItems && currentItems.map(rate => 
              <tbody key={rate.id}>
                <tr className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle text-center`}>
                  <td>{rate.timeStamp.slice(0,10)}</td>
                  <td>{numberEditor(rate.usdRate)}</td>
                  <td>{rate.source}</td>
                  {/* <td><Link className={styles.editButton} to={`/main/cryptos/${crypto_id}/edit-listing/${listing.id}`}>Edit</Link></td> */}
                  <td><button className={styles.deleteButton} onClick={e => confirmationAlertDeleteRate(e, isoCode, rate.id, deleteRate)}>Delete</button></td>
                </tr>
              </tbody> 
            )
          }
        </Table>
        <PaginationAPI data={rates.isSuccess && rates.data} getCurrentItems={getCurrentItems} setPage={setPage} />
      </>
    )
 
};
export default AllCurrencyRates