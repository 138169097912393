import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom'
import styles from "./ExchangeForm.module.scss";
import ExchangeIconImageHandler from './ExchangeIconImageHandler';
import { useAuth } from '../../Hooks/useAuth';

const ExchangeForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth()
    const { exchangeId } = useParams();

    useEffect(() => { 
        data && reset({ exchangeName: data.exchangeName, url: data.url, isDEX: data.isDEX });
    }, [data]);

    const getImageValue = (updatedImage) => setValue("icon", updatedImage); // Function sent to ImageHandler component to update SetValue state

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-1">
                        <ExchangeIconImageHandler getImageValue={getImageValue} exchangeId={exchangeId} />
                        <input {...register("icon")} type="hidden" />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-1">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Exchange Name</h6>
                        <input className={styles.fundFormInputs} placeholder="Exchange Name" {...register("exchangeName", { required: true, maxlength: 60 })} />
                        {errors.exchangeName && errors.exchangeName.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        {errors.exchangeName && errors.exchangeName.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 60 characters exceeded</span>)}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Url</h6>
                        <input className={styles.fundFormInputs} placeholder="URL" {...register("url", { required: true, maxLength: 256 })} />
                        {errors.url && errors.url.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        {errors.url && errors.url.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 256 characters exceeded</span>)}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <div style={{marginTop: "3rem", display: "flex", justifyContent: 'center'}}>
                        <label className={styles.checkbox}>
                            <input type="checkbox" {...register("isDEX")} />
                            <span>DEX</span>
                        </label>
                    </div>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
                <Row className="d-flex text-center mt-2 mb-5">
                    <Link className={styles.backLink} to="/main/exchanges">Back</Link>
                </Row>
            </Form>
        </>
    )
}
export default ExchangeForm