import React from 'react'
import styles from './SuccesfulTokenMessage.module.scss'
import { Row, Col } from 'react-bootstrap'
import store from "../../../../images/storeToken.png"
import forgot from "../../../../images/forgotPassword.png"
import login from "../../../../images/login.png"

const SuccesfulTokenMessage = ({ recoveryCodes, setRecoveryCodes }) => {

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <span className={styles.close} onClick={() => setRecoveryCodes(null)}>&times;</span>
                <Row>
                    <Col lg={4}>
                        <img src={store} width="100%" />
                        <p className={styles.codeText}>Please Store the given 10 Recovery Codes below in a text file in your computer. You might need them in the future.</p>
                    </Col>
                    <Col lg={4}>
                        <img src={forgot} width="100%" />
                        <p className={styles.codeText}>If for some reason you can't enter the Google Auth App from your phone, enter one of these Recovery Codes after Login, together with the token sent to your email </p>
                    </Col>
                    <Col lg={4}>
                        <img src={login} width="100%" />
                        <p className={styles.codeText}>After this, you can enable the Two Factor Authentication again with the Google Authenticator app from you phone</p>
                    </Col>
                </Row>
                <h2 className={styles.message}>Two Factor Authentication has been Enabled</h2>
                <Row className='mt-5'>
                    { recoveryCodes.map((code, i) => (<Col xl={4} key={i}><h3 className={styles.codeTextList}>{ code }</h3></Col>)) }
                </Row>
            </div>
        </div>
    )
}
export default SuccesfulTokenMessage