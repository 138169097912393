import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "../FundForm.module.scss";
import { useAuth } from "../../../Hooks/useAuth";
import { useBanks } from '../../../query-hooks/bank-query-hooks/useBank';

const FundBankAccountForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth();
    const banks = useBanks()
    useEffect(() => { 
        data && reset({ bankId: data.bank.id, iban: data.iban, holderName: data.holderName  });
    }, [data]);
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
                <Col lg={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter the IBAN <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} placeholder="Enter the Iban" {...register("iban", { required: true, minLength: 1, maxLength: 34 })} />
                    {errors.iban && errors.iban.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    {errors.iban && errors.iban.type === "maxLength" && (<span className={styles.formErrorNotice}>The IBAN must have 34 digit max</span>) }
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter the Holder Name</h6>
                    <input className={styles.fundFormInputs} placeholder="Holder Name" {...register("holderName")} />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Bank</h6>
                    <select className={styles.selectInput} {...register("bankId", {required: true})}>
                        { banks.isSuccess && banks.data.items.map(bank => (
                            <option key={bank.id} value={bank.id}>{bank.name}</option>
                        ))} 
                    </select>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={4} md={12}>
                    <input className={styles.submitButton} type="submit" />
                </Col>
            </Row>
            <Row className="d-flex text-center mt-2 mb-5">
                <Link className={styles.backLink} to={-1}>Back</Link>
            </Row>
        </Form>
    )
}
export default FundBankAccountForm