import React, { useState } from 'react'
import { Form, Row, Col } from "react-bootstrap"
import { useExchangeAccountsSelect } from '../../../query-hooks/exchange-query-hooks/useExchange'
import DashboardComponent from '../../../Components/DashboardComponent'
import styles from "../OrderForm.module.scss"
import { PairsHandler } from './PairsHandler'
import OrdersImportList from './OrdersImportList'
import { useOrderToImport } from '../../../query-hooks/order-query-hooks/useOrder'
import { simpleErrorMessage, today, yearFromNow } from '../../../Helpers/Helpers'
import { useForm } from "react-hook-form";


const OrderImports = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const exchangeAccounts = useExchangeAccountsSelect() 
    const [ requestBody, setRequestBody ] = useState()
    const ordersToImport = useOrderToImport(requestBody)
    const [ selectedPair, setSelectedPair ] = useState()

    const onSubmit = data => {
        if (data.exchangeAccountId === "1" || !selectedPair) {
            return simpleErrorMessage("Complete the required fields")
        }
        setRequestBody({exchangeAccountId: data.exchangeAccountId, pair: selectedPair, startTime: data.startTime, endTime: data.endTime});
    } 

    return (
        <DashboardComponent title="Orders Dashboard" subtitle="Import Orders from Exchange">
          <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xl={3}>
                        <h6 className={styles.inputLabelDark}>Select Account<em className={styles.reqSymbol}>*</em></h6>
                        <select className={styles.selectInput} defaultValue="1" {...register("exchangeAccountId", { required: true })}>
                            <option disabled={true} value="1" >Select Account</option>
                            {
                                exchangeAccounts.isSuccess && exchangeAccounts.data.map((account, i) => (
                                    <option key={account.id} value={account.id}>{account.name}</option>
                                ))
                            }
                        </select>
                    </Col>
                    <Col xl={3}>
                        <h6 className={styles.inputLabelDark}>Type Coin Pair<em className={styles.reqSymbol}>*</em></h6>
                        <PairsHandler setSelectedPair={setSelectedPair} />
                    </Col>
                    <Col xl={2}>
                        <h6 className={styles.inputLabelDark}>Start Date<em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.datetime} type="date" name="start-time" defaultValue={yearFromNow()} {...register("startTime", { required: true })} />
                        { errors.startTime && errors.startTime.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>) }
                    </Col>
                    <Col xl={2}>
                        <h6 className={styles.inputLabelDark}>End Date<em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.datetime} type="date" name="end-time" defaultValue={today()} {...register("endTime", { required: true })} />
                        { errors.endTime && errors.endTime.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>) }
                    </Col>
                    <Col xl={2} style={{marginTop: "3.5rem"}}>
                        <button className={styles.importOrdersButton} type="submit">Get Orders</button>
                    </Col>
                </Row>
                { ordersToImport.isSuccess && <OrdersImportList ordersImportList={ordersToImport.data} exchangeAccountId={requestBody.exchangeAccountId} /> }
            </Form>
        </DashboardComponent>
    )
}
export default OrderImports