import React from 'react'
import DashboardComponent from "../../Components/DashboardComponent";
import { useBanks } from '../../query-hooks/bank-query-hooks/useBank';
import AllBanks from "./AllBanks"
import { Spinner, ErrorLoading } from "../../Helpers/Helpers"

const BanksDashboard = () => {
  const banks = useBanks();
  return (
    <DashboardComponent title="Banks Dashboard" subtitle="Banks Dashboard" toCreate="/main/banks/create-bank" buttonMessage="Create a Bank">
      {banks.isLoading && <Spinner />}
      {banks.isError && <ErrorLoading error={banks.error} />}
      {banks.isSuccess && <AllBanks items={banks.data.items} />}
    </DashboardComponent>
  );
}
export default BanksDashboard