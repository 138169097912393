import React from "react";
import styles from "./LayersChart.module.scss"
import { MdOutlineBarChart } from "react-icons/md";

const LayersChart = ({ layers }) => {
    // TODO Redesign layers chart, remove history button and replace with rollback button 
    return (
        <div className={styles.mainContainer}>
            { layers && layers.slice(0).reverse().map((layer) => (
                <div key={layer.layerIndex}>
                    {/* <p className={styles.layerName}>{layer.name}</p> */}
                    <div className={styles.mainChartBar}>
                        <div className={styles.highRange} style={{width: layer.aimPercentage + layer.alertRangeHigh + "%"}}>
                            <span className={styles.tooltiptext}>
                                {layer.name}
                                <MdOutlineBarChart style={{color: "#415c5b", fontSize: "26px"}} /> {`High Range: ${layer.aimPercentage + layer.alertRangeHigh} %` }
                            </span>
                        </div>
                        <div className={styles.aimPercentage} style={{width: layer.aimPercentage + "%"}}>
                            <span className={styles.tooltiptext}>
                                {layer.name} <br />
                                <MdOutlineBarChart style={{color: " #32322F", fontSize: "26px"}} />{"Aim Range: " + layer.aimPercentage + "%" }
                            </span>
                        </div>
                        <div className={styles.lowRange} style={{width: layer.aimPercentage - layer.alertRangeLow + "%"}}>
                            <span className={styles.tooltiptext}>
                                {layer.name} <br />
                                <MdOutlineBarChart style={{color: "#52796f", fontSize: "26px"}} />{`Low Range: ${(layer.aimPercentage - layer.alertRangeLow)} %`}
                            </span>
                        </div>
                        <div className={styles.currentPercentage} style={{width: layer.currentPercentage + "%"}}>
                            <span className={styles.tooltiptext}>
                                {layer.name} <br />
                                <MdOutlineBarChart style={{color: "#2F4858", fontSize: "26px"}} />{`Current Percentage: ${layer.currentPercentage.toFixed(5)} %`}
                            </span>
                        </div>
                    </div>
                </div> ))
            }
        </div>
    ); 
};
export default LayersChart;
