import React from 'react'
import { Col } from 'react-bootstrap';
import { numberEditor } from "../../../Helpers/Helpers";
import styles from "./PercentageBar.module.scss";

const PercentageBar = ({ percentage, handlePercentageChange, fullPercentageFulfilled, orderFundings }) => {

    const handleChange = e => {
        const newValue = parseInt(e.target.value, 10);
        if (fullPercentageFulfilled && newValue > percentage.orderPercentage) {
            handlePercentageChange(percentage.fundId, percentage.orderPercentage); // If fullPercentageFulfilled is true and the new value is higher, set it to the current value
        } else { 
            handlePercentageChange(percentage.fundId, newValue); // Otherwise, update the value normally
        }
    };

    return (
        <Col lg={parseInt(12 / orderFundings.length)} key={percentage.fundId}>
            <div style={fullPercentageFulfilled ? { border: "solid 1px #6D9E93" } : {}} className={styles.sliderContainer}>
                <input 
                    type="range" 
                    value={percentage.orderPercentage} 
                    min={0}
                    max={100}
                    onChange={handleChange}
                    className={styles.slider}
                />
                <p className={styles.text}>
                    { percentage.name }{" "}<span>{ parseInt(percentage.orderPercentage) }%</span>
                </p>
                <p className={styles.text}>
                    Amount:
                </p>
                <p className={styles.amount}>
                    { numberEditor(percentage.orderAmount) }
                </p>
            </div>
        </Col>
    )
}
export default PercentageBar;