import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from "../Settings.module.scss"
import { useForm } from "react-hook-form";
import { useActivateMFA } from '../../../../query-hooks/user-query-hooks/useUser';
import { useAuth } from '../../../../Hooks/useAuth';

const TokenComponent = ({ setRecoveryCodes }) => {
    const { register, handleSubmit, setFocus, watch, setValue, formState: { errors }, } = useForm();
    const activateMFA = useActivateMFA()
    const { setUser } = useAuth()

    // Effects to focus on next input when fulfilled
    const watchedInputs = watch()
    useEffect(() => { setFocus("inputCode1") }, [])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode1") && (watchedInputs.inputCode1.length > 0 && setFocus("inputCode2") )  }, [watchedInputs])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode2") && (watchedInputs.inputCode2.length > 0 && setFocus("inputCode3") )  }, [watchedInputs])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode3") && (watchedInputs.inputCode3.length > 0 && setFocus("inputCode4") )  }, [watchedInputs])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode4") && (watchedInputs.inputCode4.length > 0 && setFocus("inputCode5") )  }, [watchedInputs])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode5") && (watchedInputs.inputCode5.length > 0 && setFocus("inputCode6") )  }, [watchedInputs])

    const onSubmitAuthCode = data => {
        const inputCodeArray = Object.values(data)
        const inputCode = inputCodeArray.join("")
        activateMFA.mutate({ inputCode: inputCode });
    } 

    useEffect(() => { 
        if (activateMFA.isSuccess) {
            setRecoveryCodes(activateMFA.data.recoveryCodes) 
            setUser(activateMFA.data.user) 
        }
    }, [activateMFA])

    // Function to handle password pasted
    const getPastedPassword = (event) => {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text');
        if (pastedText.length === 6) {
            setValue("inputCode1", pastedText.charAt(0))
            setValue("inputCode2", pastedText.charAt(1))
            setValue("inputCode3", pastedText.charAt(2))
            setValue("inputCode4", pastedText.charAt(3))
            setValue("inputCode5", pastedText.charAt(4))
            setValue("inputCode6", pastedText.charAt(5))
        }
    } 

    return (
        <>
            <form className="my-5" onSubmit={handleSubmit(onSubmitAuthCode)} autoComplete="off">
                <Row className={styles.codeContainer}>
                    <p className={styles.message}>* Enter the 6 digits code fom Google Auth App</p>
                    <Col lg={2} xs={2} md={2}>
                        <input {...register("inputCode1", { required: true })} maxLength="1" className={styles.codeInput} onPaste={getPastedPassword} />
                        {errors.inputCode1 && ( <p className={styles.formErrorNotice}>Required</p> )}
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                        <input {...register("inputCode2", { required: true })} maxLength="1" className={styles.codeInput} />
                        {errors.inputCode2 && ( <p className={styles.formErrorNotice}>Required</p> )}
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                        <input {...register("inputCode3", { required: true })} maxLength="1" className={styles.codeInput} />
                        {errors.inputCode3 && ( <p className={styles.formErrorNotice}>Required</p> )}
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                        <input {...register("inputCode4", { required: true })} maxLength="1" className={styles.codeInput} />
                        {errors.inputCode4 && ( <p className={styles.formErrorNotice}>Required</p> )}
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                        <input {...register("inputCode5", { required: true })} maxLength="1" className={styles.codeInput} />
                        {errors.inputCode5 && ( <p className={styles.formErrorNotice}>Required</p> )}
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                        <input {...register("inputCode6", { required: true })} maxLength="1" className={styles.codeInput} />
                        {errors.inputCode6 && ( <p className={styles.formErrorNotice}>Required</p> )}
                    </Col>
                </Row>
                <Row className="justify-content-center my-4">
                    <Col lg={8} xs={12}>
                        <button className={styles.registerButton} type="submit">Enter</button>
                    </Col>
                </Row>
            </form>
        </>
  )
}
export default TokenComponent;