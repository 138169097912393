import React, { useEffect } from 'react'
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import styles from './ListingForm.module.scss'
import { useAuth } from "../../../Hooks/useAuth";

const ListingForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth()
    const today = () => new Date().toISOString().slice(0, 16)
    useEffect(() => { 
        data && reset({ cmcRank: data.cmcRank, source: data.source, usdPrice: data.usdPrice, btcPrice: data.btcPrice });
    }, [data]);
    
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
                <Col lg={6} md={12}>
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Date and Time <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.datetime} type="datetime-local" defaultValue={data ? data.timeStamp.slice(0,16) : today()} min="2018-06-07T00:00" max="2025-06-14T00:00" {...register("timeStamp", { required: true })} />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter an CMC Rank <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="CmcRank must be 5 digits max"  {...register("cmcRank", { required: true, valueAsNumber: true, max: 2147483647 })}  />
                    {errors.cmcRank && errors.cmcRank.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                    {errors.cmcRank && errors.cmcRank.type === "max" && <span className={styles.formErrorNotice}>Max value: 2147483647</span>}
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter USD Price <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="USD Price"  {...register("usdPrice", { required: true, valueAsNumber: true })}  />
                    {errors.usdPrice && errors.usdPrice.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter BTC Price <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="BTC Price"  {...register("btcPrice", { required: true, valueAsNumber: true })}  />
                    {errors.btcPrice && errors.btcPrice.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Source</h6>
                    <input className={styles.fundFormInputs} placeholder="Enter a Source (Optional)" {...register("source")} />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={4} md={12}>
                    <input className={styles.submitButton} type="submit" />
                </Col>
            </Row>
            <Row className="d-flex text-center mt-2 mb-5">
                <Link to={-1} className={styles.backLink}>Back</Link>
            </Row>
        </Form>
    )
}
export default ListingForm