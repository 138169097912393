import React from 'react'
import styles from "./UsersDashboard.module.scss"
import { useAuth } from '../../Hooks/useAuth'
import { useGetAPIStatus, useGetAPPInfo, useGetAPPStats } from '../../query-hooks/user-query-hooks/useUser'
import CollapsibleComponent from '../../Components/CollapsibleComponent'
import { Table } from 'react-bootstrap'
import { dateFormat } from '../../Helpers/Helpers'

const ServerStatus = () => {
    const { darkmode } = useAuth()
    const apiStatus = useGetAPIStatus()
    const appInfo = useGetAPPInfo()
    const appStats = useGetAPPStats()

    return (
    <div className={darkmode ? styles.cardTableDark : styles.cardTable} style={{padding:"2rem"}}>
        {   appInfo.isSuccess ? 
            <CollapsibleComponent title="App Info">
                <Table borderless responsive>
                    <thead>
                        <tr className={styles.theader}>
                            <th>API Version</th>
                            <th>Build Date</th>
                            <th>Host Name</th>
                            <th>Running Env</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                            <td>{ appInfo.data.apiVersion }</td>
                            <td>{ appInfo.data.buildDate }</td>
                            <td>{ appInfo.data.hostName }</td>
                            <td>{ appInfo.data.runningEnvironment }</td>
                        </tr>
                    </tbody>
                </Table>
            </CollapsibleComponent> : <h2>No Data Retrieved</h2>
        }
        {/*  */}
        <CollapsibleComponent title="External APIs State">
            <Table borderless responsive>
                <thead>
                    <tr className={styles.theader}>
                        <th>Date-Time</th>
                        <th style={{textAlign:"left"}}>Api Name</th>
                        <th>State</th>
                        <th>Status Code</th>
                        <th>Error Message</th>
                    </tr>
                </thead>
                <tbody>
                    { apiStatus.isSuccess && apiStatus.data.map((state, i) => (
                        <tr key={i} className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                            <td>{ dateFormat(state.timeStamp) }</td>
                            <td style={{textAlign:"left"}}>{ state.apiName }</td>
                            <td>{ state.state }</td>
                            <td>{ state.statusCode }</td>
                            <td>{ state.errorMessage === "" ? "-" : state.errorMessage }</td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>
        </CollapsibleComponent>
        {/*  */}
        {   appStats.isSuccess ?             
            <CollapsibleComponent title="Stats">
                <Table borderless responsive>
                    <thead>
                        <tr className={styles.theader}>
                            <th>Total Requests</th>
                            <th>Failed Requests</th>
                            <th>Succesful Requests</th>
                            <th>Average Response Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                            <th>{ appStats.data.totalNumRequests }</th>
                            <th>{ appStats.data.failedRequests }</th>
                            <th>{ appStats.data.successfulRequests }</th>
                            <th>{ appStats.data.averageResponseTime }</th>
                        </tr>
                    </tbody>
                </Table>
            </CollapsibleComponent> : <h2>No Data Retrieved</h2>
        }
    </div>
  )
}
export default ServerStatus;