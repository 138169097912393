import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import styles from "../FundForm.module.scss";
import { useAuth } from "../../../Hooks/useAuth";

const FundsLayerForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth();
    const navigate = useNavigate()
    
    useEffect(() => { 
        data && reset({ name: data.name, description: data.description, aimPercentage: data.aimPercentage, alertRangeLow: data.alertRangeLow, alertRangeHigh: data.alertRangeHigh });
    }, [data]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className='justify-content-center'>
                <Col lg={4} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter the Fund Layer Name <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} placeholder="Enter the Fund Layer Name" {...register("name", { required: true, maxLength: 40 })} />
                    {errors.name && errors.name.type === "required" && (<span>This field is required</span>)}
                    {errors.name && errors.name.type === "maxLength" && (<span>Max length of 40 digits exceeded</span>)}
                </Col>
                <Col lg={5} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Description</h6>
                    <input className={styles.fundFormInputs} placeholder="Description (Optional)" {...register("description")} />
                    {errors.description && errors.description.type === "required" && (<span>This field is required</span>)}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter an Aim Percentage <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Aim Percentage" {...register("aimPercentage", { required: true, valueAsNumber: true, max: 255, })} />
                    {errors.aimPercentage && errors.aimPercentage.type === "required" && (<span>This field is required</span>)}
                    {errors.aimPercentage && errors.aimPercentage.type === "max" && (<span>Max decimals: 255</span>)}
                </Col> 
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter an Alert Range Low <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Alert Range Low" {...register("alertRangeLow", { required: true, valueAsNumber: true, max: 255, })} />
                    {errors.alertRangeLow && errors.alertRangeLow.type === "required" && (<span>This field is required</span>)}
                    {errors.alertRangeLow && errors.alertRangeLow.type === "max" && (<span>Max decimals: 255</span>)}
                </Col> 
                <Col lg={3} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter an Alert Range High <em className={styles.reqSymbol}>*</em></h6>
                    <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} step="any" placeholder="Alert Range High" {...register("alertRangeHigh", { required: true, valueAsNumber: true, max: 255, })} />
                    {errors.alertRangeHigh && errors.alertRangeHigh.type === "required" && (<span>This field is required</span>)}
                    {errors.alertRangeHigh && errors.alertRangeHigh.type === "max" && (<span>Max decimals: 255</span>)}
                </Col> 
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={4} md={12}>
                    <input className={styles.submitButton} type="submit" />
                </Col>
            </Row>
            <Row className="d-flex text-center mt-2 mb-5">
                <h5 className={styles.backLink} onClick={() => navigate(-1)}>Back</h5>
            </Row>
        </Form>
    )
}
export default FundsLayerForm