import React from "react";
import useWallets from "../../query-hooks/wallet-query-hook/useWallet";
import AllWallets from "./AllWallets";
import DashboardComponent from "../../Components/DashboardComponent";
import { ErrorLoading, Spinner } from "../../Helpers/Helpers"

const WalletsDashboard = () => {
  const wallets = useWallets();
  return (
    <DashboardComponent title="Wallets Dashboard" subtitle="Wallets Dashboard" toCreate="/main/wallets/create-wallet" buttonMessage="Create a Wallet">
      {wallets.isLoading && <Spinner />}
      {wallets.isError && <ErrorLoading error={wallets.error} />}
      {wallets.isSuccess && <AllWallets items={wallets.data.items} />}
    </DashboardComponent>
  );
};
export default WalletsDashboard;
