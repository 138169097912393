import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "./BenchmarkChart.css"
import { Row, Col } from 'react-bootstrap';
import {  IoIosDownload } from "react-icons/io";
import styles from "../BenchmarkDashboard.module.scss"
import SharpeRatioFunction from '../Calculations/SharpeRatioFunction';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export const options = {
    indexAxis: 'y',
    elements: {  bar: { borderWidth: 2 } },
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 2,
    layout: { padding: { left: 50, right: 100 } },  
    plugins: { 
        legend: { display: false }, 
        datalabels: { 
            formatter: function(value, context) {
                return `${value}%`
            },
            display: true, align: 10, anchor: "end", labels: { title: { font: { size: 18, family: "IngramMonoRegular" } } } 
        },
    },
    scales: { 
        y: { ticks: { crossAlign: "far", font: { family: "LarkenLight", size: 19 }, padding: 45 }, grid: { display: false }, border: { display: false } }, 
        x: { ticks: { display: false }, grid: { lineWidth: 3, color: (context) => context.tick?.value === 0 && "#e2e2e2" }, border: { display: false }}
    }
};

const ReturnsChart = ({ data, benchmarkData, fund, barchartRef, handleDownloadImage, handleDownloadSVG, transformedFundName }) => {
    // Fund Calculations
    const fundData = data.returns[fund.id]
    const fundMonthlyReturns = {
        id: fund.id,
        name: transformedFundName, 
        val: (fundData[fundData.length - 1].price - fundData[0].price) / fundData[0].price,
    }
    // Quotes Calculations 
    const quotesMonthlyReturns = []
    data.chartQuotes.forEach(element => {
        if (data.returns[element.id]) {
            const quote = data.returns[element.id]
            const monthlyReturns = { 
                id: element.id, 
                name: element.name, 
                symbol: element.symbol, 
                val: (quote[quote.length - 1].price - quote[0].price) / quote[0].price,
        }
            quotesMonthlyReturns.push(monthlyReturns)
        }
    });

    // Function to sort graph by given order
    const givenSymbols = [ "BTC-USD", "SPY", "GC=F", "BNDX" ]
    const handleSortingByGivenSymbols = (quotes, givenSymbolsArray) => {
        const sortedObjects = quotes.sort((a, b) => {
            return givenSymbolsArray.indexOf(a.symbol) - givenSymbolsArray.indexOf(b.symbol)
        })
        return sortedObjects
    }
    // Create array of final data for graph
    const allData = [ fundMonthlyReturns, ...handleSortingByGivenSymbols(quotesMonthlyReturns, givenSymbols) ];

    // Set labels for chart
    const labels = allData.map(element => [`${element.name} ${element.symbol ? `(${element.symbol})` : ""}`, `Sharpe Ratio: ${SharpeRatioFunction(data, fund, benchmarkData, element.id)}`] )
    
    const colors = allData.map((data, i) => { // Set of colors for chart lines
        if (i === 0 && fund.fundName === "Genesis") {
            return "#669771"
        } 
        if (i === 0 && fund.fundName === "Oracle") {
            return "#6D9E93"
        } 
        if (data.val >= 0) {
            return "#A5C6AC"
        } 
        return "#0E3531"
    })

    const graphdata = {
        labels,
        datasets: [{ 
            label: "Returns", 
            data: allData.map(x => (x.val * 100).toFixed(2)), 
            borderColor: colors, 
            borderRadius: 5, 
            backgroundColor: colors,
            minBarLength: 30,
            maxBarThickness: 55
        }]
    };

    return (
        <>
            <div className={styles.dropdown} title="Download Chart Image">
                <IoIosDownload className={styles.downloadIcon} />
                <div className={styles.dropdownContent}>
                    <p onClick={() => handleDownloadImage("barchart", barchartRef)}>Download as PNG</p> 
                    <p onClick={() => handleDownloadSVG("barchart", barchartRef)}>Download as SVG</p>
                </div>
            </div>
            <span className={styles.chartTitle} style={{fontFamily:"LarkenMedium"}}>{transformedFundName} Performance and Other Assets</span>
            <Row>
                <Col xl={12} className={styles.chartsContainer} ref={barchartRef}>
                    <Bar options={options} data={graphdata} height={500} />
                </Col>
            </Row>   
        </>
    ) 
}
export default ReturnsChart;
