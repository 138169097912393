import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import styles from "../../dashboard/Banks/BankForm.module.scss"
import { useAuth } from "../../Hooks/useAuth";

const FormGroupInputs = ({ fieldName, title, inputText, register, errors, placeHolder, isRequired, ...props }) => {
    const { darkmode } = useAuth()
    return (
        <Col lg={6} md={12} className="py-1">
        <h6 className={darkmode ? styles.inputLabelDark : styles.inputLabel}>{title} <em className={styles.reqSymbol}>*</em></h6>
        <InputGroup>
            <Form.Control
                style={{ borderRadius: "10px 0 0 10px", border: "solid 1px #6D9E93", height: "3rem", backgroundColor: "transparent", color: "#CACCBD", fontSize:"14px" }}
                className={styles.fundFormInputs}
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
                step="any"
                placeholder={placeHolder}
                {...register(fieldName, { required: { value: isRequired, message: "This is required" }, valueAsNumber: true })}
                onChange={e => props.setAmount && props.setAmount(e.target.value)}
                disabled={props.disabled}
            />
            <InputGroup.Text
                style={{borderRadius: "0 10px 10px 0", height: "3rem", backgroundColor: "transparent", border: "solid 1px #6D9E93", color: "#CACCBD"}} 
                className={styles.fundFormInputs}>
                { inputText }
            </InputGroup.Text>
        </InputGroup>
        { errors[fieldName] && (<span className={styles.formErrorNotice}>{ errors[fieldName].message }</span>) }
        </Col>
    );
};
export default FormGroupInputs;
