import React, { useState, useEffect, useRef } from 'react'
import styles from "./AllBenchmarks.module.scss"
import { dateFormatter, numberEditor } from '../../../Helpers/Helpers';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { useDeleteBenchmarkCandle, confirmationAlertDeleteBenchmarkCandle, useEditCandles } from '../../../query-hooks/benchmark-query-hooks/useBenchmark';

const BenchmarkDataCandlesComponent = ({ benchmark }) => {
    const editCandle = useEditCandles()
    const deleteCandle = useDeleteBenchmarkCandle() 
    const [ editMode, setEditMode ] = useState(false)
    const [ value, setValue ] = useState()
    const inputRef = useRef(null)

    const handleEditCandles = () => editCandle.mutate({ benchmarkId: benchmark.benchmark.id, date: benchmark.dateClose.slice(0, 10), payload: { close: value } })

    useEffect(() => {
        editCandle.isSuccess && setEditMode(false)
    }, [editCandle.isSuccess])

    useEffect(() => {
        editMode && inputRef.current.focus()
    }, [ editMode ])

    const dateCloseFormatter = dateString => {
        const currentDate = new Date(dateString)
        let currentHour = currentDate.getHours();
        if (currentHour === 0) {
            currentDate.setHours(12);
        } else {
            currentDate.setHours(currentHour - 12);
        }
        return dateFormatter(currentDate.toISOString())
    }
    
    return (
        <tr className='text-center'>
            <td style={{textAlign:"start"}}><span style={{color: "#6D9E93"}}>{ dateCloseFormatter(benchmark.dateClose) }</span></td>
            <td>
                { editMode ?
                    <input 
                        defaultValue={benchmark.close} 
                        type="number" 
                        className={styles.tableInput}
                        onChange={e => setValue(e.target.value)}
                        ref={inputRef}
                        onKeyDown={e => e.key === "Enter" && handleEditCandles()}
                    /> : numberEditor(benchmark.close) }
            </td>
            {   editMode ? 
                <td>
                    <button 
                        onClick={() => handleEditCandles()}
                        disabled={!value}
                        className={styles.checkSymbol}
                        title="Save"
                    >✔
                    </button><button onClick={() => setEditMode(false)} className={styles.XSymbol} title="Cancel">x</button>
                </td> :
                <td>
                    <AiOutlineEdit
                        className={styles.icons} 
                        style={{color:"#A5C6AC", margin:"0 .5rem"}}
                        onClick={() => setEditMode(true)}
                        title="Edit"
                    />
                    <AiOutlineDelete
                        className={styles.icons} 
                        style={{color:"#CD5C5C", margin:"0 .5rem"}}
                        onClick={e => confirmationAlertDeleteBenchmarkCandle(e, benchmark.benchmark.id, benchmark.dateClose.slice(0, 10), deleteCandle)} 
                        title="Edit"
                    />
                </td>
            }
        </tr>
  )
}
export default BenchmarkDataCandlesComponent;