import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useImportListings, useGetBookingPeriods, useImportScreenshots } from '../../../query-hooks/crypto-query-hooks/useCryptos';
import { bookingPerioddateFormat, simpleSuccessfulMessage } from '../../../Helpers/Helpers';
import { Form, Row, Col } from 'react-bootstrap'
import styles from "./ImportListingsModal.module.scss"
import ModalComponent from '../../../Components/ModalComponent';
import DragDropComponent from './DragDropComponent';

export const ImportListingsModal = ({ setImportInput }) => {
    const importListings = useImportListings()
    const importScreenshots = useImportScreenshots()
    const bookingPeriods = useGetBookingPeriods()
    const [ displayTableInput, setDisplayTableInput ] = useState(true)
    
    // Form to submit Excel string import 
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    
    const onSubmitExcelImport = e => {
        const date = new Date(e.date)
        if (displayTableInput) {
            const listings = { data: e.payload, tag: e.tag, date: date.toISOString() }
            importListings.mutate({ listings, bookingPeriod: e.bookingPeriod } )
        } else {
            const listings = { files: e.files, fiatCurrency: e.fiatCurrency, endDate: date.toISOString() }
            importScreenshots.mutate({ listings, bookingPeriod: e.bookingPeriod })
        }
    }
    
    // If Import Listing is succesful, the modal closes and a success message is displayed
    useEffect(() => {
        if (importListings.isSuccess) {
            setImportInput(false)
            simpleSuccessfulMessage("Listings Imported Successfully")
            return 
        } 
    }, [ importListings ])

     // If Import Screenshots is succesful, the modal closes and a success message is displayed
     useEffect(() => {
        if (importScreenshots.isSuccess) {
            setImportInput(false)
            simpleSuccessfulMessage(`Screenshots: ${importScreenshots.data}`)
            return
        } 
    }, [ importScreenshots ])

    // Default date value as the last day of previous month
    useEffect(() => {
        const today = new Date()
        today.setDate(1);
        today.setDate(0)
        setValue("date", today.toISOString().slice(0, 10))
    }, [])

    // Change default date value to last day of previous month when different booking period is selected
    const changeDefaultDate = period => {
        const year = period.slice(0, 4)
        const month = period.slice(4, 6)
        const date = new Date(year, month, 1, 18, 0, 0, 0);
        date.setDate(0)
        setValue("date", date.toISOString().slice(0, 10))
    }

    // Function to get files array from DragDrop component and store it in the form
    const getFilesHandler = filesArray => setValue("files", filesArray)

    return (
        <ModalComponent stateTrigger={setImportInput}>
            <button className={displayTableInput ? styles.chooseButton : styles.chooseButtonInactive } onClick={() => setDisplayTableInput(true)}>Pricings from Table</button>
            <button className={!displayTableInput ? styles.chooseButton : styles.chooseButtonInactive} onClick={() => setDisplayTableInput(false)}>Import Screenshots</button>
            <Form onSubmit={handleSubmit(onSubmitExcelImport)}>
                <Row className='justify-content-center my-3'>
                    <Col xl={6} md={12}>
                        <h6 className={styles.inputLabelDark}>Select a Booking Period</h6>
                        <select className={styles.selectInput} {...register("bookingPeriod", {required: true})} defaultValue="" onChange={e => changeDefaultDate(e.target.value)}>
                            <option value="" disabled>Select a Booking Period</option>
                            { bookingPeriods.isSuccess && bookingPeriods.data.map((bookingPeriod, i) => (<option key={i} value={bookingPeriod}>{ bookingPerioddateFormat(bookingPeriod) }</option>)) }
                        </select>
                        {errors.bookingPeriod && errors.bookingPeriod.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col xl={6} md={12}>
                        <h6 className={styles.inputLabelDark }>Select {displayTableInput ? 'a Date' : 'an End Date' }<em className={styles.reqSymbol}>*</em></h6>
                        <input className={styles.datetime} type="date" {...register("date", { required: true })}  />
                        {errors.date && errors.date.type === "required" && (<span className={styles.formErrorNotice}>You must pick a date</span>)}
                    </Col>
                </Row>
                {   displayTableInput ? 
                        <Row className='justify-content-center'>
                            <Col xl={6} md={12}>
                                <h6 className={styles.inputLabelDark}>Enter a Tag</h6>
                                <input className={styles.fundFormInputs} placeholder="Enter a Tag" {...register("tag")} />
                            </Col>
                        </Row> :
                        <Row className='justify-content-center'>
                            <Col xl={6} md={12}>
                                <h6 className={styles.inputLabelDark}>Listing Currency (Optional)</h6>
                                <select className={styles.selectInput} {...register("fiatCurrency")} defaultValue="1"> 
                                    <option disabled={true} value="1">Select a Fiat Currency</option>
                                    <option value="EUR">Euro</option>
                                    <option value="USD">USD</option>
                                </select> 
                            </Col>
                        </Row>
                }
                {   displayTableInput ? 
                        <Row className='justify-content-center my-3'>
                            <Col xl={6}>
                                <h6 className={styles.inputLabelDark}>Paste text from Pricings Excel Table</h6>
                                <textarea className={styles.textareaInput} {...register("payload", {required: true})} placeholder="Paste text..." />
                                {errors.payload && errors.payload.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>}
                            </Col>
                        </Row> : 
                        <Row className='justify-content-center my-3'>
                            <Col xl={6}>
                                <h6 className={styles.inputLabelDark}>Add Screenshots</h6>
                                <DragDropComponent getFilesHandler={getFilesHandler} />
                            </Col>
                        </Row>
                }
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" value="Import Listings" />
                    </Col>
                </Row>
            </Form>
        </ModalComponent>
    )
}
