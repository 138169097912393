import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "./BankForm.module.scss";
import { useAuth } from '../../Hooks/useAuth';
import TextInput from '../../Components/FormInputs/TextInput';

const BankForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth()
    useEffect(() => { 
        data && reset({ address: data.address, bic: data.bic, name: data.name, branch: data.branch, city: data.city, country: data.country, countryCode: data.countryCode, url: data.url, 
            zipcode: data.zipcode });
    }, [data]);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="justify-content-center">
                    <TextInput fieldName="name" register={register} errors={errors} title="Enter a Name" placeHolder="Enter a Name" isRequired={true} maxLength={20} minLength={0} />  
                    <TextInput fieldName="bic" register={register} errors={errors} title="Enter a BIC Code" placeHolder="Enter the BIC Code" isRequired={true} maxLength={11} minLength={8} />  
                </Row>
                <Row className="justify-content-center">
                    <TextInput fieldName="city" register={register} errors={errors} title="Enter the Bank City" placeHolder="City" isRequired={false} />  
                    <TextInput fieldName="branch" register={register} errors={errors} title="Enter the Branch Name" placeHolder="Bank Branch" isRequired={false} />  
                </Row>
                <Row className="justify-content-center">
                    <TextInput fieldName="address" register={register} errors={errors} title="Enter the Bank Address" placeHolder="Bank Address" isRequired={false} />  
                    <TextInput fieldName="zipcode" register={register} errors={errors} title="Enter the Bank Zip Code" placeHolder="Bank Zipcode" isRequired={false} />  
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Bank Country</h6>
                        <input className={styles.fundFormInputs} placeholder="Bank Country" {...register("country")} />
                    </Col>
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter the Bank Country Code</h6>
                        <input className={styles.fundFormInputs} placeholder="Country Code" {...register("countryCode", { maxLength: 2 })} />
                        {errors.countryCode && errors.countryCode.type === "maxLength" && (<span className={styles.formErrorNotice}>The country code must have 2 digits maximum</span>)}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={12} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter the Bank Url</h6>
                        <input className={styles.fundFormInputs} placeholder="Bank Url" {...register("url")} />
                    </Col>
                </Row>
                
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input data-testid="submitButton" className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
                <Row className="d-flex text-center mt-2 mb-5">
                    <Link className={styles.backLink} to="/main/banks">Back</Link>
                </Row>
            </Form>
        </>
    )
}
export default BankForm