import React, { useState, useEffect } from 'react'
import { Table  } from 'react-bootstrap';
import styles from "./BenchmarkDashboard.module.scss"
import { CSVLink } from "react-csv";

const BenchmarkDataTables = ({ data, fund, quotes }) => {
    const [ handledData, setHandledData ] = useState()
    useEffect(() => {
        if (data) {
            const fundHandledData = { id: fund.id, name: fund.fundName, data: data[fund.id] }
            const quotesHandledData = quotes.map(quote => ({ id: quote.id, name: quote.name, data: data[quote.id] }))
            const handledBenchmarkData = [fundHandledData, ...quotesHandledData]
            setHandledData(handledBenchmarkData)
        }
    }, [data])

    // Function to format date for x label style
    const dateFormatter = dateString => {
        const date = new Date(dateString)
        return date.toLocaleString("en-GB", { day: "numeric", month: "long", year: "numeric" })
    } 

    // Function to format data for CSV download
    const CSVData = data => {
        const csvData = []
        data.map(element => {
            const csvElement = []
            csvElement.push(element.name)
            csvElement.push('Date')
            csvElement.push('Change')
            csvElement.push('Normalized Value')
            csvElement.push('Price')
            csvData.push(csvElement)
            element.data.map(benchmarkUnit => {
                const csvUnit = []
                csvUnit.push('')
                csvUnit.push(dateFormatter(benchmarkUnit.date))
                csvUnit.push(benchmarkUnit.change.toFixed(4))
                csvUnit.push(benchmarkUnit.normalizedValue.toFixed(4))
                csvUnit.push(benchmarkUnit.price.toFixed(4))
                csvData.push(csvUnit)
            })
        })
        return csvData
    }

    if (handledData) {
        return (
            <>
                <CSVLink data={CSVData(handledData)} filename={`${ fund.fundName }.csv`} className={styles.downloadCSVLink}>Download CSV</CSVLink>
                {   handledData.map(element => (
                        <div key={element.id} className='my-5'>
                            <h1 className={styles.tablesTitle}>{ element.name }</h1>
                            <Table responsive borderless={false}>
                                <thead>
                                    <tr className={`${styles.theader} align-middle `}>
                                        <th className='text-start'>Date</th>
                                        <th>Change</th>
                                        <th>Normalized Value</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                { element.data.map(benchmarkUnit => (
                                    <tbody key={benchmarkUnit.date}>
                                        <tr className={`${styles.tableStrips} align-middle `}>
                                            <td className='text-start'>{ dateFormatter(benchmarkUnit.date) }</td>
                                            <td>{ benchmarkUnit.change }</td>
                                            <td>{ benchmarkUnit.normalizedValue }</td>
                                            <td>{ benchmarkUnit.price }</td>
                                        </tr>
                                    </tbody>
                                    ))}
                            </Table>
                        </div>
                    ))
                }
            </>
        )
    }
}
export default BenchmarkDataTables;