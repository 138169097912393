import React from "react";
import styles from "./PieChart.module.scss"
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { numberEditor } from "../../../Helpers/Helpers";

class PieChartComponent extends React.Component {
    COLORS = ['#2F4858', '#3D616D', '#4F7A7F', '#66938F', '#83AD9E', '#A5C6AC'];

    CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className={styles.layerTooltip}>
                    <label>{`${payload[0].name} : ${numberEditor(payload[0].value)}%`}</label>
                </div>
            );
        }
        return null;
    };

    render() {
        const finalData = this.props.data.length > 0 ? this.props.data : [ { [this.props.nameKey]: "No Data", [this.props.dataKey]: 1 } ] 
        const topMaxValues = (data, n) => { // Function to get top 5 highest percentages and group the rest in an 'Others'Group
            if (data && data.length < n)  {
                const positivePercentages = data.filter(x => x.endPercentage > 0) // Filter original data to remove negative endPercentage values
                return positivePercentages
            }
            if (data && data.length >= n) {
                const positivePercentages = data.filter(x => x.endPercentage > 0) // Filter original data to remove negative endPercentage values
                const filteredData = positivePercentages.slice().sort((a, b) => b.endPercentage - a.endPercentage) // Filtered list is sorted by max values
                const topNMaxValues = filteredData.slice(0, n)  // Top N values are displayed 
                const othersGroup = filteredData.slice(n) // Filter to get the remaining coins outside the selected rank to group them together
                const otherPercentageSum = othersGroup.reduce((acc, obj) => { return acc + obj.endPercentage}, 0) // Get the total percentage summary of the "Other coins group"
                topNMaxValues.push({displayName: "Others", endPercentage: otherPercentageSum})  // Object with "Others" group is injected in filtered rank Array as single value
                return topNMaxValues
            } 
        }
        return (
            <div style={{ width: "100%", height: this.props.height }}>
                <ResponsiveContainer>
                    <PieChart >
                        <Pie
                            data={topMaxValues(finalData, this.props.topRank)}
                            color="#000000"
                            dataKey={this.props.dataKey}
                            nameKey={this.props.nameKey}
                            cx="50%"
                            cy="50%"
                            outerRadius={this.props.outerRadius}
                            fill="#8884d8"
                            >
                            {finalData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip content={<this.CustomTooltip />} />
                        {/* <Legend /> */}
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
export default PieChartComponent;