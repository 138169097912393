import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from './AllCurrencies.module.scss'
import { useDeleteCurrency, confirmationAlertDeleteCurrency } from "../../query-hooks/currency-query-hooks/useCurrency";
import { useAuth } from "../../Hooks/useAuth";
import { numberEditor } from "../../Helpers/Helpers";

const AllCurrencies = ({ currencies }) => {
    const { darkmode } = useAuth();
    const mutation = useDeleteCurrency()
    return (
        <>
            <Table responsive borderless={true} >
                <thead>
                    <tr className={darkmode ? styles.theaderDark : styles.theader}>
                        <th>ISOCode</th>
                        <th>Name</th>
                        <th>Symbol</th>
                        <th>Currency Rate</th>
                    </tr>
                </thead>
                {currencies &&
                    currencies.items.map(currency => (
                        <tbody key={currency.isoCode}>
                            <tr className={`align-middle ${darkmode ? styles.tableStripsDark : styles.tableStrips}`} >
                                <td>{currency.isoCode}</td>
                                <td>{currency.name}</td>
                                <td>{currency.symbol}</td>
                                <td>{numberEditor(currency.currencyRate?.usdRate)}</td>
                                <td><Link className={styles.editButton} to={`/main/currencies/edit-currency/${currency.isoCode}`}>Edit</Link></td>
                                <td><button className={styles.deleteButton} onClick={(e) => confirmationAlertDeleteCurrency(e, currency.isoCode, mutation)}>Delete</button></td>
                            </tr>
                    </tbody>
                    ))
                }
            </Table>            
        </>
    );
};
export default AllCurrencies;
