import { getMonthlyReturns } from '../../../query-hooks/benchmark-query-hooks/useBenchmark';

const SharpeRatioFunction = ( data, fund, benchmarkData, elementId ) => {

    // 1- MMult function for Matrix Multiplication for Portfolio Calculation
    const matrixMultiply = (rowVector, columnVector) => {
        if (rowVector.length !== columnVector.length) {
            return "Matrix dimensions are not compatible for multiplication.";
        }
        let result = 0;
        for (let i = 0; i < rowVector.length; i++) {
            result += rowVector[i] * columnVector[i];
        }
        return result;
    }
    // 2- Standard Deviation Function by Portfolio Returns 
    const standardDeviationFunction = (data) => {
        // Calculate Mean
        const mean = data.reduce((acc, value) => acc + value, 0) / data.length ;
      
        // Calculte sum of square of mean differences
        const sumOfSquares = data.reduce((acc, value) => {
          const diff = value - mean;
          return acc + diff * diff;
        }, 0);
      
        // Calculate population Standar deviation
        const populationStdDev = Math.sqrt(sumOfSquares / data.length);
        return populationStdDev;
    }
    
    // -----------------------------------------------------------------------
    // Fund Monthly Return 
    const fundData = data.returns[fund.id]
    const fundMonthlyReturns = { id: fund.id, name: fund.fundName, monthlyReturns: getMonthlyReturns(fundData) }

    // Quotes Monthly Returns
    const quotesMonthlyReturns = []
    data.chartQuotes.forEach(element => {
        if (data.returns[element.id]) {
            const monthlyReturns = getMonthlyReturns(data.returns[element.id])
            const quoteData = { id: element.id, name: element.name, symbol: element.symbol, monthlyReturns: monthlyReturns }
            quotesMonthlyReturns.push(quoteData)
        }
    });
    const allMonthlyReturns = [ fundMonthlyReturns, ...quotesMonthlyReturns ]

    // Calculate Portfolio Returns
    let portfolioValues = []
    allMonthlyReturns.map(x => 
        x.monthlyReturns.forEach((returnObj, i) => {
            portfolioValues[i] = portfolioValues[i] || []
            portfolioValues[i].push(returnObj.value)
        }) 
    )

    const portfolio = portfolioValues.map(val => matrixMultiply( val, allMonthlyReturns.map(monthlyReturn => monthlyReturn.id === elementId ? 1 : 0 ) )) 
    const avgMonthlyPortfolioReturn = portfolio.reduce((acc, curr) => acc + curr, 0) / portfolio.length
    
    // ------- For Sharpe Ratio Calculation
    // 1- Annualized Portfolio Return
    const annualizedPortfolioReturn = Math.pow((avgMonthlyPortfolioReturn + 1), 12 ) - 1
    // 2- Annualized Population Standard Deviation 
    const populationStandardDeviation = standardDeviationFunction(portfolio)
    const annualizedPopulationStandardDeviation = populationStandardDeviation * Math.sqrt(12)
    const sharpeRatio = (annualizedPortfolioReturn - benchmarkData.annualizedBenchmarkReturn) / annualizedPopulationStandardDeviation
    return sharpeRatio.toFixed(2)
}
export default SharpeRatioFunction;