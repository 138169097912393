import React, { useEffect } from 'react'
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import styles from '../ExchangeForm.module.scss'
import { useAuth } from "../../../Hooks/useAuth";

const ExchangeAccountForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { darkmode } = useAuth()
    
    useEffect(() => { 
        data && reset({ name: data.name, accountKey: data.accountKey, accountSecret: data.accountSecret, accountPrivateKey: data.accountPrivateKey });
    }, [data]);
    
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Exchange Account Name</h6>
                    <input className={styles.fundFormInputs} placeholder="Exchange Account Name"  {...register("name", { required: true })}  />
                    {errors.name && errors.name.type === "required" && <span>This field is required</span>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>API Key</h6>
                    <input className={styles.fundFormInputs} placeholder="Account Key"  {...register("accountKey", { required: false })}  />
                    {errors.name && errors.name.type === "required" && <span>This field is required</span>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>API Secret</h6>
                    <input type="password" className={styles.fundFormInputs} placeholder="Account Secret"  {...register("accountSecret", { required: false })}  />
                    {errors.name && errors.name.type === "required" && <span>This field is required</span>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>API Private Key (optional)</h6>
                    <textarea className={styles.fundFormInputs} placeholder="Enter private key"  {...register("accountPrivateKey", { required: false })}  />
                    {errors.name && errors.name.type === "required" && <span>This field is required</span>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={6} md={12} className="py-3">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Account Closed Date</h6>
                    <input className={styles.datetime} type="date" name="booking-period" {...register("closedDateTime", { required: false })}/>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={4} md={12}>
                    <input className={styles.submitButton} type="submit" />
                </Col>
            </Row>
            <Row className="d-flex text-center mt-2 mb-5">
                <Link className={styles.backLink} to={-1}>Back</Link>
            </Row>
        </Form>
    )
}
export default ExchangeAccountForm