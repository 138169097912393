import React from "react";
import { Link } from "react-router-dom";
import styles from "./DashboardComponent.module.scss";
import { useAuth } from "../Hooks/useAuth";

const DashboardComponent = ({title, subtitle, toCreate, buttonMessage, children}) => {
    const { darkmode } = useAuth();
    return (
        <>
            <h5 className={darkmode ? styles.subTitleDark : styles.subTitle}>{ title }</h5>
            <h2 className={darkmode ? styles.mainTitleDark : styles.mainTitle}>{ subtitle }</h2>
            <div className={darkmode ? styles.cardTableDark : styles.cardTable}>
                { buttonMessage && <Link className={darkmode ? styles.createButtonDark : styles.createButton} to={toCreate ? toCreate : "/"}>{buttonMessage}</Link> }
                <div style={darkmode ? {color: "white"} : {color: "black"}}>
                    { children }
                </div>
            </div>
        </>
    )
}
export default DashboardComponent