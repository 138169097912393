import React, { useState } from 'react'
import { useUsers, useUserRoles, useFilterUserByRole, useMutateUserRole } from '../../query-hooks/user-query-hooks/useUser'
import { useAuth } from "../../Hooks/useAuth";
import Pagination from '../../Components/Pagination';
import { Row, Col } from 'react-bootstrap';
import AllUsers from './AllUsers';
import styles from './UsersDashboard.module.scss'
import { ErrorLoading, Spinner } from "../../Helpers/Helpers"


const UsersDashboard = () => {
    const { user, darkmode } = useAuth()
    const allUsers = useUsers(user.token) 
    const allRoles = useUserRoles(user.token)
    const [ chosenRole, setChosenRole ] = useState()
    const filteredUsers = useFilterUserByRole(user.token, chosenRole)
    const [currentItems, setCurrentItems] = useState();
    const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
    const mutation = useMutateUserRole()
    const handleUserRole = (action, userId, role) => mutation.mutate( { action: action, userId: userId, role: role, user: user } )
    return (
        <div className={darkmode ? styles.cardTableDark : styles.cardTable} style={{padding:"3rem"}}>
            <Row>
                <Col xl={4}></Col>    
                <Col xl={6} className="d-flex justify-content-end">
                    {/* Select input to filter users by role */}
                    <select className={styles.selectInput} onChange={e => setChosenRole(e.target.value) } defaultValue="1">
                        <option value={1} disabled={true} className={styles.options}>Filters users by role</option>
                        { allRoles.isSuccess && allRoles.data.map(role => <option key={role.normalizedName} value={role.name} className={styles.options}>{ role.name }</option>) }
                    </select>
                </Col>
                <Col xl={2}>
                    <button className={styles.resetButton} onClick={() => setChosenRole()} >Reset filter</button>
                </Col>
            </Row>
            { chosenRole ? 
                <>
                    <AllUsers currentItems={currentItems} allRoles={allRoles} handleUserRole={handleUserRole} />
                    { filteredUsers.isSuccess && <Pagination items={filteredUsers.data} getCurrentItems={getCurrentItems} itemsPerPage={15} /> }
                    { filteredUsers.isLoading && <Spinner /> } { filteredUsers.isError && <ErrorLoading /> }

                </> :
                <>
                    <AllUsers currentItems={currentItems} allRoles={allRoles} handleUserRole={handleUserRole} />
                    { allUsers.isSuccess && <Pagination items={allUsers.data.items} getCurrentItems={getCurrentItems} itemsPerPage={15} /> } 
                    { allUsers.isLoading && <Spinner /> } { allUsers.isError && <ErrorLoading /> }
                </>
            }
        </div>
    )
}
export default UsersDashboard 