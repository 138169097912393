import React from 'react'
import { Navigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";

export const ProtectedRoute = ({ children }) => {
  const { user, storeUser } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  storeUser(user)
  return children;
};
