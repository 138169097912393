import React, { useState } from "react";
import { Collapse, Row, Col } from "react-bootstrap";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styles from "./Tables.module.scss";
import { numberEditor } from "../../../Helpers/Helpers";

const DashboardTradingLogCollapseTable = ({ trades, quoteAsset, baseAsset }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        {open ? (
          <div className={styles.hideShowMessage}>
            Hide Trades
            <IoIosArrowUp
              className={styles.toggleIcon}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            />
          </div>
        ) : (
          <div className={styles.hideShowMessage}>
            Show Trades
            <IoIosArrowDown
              className={styles.toggleIcon}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            />
          </div>
        )}
      </div>
      <Collapse in={open}>
        <div className={styles.collapsibleContainer} id="example-collapse-text" >
            <Row className={styles.collapsibleTableHeader}>
                <Col xs={4}>Date Time</Col>
                <Col xs={2}>Price</Col>
                <Col xs={2}>Filled</Col>
                <Col xs={2}>Total</Col>
                <Col xs={2}>Fee</Col>
            </Row>
            {trades.map((trade, it) => (
                <Row key={it} className={styles.collapsibleRow}>
                    <Col xs={4}>{trade.dateTime.replace("T", " / ").replace("Z", "")}</Col>
                    <Col xs={2} title={trade.unitPrice}>{numberEditor(trade.unitPrice)} {quoteAsset} </Col>
                    <Col xs={2} title={trade.executed}>{numberEditor(trade.executed)} {baseAsset}</Col>
                    <Col xs={2} title={trade.total}>{numberEditor(trade.total)} {quoteAsset}</Col>
                    <Col xs={2} title={trade.fee}>{numberEditor(trade.fee)} {trade.feeCurrencySymbol}</Col>
                    {/* <hr /> */}
                </Row>
            ))}
        </div>
      </Collapse>
    </>
  );
};
export default DashboardTradingLogCollapseTable;
