import React from "react";
import styles from "../styles/Login.module.scss" 
import { useForm } from "react-hook-form";
import Logo from "../images/logo1.png";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import { LOGIN_API_URL } from "../settings";
import { simpleSuccessfulMessage, simpleErrorMessage } from "../Helpers/Helpers";
import Swal from "sweetalert2";

const Authentication = () => {
  const navigate = useNavigate();
  const { user, login } = useAuth();

  const onHandleNewCode = () => {
    user && axios.get(`${LOGIN_API_URL}/auth/verify-email/${user.email}`)
    .then(() => simpleSuccessfulMessage("Check your email for the new Verification Code")).catch(error => console.log(error))
  }

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (data) => {
    await axios.post(`${LOGIN_API_URL}/auth/verify-email`, data)
    .then(res => {
      login(res.data)
      Swal.fire({ 
        title: "You are succesfully registered",
        icon: "success", 
        confirmButtonColor: "#6d9e93",
        confirmButtonText: "Enter"
      }).then(res => res.isConfirmed && navigate("/main/dashboard", { replace: true }))
    })   
    .catch((error) => {
      console.log(error);
      if (error.response.data.errors[0].code === "InvalidToken") {
        simpleErrorMessage("Invalid Token. Please enter correct token or request another code.")
      }
    });
  };

  return (
    <main>
      <div>
        <Container className="d-flex justify-content-center">
          <form
            className={styles.loginForm}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="on"
          >
            <Row>
              <Col className="d-flex justify-content-center">
                <img className="mb-5" src={Logo} alt="Hodl" />
              </Col>
              <Col lg={12} xs={12} md={12}>
                  <h5 className={styles.codeMessage}>
                    To login need to confirm email. Confirmation code has been sent to your email.
                  </h5>
              </Col>
              <Col lg={12} xs={12} md={12}>
                <input {...register("email", { required: true })} value={user && user.email} />
                {errors.email && (<p className={styles.formErrorNotice}>This field is required</p>)}
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12} md={12} className="mt-3">
                <input type="text" {...register("verifyCode", { required: true })} placeholder="Enter the code sent to your email" />
                {errors.verifyCode && (<p className={styles.formErrorNotice}>This field is required</p>)}
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <button className={styles.registerButton} type="submit">
                  Authenticate your account
                </button>
              </Col>
            </Row>
            <Row>
              <Col className={styles.linkDiv} style={{ marginTop: "1rem" }}>
                <h5 className={styles.link} onClick={() => onHandleNewCode()}> Click here to get the Verification code in your email</h5>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </main>
  );
};

export default Authentication;
