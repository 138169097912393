import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from "../../FundForm.module.scss";
import { useAuth } from '../../../../Hooks/useAuth';
import CryptoForm from './Components/CryptoForm';
import CurrencyForm from './Components/CurrencyForm';
import FundShareForm from './Components/FundShareForm';
import { useParams, useNavigate } from 'react-router-dom';

const FundsHoldingFormStateHandler = ({ onSubmit }) => {
    const { darkmode } = useAuth()
    const { fundId } = useParams()
    const navigate = useNavigate()
    const [ showInput, setShowInput ] = useState("0") // State to trigger what form to display

    return (
        <>
            <Row className={styles.radiosRow}>
                <Col xl={4} md={12}>
                    <label className={styles.container}><h6 className={ darkmode ? styles.radioLabelDark : styles.radioLabel }>Crypto</h6>
                        <input type="radio" name="CurrencyOption" value="0" defaultChecked={true} onClick={e => setShowInput(e.target.value) } />
                        <span className={styles.checkmark}></span>
                    </label>
                </Col>
                <Col xl={4} md={12}>
                    <label className={styles.container}><h6 className={ darkmode ? styles.radioLabelDark : styles.radioLabel }>Currency</h6>
                        <input type="radio" name="CurrencyOption" value="1" onClick={e => setShowInput(e.target.value) } />
                        <span className={styles.checkmark}></span>
                    </label>
                </Col>
                <Col xl={4} md={12}>
                    <label className={styles.container}><h6 className={ darkmode ? styles.radioLabelDark : styles.radioLabel }>Fund Shares</h6>
                        <input type="radio" name="CurrencyOption" value="2" onClick={e => setShowInput(e.target.value) } />
                        <span className={styles.checkmark}></span>
                    </label>
                </Col>
            </Row>

            { showInput === "0" && <CryptoForm onSubmit={onSubmit} fundId={fundId} darkmode={darkmode} />   }
            { showInput === "1" && <CurrencyForm onSubmit={onSubmit} fundId={fundId} darkmode={darkmode} /> }
            { showInput === "2" && <FundShareForm onSubmit={onSubmit} fundId={fundId} darkmode={darkmode} /> }

            <Row className="d-flex text-center mt-2 mb-5">
                <h5 className={styles.backLink} onClick={() => navigate(-1)}>Back</h5>
            </Row>
        </>
    )
}
export default FundsHoldingFormStateHandler