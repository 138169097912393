import React, { useState } from 'react'
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import styles from "./BenchmarkDashboard.module.scss"
import { useBenchmarkFunds, useBenchmarkQuotes, useMonthlyReturns, useReturnsChartMonthlyReturns } from '../../query-hooks/benchmark-query-hooks/useBenchmark';
import BenchmarkDataDisplay from './BenchmarkDataDisplay';
import DateSliders from './Components/DateSliders';
import BenchmarkDataTables from './BenchmarkDataTables';
import { Spinner } from '../../Helpers/Helpers';
import AllBenchmarks from './CRUD/AllBenchmarks';
import { IoIosArrowDropleft } from "react-icons/io";
import { dateFormatter } from '../../Helpers/Helpers';

const BenchmarkDashboard = () => {
    const benchmarkFunds = useBenchmarkFunds()
    const quotes = useBenchmarkQuotes()
    const [ chosenFund, setChosenFund ] = useState()
    const [ dates, setDates ] = useState()
    const [ displayCrudModal, setDisplayCrudModal ] = useState(false)
    const monthlyReturns = useMonthlyReturns(chosenFund, dates, quotes?.data) 
    const returnsChartMonthlyReturns = useReturnsChartMonthlyReturns(chosenFund, dates)
    // Centralized function to handle Fund Names display on the presentational sheet
    const handleNameTransformation = fundName => {
        if (fundName.toLowerCase().includes("genesis")) {
            return "Hodl Actively Managed"
        }
        if (fundName.toLowerCase().includes("algorithmic")) {
            return `Algorithmic Strategy (${ fundName })`
        }
        if (fundName.toLowerCase().includes("oracle")) {
            return `Algorithmic Strategy (${ fundName })`
        }
        return fundName
    }

    const resetForm = () => {
        setChosenFund()
        setDates()
    }

    if (monthlyReturns.isLoading) {
        return <Spinner />
    }

    if (monthlyReturns.isSuccess && quotes.isSuccess && returnsChartMonthlyReturns.isSuccess) {
        const transformedFundName = handleNameTransformation(chosenFund.fundName)
        return (
            <div className={styles.mainChartsDashboard}>
                <IoIosArrowDropleft onClick={() => resetForm()} className={styles.backIcon} title="Back" /> 
                <h1 className={styles.fundTitle}>{ transformedFundName }</h1>
                <h2 className={styles.titleDates}>{ dateFormatter(monthlyReturns.data.data[chosenFund.id][0].date) } - { dateFormatter(monthlyReturns.data.data[chosenFund.id].slice(-1)[0].date) } </h2>
                <Tabs defaultActiveKey="benchmark" id="uncontrolled-tab-example">
                    <Tab eventKey="benchmark" title="Benchmark" tabClassName="text-success">
                        <BenchmarkDataDisplay 
                            data={monthlyReturns.data.data} 
                            benchmarkData={monthlyReturns.data.benchmarkData}
                            fund={chosenFund}
                            quotes={quotes}
                            returnsChartData={returnsChartMonthlyReturns.data}
                            transformedFundName={transformedFundName}
                        />
                    </Tab>
                    <Tab eventKey="data" title="Data" tabClassName="text-success">
                        <BenchmarkDataTables 
                            data={returnsChartMonthlyReturns.data.returns} 
                            fund={chosenFund} 
                            quotes={returnsChartMonthlyReturns.data.chartQuotes} 
                        />
                    </Tab>
                </Tabs>
            </div>
        ) 
    }

    return (
        <div >
            { displayCrudModal && <AllBenchmarks setDisplayCrudModal={setDisplayCrudModal} /> }
            <Row className='mb-5 justify-content-center text-center'>
                <Col xl={8}>
                    <button className={styles.editButton} onClick={() => setDisplayCrudModal(true)}>Edit Benchmark</button>
                </Col>
            </Row>
            <Row className='mb-5 justify-content-center'>
                <Col xl={10}>
                    <h6 className={`${styles.inputLabelDark} text-center`} >Select Strategy<em className={styles.reqSymbol}>*</em></h6>
                    <Row>
                        { benchmarkFunds.isSuccess && benchmarkFunds.data.map(fund => (
                            <Col key={fund.id} xl={4} className="d-flex justify-content-center">
                                <button 
                                    onClick={() => setChosenFund(benchmarkFunds.data.find(benchmarkFund => benchmarkFund.id === fund.id))}
                                    className={fund.id === chosenFund?.id ? styles.fundButtonActive : styles.fundButtonInactive}
                                >{ handleNameTransformation(fund.fundName) }</button>
                            </Col> 
                        ))}
                    </Row>
                    <div>
                        { chosenFund && <DateSliders dates={[...chosenFund.navPeriods].reverse()} setDates={setDates} /> }
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default BenchmarkDashboard;