import React from 'react'

const BenchmarkFundDataTable = ({ returnsChartData }) => {
    // console.log(returnsChartData)
    return (
        <div>
            <h1>Table</h1>
        </div>
    )
}
export default BenchmarkFundDataTable;