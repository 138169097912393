import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom'
import styles from "./CryptoForm.module.scss";
import CryptoSearchInput from './CryptoSearchInput';
import CryptosImageHandler from './CryptosImageHandler';
import { useAuth } from '../../Hooks/useAuth';
import useCryptos, { useCreateCrypto } from '../../query-hooks/crypto-query-hooks/useCryptos';
import { simpleSuccessfulMessage } from '../../Helpers/Helpers';

const CryptoForm = ({ onSubmit, data, closeModalMode }) => {
    const { crypto_id } = useParams()
    const { darkmode } = useAuth()
    const [ newCryptoFetched, setNewCryptoFetched ] = useState()
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({ defaultValues: { active: true } });
    const onHandleNewCryptoFetched = newCrypto => setNewCryptoFetched(newCrypto)
    const allCryptos = useCryptos()
    const createCrypto = useCreateCrypto()

    useEffect(() => { // Side effect to load data cryptos data based on params id, resetting the form values in case of edit form (react-hook-form reset)
        data && reset({ decimals: data.decimals, symbol: data.symbol, name: data.name, isStableCoin: data.isStableCoin, isLocked: data.isLocked, active: data.active });
        newCryptoFetched && reset({ symbol: newCryptoFetched.symbol, name: newCryptoFetched.name });
    }, [data, newCryptoFetched]);

    useEffect(() => {
        if (data && data.listingReference) {
            setTimeout(() => {
                setValue("listingCryptoId", data.listingReference.id);
            }, 1000);
        }
    }, [data])

    const getImageValue = (updatedImage) => setValue("icon", updatedImage); // Function sent to ImageHandler component to update SetValue state
    
    // Create crypto function only if modal mode is true (OrderForm Component)
    const onCreateCrypto = data => {
        if (data.listingCryptoId === "1") {
            data.listingCryptoId = null
        }
        return createCrypto.mutate(data)
    } 
    useEffect(() => { createCrypto.isSuccess && closeModalMode(false) }, [createCrypto])
    useEffect(() => { createCrypto.isSuccess && simpleSuccessfulMessage("Currency created succesfully") }, [createCrypto])
    return (
        <>
            <Row>
                <Col lg={3} md={12} className="py-3 mx-3"></Col>
                {   !data &&
                    <Col lg={6} >
                        <CryptoSearchInput onHandleNewCryptoFetched={onHandleNewCryptoFetched} />
                    </Col>
                }
            </Row>
            <Form onSubmit={closeModalMode ? handleSubmit(onCreateCrypto) : handleSubmit(onSubmit)}>
                <Row>
                    <Col lg={3} md={12} className="py-3 mx-3">
                        <CryptosImageHandler crypto_id={crypto_id} getImageValue={getImageValue} newCryptoFetched={newCryptoFetched}/>
                        <input {...register("icon")} type="hidden" />
                    </Col>
                    <Col lg={6} md={12} className="py-5">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Symbol</h6>
                        <input className={styles.fundFormInputs} placeholder="Symbol must be 8 digits max" {...register("symbol", { required: true, maxLength: 8 })} />
                        {errors.symbol && errors.symbol.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        {errors.symbol && errors.symbol.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 8 digits exceeded</span>)}

                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Name</h6>
                        <input className={styles.fundFormInputs} placeholder="Name" {...register("name", { required: true })} />
                        {errors.name && errors.name.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}

                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter Decimals number</h6>
                        <input className={styles.fundFormInputs} type="number" onWheel={e => e.currentTarget.blur()} placeholder="Decimals" {...register("decimals", { required: true, valueAsNumber: true, max: 255, })} />
                        {errors.decimals && errors.decimals.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                        {errors.decimals && errors.decimals.type === "max" && (<span className={styles.formErrorNotice}>Max decimals: 255</span>)}
                        <Col lg={12} md={12} className="py-1">
                            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Listing Currency (Optional)</h6>
                            <select className={styles.selectInput} {...register("listingCryptoId")} defaultValue="1"> 
                                <option disabled={true} value="1">Select a Crypto Listing (Optional)</option>
                                { allCryptos.isSuccess && allCryptos.data.map(crypto => (<option key={crypto.id} value={crypto.id}>{crypto.name}</option>))} 
                            </select> 
                        </Col>
                        <div style={{marginTop: "3rem", display: "flex", justifyContent: "center"}}>
                            <label className={styles.checkbox}>
                                <input type="checkbox" {...register("active")} />
                                <span>Active</span>
                            </label>
                            <label className={styles.checkbox}>
                                <input type="checkbox" {...register("isStableCoin")} />
                                <span>Stablecoin</span>
                            </label>
                            <label className={styles.checkbox}>
                                <input type="checkbox" {...register("isLocked")} />
                                <span>Locked</span>
                            </label>
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
                {   !closeModalMode &&
                        <Row className="d-flex text-center mt-2 mb-5">
                            <Link className={styles.backLink} to={-1}>Back</Link>
                        </Row>
                }
            </Form>
        </>
    )
}
export default CryptoForm