import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./TransferForm.module.scss"

const CurrencyPopup = ({ data, setValue }) => {
    const setTransferFullBalance = e => {
        e.preventDefault()
        setValue("transferAmount", data.endBalance)
    }

    return (
        <Row className="d-flex justify-content-center" style={{margin:"1rem 0 0 0"}}>
            <Col xl={12}>
                <Row className={styles.currencyPopupContainer} style={ !data ? { border: "none" } : {} }>
                    { data &&
                        <>
                            <Col xl={4} className={styles.currencyPopupText}>
                                <span>Currency</span> 
                                <p>{ data.displayName }</p>
                            </Col>
                            <Col xl={4} className={styles.currencyPopupText}>
                                <span>Balance</span> 
                                <p>{ data.endBalance}</p>
                            </Col>
                            <Col xl={4} className={styles.currencyPopupText}>
                                <button className={styles.editButton} style={{margin:"1rem 0"}} onClick={setTransferFullBalance}>Transfer Full Balance</button>
                            </Col>
                        </>
                    }
                </Row>
            </Col>
        </Row>
        
    );
};
export default CurrencyPopup;

