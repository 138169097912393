import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useFundDetail, useSandboxFundData } from '../../../query-hooks/dashboard-query-hooks/useDashboard';
import SandboxComponent from './SandboxComponent';
import styles from "./Sandbox.module.scss"
import { ErrorLoading, Spinner, numberEditor } from '../../../Helpers/Helpers';
import { useAuth } from '../../../Hooks/useAuth';

const Sandbox = () => {
    const { darkmode } = useAuth()
    const { fundId } = useParams() 
    const [ queryString, setQueryString ] = useState("")
    const fundData = useSandboxFundData(fundId, queryString);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const scrollRef = useRef(null);
    const fundDetails = useFundDetail(fundId)
    const [totalUSDPrice, setTotalUSDPrice] = useState(0);
    const [childUSDPrice, setChildUSDPrice] = useState([]);
    const [totalUSDPricePercentage, setTotalUSDPricePercentage] = useState(0);
    const [childUSDPricePercentage, setChildUSDPricePercentage] = useState([]);

    const onHandleMouseDown = e => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    }

    const onHandleMouseUp = () => setIsDragging(false)

    const onHandleMouseMove = e => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 1; // adjust scrolling speed
        scrollRef.current.scrollLeft = scrollLeft - walk;
    }

    // Block to calculate USD Price total updated when Trade and Price are input
    const updateUSDTotalValues = (index, value) => {
        setChildUSDPrice((prevChildValues) => {
          const newChildValues = [...prevChildValues]; // Creates a new variable with old array of values
          newChildValues[index] = value; // Gets the value in the array by its index and replaces it with the new value
          return newChildValues;
        });
    };

    useEffect(() => {
        const newTotal = childUSDPrice.reduce((acc, value) => acc + value, 0);
        setTotalUSDPrice(newTotal);
    }, [childUSDPrice]);

    // Block to calculate USD Price total PERCENTAGES updated when Trade and Price are input
    const updateUSDTotalPercentages = (index, value) => {
        setChildUSDPricePercentage((prevChildValues) => {
            const newChildValues = [...prevChildValues]; 
            newChildValues[index] = value; 
            return newChildValues;
        });
    };
    
    useEffect(() => {
        const newTotal = childUSDPricePercentage.reduce((acc, value) => acc + value, 0);
        setTotalUSDPricePercentage(newTotal);
    }, [childUSDPricePercentage]);

    const theaderWidth = "3rem"

    return (
        <>  
            { fundDetails.isSuccess && <h1 className={styles.mainTitleDark}>Sandbox { fundDetails.data.fundName }</h1> }
            <input 
                onChange={e => setQueryString(e.target.value)} 
                value={queryString} 
                className={styles.sandboxInputFilter} 
                placeholder='Filter by coin'
            />
            <div 
                className={isDragging ? styles.dragContainer : styles.notDragContainer} 
                onMouseDown={onHandleMouseDown} 
                onMouseUp={onHandleMouseUp} 
                onMouseMove={onHandleMouseMove} 
                ref={scrollRef}
            >
                { fundData.isSuccess &&
                    <table className={styles.table}>
                        <thead>
                            <tr className={darkmode ? styles.theaderDark : styles.theader}>
                                <th>Coin</th>
                                <th>Ticker</th>
                                <th>Quantity</th>
                                <th style={{width:theaderWidth, borderBottom:"none", borderTop:"none"}}></th>
                                <th>Current BTC Price</th>
                                <th>% Change</th>
                                <th>Total PnL</th>
                                <th style={{width:theaderWidth, borderBottom:"none", borderTop:"none"}}></th>
                                <th>Trade</th>
                                <th>Price</th>
                                <th>USD Price</th>
                                <th>BTC Value</th>
                                <th style={{width:theaderWidth, borderBottom:"none", borderTop:"none"}}></th>
                                <th>Total $</th>
                                <th>Total %</th>
                                <th style={{width:theaderWidth, borderBottom:"none", borderTop:"none"}}></th>
                                <th>Old Entry Price</th>
                                <th>New Entry Price</th>
                                <th>% Change</th>
                                <th style={{width:theaderWidth, borderBottom:"none", borderTop:"none"}}></th>
                                <th>Fund Holdings</th>
                                <th>Layer</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            { fundData.data.map((data, index) => (
                                <SandboxComponent 
                                    data={data} 
                                    key={data.cryptoCurrency.id} 
                                    fundId={fundId} 
                                    updateUSDTotalValues={updateUSDTotalValues}
                                    updateUSDTotalPercentages={updateUSDTotalPercentages} 
                                    index={index} 
                                    totalUSDPrice={totalUSDPrice} />
                            )) }
                        </tbody>
                        <tfoot>
                            <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                                <th colSpan="13" style={{textAlign:"right", fontWeight: "900", color: "#6D9E93", border:"none", padding:"1.5rem", }}>Total</th>
                                <td>{ numberEditor(totalUSDPrice) }</td>
                                <td>{ numberEditor(totalUSDPricePercentage) }%</td>
                            </tr>
                        </tfoot>
                    </table>
                }
                { fundData.isLoading && <Spinner /> }
                { fundData.isError && <ErrorLoading error={fundData.error} />}

            </div>
        </>
    )
}
export default Sandbox;