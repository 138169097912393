import React, { useEffect } from "react";
import styles from "../styles/Login.module.scss";
import { useForm } from "react-hook-form";
import Logo from "../images/logo1.png";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useAuth } from "../Hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_API_URL } from "../settings";
import { AlertFunction } from "../Helpers/Helpers"
import { GoogleLogin } from '@react-oauth/google';
import { useCheckGoogleOAuthToken } from "../query-hooks/user-query-hooks/useUser";

const Login = () => {
  const { login, user } = useAuth();
  const navigate = useNavigate();
  const checkGoogleOAuthToken = useCheckGoogleOAuthToken()

  useEffect(() => { // When page loads check if user exists in LocalStorage so it navigates to main dashboard
    if (user) { 
      login(user)
      navigate("/main/dashboard")
    }
  }, [])

  const { register, handleSubmit, formState: { errors }, } = useForm();

  const onSubmit = async (data) => {
    await axios.post(`${LOGIN_API_URL}/auth/login`, data, {
        headers: {
          "Content-Type": "application/json",
          "email": data.email
        },
      })
      .then(res => {
        login(res.data)
        if (res.data.twoFactorEnabled === true) {
          return navigate("/mfa-check")
        };
        navigate("/main/dashboard")
      }) 
      .catch(err => console.log(err));
  };

  // Google OAuthentication
  const googleLogin = idToken => checkGoogleOAuthToken.mutate({ provider: "Google", token: idToken.credential });
  useEffect(() => { 
    if (checkGoogleOAuthToken.isSuccess) {
      login(checkGoogleOAuthToken.data) 
      navigate("/main/dashboard")
    }
  }, [checkGoogleOAuthToken])

  return (
    <main>
      <div>
        <Container className="d-flex justify-content-center">
          <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)} autoComplete="on" >
            <Row>
              <Col className="d-flex justify-content-center">
                <img className="mb-5" src={Logo} alt="Hodl" />
              </Col>
              <Col lg={12} xs={12} md={12}>
                <input type="email" {...register("email", { required: true })} placeholder="Email" />
                {errors.email && ( <p className={styles.formErrorNotice}>This field is required</p> )}
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12} md={12} className="mt-3">
                <input type="password" {...register("password", { required: true })} placeholder="Password" />
                {errors.password && ( <p className={styles.formErrorNotice}>This field is required</p> )}
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <button className={styles.registerButton} type="submit"> Login </button>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <Link to="/register" style={{textDecoration: 'none'}}>
                  <button className={styles.registerButton} style={{backgroundColor: 'transparent'}} type="submit"> Registration </button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xl={12} className="d-flex justify-content-center mt-4 mb-3">
                <GoogleLogin onSuccess={res => googleLogin(res)} shape="pill" width="25px" size="large" />
              </Col>
            </Row>
            <Row>
              <Col className={styles.linkDiv}>
                <Link className={styles.link} to="/reset-password"> Forgot your password? </Link>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </main>
  );
};

export default Login;
