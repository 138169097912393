import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFund, useCreateFund, useEditFund, useFundAllClasses } from "../../query-hooks/fund-query-hooks/useFund";
import FundForm from "./FundForm";
import FormComponent from "../../Components/FormComponent"
import { ErrorLoading, Spinner } from "../../Helpers/Helpers";
import AllClasses from "./Classes/AllClasses";

const FundFormMutation = () => {
  const { fundId } = useParams();
  const navigate = useNavigate();
  const fund = useFund(fundId);
  const editFund = useEditFund();
  const createFund = useCreateFund();
  const allFundClasses = useFundAllClasses(fundId)
  const onSubmit = data => {
    if (data.hwmResetDate === "") {
      data.hwmResetDate = null
    }
    if (data.dateEnd === "") {
      data.dateEnd = null
    }
    fundId ? editFund.mutate( {fund: data, fundId: fundId} ) : createFund.mutate(data)  
  } 
  useEffect(() => { createFund.isSuccess && navigate(-1) }, [createFund])
  return (
    <FormComponent title="Fund Dashboard" subtitle={fundId ? "Edit Fund" : "Create Fund"}>
      { !fundId && <FundForm onSubmit={onSubmit} /> }
      { fund.isSuccess && <FundForm onSubmit={onSubmit} data={fund.data} /> }   
      { fund.isLoading && <Spinner /> }
      { fund.isError && <ErrorLoading /> } 
      { allFundClasses.isSuccess && <AllClasses classes={allFundClasses.data} fundId={fundId} /> }
    </FormComponent>
  );
};
export default FundFormMutation;