import axios from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { API_URL } from "../../settings";
import Swal from "sweetalert2";

// Function to get all exchanges
const fetchExchanges = () => axios.get(`${API_URL}/exchanges`).then(response => response.data);
export default function useExchanges() {
  return useQuery("exchanges", fetchExchanges);
}

// Function to get all exchange accounts
const fetchAllExchangeAccounts = () => axios.get(`${API_URL}/exchangeaccounts`).then(response => response.data);
export function useAllExchangeAccounts() {
  return useQuery("allExchangeAccounts", fetchAllExchangeAccounts);
}

// Function to get all exchange accounts for select boxes
const fetchExchangeAccountsSelect = () => axios.get(`${API_URL}/exchangeaccounts/select`).then(response => response.data);
export function useExchangeAccountsSelect() {
  return useQuery("exchangeAccountsSelect", fetchExchangeAccountsSelect);
}

// Function to get exchange data
const fetchExchange = (exchangeId) => axios.get(`${API_URL}/exchanges/${exchangeId}`).then(response => response.data)
export function useExchange(exchangeId) {
  return useQuery([ "exchange", exchangeId ], () => fetchExchange(exchangeId), {enabled: exchangeId !== undefined});
}

// Function to get exchange accounts of one exchange
const fetchExchangeAccounts = (exchangeId) => axios.get(`${API_URL}/exchanges/${exchangeId}/accounts`).then(response => response.data)
export function useExchangeAccounts(exchangeId) {
  return useQuery( [ "exchangeAccounts", exchangeId ], () => fetchExchangeAccounts(exchangeId), {enabled: exchangeId !== undefined} )
}

// Function to get one exchange account
const fetchExchangeAccount = (exchangeAccountId) => axios.get(`${API_URL}/exchangeaccounts/${exchangeAccountId}`).then(response => response.data)
export function useExchangeAccount(exchangeAccountId) {
  return useQuery( [ "exchangeAccount", exchangeAccountId ], () => fetchExchangeAccount(exchangeAccountId), {enabled: exchangeAccountId !== undefined} )
}

//MUTATIONS

//EXCHANGE MUTATIONS
//Function to post Exchange
const postExchange = (exchange) => axios.post(`${API_URL}/exchanges`, exchange).then(response => response.data);

export function useCreateExchange() {
  const queryClient = useQueryClient()
  return useMutation(exchange => postExchange(exchange),
  {
    onSuccess: () => {
      queryClient.invalidateQueries("exchanges")
    }
  }
  );
}

//Function to edit Exchange
const editExchange = (exchange, exchangeId) => axios.put(`${API_URL}/exchanges/${exchangeId}`, exchange).then(response => response.data);

export function useEditExchange() {
  const queryClient = useQueryClient()
  return useMutation(({ exchange, exchangeId }) => editExchange(exchange, exchangeId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("exchanges")
      }
    }
  );
}

//Function to delete Exchange
const deleteExchange = (exchangeId) => axios.delete(`${API_URL}/exchanges/${exchangeId}`).then(response => response.data);
export function useDeleteExchange() {
  const queryClient = useQueryClient()
  return useMutation(({ exchangeId }) => deleteExchange(exchangeId), 
  { onSuccess: async () => queryClient.invalidateQueries("exchanges"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) } )
}
// Message object inserted in delete message
const deleteMessageObject = {
  title: "Are you sure you want to delete this Exchange?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteExchange = (event, exchangeId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageObject).then((result) => result.isConfirmed && mutation.mutate({ exchangeId: exchangeId }) );
};

// EXCHANGE ACCOUNT MUTATIONS

//Function to post Exchange Account
const postExchangeAccount = (exchangeAccount) => axios.post(`${API_URL}/exchangeaccounts`, exchangeAccount).then(response => response.data);

export function useCreateExchangeAccount() {
  const queryClient = useQueryClient()
  return useMutation(exchangeAccount => postExchangeAccount(exchangeAccount),
  { onSuccess: async () => queryClient.invalidateQueries("exchangeAccounts"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) } )
}

//Function to edit Exchange Account
const editExchangeAccount = (exchangeAccount, exchangeAccountId) => axios.put(`${API_URL}/exchangeaccounts/${exchangeAccountId}`, exchangeAccount).then(response => response.data);

export function useEditExchangeAccount() {
  const queryClient = useQueryClient()
  return useMutation(({ exchangeAccount, exchangeAccountId }) => editExchangeAccount(exchangeAccount, exchangeAccountId),
  { onSuccess: async () => queryClient.invalidateQueries("exchangeAccounts"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) } )
}

//Function to delete Exchange
const deleteExchangeAccount = exchangeAccountId => axios.delete(`${API_URL}/exchangeaccounts/${exchangeAccountId}`).then(response => response.data);
export function useDeleteExchangeAccount() {
  const queryClient = useQueryClient()
  return useMutation(({ exchangeAccountId }) => deleteExchangeAccount(exchangeAccountId), 
  { onSuccess: async () => queryClient.invalidateQueries("exchangeAccounts"), onError: async (error) => Swal.fire(error.response.data.errors[0].description) } )
}
// Message object inserted in delete message
const deleteMessageExchangeAccount = {
  title: "Are you sure you want to delete this Exchange Account?", 
  text: "You won't be able to revert this!", 
  icon: "warning", 
  showCancelButton: true, 
  confirmButtonColor: "#6d9e93",
  cancelButtonColor: "#d33", 
  confirmButtonText: "Yes, delete it!"
}

export const confirmationAlertDeleteExchangeAccount = (event, exchangeAccountId, mutation) => {
  event.preventDefault();
  Swal.fire(deleteMessageExchangeAccount).then((result) => result.isConfirmed && mutation.mutate({ exchangeAccountId: exchangeAccountId }) );
};

