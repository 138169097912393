import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./FundingsPercentageBar.module.scss";
import useFunds from "../../../query-hooks/fund-query-hooks/useFund";
import { numberEditor } from "../../../Helpers/Helpers";

const FundingsPercentageBar = ({ setFundings, importData }) => {

    const [percentages, setPercentages] = useState();
    const [fullPercentageFulfilled, setFullPercentageFulfilled] = useState();
    const funds = useFunds()    

    const handlePercentageChange = (fundId, value) => {
        setPercentages((prevPercentages) => [
            ...prevPercentages.map(obj => {
                if (obj.fundId === fundId) {
                    return {
                        ...obj,
                        percentage: value,
                        amount: (value * importData.transferAmount) / 100,
                    };
                }
                return obj;
            }),
        ]);
    };

    useEffect(() => {
        funds.isSuccess &&
            setPercentages(
                funds.data.items.map(fund => ({
                    fundId: fund.id,
                    percentage: 0,
                    amount: 0,
                    name: fund.fundName,
                }))
            );
    }, [funds.data]);

    useEffect(() => {
        // Effect to count the total percentages to activate if fullPercentageFulfilled state 100% is reached
        const totalPercentage = percentages && percentages.reduce((acc, curr) => curr.percentage + acc, 0);
        totalPercentage === 100 ? setFullPercentageFulfilled(true) : setFullPercentageFulfilled(false);
    }, [percentages]);

    // Function that sends fundings 
    useEffect(() => {
        if (fullPercentageFulfilled) {
            const filter = percentages && percentages.map((fund) => ({
                fundId: fund.fundId,
                fundAmount: fund.amount,
            }));
            return setFundings(filter);
        } setFundings()
    }, [fullPercentageFulfilled])

    return (
        <>
            <div className="mb-3" style={{height:"2rem"}}>
                { fullPercentageFulfilled && <button className={styles.editButton} onClick={() => setFullPercentageFulfilled()}>Reset Fundings</button> }
            </div>
            <Row className={styles.barContainer}>
                { percentages && percentages.map((percentage) => (
                    <Col lg={parseInt(12 / funds.length)} key={percentage.fundId}>
                        <div style={fullPercentageFulfilled ? { border: "solid 3px #6D9E93" } : {}} className={styles.sliderContainer}>
                            <input 
                                type="range" 
                                defaultValue={percentage.percentage} 
                                min={0}
                                max={100}
                                onChange={e => handlePercentageChange(percentage.fundId, parseInt(e.target.value))}
                                className={styles.slider}
                                disabled={fullPercentageFulfilled}
                            />
                            <p className={styles.text}>
                                {percentage.name.length > 9 ? `${percentage.name.slice(0, 9)}...` : percentage.name}
                            </p>
                            <p style={{textAlign:"center", fontSize:"15px"}}>
                                { parseInt(percentage.percentage) }%
                            </p>
                            <p className={styles.text}>
                                Amount:
                            </p>
                            <p className={styles.amount}>
                                {numberEditor(percentage.amount)}
                            </p>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    );
};
export default FundingsPercentageBar;