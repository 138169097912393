import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFund, useCreateFundClass, useEditFundClass, useFundAllClasses } from "../../../query-hooks/fund-query-hooks/useFund";
import FormComponent from "../../../Components/FormComponent"
import { ErrorLoading, Spinner } from "../../../Helpers/Helpers";
import FundClassForm from "./FundClassForm";

const FundClassFormMutation = () => {
    const { fundId, fundClassId } = useParams();
    const navigate = useNavigate();
    const fund = useFund(fundId);
    const createFundClass = useCreateFundClass();
    const editFundClass = useEditFundClass();
    const allFundClasses = useFundAllClasses(fundId)

    const onSubmit = data => {
        // Create a new data object to avoid posting the fund data that is only to display in the form
        const classData = {  
            className: data.className, 
            description: data.description, 
            dateStart: data.dateStart,
            dateEnd: data.dateEnd === "" ? null : data.dateEnd,
            initialShareValue: data.initialShareValue,
            managementFee: data.managementFee, 
            managementFeeFrequency: data.managementFeeFrequency, 
            performanceFee: data.performanceFee, 
            performanceFeePolicy: data.performanceFeePolicy,
            totalValue: data.totalValue, 
            totalShares: data.totalShares
        }
        fundClassId ?  editFundClass.mutate({ fundId, fundClassId, payload: classData }) : createFundClass.mutate({ fundId, payload: classData })  
    } 
    useEffect(() => { createFundClass.isSuccess && navigate(-1) }, [ createFundClass ])
    useEffect(() => { editFundClass.isSuccess && navigate(-1) }, [ editFundClass ])

    return (
        <FormComponent title="Fund Dashboard" subtitle={fundClassId ? "Edit Fund Class" : "Create Fund Class"}>
            { !fundClassId && <FundClassForm onSubmit={onSubmit} fundData={fund.data} /> }
            { (allFundClasses.isSuccess && fundClassId) && <FundClassForm onSubmit={onSubmit} data={allFundClasses.data.find(fundClass => fundClass.id === fundClassId)} fundData={fund.data} /> }   
            { fund.isLoading && <Spinner /> }
            { fund.isError && <ErrorLoading /> }
        </FormComponent>
    );
};
export default FundClassFormMutation;