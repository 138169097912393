import React, { useState, useEffect } from 'react'
import { useOrderTrades, confirmationAlertDeleteTrade, useDeleteTrade, useOrder, useDeleteManyTrades, confirmationAlertDeleteTrades, useDeleteAllTrades, confirmationAlertDeleteAllTrades } from "../../../query-hooks/order-query-hooks/useOrder"
import { useParams, Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { useAuth } from '../../../Hooks/useAuth'
import styles from "../AllOrders.module.scss"
import { Spinner, dateFormat, ErrorLoading, numberEditor } from '../../../Helpers/Helpers'
import OrderMetadata from '../OrderMetadata'
import EmptyMessageComponent from '../../../Components/EmptyMessageComponent'
import ImportTradesModal from './ImportTradesModal'
import PaginationAPI from '../../../Components/PaginationAPI'
import { AiOutlineDelete } from "react-icons/ai";
import { MdDeleteSweep } from "react-icons/md";
import EditPercentageBar from '../OrderFundings/EditPercentageBar'
import CreatePercentageBar from '../OrderFundings/CreatePercentageBar'

const AllTrades = () => {
    const { darkmode } = useAuth()
    const { orderId } = useParams()
    const [currentItems, setCurrentItems] = useState([]);
    const [ tradesToDelete, setTradesToDelete ] = useState([]) // Array list of trades ids to delete
    const [ importBlockchainButton, setImportBlockchainButton ] = useState(false)
    const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
    const [ page, setPage ] = useState()
    const order = useOrder(orderId)
    const orderTrades = useOrderTrades(orderId, page)
    
    useEffect(() => {
        if (orderTrades.isSuccess) {
            setImportBlockchainButton(orderTrades.data.items.some(trade => trade.transactionId !== "-")) 
        }
    }, [orderTrades.data])
    
    // Delete Trades
    const deleteTrade = useDeleteTrade()
    const deleteManyTrades = useDeleteManyTrades()
    const [ importTradesWindow, setImportTradesWindow ] = useState(false)
    const deleteAllTrades = useDeleteAllTrades()

    // Handler to remove selected trades in checkbox
    const handleTradesToDelete = (tradeId, checked) => checked ? setTradesToDelete([...tradesToDelete, tradeId]) : setTradesToDelete(tradesToDelete.filter(x => x !== tradeId))

    // Select all trades Checkbox handler
    const selectAllCheckbox = bool => {
        if (bool && orderTrades.isSuccess) return setTradesToDelete(orderTrades.data.items.map(trade => trade.id))
        if (!bool) return setTradesToDelete([])
    }
    
    if (orderTrades.isSuccess && orderTrades.data.items.length < 1) {
        return (
            <>
                <OrderMetadata orderId={orderId} orderTrades={orderTrades.data.items} />
                <Table borderless className='my-5'>
                    <tbody>
                        { order.isSuccess && ( order.data.orderFundings.length > 0 ? <EditPercentageBar order={order.data} orderId={orderId} /> : <CreatePercentageBar order={order.data} orderId={orderId} /> ) }
                    </tbody>
                </Table>
                <Link className={darkmode ? styles.createOrderButtonDark : styles.createOrderButton} to={`/main/orders/trades/create-trade/${orderId}`}>Add a Trade</Link>
                <button className={styles.createOrderButtonDark} onClick={() => setImportTradesWindow(true)}>Import Trades from NAV Table</button>
                { importTradesWindow && <ImportTradesModal setImportTradesWindow={setImportTradesWindow} orderId={orderId} /> }
                <EmptyMessageComponent message="No Trades Created" />
            </>
        ) 
    }

    if (orderTrades.isSuccess && orderTrades.data.items.length > 0 ) {
        return (
            <>
                <OrderMetadata orderId={orderId} orderTrades={orderTrades.data.items} />
                <div className={styles.cardDark}>
                <Table borderless className='my-5'>
                    <tbody>
                        { order.isSuccess && ( order.data.orderFundings.length > 0 ? <EditPercentageBar order={order.data} orderId={orderId} /> : <CreatePercentageBar order={order.data} orderId={orderId} /> ) }
                    </tbody>
                </Table>
                <Link className={darkmode ? styles.createOrderButtonDark : styles.createOrderButton} to={`/main/orders/trades/create-trade/${orderId}`}>Add a Trade</Link>
                <button className={styles.createOrderButtonDark} onClick={() => setImportTradesWindow(true)}>Import Trades from NAV Table</button>
                { (importBlockchainButton && order.isSuccess) && 
                    <Link 
                        className={darkmode ? styles.createOrderButtonDark : styles.createOrderButton} 
                        to={`/main/orders/blockchain-import-orders/${orderId}/${order.data.walletAddress}`}
                        state={{ startDateParams: order.data.dateTime.slice(0, 10) }}
                    >Import from Blockchain</Link> }
                { importTradesWindow && <ImportTradesModal setImportTradesWindow={setImportTradesWindow} orderId={orderId} /> }
                { tradesToDelete.length > 0 &&
                    <>
                        <button className={styles.deleteAllTradesButton} onClick={e => confirmationAlertDeleteAllTrades(e, orderId, deleteAllTrades)}>
                            <MdDeleteSweep 
                                className={styles.deleteTradesIcons}
                                style={{color:"#CD5C5C"}}
                            />Delete All Trades
                        </button>
                        <button className={styles.deleteTradesButton} onClick={e => confirmationAlertDeleteTrades(e, orderId, tradesToDelete, deleteManyTrades)}>
                            <AiOutlineDelete 
                                className={styles.deleteTradesIcons} 
                            />Delete Only Selected Trades
                        </button>
                    </>
                }
                <div className={styles.scrollableTable}>
                    <Table borderless={true}>
                        <thead className={styles.stickyHeader}>
                            <tr className={styles.theader}>
                                <th>Date - Time</th>
                                <th>Transaction ID</th>
                                <th>Unit Price</th>
                                <th>Executed</th>
                                <th>Total</th>
                                <th>Fee</th>
                                <th>
                                    <label className={styles.checkbox}>
                                        <input type="checkbox" onClick={e => selectAllCheckbox(e.target.checked)} style={{border:"solid 3px #6D9E93"}} />
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        { currentItems && currentItems.map(trade => (
                            <tbody key={trade.id}>
                                <tr className={darkmode ? styles.tableStripsDark : styles.tableStrips}>
                                    <td>{dateFormat(trade.dateTime)}</td>
                                    <td><a href={trade.transactionUrl} target="_blank" style={{color: "#6D9E93"}}>{ trade.transactionId }</a></td>
                                    <td>{numberEditor(trade.unitPrice)} {order.isSuccess && order.data.quoteAsset.symbol}</td>
                                    <td>{numberEditor(trade.executed)} {order.isSuccess && order.data.baseAsset.symbol}</td>
                                    <td>{numberEditor(trade.total)} {order.isSuccess && order.data.quoteAsset.symbol}</td>
                                    <td>{numberEditor(trade.fee)} <span style={{fontSize:"11px"}}>{trade.feeCurrency && trade.feeCurrency.symbol}</span></td>
                                    <td>
                                        <label className={styles.checkbox}>
                                            <input 
                                                type="checkbox" 
                                                onChange={e => handleTradesToDelete(trade.id, e.target.checked)} 
                                                checked={tradesToDelete.some(x => x === trade.id)}    
                                            />
                                        </label>
                                    </td>
                                    { tradesToDelete.length < 1 && 
                                        <td>
                                            <AiOutlineDelete 
                                                className={styles.icons} 
                                                style={{color:"#CD5C5C"}}
                                                onClick={e => confirmationAlertDeleteTrade(e, orderId, trade.id, deleteTrade)} 
                                                title="Delete" 
                                            />
                                        </td>
                                    }
                                </tr>
                            </tbody>
                        ))}
                    </Table>
                    <PaginationAPI data={orderTrades.data} getCurrentItems={getCurrentItems} setPage={setPage} />
                    </div>
                </div>
            </>
        )
    }
    if (orderTrades.isLoading) return <Spinner />  
    if (orderTrades.isError) return <ErrorLoading />  
}
export default AllTrades