import React, { useState } from 'react';
import styles from "./PercentageBar.module.scss"
import useFunds from "../../../query-hooks/fund-query-hooks/useFund"
import PercentageBarComponent from './PercentageBarComponent';

const PercentageBar = ({ order, orderId }) => {
    const funds = useFunds()
    const [ displayEditButton, setDisplayEditButton ] = useState(true)

    if (displayEditButton) {
        return (
            <tr>
                <td>
                    <button 
                    className={styles.editButton}
                    onClick={() => setDisplayEditButton(false)}>Order Fundings</button>
                </td>
            </tr>
        )
    }

    return <PercentageBarComponent order={order} orderId={orderId} orderFundings={funds.isSuccess && funds.data.items} setDisplayEditButton={setDisplayEditButton} />
};

export default PercentageBar;
