import React, { useEffect } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import styles from "../../../styles/Login.module.scss"
import { useForm } from "react-hook-form";
import Logo from "../../../images/logo1.png";
import { Container, Row, Col } from "react-bootstrap";
import { useDisableMFA, useRecoveryMFA } from "../../../query-hooks/user-query-hooks/useUser";
import { simpleSuccessfulMessage } from "../../../Helpers/Helpers";


const RecoveryMFA = () => {
    const { user, setUser } = useAuth()
    const disableMFA = useDisableMFA()
    const recoveryMFA = useRecoveryMFA()
    const navigate = useNavigate()
    const { register, handleSubmit, setFocus, setValue, watch, formState: { errors }, } = useForm();

    // Effect that disables MFA to receive token in email at rendering
    useEffect(() => { user && disableMFA.mutate(user.email) }, [user])
    
    const onSubmit = data => {
        const inputCodeArray = Object.values(data)
        const inputCode = inputCodeArray.join("")
        recoveryMFA.mutate({ email: user.email, token: inputCode.slice(0, 6), recoveryCode: data.recoveryCode })
    }

    useEffect(() => { 
        if (recoveryMFA.isSuccess) {
            simpleSuccessfulMessage("MFA account has been recovered succesfully")
            setUser(null)  
            navigate("/")
        } 
    }, [recoveryMFA])

    // Effects to focus on next input when fulfilled
    const watchedInputs = watch()
    useEffect(() => { setFocus("inputCode1") }, [])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode1") && (watchedInputs.inputCode1.length > 0 && setFocus("inputCode2") )  }, [watchedInputs])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode2") && (watchedInputs.inputCode2.length > 0 && setFocus("inputCode3") )  }, [watchedInputs])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode3") && (watchedInputs.inputCode3.length > 0 && setFocus("inputCode4") )  }, [watchedInputs])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode4") && (watchedInputs.inputCode4.length > 0 && setFocus("inputCode5") )  }, [watchedInputs])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode5") && (watchedInputs.inputCode5.length > 0 && setFocus("inputCode6") )  }, [watchedInputs])
    useEffect(() => { watchedInputs.hasOwnProperty("inputCode6") && (watchedInputs.inputCode5.length > 0 && setTimeout(() => { setFocus("recoveryCode") }, [1000]) )  }, [watchedInputs])


    // Function to handle password pasted
    const getPastedPassword = (event) => {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text');
        if (pastedText.length === 6) {
            setValue("inputCode1", pastedText.charAt(0))
            setValue("inputCode2", pastedText.charAt(1))
            setValue("inputCode3", pastedText.charAt(2))
            setValue("inputCode4", pastedText.charAt(3))
            setValue("inputCode5", pastedText.charAt(4))
            setValue("inputCode6", pastedText.charAt(5))
        }
        setTimeout(() => {
            setFocus("recoveryCode")
        }, [1000])
    } 

    return (
        <main>
            <div>
                <Container className="d-flex justify-content-center">
                    <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)} autoComplete="off" >
                        <Row>
                            <Col lg={12} className="d-flex justify-content-center">
                                <img className="mb-5" src={Logo} alt="Hodl" />
                            </Col>
                        </Row>
                        <Row className={styles.codeContainer}>
                            <h2 className={styles.inputTitle}>MFA Disable Token Code</h2>
                            <p className={styles.message}>* Paste the 6 digits code sent to your email.</p>
                            <Col lg={1} xs={2} md={2}>
                                <input {...register("inputCode1", { required: true })} maxLength="1" className={styles.codeInput} onPaste={getPastedPassword} />
                                {errors.inputCode1 && ( <p className={styles.formErrorNotice}>Required</p> )}
                            </Col>
                            <Col lg={1} xs={2} md={2}>
                                <input {...register("inputCode2", { required: true })} maxLength="1" className={styles.codeInput} />
                                {errors.inputCode2 && ( <p className={styles.formErrorNotice}>Required</p> )}
                            </Col>
                            <Col lg={1} xs={2} md={2}>
                                <input {...register("inputCode3", { required: true })} maxLength="1" className={styles.codeInput} />
                                {errors.inputCode3 && ( <p className={styles.formErrorNotice}>Required</p> )}
                            </Col>
                            <Col lg={1} xs={2} md={2}>
                                <input {...register("inputCode4", { required: true })} maxLength="1" className={styles.codeInput} />
                                {errors.inputCode4 && ( <p className={styles.formErrorNotice}>Required</p> )}
                            </Col>
                            <Col lg={1} xs={2} md={2}>
                                <input {...register("inputCode5", { required: true })} maxLength="1" className={styles.codeInput} />
                                {errors.inputCode5 && ( <p className={styles.formErrorNotice}>Required</p> )}
                            </Col>
                            <Col lg={1} xs={2} md={2}>
                                <input {...register("inputCode6", { required: true })} maxLength="1" className={styles.codeInput} />
                                {errors.inputCode6 && ( <p className={styles.formErrorNotice}>Required</p> )}
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center my-5">
                            <h2 className={styles.inputTitle}>Recovery Code</h2>
                            <p className={styles.message}>* 8 digit Backup code to recover the Google MFA Account.</p>
                            <Col lg={6}>
                                <input {...register("recoveryCode", { required: true }) } maxLength="8" className={styles.codeInput} />
                            </Col>
                        </Row>
                        <Row className="justify-content-center my-4">
                            <Col lg={4} xs={12}>
                                <button className={styles.registerButton} type="submit">Enter</button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles.linkDiv}>
                                <Link className={styles.link} to="/" onClick={() => setUser(null)}> Back to Login </Link>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </main>
    )
}
export default RecoveryMFA;